/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Button,
    Link,
    Paper, TableCell, TableContainer, TableRow, Typography
} from '@material-ui/core'
import * as React from 'react'
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router-dom'

import Config from '../../config'
import { HomeworkType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { useTableStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'
import FormButtons from '../atoms/FormButtons'
import BasicTable from '../molecules/BasicTable'

interface Props {
    homeworks: HomeworkType[],
    setCreateNew: (openCreateScreen: boolean) => void,
}

const DefaultAssignmentScreen: React.FC<Props> = ({
    homeworks,
    setCreateNew
}) => {
    const [homeworkRow, setHomeworkRow] = React.useState<HomeworkType[]>([])
    const {
        homeworkShared, fetchClassNames, me, classNames
    } = useTeacher()
    React.useEffect(() => {
        if (me) {
            setHomeworkRow(homeworkShared)
        } else {
            setHomeworkRow(homeworks)
        }
    }, [])

    const tableClasses = useTableStyles()
    const history = useHistory()

    React.useEffect(() => {
        if (me) {
            setHomeworkRow(homeworkShared)
        } else {
            setHomeworkRow(homeworks)
        }
    }, [])

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { result, error, loading }:
        {
            result: { title: string, id: string }[][]
            | undefined, error: Error
            | undefined, loading: boolean
        } = fetchClassNames

    // used so that the row onClick is ignored on the href
    const handleClick = (event: any, homeworkId?: string) => {
        if (event.stopPropagation()) {
            event.stopPropagation()
        }
        if (homeworkId) history.push('/lektier/flow', { id: homeworkId })
    }

    if (error) {
        showMessage(error.message)
    }
    return (
        <>
            {!loading && (
                <>
                    <div style={{ marginBottom: 20 }}>

                        <FormButtons
                            createButtonText="Opret en ny lektie"
                            onClickCreate={() => setCreateNew(true)} />
                    </div>
                    <TableContainer className={tableClasses.table} component={Paper}>
                        <Typography variant="h6" style={{ textAlign: 'center' }}>Mine lektier</Typography>
                        {(!homeworkRow || homeworkRow.length === 0) ? <Typography>Ingen lektier fundet.</Typography>
                            : (
                                <BasicTable headerNames={['Lektie navn', 'URL', 'Klasser', 'Udløbsdato', '']}>
                                    {/* eslint-disable-next-line @typescript-eslint/no-unused-vars */}
                                    {homeworkRow && homeworkRow.map((item: any, index) => (
                                        <TableRow
                                            style={{ cursor: 'pointer' }}
                                            key={item.id}
                                            hover
                                            onClick={() => history.push(`lektier/${item.id}`)}>
                                            <TableCell component="th" scope="row"><b>{item.name}</b></TableCell>
                                            {item.url && (
                                                <TableCell component="th" scope="row">
                                                    <Link
                                                        target="_blank"
                                                        href={`${Config.StudentUri}/lektie/${item.id}`}
                                                        // used to not click on the assignment row
                                                        onClick={(event: any) => handleClick(event)}>
                                                        <b>/{item.id}</b>
                                                    </Link>
                                                </TableCell>
                                            )}
                                            <TableCell component="th" scope="row">
                                                {
                                                    me ? (classNames && classNames[index]
                                                        && classNames[index].map((className: {
                                                            title: string,
                                                            id: string
                                                        }, commaIndex: number) => (
                                                            <Typography key={className.id} variant="body2">
                                                                <Link onClick={() => history.push('klasser', {
                                                                    class: item.classIds[commaIndex]
                                                                })}>
                                                                    <b>{className.title}</b>
                                                                </Link>
                                                                {classNames[index].length - 1 === commaIndex
                                                                    ? ''
                                                                    : ','}
                                                            </Typography>
                                                        )))
                                                        : (result && result[index]
                                                            && result[index].map((className: {
                                                                title: string,
                                                                id: string
                                                            }, commaIndex: number) => (
                                                                <Typography key={className.id} variant="body2">
                                                                    <Link onClick={() => history.push('klasser', {
                                                                        class: item.classIds[commaIndex]
                                                                    })}>
                                                                        <b>{className.title}</b>
                                                                    </Link>
                                                                    {result[index].length - 1 === commaIndex
                                                                        ? ''
                                                                        : ','}
                                                                </Typography>
                                                            )))
                                                }
                                            </TableCell>
                                            <TableCell component="th" scope="row">
                                                {/* reversed to correct the format */}
                                                <b>{item.expirationDate.split('-').reverse().join('.')}</b>
                                            </TableCell>
                                            <TableCell align="right">
                                                <Button
                                                    style={{ textTransform: 'none' }}
                                                    size="small"
                                                    onClick={(event: any) => handleClick(event, item.id)}
                                                    variant="contained"
                                                    color="primary">
                                                    Se lektie
                                                </Button>
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                </BasicTable>
                            )}
                    </TableContainer>
                    <ReactPlayer
                        style={{ marginTop: 120, zIndex: 9, position: 'relative', }}
                        url="https://player.vimeo.com/video/780136130"
                        width="100%"
                        controls />
                </>
            )}
        </>
    )
}
export default DefaultAssignmentScreen
