import {
    Button,
    createStyles,
    createTheme,
    Grid,
    makeStyles,
    Popover,
    Snackbar,
    TextField,
    ThemeProvider,
    Typography
} from '@material-ui/core'
import { lightBlue, red } from '@material-ui/core/colors'
import HelpIcon from '@material-ui/icons/Help'
import LinkIcon from '@material-ui/icons/Link'
import QRCode from 'qrcode'
import * as React from 'react'
import { useAsync } from 'react-async-hook'

import Config from '../../config'
import {
    CommunityQuizType, HomeworkType, QuizLibraryType, VideoType
} from '../../types/types'
import {
    getHomework,
    getHomeWorkClasses,
    getHomeWorkQuizzes,
    getHomeWorkVideos,
    updateHomeworkClasses,
    updateHomeworkDate,
    updateHomeworkPrivacy,
    updateHomeworkQuizzes,
    updateHomeworkVideos
} from '../../utils/Services/homework-service'
import { useTeacher } from '../../utils/TeacherManager'
import { fireWrap } from '../../utils/wrap-functions'
import GoBackHeader from '../atoms/GoBackHeader'
import SwitchWithSideText from '../atoms/SwitchWithSideText'
import AddClassDialog from '../molecules/AddClassDialog'
import { AnswersModal } from '../molecules/AnswersModal'
import HomeworkClassTable from '../molecules/HomeworkClassTable'
import HomeworkItemsTable from '../molecules/HomeworkItemsTable'
import { Loading } from '../molecules/Loading'
import { drawerWidth } from '../wrappers/BodyWithTopBar'

interface Props {
    headerText: string,
    selectedId: string,
    isSelected: (item: any) => void
    goBack?: () => void
}

const theme = createTheme({
    palette: {
        primary: lightBlue,
        secondary: red,
    },
})

const useStyles = makeStyles(() => createStyles({
    text: {
        textAlign: 'center'
    },
    gridContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    addClassLink: {
        margin: 'auto', marginTop: 15, marginBottom: '3%', cursor: 'pointer'
    },
    copyLinkButton: {
        display: 'flex', alignItems: 'center', cursor: 'pointer'
    },
    centeredSnackbar: {
        [theme.breakpoints.up('md')]: {
            marginLeft: drawerWidth / 2
        },
    }
}))

const UpdateAssignmentComponent: React.FC<Props> = (
    {
        headerText, selectedId, isSelected, goBack
    }
) => {
    const classes = useStyles()
    const {
        fetchClasses, fetchClassNames, fetchHomeworks
    } = useTeacher()
    const fetchHomeworkClasses = useAsync(getHomeWorkClasses, [selectedId])

    const [open, setOpen] = React.useState(false)
    const [copied, setCopied] = React.useState(false)
    const [homeworkVideos, setHomeworkVideos] = React.useState<VideoType[]>([])
    const [homeworkQuizzes, setHomeworkQuizzes] = React.useState<QuizLibraryType[]>([])
    const [defaultCheckedState, setDefaultCheckedState] = React.useState<boolean[]>([])
    const [imageUrl, setImageUrl] = React.useState('')

    const [selected, setSelected] = React.useState<HomeworkType>()
    const [loading, setLoading] = React.useState(false)

    const [modalIsOpen, setModalIsOpen] = React.useState(false)
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const popoverOpen = Boolean(anchorEl)

    const onDelete = async (classIdToBeDeleted: string) => {
        if (!fetchHomeworkClasses.result) return
        const indexToBeDeleted = fetchHomeworkClasses.result.map((item) => item.id).indexOf(classIdToBeDeleted)
        if (indexToBeDeleted >= 0) fetchHomeworkClasses.result.splice(indexToBeDeleted, 1)
        setLoading(true)
        await updateHomeworkClasses(selectedId, fetchHomeworkClasses.result.map((item) => item.id),
            [...selected!.exceptions.filter((exception) => exception.id !== classIdToBeDeleted)])
        // to update class names on homework screen
        fetchClassNames.execute()
        setLoading(false)
    }
    const [selectedClass, setSelectedClass] = React.useState('')
    const onUpdate = async (classIdToBeUpdated: string) => {
        setOpen(true)
        setSelectedClass(classIdToBeUpdated)
    }

    const onVideoItemDelete = async (videoIdToBeDeleted: string) => {
        if (!selected?.videos) return
        const indexToBeDeleted = selected.videos.indexOf(videoIdToBeDeleted)
        if (indexToBeDeleted >= 0) selected.videos.splice(indexToBeDeleted, 1)
        setLoading(true)
        await updateHomeworkVideos(selectedId, selected.videos)
        // to update homeworks visible for library items
        fetchHomeworks.execute()
        const fetchedVideos = await getHomeWorkVideos(selectedId)
        setLoading(false)
        setHomeworkVideos(fetchedVideos)
    }

    const onQuizItemDelete = async (quizIdToBeDeleted: string) => {
        if (!selected?.quizzes) return
        const indexToBeDeleted = selected.quizzes.indexOf(quizIdToBeDeleted)
        setLoading(true)
        if (indexToBeDeleted >= 0) {
            selected.quizzes.splice(indexToBeDeleted, 1)
            await updateHomeworkQuizzes(selectedId, selected.quizzes)
            // to update homeworks visible for library items
            fetchHomeworks.execute()
            const fetchedQuizzes = await getHomeWorkQuizzes(selectedId)
            setHomeworkQuizzes(fetchedQuizzes)
        }
        setLoading(false)
    }

    const addClasses = async (
        { checkedClasses, exclusions }: { checkedClasses: string[]; exclusions: { id: string }[] }
    ) => {
        let homeworkClassesToBeAdded = checkedClasses
        if (fetchHomeworkClasses.result && fetchHomeworkClasses.result.length > 0) {
            homeworkClassesToBeAdded = fetchHomeworkClasses.result.map((item) => item.id)
                .filter((item) => !checkedClasses.includes(item))
                .concat(checkedClasses.map((check) => check))
        }
        setLoading(true)
        await updateHomeworkClasses(selectedId, homeworkClassesToBeAdded,
            [...(selected?.exceptions
                ?.filter((item) => {
                    return item.id && !exclusions.map((e) => e.id).includes(item.id)
                }) || []), ...exclusions])
        setLoading(false)
        // to update selected classes
        fetchHomeworkClasses.execute(selectedId)
        // to update class names of homeworks on home screen
        fetchClassNames.execute()
        window.location.reload()
        setOpen(false)
    }

    const updateDate = async (date: string) => {
        if (selected) {
            await updateHomeworkDate(selectedId, date)
            // to update date on default homework screen
            fetchHomeworks.execute()
        }
    }

    const handleUpdatePrivacy = async (value: boolean) => {
        if (selected) {
            await updateHomeworkPrivacy(selectedId, value)
            // to update privacy on default homework screen
            fetchHomeworks.execute()
        }
    }

    const copy = async () => {
        if (selected && selected.url) {
            await navigator.clipboard.writeText(`${Config.StudentUri}/lektie/${selected.id}`)
            setCopied(true)
        }
    }

    const handleClose = (event: any, reason: any) => {
        if (reason === 'clickaway') return
        setCopied(false)
    }

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            const selectedAssignment = await getHomework(selectedId)
            if (selectedAssignment) {
                let fetchedVideos: VideoType[] = []
                let fetchedQuizzes: QuizLibraryType[] = []
                if (selectedAssignment.quizzes.length > 0) {
                    fetchedQuizzes = await getHomeWorkQuizzes(selectedId)
                }
                if (selectedAssignment.videos.length > 0 && selectedAssignment.quizzes.length === 0) {
                    fetchedVideos = await getHomeWorkVideos(selectedId)
                }
                setSelected(selectedAssignment)
                setHomeworkVideos(fetchedVideos)
                setHomeworkQuizzes(fetchedQuizzes)
            }
            setLoading(false)
        })()
    }, [selectedId])

    React.useEffect(() => {
        if (fetchClasses.result) setDefaultCheckedState(new Array(fetchClasses.result.length).fill(false))
    }, [fetchClasses.result])
    React.useEffect(() => {
        fireWrap(async () => {
            // to get selected homework classes when homework changes
            fetchHomeworkClasses.execute(selectedId)
        }, {
            operation: 'Loading Results',
            stateFunc: setLoading
        })
    }, [selectedId, selectedClass])

    const isLoading = () => {
        return loading || fetchHomeworkClasses.loading || fetchClasses.loading || fetchClassNames.loading
            || fetchHomeworks.loading
    }
    React.useEffect(() => {
        (async () => {
            if (selected) {
                const response = await QRCode.toDataURL(`${Config.StudentUri}/lektie/${selected.id}`)
                setImageUrl(response)
            }
        })()
    }, [selected])

    if (isLoading()) {
        return <Loading />
    }

    console.log({ selected })

    return (
        <ThemeProvider theme={theme}>
            <>
                {selected && (
                    <>
                        <GoBackHeader
                            headerText={headerText}
                            onClick={() => (
                                goBack ? goBack() : isSelected(null)
                            )} />
                        <Grid container>
                            <Grid container style={{ marginBottom: 20 }}>
                                <Grid item xs={12} sm={6} lg={2}>
                                    <Typography variant="h6" className={classes.text}>
                                        <b>Lektie navn</b>
                                    </Typography>
                                    <Typography variant="h6" className={classes.text}>{selected.name}</Typography>
                                </Grid>
                                <Grid className={classes.gridContainer} item xs={12} sm={6} lg={3}>
                                    <Typography variant="h6" className={classes.text}><b>Udløbsdato</b></Typography>
                                    <TextField
                                        id="date"
                                        label="Udløbsdato"
                                        type="date"
                                        defaultValue={selected.expirationDate}
                                        onChange={(event) => updateDate(event.target.value)}
                                        InputLabelProps={{
                                            shrink: true,
                                        }} />
                                </Grid>
                                <Grid item xs={12} sm={6} lg={2}>
                                    <SwitchWithSideText
                                        falseText="Med elevlogin"
                                        trueText="Uden elevlogin"
                                        checked={selected.publicHomeworkForStudents}
                                        setChecked={(value) => {
                                            setSelected({ ...selected, publicHomeworkForStudents: value })
                                            handleUpdatePrivacy(value)
                                        }} />
                                    <div style={{ width: '100%', display: 'flex' }}>
                                        <Typography
                                            aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                                            aria-haspopup="true"
                                            onMouseEnter={handlePopoverOpen}
                                            onMouseLeave={handlePopoverClose}
                                            style={{
                                                cursor: 'pointer',
                                                color: '#03a9f4',
                                                marginLeft: 'auto',
                                                marginRight: 'auto'
                                            }}>
                                            <HelpIcon />
                                        </Typography>
                                        <Popover
                                            id="mouse-over-popover"
                                            style={{ pointerEvents: 'none' }}
                                            disableAutoFocus
                                            disableEnforceFocus
                                            open={popoverOpen}
                                            anchorEl={anchorEl}
                                            anchorOrigin={{
                                                vertical: 'bottom',
                                                horizontal: 'left',
                                            }}
                                            transformOrigin={{
                                                vertical: 'top',
                                                horizontal: 'left',
                                            }}
                                            onClose={handlePopoverClose}>
                                            <Typography style={{ paddingLeft: 10, paddingRight: 10 }}>
                                                <p><b>Med elevlogin</b> betyder at eleverne SKAL logge ind for<br />
                                                    at se quizzer/videoer.<br />
                                                </p>
                                                <p><b>Uden elevlogin</b> betyder at eleverne IKKE behøver at<br />
                                                    logge ind, men at de i stedet får et midlertidigt <br />
                                                    brugernavn. (en anonym nørd)
                                                </p>
                                            </Typography>
                                        </Popover>

                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={6} lg={2}>
                                    <div
                                        style={{
                                            display: 'flex',
                                            justifyContent: 'center',
                                            alignItems: 'center'
                                        }}>
                                        <a href={imageUrl} download>
                                            <img
                                                style={{
                                                    cursor: 'pointer'
                                                }}
                                                src={imageUrl}
                                                alt="qrcode"
                                                title="Hent" />
                                        </a>
                                    </div>
                                </Grid>
                                <Grid item xs={12} sm={12} lg={3} className={classes.gridContainer}>
                                    {homeworkQuizzes.length > 0 && (
                                        <Button
                                            style={{ marginBottom: 15, textTransform: 'none' }}
                                            variant="contained"
                                            color="primary"
                                            onClick={() => setModalIsOpen(true)}>Se elevernes svar
                                        </Button>
                                    )}
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={copy}
                                        startIcon={<LinkIcon />}
                                        className={classes.copyLinkButton}>
                                        <Typography
                                            variant="h5"
                                            style={{ textTransform: 'none' }}
                                            color="textPrimary">Kopier elev-link
                                        </Typography>
                                    </Button>
                                </Grid>
                            </Grid>

                            <HomeworkClassTable
                                onUpdate={onUpdate}
                                onDelete={onDelete}
                                homeworkClasses={fetchHomeworkClasses.result || []} />

                            <Button
                                onClick={() => {
                                    setSelectedClass('ignore')
                                    setOpen(true)
                                }}
                                className={classes.addClassLink}>
                                Tilføj klasse
                            </Button>

                            <Grid container>
                                {homeworkVideos.length > 0
                                    ? (
                                        <Grid item xs={12} sm={6}>
                                            <HomeworkItemsTable
                                                header="Videos"
                                                onItemDelete={onVideoItemDelete}
                                                items={homeworkVideos} />
                                        </Grid>
                                    )
                                    : homeworkQuizzes.length > 0
                                    && (
                                        <Grid item xs={12} sm={6}>
                                            <HomeworkItemsTable
                                                header="Quizzes"
                                                onItemDelete={onQuizItemDelete}
                                                items={homeworkQuizzes} />
                                        </Grid>
                                    )}
                            </Grid>
                        </Grid>
                        {(defaultCheckedState.length
                            === selected.classIds.length) || defaultCheckedState.length > 0
                            ? (
                                <AddClassDialog
                                    selectedClass={selectedClass}
                                    selectedId={selectedId}
                                    open={open}
                                    setOpen={setOpen}
                                    confirmSelection={addClasses}
                                    selectedClassIds={(fetchHomeworkClasses.result
                                        && fetchHomeworkClasses.result.map((item) => item.id)) || []}
                                    allClasses={fetchClasses.result}
                                    defaultCheckedState={defaultCheckedState} />
                            )
                            // eslint-disable-next-line no-console
                            : console.log('No classes created!')}
                        {copied && (
                            <Snackbar
                                open={copied}
                                className={classes.centeredSnackbar}
                                onClose={handleClose}
                                autoHideDuration={2000}
                                message="Kopieret!" />
                        )}
                        {modalIsOpen && (
                            <AnswersModal
                                homeworkQuizzes={homeworkQuizzes as CommunityQuizType[]}
                                onClose={() => setModalIsOpen(false)}
                                isOpen={modalIsOpen}
                                selectedId={selectedId}
                                headerText={`Resultater - ${selected.name}`} />
                        )}
                    </>
                )}
            </>
        </ThemeProvider>
    )
}
export default UpdateAssignmentComponent
