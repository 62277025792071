/* eslint-disable @typescript-eslint/no-unused-vars */
import { makeStyles } from '@material-ui/core/styles'

export const priceContainerStyle = makeStyles((theme) => ({
    container: {
        height: '100%',
        width: '100%',
        display: 'flex',
        marginTop: 10,
        flexDirection: 'column',
        alignItems: 'center',
        borderRadius: '5px',
        paddingTop: 12,
        backgroundColor: '#FFF',
        paddingBottom: 12,
        paddingLeft: 0,
        cursor: 'pointer',
        paddingRight: 0,
        fontFamily: 'Encode, sans-serif',
    },
    boxTittle: {
        textAlign: 'center',
        fontSize: 20,
    },
    priceContainer: {
        paddingTop: '1vh',
        paddingBottom: '1vh',
        paddingLeft: 10,
        paddingRight: 10,
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
        flexWrap: 'wrap'
    },
    priceText: {
        color: '#FFF',
        textAlign: 'center',
    },
    priceMonth: {
        padding: 3,
        color: '#FFF',
        fontSize: 16,
        marginTop: 10
    },
    ul: {
        display: 'flex',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        flexDirection: 'column'
    },
    li: {
        listStyle: 'none',
        display: 'flex',
        fontFamily: 'Encode, sans-serif',
        justifyContent: 'flex-start',
        flexDirection: 'column',
        padding: 10,
        fontWeight: 300
    },
    items: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center'
    },
    button: {
        color: '#FFF',
        fontWeight: 500
    },
    button1: {
        color: '#FFF',
        fontWeight: 500,
        width: '100%'
    },
    priceSentence: {
        fontWeight: 300,
        paddingLeft: 20,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center'
    }
}))
