import { Paper, TableContainer, Typography } from '@material-ui/core'
import * as React from 'react'

import { BottomBar } from '../../components/molecules/BottomBar'
import { ContactForm } from '../../components/molecules/ContactForm'
import { Logo } from '../../components/molecules/Logo'
import { SchoolPlansComponents } from '../../components/organisms/SchoolPlansComponents'
import { Body } from '../../components/wrappers/Body'
import { useTableStyles } from '../../utils/styles'

interface Props {
}

export const SchoolPlans: React.FC<Props> = () => {
    const tableClasses = useTableStyles()

    return (
        <div className={tableClasses.authBackground}>
            <Body>
                <div style={{ paddingTop: 100 }}>

                    <TableContainer
                        style={{ backgroundColor: 'rgba(252,252,252,0.9)' }}
                        className={tableClasses.table}
                        component={Paper}>
                        <div className={tableClasses.authLogoContainer2}>
                            <Logo />
                        </div>
                        <Typography variant="h4" style={{ textAlign: 'center', marginTop: 20, marginBottom: 50 }}>
                            Skolens Prisplaner
                        </Typography>
                        <div>
                            <SchoolPlansComponents />
                        </div>
                        <div>
                            <Typography variant="h4" style={{ textAlign: 'center', marginTop: 20 }}>
                                Kontakt os ift. betaling med EAN
                            </Typography>
                            <div style={{ maxWidth: '80%', margin: 'auto', paddingBottom: 50 }}>
                                <ContactForm />
                            </div>

                        </div>
                    </TableContainer>
                </div>
            </Body>
            <BottomBar onlyPicture />
        </div>

    )
}
