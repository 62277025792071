import * as React from 'react'

import { useLoadingManager } from '../../utils/hooks'
import {
    createInitiallyCommunityQuiz,
} from '../../utils/Services/community-quiz-service'
import Alert from '../atoms/Alert'
import CreateScreenTemplate from '../molecules/CreateScreenTemplate'
import { FirstCreateCommunityQuizStep } from '../molecules/FirstCreateCommunityQuizStep'
import { Loading } from '../molecules/Loading'

interface Props {
    setCreateNew: (boolean: boolean) => void,
    setFinishCreatingId: (id: string) => void,
    onQuizCreate: () => void
}

const FirstCreateCommunityQuizScreen: React.FC<Props> = ({ setCreateNew, setFinishCreatingId, onQuizCreate }) => {
    const { loading, startLoading, stopLoading } = useLoadingManager()

    const [title, setTitle] = React.useState<string>('')
    const [category, setCategory] = React.useState<string[]>([])
    const [createError, setCreateError] = React.useState<boolean>(false)
    const [topics, setTopics] = React.useState<string[]>([])
    const [selectedGrades, setSelectedGrades] = React.useState<string[]>([])
    const [openQuiz, setOpenQuiz] = React.useState<boolean>(false)
    const [publicQuiz, setPublicQuiz] = React.useState<boolean>(false)

    const [errorMessage, setErrorMessage] = React.useState<string>('')

    const handleGoBack = () => {
        setCreateNew(false)
    }
    const onCreate = async () => {
        if (!validateForm()) { setCreateError(true); setErrorMessage('Please fill out all the inputs'); return }
        const filteredGrades = selectedGrades.flatMap((grade) => {
            if (!Number.isNaN(parseInt(grade.slice(0, -8), 10))) return parseInt(grade.slice(0, -8), 10)
            return []
        })
        startLoading()
        const createdQuiz = await createInitiallyCommunityQuiz(title, topics, category,
            filteredGrades, publicQuiz, !openQuiz)
        onQuizCreate()
        stopLoading()
        setTitle('')
        setCreateNew(false)
        setFinishCreatingId(createdQuiz.id)
    }
    const validateForm = () => {
        if (title.length > 0) return true
        return false
    }

    if (loading) {
        return <Loading />
    }

    return (
        <CreateScreenTemplate
            headerText="Ny quiz"
            goBackFunction={handleGoBack}
            goBackText="Mine quizzer"
            createButtonText="Næste"
            setCreateNew={(boolean: boolean) => setCreateNew(boolean)}
            create={onCreate}
            disableButtons={loading}
            disableCreate={!validateForm()}>
            <FirstCreateCommunityQuizStep
                title={title}
                setTitle={setTitle}
                topics={topics}
                categories={category}
                setTopics={setTopics}
                setCategory={setCategory}
                openQuiz={openQuiz}
                setOpenQuiz={setOpenQuiz}
                publicQuiz={publicQuiz}
                setPublicQuiz={setPublicQuiz}
                selectedGrades={selectedGrades}
                setSelectedGrades={setSelectedGrades} />

            <Alert
                text={errorMessage}
                setOpen={setCreateError}
                open={createError}
                type="alert" />
        </CreateScreenTemplate>
    )
}
export default FirstCreateCommunityQuizScreen
