import {
    createStyles, Grid, Link, makeStyles,
    Typography
} from '@material-ui/core'
import * as React from 'react'

interface Props {
    boldText: string,
    goText?: string,
    smallText?: string,
    onClick: () => void
}

const useStyles = makeStyles(() => createStyles({
    linkToClasses: {
        display: 'flex',
        justifyContent: 'space-between',
        borderBottom: '1px solid rgb(211,211,211)',
    },
    rowStyle: {
        display: 'table',
        tableLayout: 'fixed',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        width: '100%',
    },
    rowStyleContent: {
        display: 'table-cell',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
    }
}),)

const ClassListItem: React.FC<Props> = ({
    boldText, goText, smallText, onClick
}) => {
    const classes = useStyles()
    return (
        <Grid item xs={12} className={classes.linkToClasses}>
            <div style={{
                display: 'flex', flexDirection: 'column',

            }}>
                <Typography className={classes.rowStyle}><b className={classes.rowStyleContent}>{boldText}</b>
                </Typography>

                {smallText && <Typography variant="caption">{smallText}</Typography>}
            </div>
            {goText ? <Typography color="textSecondary">{goText} {'>'}</Typography>
                : (
                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                    <Link onClick={onClick} style={{ cursor: 'pointer' }}>
                        <Typography color="textSecondary">Gå&nbsp;{'>'}</Typography>
                    </Link>
                )}
        </Grid>
    )
}
export default ClassListItem
