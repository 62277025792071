import {
    Button, createStyles, IconButton, makeStyles, Typography
} from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import * as React from 'react'

interface Props {
    headerText?: string,
    buttonText?: string,
    disabled?: boolean,
    onClick: () => void,
    hasMinWidth?: boolean
}

const GoBackHeader: React.FC<Props> = ({
    disabled, headerText, buttonText, onClick, hasMinWidth = true
}) => {
    const useStyles = makeStyles(() => createStyles({
        headerContainer: {
            minWidth: hasMinWidth ? 550 : 'auto',
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            marginBottom: 20,
            flexWrap: 'wrap'
        }
    }))
    const classes = useStyles()
    return (
        <div className={classes.headerContainer}>
            {buttonText ? (
                <Button disabled={disabled} variant="outlined" onClick={onClick} startIcon={<ArrowBackIcon />}>
                    {buttonText}
                </Button>
            )
                : (
                    <IconButton disabled={disabled} style={{ marginRight: 19 }} onClick={onClick}>
                        <ArrowBackIcon />
                    </IconButton>
                )}
            {headerText
                && (
                    <Typography
                        variant="h5"
                        style={{
                            margin: 'auto',
                            left: -24 - (buttonText ? buttonText.length : 0) * 3,
                            position: 'relative'
                        }}>
                        {headerText}
                    </Typography>
                )}
        </div>
    )
}
export default GoBackHeader
