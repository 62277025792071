import '../../styles/style.css'

import {
    Grid, TableContainer, Typography
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import ScreeRotateIcon from '@material-ui/icons/ScreenRotation'
import * as React from 'react'
import { useHistory, useParams } from 'react-router-dom'

import Logo from '../../assets/LogoGeekster.png'
import { BackgroundImage } from '../../components/atoms/BackgroundImage'
import FAB from '../../components/atoms/Fab'
import { Loading } from '../../components/molecules/Loading'
import { pageStyles } from '../../styles/PageStyle'
import { pdfStyles } from '../../styles/PDFStyle'
import Routes from '../../utils/Routes'
import { downloadPDFBase64, getPDFById } from '../../utils/Services/pdf-service'

interface Props {

}
export const PDFSharePage: React.FC<Props> = () => {
    const classes = pageStyles()
    const history = useHistory()
    const pdfClasses = pdfStyles()
    const [pdfFile, setPdfFile] = React.useState('')
    const [loading, setLoading] = React.useState(false)
    const [base64, setBase64] = React.useState('')
    const [fileName, setFileName] = React.useState('')

    const { pdfPath }: { pdfPath: string | undefined } = useParams()

    const isUrlFound = async (url: string) => {
        try {
            const response = await fetch(url, {
                method: 'HEAD',
                cache: 'no-cache'
            })

            return response.status === 200

        } catch (error) {
            // console.log(error);
            return false
        }
    }
    React.useEffect(() => {
        setLoading(true);
        (async () => {
            if (!pdfPath) return
            const pdfDoc = await getPDFById(pdfPath)
            const myFile = pdfDoc.mainFile.uri
            if (await isUrlFound(myFile)) {
                setPdfFile(myFile)
            }
        })()
        setLoading(false)
    }, [pdfPath])

    if (loading) {
        return <Loading />
    }

    React.useEffect(() => {
        if (!base64 || !fileName) return;
        (() => {
            const downloadLink = document.createElement('a')
            downloadLink.href = base64
            downloadLink.download = fileName
            downloadLink.click()
            setFileName('')
        })()
    }, [base64, fileName])

    return (
        <div className={pdfClasses.paperContainer}>
            <Box style={{ width: '100%' }} height={85} display="flex" alignItems="center">
                <Box justifyContent="center" pl={2} mr={5}>
                    <img style={{ width: 60, height: 60 }} src={Logo} alt="Geekster Logo" />
                </Box>
                <Box justifyContent="center" mr={5}>
                    <Button
                        variant="contained"
                        startIcon={<ClearIcon />}
                        size="small"
                        onClick={() => history.push(Routes.Login.path)}>Forlad kopi-ark
                    </Button>
                </Box>
                <FAB Icon={ScreeRotateIcon} />
            </Box>

            <TableContainer style={{ overflow: 'hidden' }} className={classes.table} component={Box}>
                <Button
                    style={{ marginLeft: 'auto', marginRight: 'auto', backgroundColor: '#fff' }}
                    variant="outlined"
                    color="primary"
                    onClick={async () => {
                        if (!pdfPath) return
                        const pdf = await downloadPDFBase64(pdfPath)
                        setBase64(pdf)
                        setFileName('download.pdf')
                    }}
                    startIcon={<CloudDownloadIcon />}>
                    <Typography
                        variant="h5"
                        style={{ textTransform: 'none' }}
                        color="textPrimary">Download
                    </Typography>
                </Button>
                <Grid
                    container
                    justifyContent="center"
                    alignItems="center"
                    className={classes.gridContainer}>
                    <embed
                        src={`${pdfFile}#toolbar=0`}
                        style={{ width: '100%', minHeight: 800 }}
                        type="application/pdf" />
                </Grid>
            </TableContainer>
            <BackgroundImage />
        </div>
    )
}
