const checkTableForMicro = [
    [
        { index: 1, side: 'right', otherSide: 'left' },
        { index: 3, side: 'bottom', otherSide: 'top' },
    ],
    [
        { index: 0, side: 'left', otherSide: 'right' },
        { index: 2, side: 'right', otherSide: 'left' },
        { index: 4, side: 'bottom', otherSide: 'top' },
    ],
    [
        { index: 1, side: 'left', otherSide: 'right' },
        { index: 5, side: 'bottom', otherSide: 'top' },
    ],
    [
        { index: 0, side: 'top', otherSide: 'bottom' },
        { index: 4, side: 'right', otherSide: 'left' },
        { index: 6, side: 'bottom', otherSide: 'top' },
    ],
    [
        { index: 3, side: 'left', otherSide: 'right' },
        { index: 5, side: 'right', otherSide: 'left' },
        { index: 1, side: 'top', otherSide: 'bottom' },
        { index: 7, side: 'bottom', otherSide: 'top' },
    ],
    [
        { index: 2, side: 'top', otherSide: 'bottom' },
        { index: 4, side: 'left', otherSide: 'right' },
        { index: 8, side: 'bottom', otherSide: 'top' },
    ],
    [
        { index: 7, side: 'right', otherSide: 'left' },
        { index: 3, side: 'top', otherSide: 'bottom' },
    ],
    [
        { index: 6, side: 'left', otherSide: 'right' },
        { index: 8, side: 'right', otherSide: 'left' },
        { index: 4, side: 'top', otherSide: 'bottom' },
    ],
    [
        { index: 7, side: 'left', otherSide: 'right' },
        { index: 5, side: 'top', otherSide: 'bottom' },
    ],
]

const checkTableForMini = [
    [
        { index: 1, side: 'right', otherSide: 'left' },
        { index: 4, side: 'bottom', otherSide: 'top' },
    ],
    [
        { index: 0, side: 'left', otherSide: 'right' },
        { index: 2, side: 'right', otherSide: 'left' },
        { index: 5, side: 'bottom', otherSide: 'top' },
    ],
    [
        { index: 1, side: 'left', otherSide: 'right' },
        { index: 3, side: 'right', otherSide: 'left' },
        { index: 6, side: 'bottom', otherSide: 'top' },
    ],
    [
        { index: 2, side: 'left', otherSide: 'right' },
        { index: 7, side: 'bottom', otherSide: 'top' },
    ],
    [
        { index: 5, side: 'right', otherSide: 'left' },
        { index: 0, side: 'top', otherSide: 'bottom' },
        { index: 8, side: 'bottom', otherSide: 'top' },
    ],
    [
        { index: 4, side: 'left', otherSide: 'right' },
        { index: 6, side: 'right', otherSide: 'left' },
        { index: 9, side: 'bottom', otherSide: 'top' },
        { index: 1, side: 'top', otherSide: 'bottom' },
    ],
    [
        { index: 5, side: 'left', otherSide: 'right' },
        { index: 7, side: 'right', otherSide: 'left' },
        { index: 10, side: 'bottom', otherSide: 'top' },
        { index: 2, side: 'top', otherSide: 'bottom' },
    ],
    [
        { index: 6, side: 'left', otherSide: 'right' },
        { index: 11, side: 'bottom', otherSide: 'top' },
        { index: 3, side: 'top', otherSide: 'bottom' },
    ],
    [
        { index: 9, side: 'right', otherSide: 'left' },
        { index: 4, side: 'top', otherSide: 'bottom' },
        { index: 12, side: 'bottom', otherSide: 'top' },
    ],
    [
        { index: 8, side: 'left', otherSide: 'right' },
        { index: 10, side: 'right', otherSide: 'left' },
        { index: 13, side: 'bottom', otherSide: 'top' },
        { index: 5, side: 'top', otherSide: 'bottom' },
    ],
    [
        { index: 9, side: 'left', otherSide: 'right' },
        { index: 11, side: 'right', otherSide: 'left' },
        { index: 14, side: 'bottom', otherSide: 'top' },
        { index: 6, side: 'top', otherSide: 'bottom' },
    ],
    [
        { index: 10, side: 'left', otherSide: 'right' },
        { index: 15, side: 'bottom', otherSide: 'top' },
        { index: 7, side: 'top', otherSide: 'bottom' },
    ],
    [
        { index: 13, side: 'right', otherSide: 'left' },
        { index: 8, side: 'top', otherSide: 'bottom' },
    ],
    [
        { index: 12, side: 'left', otherSide: 'right' },
        { index: 14, side: 'right', otherSide: 'left' },
        { index: 9, side: 'top', otherSide: 'bottom' },
    ],
    [
        { index: 13, side: 'left', otherSide: 'right' },
        { index: 15, side: 'right', otherSide: 'left' },
        { index: 10, side: 'top', otherSide: 'bottom' },
    ],
    [
        { index: 14, side: 'left', otherSide: 'right' },
        { index: 11, side: 'top', otherSide: 'bottom' },
    ],
]

function microSolver(puzzleList, activePice, placeIndex) {
    const checks = checkTableForMicro[placeIndex]
    //   console.log(puzzleList);
    return checks.every((check) => {
        const piceToCheck = puzzleList[check.index]
        // console.log(piceToCheck);
        if (piceToCheck && !piceToCheck.id) {
            return true
        } else {
            const { data } = piceToCheck
            const activeSide = activePice.data[check.side]
            const otherSide = data[check.otherSide]
            if (activePice.data.id === data.id) {
                return true
            }
            //   console.log(activeSide.connect_id, otherSide.connect_id);
            if (activeSide.connect_id !== otherSide.connect_id) {
                return false
            }
        }
        return true
    })
}

function miniSolver(puzzleList, activePice, placeIndex) {
    const checks = checkTableForMini[placeIndex]
    //   console.log(puzzleList);
    return checks.every((check) => {
        const piceToCheck = puzzleList[check.index]
        // console.log(piceToCheck);
        if (piceToCheck && !piceToCheck.id) {
            return true
        } else {
            const { data } = piceToCheck
            const activeSide = activePice.data[check.side]
            const otherSide = data[check.otherSide]
            if (activePice.data.id === data.id) {
                return true
            }
            //   console.log(activeSide.connect_id, otherSide.connect_id);
            if (activeSide.connect_id !== otherSide.connect_id) {
                return false
            }
        }
        return true
    })
}

export { miniSolver, microSolver }
