import {
    Button, createStyles, makeStyles,
    Typography
} from '@material-ui/core'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import AnnouncementIcon from '@material-ui/icons/Announcement'
import AssignmentLateIcon from '@material-ui/icons/AssignmentLate'
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark'
import ComputerIcon from '@material-ui/icons/Computer'
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks'
import SchoolIcon from '@material-ui/icons/School'
import SportsEspotsIcon from '@material-ui/icons/SportsEsports'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

interface Props {
    headerText: string,
    iconName: 'ComputerIcon' |
    'LibraryBooksIcon' |
    'SchoolIcon' |
    'AssignmentLateIcon' |
    'VideoLibraryIcon' |
    'CollectionsBookmarkIcon' |
    'SportsEspotsIcon' |
    'AccountBox' |
    'Announcement',
    bottomText?: string,
    bottomTextBold?: string,
    bottomTextLink?: string,
    isTeacherLikeAParent?: boolean
    bottomTextFunction?: (value: boolean) => void
}

const useStyles = makeStyles(() => createStyles({
    cardContent: {
        minWidth: 170,
        display: 'flex',
        justifyContent: 'space-around',
        flexDirection: 'column',
        alignItems: 'center',
        marginLeft: 'auto',
        // minHeight: '15vh',
    },
}),)

const BasicCardContent: React.FC<Props> = ({
    headerText,
    bottomText,
    iconName,
    bottomTextLink,
    bottomTextBold,
    bottomTextFunction,
    isTeacherLikeAParent = false
}) => {
    const classes = useStyles()
    const history = useHistory()

    return (
        <div
            style={{
                minHeight: bottomText ? 0 : 125.90,
                justifyContent: bottomText ? 'space-around' : 'flex-start'
            }}
            className={
                classes.cardContent
            }>
            <Typography variant="body1" align="center"><b>{headerText}</b></Typography>
            <div style={{ marginTop: bottomText ? 6 : 20, marginBottom: 6 }}>
                {iconName === 'ComputerIcon' ? <ComputerIcon fontSize="large" /> : null}
                {iconName === 'LibraryBooksIcon' ? <LibraryBooksIcon fontSize="large" /> : null}
                {iconName === 'SchoolIcon' ? <SchoolIcon fontSize="large" /> : null}
                {iconName === 'AssignmentLateIcon' ? <AssignmentLateIcon fontSize="large" /> : null}
                {iconName === 'VideoLibraryIcon' ? <VideoLibraryIcon fontSize="large" /> : null}
                {iconName === 'CollectionsBookmarkIcon' ? <CollectionsBookmarkIcon fontSize="large" /> : null}
                {iconName === 'SportsEspotsIcon' ? <SportsEspotsIcon fontSize="large" /> : null}
                {iconName === 'AccountBox' ? <AccountBoxIcon fontSize="large" /> : null}
                {iconName === 'Announcement' ? <AnnouncementIcon fontSize="large" /> : null}
            </div>
            {!bottomTextFunction && bottomTextLink
                ? (
                    <Button
                        onClick={() => history.push(bottomTextLink, { className: undefined })}
                        color="primary">{!isTeacherLikeAParent && 'Du har'}&nbsp;
                        <b>{bottomTextBold}</b> &nbsp;{bottomText}
                    </Button>
                )
                : <Typography variant="body1" align="center">{bottomText}</Typography>}
            {
                bottomTextFunction
                && (
                    <Button
                        onClick={() => bottomTextFunction(true)}
                        color="primary">Tilføj lærer
                    </Button>
                )
            }

        </div>
    )
}
export default BasicCardContent
