import {
    Card, CardContent, createStyles, makeStyles
} from '@material-ui/core'
import * as React from 'react'

import BasicOneColumnCardContent from '../atoms/BasicOneColumnCardContent'

interface Props {
    headerText: string,
    iconName: 'ComputerIcon' |
    'LibraryBooksIcon' |
    'SchoolIcon' |
    'AssignmentLateIcon' |
    'VideoLibraryIcon' |
    'CollectionsBookmarkIcon' |
    'AccountBox',
    bottomText: string
}

const useStyles = makeStyles(() => createStyles({
    card: {
        borderRadius: 25,
        elevation: 3,
        minHeight: '16vh',
        // maxHeight: '25vh',
    },
}),)

const Simplecard: React.FC<Props> = ({ headerText, bottomText, iconName }) => {
    const classes = useStyles()

    return (
        <Card className={classes.card}>
            <CardContent>
                <BasicOneColumnCardContent headerText={headerText} bottomText={bottomText} iconName={iconName} />
            </CardContent>
        </Card>
    )
}
export default Simplecard
