import {
    Button,
    createStyles,
    Dialog, DialogActions, DialogTitle, makeStyles, ThemeProvider,
    withStyles
} from '@material-ui/core'
import * as React from 'react'

import { buttonTheme } from '../../utils/styles'
import { drawerWidth } from '../wrappers/BodyWithTopBar'

const useStyles = makeStyles((theme) => createStyles({
    centeredDialog: {
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
        },
        marginLeft: drawerWidth - 5,
        zIndex: 1301
    }
}))

interface Props {
    text: string,
    open: boolean,
    onCancel: () => void,
    onConfirm: (params?: any) => void,
    textConfirm?: string,
    textCancel?: string,
    confirmColor?: string,
    cancelColor?: string,
}

const ConfirmDialog: React.FC<Props> = ({
    confirmColor,
    cancelColor,
    textConfirm = 'Ja, helt sikker',
    textCancel = 'Nej',
    text,
    onCancel,
    onConfirm,
    open,
}) => {
    const classes = useStyles()

    const StyledCancelButton = withStyles({
        root: {
            color: cancelColor,
        }
    })(Button)

    const StyledConfirmButton = withStyles({
        root: {
            color: confirmColor,
        }
    })(Button)

    return (

        <Dialog
            open={open}
            onClose={onCancel}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
            className={classes.centeredDialog}>
            <DialogTitle id="alert-dialog-title">{text}</DialogTitle>
            <DialogActions>
                <ThemeProvider theme={buttonTheme}>
                    {cancelColor ? <StyledCancelButton onClick={onCancel}>{textCancel}</StyledCancelButton>
                        : (
                            <Button onClick={onCancel}>
                                {textCancel}
                            </Button>
                        )}
                    {confirmColor ? <StyledConfirmButton onClick={onConfirm}>{textConfirm}</StyledConfirmButton>
                        : (
                            <Button onClick={onConfirm} color="secondary">
                                {textConfirm}
                            </Button>
                        )}
                </ThemeProvider>
            </DialogActions>
        </Dialog>
    )
}
export default ConfirmDialog
