/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'

import styles from '../styles/copyUrlBox.module.css'
import CheckSvg from './svg/checkSvg'
import CopySvg from './svg/copySvg'

export default function CopyUrlBox({ url }) {
    const [copied, setCopied] = useState(null)

    const handleCopy = () => {
        setCopied(true)
        navigator.clipboard.writeText(url)
        setTimeout(() => {
            setCopied(null)
        }, 1000)
    }

    return (
        <div className={styles.container}>
            <h1 className={styles.url}>{url}</h1>
            <button type="button" onClick={handleCopy} className={styles.button}>
                {copied ? (
                    <>
                        <CheckSvg />
                        Kopieret!
                    </>
                ) : (
                    <>
                        <CopySvg />
                        kopiér
                    </>
                )}
            </button>
        </div>
    )
}
