import { showMessage } from './notifications'

interface WrapOpts {
    stateFunc?(busy: boolean): void
    errorStateFunc?(busy: boolean): void
    operation?: string
    showSuccess?: boolean
}

export async function wrap<T>(f :() => Promise<T>, opts?: WrapOpts) {
    try {
        if (opts?.stateFunc) {
            opts.stateFunc(true)
        }

        const r = await f()

        if (opts?.showSuccess) {
            showMessage(`Fuldført med succes ${opts.operation || 'Kontakt serveren'}.`, 'success')
        }

        if (opts?.errorStateFunc) {
            opts.errorStateFunc(false)
        }

        return r
    } catch (error) {
        // error.response covers errors thrown by API/Lambda
        // error.message is used for errors thrown by `throw Error(...)`
        // eslint-disable-next-line no-nested-ternary
        const additionalErrorMessage = error.response ? error.response.data.error : (
            error.message ? error.message : ''
        )
        if (additionalErrorMessage === 'User already exists') {
            showMessage(
                'Hovsa-det ser ud til at du allerede har en konto. Log ind for evt. at forny din adgan',
                'error'
            )
        } else {
            showMessage(`En fejl opstod ${opts?.operation || 'Kontakt serveren'}!
            ${additionalErrorMessage}`, 'error')
        }

        if (opts?.errorStateFunc) {
            opts.errorStateFunc(true)
        }
        return undefined
    } finally {
        if (opts?.stateFunc) {
            opts.stateFunc(false)
        }
    }
}

export function fireWrap<T>(f :() => Promise<T>, opts?: WrapOpts): void {
    wrap(f, opts).then()
}
