import { API } from 'aws-amplify'

import { LibraryFilter, QuizLibraryType } from '../../types/types'

export const listQuizzes = async (): Promise<QuizLibraryType[]> => {
    const quizzes = await API.get('api', '/quizzes/public', {})
    return quizzes
}

export const filterQuizzes = async (filterParams: LibraryFilter): Promise<QuizLibraryType[]> => {
    const videos = await API.post('api', '/quizzes/filter', {
        body: filterParams
    })
    return videos
}

export const updateQuizHomeworks = async (quizId: string, homeworks: string[]) => {
    return API.put('api', `/quizzes/homeworks/${quizId}`, {
        body: { homeworks }
    })
}

export const getQuizById = async (quizId: string): Promise<QuizLibraryType> => {
    const quiz = await API.get('api', `/quizzes/${quizId}`, {})
    return quiz
}
