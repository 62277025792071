import {
    Button, Paper, TableContainer, ThemeProvider
} from '@material-ui/core'
import * as React from 'react'
import { useAsyncCallback } from 'react-async-hook'
import { useLocation } from 'react-router-dom'

import ConfirmDialog from '../../components/atoms/ConfirmDialog'
import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import AfterCreateAssignmentScreen from '../../components/organisms/AfterCreateAssignmentScreen'
import CreateAssignmentScreen from '../../components/organisms/CreateAssignmentScreen'
import DefaultAssignmentScreen from '../../components/organisms/DefaultAssignmentScreen'
import UpdateAssignmentComponent from '../../components/organisms/UpdateAssignmentComponent'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { HomeworkType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { deleteHomework } from '../../utils/Services/homework-service'
import { buttonTheme, useTableStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'

export const AssignmentPage = () => {
    const buttonClasses = buttonStyles()
    const location: any = useLocation()
    const myparam = location.state ? location.state.id : undefined

    const { fetchHomeworks, fetchClassNames, me } = useTeacher()
    const { result, error, loading }:
        { result: HomeworkType[], error: Error, loading: boolean } = fetchHomeworks
    const tableClasses = useTableStyles()
    const [selected, setSelected] = React.useState<string | undefined>(myparam)
    const [createNew, setCreateNew] = React.useState<boolean>(false)
    const [afterCreate, setAfterCreate] = React.useState<boolean>(false)
    const [confirmDelete, setConfirmDelete] = React.useState(false)

    const onCreate = () => {
        // to update default homework screen
        fetchHomeworks.execute()
        // to ensure class names on homework screen are correct after creating a homework
        fetchClassNames.execute()
    }
    const onDelete = async () => {
        setConfirmDelete(true)
    }
    const onDeleteConfirm = async () => {
        if (selected) await deleteHomework(selected)
        // to update default homework scree
        fetchHomeworks.execute()
        // to ensure classes are render correctly
        fetchClassNames.execute()
        setSelected(undefined)
    }
    const onDeleteHomework = useAsyncCallback(onDeleteConfirm)

    const isLoading = () => {
        return loading || onDeleteHomework.loading || fetchClassNames.loading
    }
    const renderCreateNewScreen = () => (
        <CreateAssignmentScreen
            onHomeworkCreate={onCreate}
            setCreateNew={setCreateNew}
            setAfterCreate={setAfterCreate} />
    )

    const renderAfterCreateScreen = () => (
        <AfterCreateAssignmentScreen setAfterCreate={setAfterCreate} setCreateNew={setCreateNew} />
    )

    const renderDefaultScreen = () => (
        <DefaultAssignmentScreen
            homeworks={result}
            setCreateNew={setCreateNew} />
    )

    if (isLoading()) {
        return <Loading />
    }

    // TODO: Render memory leaks
    const renderUpdateScreen = () => {
        return (
            <>
                <TableContainer className={tableClasses.table} component={Paper}>
                    {selected && (
                        <UpdateAssignmentComponent
                            selectedId={selected}
                            isSelected={setSelected}
                            headerText="Mine lektier" />
                    )}
                </TableContainer>
                <ConfirmDialog
                    text="Er du sikker på, at du vil slette dette lektier?"
                    open={confirmDelete}
                    onCancel={() => setConfirmDelete(false)}
                    onConfirm={() => {
                        onDeleteHomework.execute().then(() => { })
                        setConfirmDelete(false)
                    }} />
                <ThemeProvider theme={buttonTheme}>
                    <Button
                        className={buttonClasses.leftButton}
                        variant="contained"
                        color="secondary"
                        disabled={onDeleteHomework.loading || !!me}
                        onClick={onDelete}>
                        Slet lektie
                    </Button>
                </ThemeProvider>
            </>
        )
    }

    if (error) {
        showMessage(error.message)
    }
    if (isLoading() || onDeleteHomework.loading) {
        return <Loading />
    }
    return (
        <BodyWithTopBar>
            {!isLoading() && createNew && !selected && !afterCreate && renderCreateNewScreen()}
            {!isLoading() && !createNew && !selected && afterCreate && renderAfterCreateScreen()}
            {!isLoading() && !createNew && !selected && !afterCreate && renderDefaultScreen()}
            {!isLoading() && !createNew && selected && !afterCreate && renderUpdateScreen()}
            <BottomBar onlyPicture />
        </BodyWithTopBar>
    )
}
