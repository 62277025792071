import { API } from 'aws-amplify'

import { ClassType, SignUpIntentType, StudentType } from '../../types/types'

export const listStudents = async () => {
    return await API.get('api', '/students', {}) as StudentType[]
}

export const listStudentClasses = async (userId: string) => {
    return await API.get('api', `/student/classes/${userId}`, {}) as ClassType[]
}

export const listSharedStudentClasses = async (teacherId: string, userId: string) => {
    return await API.get('api', `/teacher/${teacherId}/student/${userId}`, {}) as ClassType[]
}

export const getStudent = async (id: string) => {
    return await API.get('api', `/students/${id}`, {}) as StudentType
}

export const createStudent = async (firstName: string, lastName: string,
    username: string, schoolClasses: string[], password: string) => {
    return API.post('api', '/student', {
        body: {
            firstName, lastName, username, schoolClasses, password
        }
    })
}

export const createStudentSignUpLink = async (schoolClassIds?: string[]) => {
    return await API.post('api', '/student/invite', {
        body: {
            schoolClasses: schoolClassIds
        }
    }) as SignUpIntentType
}

export const updateStudent = async (studentId: string, schoolClasses: string[]) => {
    return API.put('api', `/student/${studentId}`, {
        body: { schoolClasses }
    })
}

export const deleteStudent = async (id: string) => {
    return API.del('api', `/student/${id}`, {})
}
