/* eslint-disable react/jsx-indent */
import {
    Paper, TableCell, TableContainer, TableRow, Typography
} from '@material-ui/core'
import * as React from 'react'
import ReactPlayer from 'react-player'

import { ClassType, TeacherType } from '../../types/types'
import { anonStudentIdPrefix } from '../../utils/globalData'
import { useTableStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'
import FormButtons from '../atoms/FormButtons'
import BasicTable from '../molecules/BasicTable'

interface Props {
    classes: ClassType[],
    setSelected: (id: string) => void,
    setCreateNew: (openCreateScreen: boolean) => void,
}

const DefaultClassScreen: React.FC<Props> = ({ classes, setCreateNew, setSelected }) => {
    const {
        me, classesShared, teachersSharedWithMe, teacher
    } = useTeacher()
    const [classRows, setClassRows] = React.useState<ClassType[]>([])
    React.useEffect(() => {
        if (me) {
            setClassRows(classesShared)
        } else {
            setClassRows(classes)
        }
    }, [])

    const [transformedClasses, setTransformedClasses] = React.useState<any[]>()
    const tableClasses = useTableStyles()

    const getNumberOfNonAnonStudents = (studentClass: ClassType) => {
        return studentClass.students?.filter((
            student
        ) => !student.startsWith(anonStudentIdPrefix)).length
    }
    React.useEffect(() => {
        setTransformedClasses(classRows.map((classData) => ({
            ...classData,
            teachers:
                classData.teachers?.filter((t) => t !== teacher?.id && t).map((t) => {
                    const sharedWithMe: TeacherType[] = []
                    if (me && !teachersSharedWithMe.map((ts: TeacherType) => ts.id).includes(me.id)
                    ) {
                        teachersSharedWithMe.push(me)
                    }
                    teachersSharedWithMe.forEach((sh: TeacherType) => {
                        if (sh.id === t) sharedWithMe.push(sh)
                    })
                    return sharedWithMe
                }
                )
        })))
    }, [classRows, teacher, teachersSharedWithMe])
    return (
        <>
            <TableContainer className={tableClasses.table} component={Paper}>
                <Typography variant="h6" style={{ textAlign: 'center' }}>Mine klasser</Typography>
                {transformedClasses && transformedClasses.length === 0
                    ? (<Typography align="center">Ingen klasser fundet.</Typography>)
                    : (
                        <BasicTable headerNames={['Navn', 'Beskrivelse', 'Lektier', 'Elever', 'Delt med']}>
                            {transformedClasses && transformedClasses.map((item) => (
                                <TableRow
                                    style={{ cursor: 'pointer' }}
                                    key={item.id}
                                    hover
                                    onClick={() => setSelected(item.id)}>
                                    <TableCell component="th" scope="row">
                                        <b>{item.title}</b>
                                    </TableCell>
                                    <TableCell component="th" scope="row"><b>{item.description}</b></TableCell>
                                    <TableCell component="th" scope="row">
                                        <b>{item.homeworks?.length}</b>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <b>{getNumberOfNonAnonStudents(item)}</b>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <b>
                                            <ul>
                                                {item.teachers.map((t: TeacherType[]) => t[0]?.email
                                                    && (<li>{t[0]?.email}</li>))}
                                                {item.teachers.filter(
                                                    (t: TeacherType[]) => {
                                                        return t[0]?.email === undefined
                                                    }
                                                ).length
                                                    ? (
                                                        <li>
                                                            {`Der er også andre ${item.teachers.filter(
                                                                (t: TeacherType[]) => t[0]?.email === undefined
                                                            ).length} lærere i dette`}
                                                            <br />klasse, som du ikke har adgang til
                                                        </li>
                                                    )
                                                    : null}
                                            </ul>

                                        </b>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </BasicTable>
                    )}
            </TableContainer>
            <FormButtons
                createButtonText="Opret ny klasse"
                onClickCreate={() => setCreateNew(true)} />
            <ReactPlayer
                style={{ marginTop: 120, zIndex: 9, position: 'relative', }}
                url="https://player.vimeo.com/video/677175483"
                width="100%"
                controls />
        </>
    )
}
export default DefaultClassScreen
