/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Paper, TableContainer, Typography
} from '@material-ui/core'
import * as React from 'react'
import ReactPlayer from 'react-player'

import { BottomBar } from '../../components/molecules/BottomBar'
import { ContactForm } from '../../components/molecules/ContactForm'
import { Loading } from '../../components/molecules/Loading'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { useTableStyles } from '../../utils/styles'

export const Help: React.FC = () => {
    const tableClasses = useTableStyles()
    const [loading, setLoading] = React.useState<boolean>(false)

    if (loading) {
        return <Loading />
    }

    return (
        <BodyWithTopBar>
            <TableContainer className={tableClasses.table} component={Paper}>
                <Typography variant="h3" style={{ textAlign: 'center' }}>Hjælp</Typography>
                <div style={{
                    display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 15
                }}>
                    <ReactPlayer
                        url="https://vimeo.com/showcase/9275864"
                        width="100%"
                        controls />
                </div>
                <div style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    flexDirection: 'column',
                    margin: 'auto',
                    width: '100%'
                }}>
                    <Typography variant="h4" style={{ textAlign: 'center', marginTop: 20 }}>
                        Kontakt os
                    </Typography>
                    <ContactForm />
                </div>
            </TableContainer>
            <BottomBar onlyPicture />
        </BodyWithTopBar>
    )
}
