import { API } from 'aws-amplify'

import {
    ClassType,
    CreateTeacherUserType,
    CreateUniLoginAuthIntentType,
    CreateUniLoginSignUpIntentType, DelegatedCognitoLogin, FinalizeUniLoginAuthIntentType,
    FinalizeUniLoginSignUpIntentType,
    HomeworkType,
    StudentType, TeacherSignUpDetailsType,
    TeacherType,
    UniLoginAuthIntentType
} from '../../types/types'

export const initiateSignUpTeacherWithUniLogin = async (details: CreateTeacherUserType, returnUrl: string) => {
    const body: CreateUniLoginSignUpIntentType = {
        returnUrl,
        payload: details,
        group: 'teacher'
    }

    return await API.post('openapi', '/unilogin/signup', {
        body
    }) as UniLoginAuthIntentType
}

export const finalizeSignUpTeacherWithUniLogin = async (params: FinalizeUniLoginSignUpIntentType) => {
    return await API.put('openapi', '/unilogin/signup', {
        body: params
    }) as TeacherType
}

export const initiateLogInTeacherWithUniLogin = async (returnUrl: string) => {
    const body: CreateUniLoginAuthIntentType = {
        returnUrl,
        group: 'teacher'
    }

    return await API.post('openapi', '/unilogin/login', {
        body
    }) as UniLoginAuthIntentType
}

export const finalizeLogInTeacherWithUniLogin = async (params: FinalizeUniLoginAuthIntentType) => {
    return await API.put('openapi', '/unilogin/login', {
        body: params
    }) as DelegatedCognitoLogin
}

export const signUpTeacher = async (params: CreateTeacherUserType) => {
    return await API.post('openapi', '/teachers/signup', {
        body: params
    }) as TeacherType
}

export const getTeacherSignUpDetails = async (email: string) => {
    return await API.get('openapi', `/teachers/signup/${email}`, {}) as TeacherSignUpDetailsType
}

export const updateTeacher = async (
    teacherId: string,
    firstName: string,
    lastName: string,
    school: string,
    confetti: boolean,
    hasAudio: boolean | string,
    free?: boolean,
    subscriptionId?: string,
    subscriptionExpiresAt?: string
) => {
    return await API.put('api', `/teachers/${teacherId}`, {
        body: {
            firstName, lastName, school, subscriptionId, subscriptionExpiresAt, confetti, hasAudio, free
        }
    }) as TeacherType
}

export const getById = async (): Promise<TeacherType> => {
    return API.get('api', '/teachers/me', {})
}

export const getIsParent = async (): Promise<boolean> => {
    return API.get('api', '/teachers/is-parent', {})
}

export const getAnotherById = async (id: string): Promise<TeacherType> => {
    return API.get('api', `/teachers/${id}`, {})
}

export const getClassesByTeacherId = async (): Promise<ClassType[]> => {
    return API.get('api', '/teachers/classes', {})
}

export const getStudentsByTeacherId = async (): Promise<StudentType[]> => {
    return API.get('api', '/teachers/students', {})
}

export const getHomeworksByTeacherId = async (): Promise<HomeworkType[]> => {
    return API.get('api', '/teachers/homeworks', {})
}

export const getSharedHomeworksByTeacherId = async (): Promise<HomeworkType[]> => {
    return API.get('api', '/shared/homeworks', {})
}

export const addTeachersSharedWithMe = async (email: string): Promise<TeacherType> => {
    return API.put('api', '/teachers/shared-add', {
        body: { email }
    })
}

export const listTeachersSharedWithMe = async (): Promise<TeacherType[]> => {
    return API.get('api', '/teachers/shared', {})
}

export const listTeachersIAmSharedWith = async (): Promise<TeacherType[]> => {
    return API.get('api', '/teachers/iamshared', {})
}

export const getHomeworkByTeachersSharedWithMe = async (teacherId: string): Promise<HomeworkType[]> => {
    return API.get('api', `/teachers/homeworks/${teacherId}`, {})
}

export const getClassesByTeachersSharedWithMe = async (teacherId: string): Promise<ClassType[]> => {
    return API.get('api', `/teachers/classes/${teacherId}`, {})
}

export const getStudentsByTeacherSharedWithMe = async (teacherId: string): Promise<StudentType[]> => {
    return API.get('api', `/teachers/students/${teacherId}`, {})
}

export const deleteTeachersSharedWithMe = async (email: string, typeId: string): Promise<void> => {
    return API.put('api', `/teachers/shared-delete/${typeId}`, {
        body: { email }
    })
}

export const deleteMyself = async () => {
    return API.del('api', '/teachers/me', {})
}

export const getAllResources = async (): Promise<any> => {
    return API.get('api', '/resources', {})
}

export const filterAllResources = async (filterParams: any): Promise<any> => {
    return API.post('api', '/resources/filter', {
        body: filterParams
    })
}
