/* eslint-disable react/jsx-filename-extension */
import React from 'react'

import styles from '../styles/playBox.module.css'
import MathExpression from './mathExpression'

export default function PlayPuzzleBox({ box }) {
    return (
        <div className={styles.playBoxCont}>
            <div className={styles.playBoxContSides}>
                <div className={`${styles.playBoxSides} ${styles.playBoxSideLeft}`}>
                    <MathExpression expression={box.left.equation} />
                </div>
                <div className={`${styles.playBoxSides} ${styles.playBoxSideRight}`}>
                    <MathExpression expression={box.right.equation} />
                </div>
                <div className={`${styles.playBoxSides} ${styles.playBoxSideTop}`}>
                    <MathExpression expression={box.top.equation} />
                </div>
                <div className={`${styles.playBoxSides} ${styles.playBoxSideBottom}`}>
                    <MathExpression expression={box.bottom.equation} />
                </div>
            </div>
        </div>
    )
}
