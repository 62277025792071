import {
    Button, Paper, TableContainer, ThemeProvider
} from '@material-ui/core'
import { CognitoUser } from 'amazon-cognito-identity-js'
import { Auth } from 'aws-amplify'
import * as React from 'react'
import { Redirect } from 'react-router-dom'

// import { AuthButton } from '../../components/molecules/AuthButton'
import { Loading } from '../../components/molecules/Loading'
import { Logo } from '../../components/molecules/Logo'
import { NoAuthenticatedNav } from '../../components/molecules/NoAuthenticatedNav'
import { TextInputTemplate } from '../../components/molecules/TextInputTemplate'
import { Body } from '../../components/wrappers/Body'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { TeacherSignUpDetailsType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import Routes from '../../utils/Routes'
import { handleSignInError, useCognito } from '../../utils/Services/cognito-service'
import {
    getTeacherSignUpDetails,
    // initiateLogInTeacherWithUniLogin
} from '../../utils/Services/teacher-service'
import { buttonTheme, useTableStyles } from '../../utils/styles'
import { wrap } from '../../utils/wrap-functions'
import { ForgotPassModal } from '../ForgotPassword'

export const Login: React.FC = () => {
    const tableClasses = useTableStyles()
    const buttonClasses = buttonStyles()
    const { signedIn } = useCognito()

    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [signUp, setSignUp] = React.useState(false)
    // const [redirectToUniLogin, setRedirectToUniLogin] = React.useState(false)
    const [loggingIn, setLoggingIn] = React.useState(false)
    const [passwordResetNeeded, setPasswordResetNeeded] = React.useState(false)
    const [confirmationNeededFor, setConfirmationNeededFor] = React.useState<TeacherSignUpDetailsType>()
    // const [loginView, setLoginView] = React.useState(0)

    React.useEffect(() => {
        window.history.replaceState({}, document.title, window.location.pathname)
    }, [])

    const logIn = async () => {
        await wrap(async () => {
            try {
                const user = await Auth.signIn(email, password)

                if (user instanceof CognitoUser) {
                    const p = user.getSignInUserSession()?.getAccessToken()?.decodePayload()
                    const gs: string[] = (p && p['cognito:groups']) || []
                    if (!(gs.includes('teacher') || gs.includes('parent'))) {
                        await Auth.signOut()
                        showMessage('User is not a teacher.', 'error')
                    } else {
                        window.location.reload()
                    }
                }
            } catch (error) {
                if (error.code === 'PasswordResetRequiredException') {
                    setPasswordResetNeeded(true)
                } else if (error.code === 'UserNotConfirmedException') {
                    setConfirmationNeededFor(await getTeacherSignUpDetails(email))
                } else if (error.code === 'NotAuthorizedException') {
                    handleSignInError(error.message, showMessage)
                } else {
                    throw error
                }
            }
        }, {
            operation: 'Logging In',
            stateFunc: setLoggingIn
        })
    }

    // const loginWithUniLogin = async () => {
    //     await wrap(async () => {
    //         const { clientUri } = await initiateLogInTeacherWithUniLogin(`${window.location.href}/complete`)
    //         setRedirectToUniLogin(true)
    //         window.location.href = clientUri
    //     }, {
    //         operation: 'Logging In',
    //         stateFunc: setLoggingIn
    //     })
    //     // window.location.reload()
    // }

    if (confirmationNeededFor) {
        return (
            <Redirect to={{
                pathname: '/confirm',
                state: {
                    email: confirmationNeededFor.email,
                    id: confirmationNeededFor.id,
                    lastName: confirmationNeededFor.lastName,
                    pricePlan: confirmationNeededFor.subscriptionPricePlan,
                    coupon: confirmationNeededFor.coupon
                }
            }} />
        )
    }

    if (passwordResetNeeded) {
        return (
            <Redirect to={{
                pathname: Routes.ConfirmForgotPassword.path,
                state: { email }
            }} />
        )
    }

    if (loggingIn || signedIn) {
        return <Loading />
    }

    if (signUp) {
        return <Redirect to="/signup" />
    }

    return (
        <div className={tableClasses.authBackground}>
            <NoAuthenticatedNav onClick={() => setSignUp(true)} title="Opret bruger" />
            <div className={tableClasses.authLogoContainer}>
                <Logo />
            </div>
            <Body>
                {/* <div className={tableClasses.divAuthButtons}>
                    <AuthButton
                        textColor={loginView === 1 ? '#FFFFFF' : '#000'}
                        backgroundColor={loginView === 1 ? '#3C8CAC' : '#FFFFFF'}
                        onClick={() => setLoginView(1)}
                        title="Login med Geekster" />
                </div> */}
                {/* {loginView === 1 && ( */}
                <form noValidate>
                    <TableContainer
                        style={{ backgroundColor: 'rgba(252,252,252,0.9)', maxWidth: '70%', marginTop: 30 }}
                        className={tableClasses.table}
                        component={Paper}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <TextInputTemplate
                                value={email}
                                onChange={setEmail}
                                label="Indtast din e-mail" />
                            <TextInputTemplate
                                value={password}
                                label="Adgangskode"
                                onChange={setPassword}
                                type="password" />
                            <ThemeProvider theme={buttonTheme}>
                                <div>
                                    <Button
                                        className={buttonClasses.authButton}
                                        onClick={logIn}
                                        variant="contained"
                                        color="primary">
                                        Login med Geekster
                                    </Button>
                                </div>
                            </ThemeProvider>
                            <ForgotPassModal />
                        </div>
                    </TableContainer>
                </form>
                {/* )} */}
            </Body>
        </div>
    )
}
