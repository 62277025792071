/* eslint-disable consistent-return */
import {
    Button,
    Checkbox,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    makeStyles,
    Typography
} from '@material-ui/core'
import * as React from 'react'

import { TeacherType } from '../../types/types'
import { drawerWidth } from '../wrappers/BodyWithTopBar'

const useStyles = makeStyles((theme) => createStyles({
    centeredSnackbar: {
        [theme.breakpoints.up('md')]: {
            marginLeft: drawerWidth - 20
        },
    }
}),)
interface Props {
    open: boolean,
    selectedClassIds: string[],
    allTeachers: TeacherType[],
    defaultCheckedState: boolean[],
    setOpen: (isOpen: boolean) => void,
    confirmSelection: (checkedClasses: string[]) => void
}

const AddTeacherSharedDialog: React.FC<Props> = ({
    open, selectedClassIds, defaultCheckedState, allTeachers, setOpen, confirmSelection
}) => {
    const classes = useStyles()
    // eslint-disable-next-line array-callback-return
    const newTeachers = allTeachers.filter((item) => {
        if (selectedClassIds.indexOf(item.id) < 0) return item
    })
    const [checkedState, setCheckedState] = React.useState<boolean[]>(defaultCheckedState)

    const handleOnChange = (position: number) => {
        const updatedCheckedState = checkedState.map((item, index) => (index === position ? !item : item))
        setCheckedState(updatedCheckedState)
    }

    const handleSubmit = () => {
        const indexes = getChecked()
        checkedState.fill(false)
        confirmSelection(indexes)
        setOpen(false)
    }

    const getChecked = () => {
        const checkedIndexes: string[] = []
        checkedState.forEach((item, index) => {
            if (item) checkedIndexes.push(newTeachers[index].id)
        })
        return checkedIndexes
    }

    return (
        <Dialog
            className={classes.centeredSnackbar}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Tilføj lærer</DialogTitle>
            <DialogContent>
                <FormGroup>
                    {newTeachers.length === 0
                        && <Typography variant="body1">Kan ikke tilføje flere lærere!</Typography>}
                    {checkedState && newTeachers.map((item: TeacherType, index) => {
                        return (
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        name={`${item.email}`}
                                        checked={checkedState[index]}
                                        onClick={() => handleOnChange(index)} />
                                )}
                                label={`${item.email}`}
                                key={item.id} />
                        )
                    })}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    Annuller
                </Button>
                {newTeachers.length > 0 && (
                    <Button onClick={handleSubmit} color="primary">
                        Bekræft
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
export default AddTeacherSharedDialog
