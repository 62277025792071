/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'

import styles from '../styles/puzzleEditor.module.css'
import MathWithKey from './mathWithKey'

export default function MathKeys({ addMathKey }) {
    const [keyEvents] = useState([
    // { label: "mellemrum", key: "\\>" },
    ])
    // function toSuperscript(number) {
    //   const superscripts = [
    //     String.fromCharCode(0x2070), // ⁰
    //     String.fromCharCode(0x00b9), // ¹
    //     String.fromCharCode(0x00b2), // ²
    //     String.fromCharCode(0x00b3), // ³
    //     String.fromCharCode(0x2074), // ⁴
    //     String.fromCharCode(0x2075), // ⁵
    //     String.fromCharCode(0x2076), // ⁶
    //     String.fromCharCode(0x2077), // ⁷
    //     String.fromCharCode(0x2078), // ⁸
    //     String.fromCharCode(0x2079), // ⁹
    //   ];
    //   const digits = number.toString().split("");
    //   let result = "";
    //   digits.forEach((digit) => {
    //     result += superscripts[digit];
    //   });
    //   return result;
    // }

    const handleKeyEvent = (key, event) => {
        event.preventDefault()
        addMathKey(key)
    }

    return (
        <div className={styles.mathButtonsContSingle}>
            {keyEvents.map((item, index) => {
                return (
                    <button
                        type="button"
                        key={index}
                        onClick={(event) => handleKeyEvent(item.key, event)}
                        className={styles.mathButtons}>
                        {item.label}
                    </button>
                )
            })}
            <MathWithKey addMathKey={addMathKey} />
        </div>
    )
}
