import Amplify, { API, Auth } from 'aws-amplify'
import React from 'react'
import ReactDOM from 'react-dom'

import config from './config'
import RootRouter from './content/RootRouter'
import { TeacherManagerWrapped } from './utils/TeacherManager'

Amplify.configure(config)
Auth.configure(config)
API.configure(config)

ReactDOM.render(
    <React.StrictMode>
        <TeacherManagerWrapped>
            <RootRouter />
        </TeacherManagerWrapped>
    </React.StrictMode>,
    document.getElementById('root'),
)
