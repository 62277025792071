import '../../styles/alignmentTextRichTextEditor.css'

import {
    Button, createStyles, Grid, makeStyles, Paper, TableContainer, Typography
} from '@material-ui/core'
import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { QuizLibraryType, VideoType } from '../../types/types'
import {
    gradeLevels, indskolingGradeLevels, mellemtrinGradeLevels, udskolingGradeLevels
} from '../../utils/globalData'
import { filterLibrary, getGradesByKeyword } from '../../utils/helper'
import { useLoadingManager } from '../../utils/hooks'
import {
    filterCommunityQuizzes,
    listPublicCommunityQuizzes
} from '../../utils/Services/community-quiz-service'
import { filterPDFs, listPDFs } from '../../utils/Services/pdf-service'
import { filterQuizzes, listQuizzes } from '../../utils/Services/quiz-service'
import { filterStudentQuizzes, listStudentQuizzes } from '../../utils/Services/student-quiz-service'
import { filterAllResources, getAllResources } from '../../utils/Services/teacher-service'
import { filterVideos, listVideos } from '../../utils/Services/video-service'
import { useTableStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'
import { BottomBar } from '../molecules/BottomBar'
import FilterTemplate from '../molecules/FilterTemplate'
import { Loading } from '../molecules/Loading'
import { SearchBarContainer } from '../molecules/SearchBarContainer'

interface Props {
    items: VideoType[] | QuizLibraryType[],
    setRows: (items: VideoType[] | QuizLibraryType[]) => void,
    headerText: string,
    listOfTopics: string[],
    listOfCategories: string[],
    searchFunction: (searchInput: string) => void,
    searchValue: string,
    typeOfLibrary: 'video' | 'quiz' | 'communityQuiz' | 'studentQuiz' | 'pdf' | 'all'
    originalTypeOfLibrary: 'video' | 'quiz' | 'communityQuiz' | 'studentQuiz' | 'pdf' | 'all'
    setTypeOfLibrary: (type: 'video' | 'quiz' | 'communityQuiz' | 'studentQuiz' | 'pdf' | 'all') => void
}

const listOfLibraries = ['video', 'quiz', 'communityQuiz', 'studentQuiz', 'pdf']

const useStyles = makeStyles((theme) => createStyles({
    outerContainer: {
        position: 'relative',
        textAlign: 'center'
    },
    innerContainer: {
        color: '#00b3fc', 
        right: 0,
        position: 'absolute',
        top: 0,
        [theme.breakpoints.down('md')]: {
            position: 'relative',
            alignSelf: 'center',
        },
    },
}),)

const LibraryScreen: React.FC<Props> = ({
    children, setRows, headerText, searchFunction, searchValue, typeOfLibrary,
    listOfTopics, listOfCategories, items, setTypeOfLibrary: setTypesOfLibrary, originalTypeOfLibrary
}) => {
    const location: any = useLocation()

    React.useEffect(() => {
        if (location.state?.search) {
            setSearchValue(location.state.search)
        }
    }, [location.state?.search])

    const setTypeOfLibrary = () => {
        setTypesOfLibrary!(typeOfLibrary !== 'all' ? 'all' : originalTypeOfLibrary)
    }

    const {
        setSearchValue,
        selectedCategories,
        setSelectedCategories,
        selectedGrades,
        setSelectedGrades,
        selectedTopics,
        setSelectedTopics,
        showMyPrivate,
        setShowMyPrivate
    } = useTeacher()

    const [selectedTypes, setSelectedTypes] = React.useState<string[]>([])

    const classes = useTableStyles()
    const styles = useStyles()
    const { loading, startLoading, stopLoading } = useLoadingManager()

    React.useEffect(() => {
        if (selectedCategories.length || selectedGrades.length || selectedTopics.length || selectedTypes.length
            || searchValue || !showMyPrivate || showMyPrivate) {
            (async () => {
                startLoading()
                if (typeOfLibrary === 'video') await onVideoFilterChange()
                if (typeOfLibrary === 'pdf') await onPDFFilterChange()
                if (typeOfLibrary === 'quiz') await onQuizzFilterChange()
                if (typeOfLibrary === 'communityQuiz') await onCommunityQuizzFilterChange()
                if (typeOfLibrary === 'studentQuiz') await onStudentsQuizFilterChange()
                if (typeOfLibrary === 'all') await onGetAllFilterChange()
                stopLoading()
            })()
        }
    }, [selectedCategories, selectedGrades, selectedTopics, searchValue, showMyPrivate, selectedTypes])

    const onGetAllFilterChange = async () => {
        await filterLibrary([], selectedGrades, selectedTopics, selectedTypes, searchValue,
            getAllResources, filterAllResources, setRows)
    }

    const onVideoFilterChange = async () => {
        await filterLibrary(selectedCategories, selectedGrades, selectedTopics, [], searchValue,
            listVideos, filterVideos, setRows)
    }

    const onPDFFilterChange = async () => {
        await filterLibrary(selectedCategories, selectedGrades, selectedTopics, [], searchValue,
            listPDFs, filterPDFs, setRows)
    }

    const onQuizzFilterChange = async () => {
        await filterLibrary(selectedCategories, selectedGrades, selectedTopics, [], searchValue,
            listQuizzes, filterQuizzes, setRows)
    }

    const onCommunityQuizzFilterChange = async () => {
        await filterLibrary(selectedCategories, selectedGrades, selectedTopics, [], searchValue,
            listPublicCommunityQuizzes, filterCommunityQuizzes, setRows, showMyPrivate)
    }

    const onStudentsQuizFilterChange = async () => {
        await filterLibrary(selectedCategories, selectedGrades, selectedTopics, [], searchValue,
            listStudentQuizzes, filterStudentQuizzes, setRows, showMyPrivate)
    }

    const cancelSearch = () => {
        // setSelectedFitlers('')
        searchFunction('')
    }

    const handleChange = (event: any, setFunction: (input: any) => void) => {
        if (event.target.value.length === 0) setFunction([])
        setFunction(event.target.value)
    }

    const handleGradesChange = (event: any) => {
        if (event.target.value.length === 0) setSelectedGrades([])
        else {
            let newGrades = event.target.value as string[]

            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'indskoling', indskolingGradeLevels, selectedGrades)
            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'mellemtrin', mellemtrinGradeLevels, selectedGrades)
            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'udskoling', udskolingGradeLevels, selectedGrades)

            setSelectedGrades(newGrades)
        }
    }

    const getAvailableClasses = () => { // @ts-ignore
        const indexes = [...new Set(items.map((item) => item.grades).reduce((acc, curr) => [...acc, ...curr], []))]
            .map((i) => +i + 3)
        if (indexes.map((i) => i - 3).some((i) => [0, 1, 2, 3].includes(i))) {
            indexes.push(0)
        }
        if (indexes.map((i) => i - 3).some((i) => [4, 5, 6].includes(i))) {
            indexes.push(1)
        }
        if (indexes.map((i) => i - 3).some((i) => [7, 8, 9, 10].includes(i))) {
            indexes.push(2)
        }
        return indexes
    }

    const getAvailableCategories = () => { // @ts-ignore
        return [...new Set(items.map((item) => item.categories).reduce((acc, curr) => [...acc, ...curr], []))]
    }

    const getAvailableSubjects = () => { // @ts-ignore
        return [...new Set(items.map((item) => item.topics).reduce((acc, curr) => [...acc, ...curr], []))]
    }

    return (
        <>
            {typeOfLibrary === 'pdf' && (
                <p style={{
                    marginBottom: 40,
                    fontSize: 20,
                    textAlign: 'center',
                    width: '100%',
                    color: 'white'
                }}>
                    OBS: Under ombygning- men kig dig gerne omkring :)
                </p>
            )}
            <TableContainer className={classes.tableWithoutBottomButtons} component={Paper}>
                <div className={styles.outerContainer}>
                    <Typography variant="h6" style={{ textAlign: 'center', marginBottom: '2%' }}>
                        {headerText}
                    </Typography>
                    <Button
                        className={styles.innerContainer}
                        onClick={() => setTypeOfLibrary()}>
                        klik her for at søge på tværs af biblioteker
                    </Button>
                </div>
                <Grid
                    container
                    style={{
                        display: 'flex',
                        flex: 1,
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        paddingLeft: '1.5%',
                        paddingRight: '2.5%',
                    }}>
                    <FilterTemplate
                        filteredItems={items}
                        data={typeOfLibrary === 'pdf'
                            ? gradeLevels.filter((grade, index) => getAvailableClasses().indexOf(index) > -1)
                            : gradeLevels}
                        onChange={handleGradesChange}
                        text="Klassetrin"
                        selectedItems={selectedGrades} />
                    {typeOfLibrary !== 'all' && (
                        <FilterTemplate
                            filteredItems={items}
                            data={typeOfLibrary === 'pdf'
                                ? listOfCategories.filter((category) => getAvailableCategories().includes(category))
                                : listOfCategories}
                            onChange={(e) => handleChange(e, setSelectedCategories)}
                            text="Kategori"
                            selectedItems={selectedCategories} />
                    )}
                    <FilterTemplate
                        filteredItems={items}
                        data={typeOfLibrary === 'pdf'
                            ? listOfTopics.filter((subject) => getAvailableSubjects().includes(subject))
                            : listOfTopics}
                        onChange={(e) => handleChange(e, setSelectedTopics)}
                        text="Emner"
                        selectedItems={selectedTopics} />
                    {typeOfLibrary === 'all' && (
                        <FilterTemplate
                            filteredItems={items}
                            data={listOfLibraries}
                            onChange={(e) => handleChange(e, setSelectedTypes)}
                            text="Type"
                            selectedItems={selectedTypes} />
                    )}

                    <SearchBarContainer
                        searchComponent
                        value={searchValue}
                        onChange={(searchVal: string) => searchFunction(searchVal)}
                        onCancelSearch={() => cancelSearch()} />
                    {typeOfLibrary === 'communityQuiz' && (
                        <Button
                            variant="contained"
                            onClick={showMyPrivate ? () => setShowMyPrivate(false) : () => setShowMyPrivate(true)}>
                            {showMyPrivate ? 'Vis alle offentlige' : 'Vis mine quizzer'}
                        </Button>
                    )}
                </Grid>
                {loading ? <Loading />
                    : children}
            </TableContainer>
            <BottomBar onlyPicture />
        </>
    )
}
export default LibraryScreen
