import { Auth } from 'aws-amplify'

export const getCurrentUser = async () => {
    const res = await Auth.currentSession()
    const accessToken = res.getAccessToken()
    return accessToken
}

export const fileUploadFun = async (url) => {
    return url
}

export const getPuzzleImageFun = async () => {
    return null
}
