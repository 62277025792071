/* eslint-disable react/jsx-filename-extension */
import { useDraggable } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import React from 'react'

import styles from '../styles/play.module.css'

export default function Draggable({ id, children }) {
    const {
        attributes, listeners, setNodeRef, transform 
    } = useDraggable({
        id,
    })
    const style = {
    // Outputs `translate3d(x, y, 0)`
        transform: CSS.Translate.toString(transform),
    }

    return (
        <h1
            ref={setNodeRef}
            style={style}
            {...listeners}
            {...attributes}
            className={styles.dragBoxCont}>
            {children}
        </h1>
    )
}
