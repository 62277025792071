/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'

export default function BackSvg() {
    return (
        <svg
            width="50px"
            height="50px"
            viewBox="0 0 1024 1024"
            xmlns="http://www.w3.org/2000/svg">
            <path d="M224 480h640a32 32 0 1 1 0 64H224a32 32 0 0 1 0-64z" />
            <path d="m237.25 512 265.41 265.34a32 32 0 0 1-45.312 45.312l-288-288a32 32 0 0 1 0-45.312l288-288a32 32 0 1 1 45.312 45.312l-265.41 265.34z" />
        </svg>
    )
}
