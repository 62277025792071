/* eslint-disable no-redeclare */
import { Box, } from '@material-ui/core'
import React, { useEffect } from 'react'
import { addStyles, EditableMathField } from 'react-mathquill'

type Props = {
    answers: string[]
    onChange?(tags: string[]): void
    disabled?: boolean
    responses: string[]
}

addStyles()

export const MultipleAnswers: React.FC<Props> = ({
    onChange, answers: asw, disabled = false, responses
}) => {

    const [answers, setAnswers] = React.useState(asw)
    const [value, setValue] = React.useState('')

    useEffect(() => {
        if (disabled) {
            setAnswers([])
            setValue('')
            onChange!([])
        } else {
            setAnswers(responses)
            onChange!(responses)
        }

    }, [disabled])

    // @ts-ignore
    const handleTagInputKeyPress = (e: React.KeyboardEvent) => {
        if (e.key === 'Enter') {
            if (!answers.map((answer) => answer.toLowerCase()).includes(value.trim().toLowerCase())) {
                const newAnswers = [...answers, value]
                setAnswers(newAnswers)
                if (onChange) {
                    onChange(newAnswers)
                }
            }
            setValue('')
            e.preventDefault()
        }
    }

    const removeAnswer = (t: string) => {
        const filteredAnswers = answers.filter((tt: string) => tt !== t)
        setAnswers(filteredAnswers)
        if (onChange) {
            onChange(filteredAnswers)
        }
    }

    const typedAnswers = answers.map((t: string) => (
        <Box
            key={t}
            style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', alignItems: 'center', margin: 3
            }}>
            <p style={{
                display: 'flex',
                flexDirection: 'row',
                color: 'white',
                justifyContent: 'center',
                alignItems: 'center',
                textAlign: 'center',
                backgroundColor: '#485058',
                fontSize: 12,
                fontWeight: 300,
                marginBottom: 5,
                padding: 4,
                borderRadius: 5
            }}>{t}
                <button type="button" onClick={() => removeAnswer(t)}>x</button>
            </p>
        </Box>

    ))

    return (
        <>
            {!disabled && (
                <>
                    <EditableMathField
                        latex={value}
                        style={{
                            width: '100%',
                            height: 'auto',
                            padding: '10px',
                            fontSize: '20px'
                        }}
                        placeholder="Svar"
                        onKeyPress={handleTagInputKeyPress}
                        onChange={(mathField) => {
                            setValue(mathField.latex())
                        }}
                    />
                    <small style={{ color: '#c1c1c1' }}>Indtast svar på spørgsmålet og tryk enter.</small>
                </>
            )}
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                {typedAnswers}
            </div>

        </>
    )
}
