import { API } from 'aws-amplify'

import {
    CommunityQuizType, FileType, LibraryFilter, Question
} from '../../types/types'

export const getStudentQuizById = async (quizId: string): Promise<CommunityQuizType> => {
    const quiz = await API.get('api', `/quizzes/student/${quizId}`, {})
    return quiz
}
export const listStudentQuizzes = async (): Promise<CommunityQuizType[]> => {
    const quizzes = await API.get('api', '/quizzes/student/teacher', {})
    return quizzes
}

export const listStudentsQuizzes = async (): Promise<CommunityQuizType[]> => {
    const publicQuizzes = await API.get('api', '/quizzes/student/public', {})
    return publicQuizzes
}

export const createInitiallyStudentQuiz = async (title: string, topics: string[],
    categories: string[], grades: number[], isPublic: boolean,
    onlyOrderedResponse: boolean): Promise<CommunityQuizType> => {
    return API.post('api', '/quizzes/student', {
        body: {
            title, topics, categories, grades, isPublic, onlyOrderedResponse
        }
    })
}

export const finishCreatingStudentQuiz = async (quizId: string, description: string,
    questions: Question[], file: FileType | undefined): Promise<CommunityQuizType> => {
    return API.put('api', `/quizzes/student/${quizId}`, {
        body: {
            description, questions, file
        }
    })
}

export const updateStudentQuizQuestions = async (quizId: string, questions: Question[]):
    Promise<CommunityQuizType> => {
    return API.put('api', `/quizzes/student/${quizId}`, {
        body: {
            questions
        }
    })
}

export const addQuestionToStudentQuiz = async (
    quizId: string, title: string, explanation: string,
    responses: any[], vimeoVideoId?: string, youtubeVideoId?: string, picture?: FileType | undefined
): Promise<CommunityQuizType> => {
    return API.put('api', `/quizzes/student/question/${quizId}`, {
        body: {
            title, explanation, responses, vimeoVideoId, youtubeVideoId, picture
        }
    })
}
export const updateStudentQuiz = async (quizId: string, title: string, description: string,
    file: FileType | undefined, categories: string[], topics: string[],
    grades: number[], questions: Question[], isPublic: boolean,
    onlyOrderedResponse: boolean): Promise<CommunityQuizType> => {
    return API.put('api', `/quizzes/student/${quizId}`, {
        body: {
            title, description, categories, file, topics, grades, questions, isPublic, onlyOrderedResponse
        }
    })
}

export const deleteStudentQuiz = async (quizId: string) => {
    return API.del('api', `/quizzes/student/${quizId}`, {})
}
export const filterStudentQuizzes = async (filterParams: LibraryFilter): Promise<CommunityQuizType[]> => {
    const quizzes = await API.post('api', '/quizzes/student/filter', {
        body: filterParams
    })
    return quizzes
}
