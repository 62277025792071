import {
    Divider, List, ListItem, TablePagination, Typography
} from '@material-ui/core'
import * as React from 'react'

import { useTeacher } from '../../utils/TeacherManager'
import Alert from '../atoms/Alert'
import { LibraryContactForm } from './LibraryContactForm'
import PDFListItem from './PDFListItem'
import QuizListItem from './QuizListItem'
import VideoListItem from './VideoListItem'

interface Props {
    data: any[],
    typeOfLibrary: 'quiz' | 'video' | 'communityQuiz' | 'studentQuiz' | 'pdf' | 'all'
}

export const freePlan = {
    quiz: [
        '615acc6e0f75fb0017bf0ccb',
        '74e8a21e-d0f9-435a-a2ab-9366d2548738',
        '5fe1f512c7ccdb0017ec0047',
        '5f32505a3f17a11a0830b0df',
        '5f7a293a50b1f5686073712c'
    ],
    video: [
        'e18bacb4-b7f7-458f-95d4-9eee09e1e5a6',
        'a6d60168-f29f-44d9-aa4d-3635881d090f',
        '2b43a1e4-efa8-457c-b2d2-08ae4cacd516',
        '5cbc9dd9f96c1e0010c3d78a',
        '323225eb-d76b-4aef-93a6-56daba6e436a',
        '5c977df84ea4ef306c829adc',
        '5c977df84ea4ef306c829aef',
        '5e75511809bcec6af4b4800e',
        '5d3cd8417aae25d3aa623e29',
        '5c977df84ea4ef306c829add',
        '5d25aeba7aae25578b623e27',
        '5c977df84ea4ef306c829ae3'
    ]

}

const PaginatedList: React.FC<Props> = ({ data, typeOfLibrary }) => {
    const { teacher } = useTeacher()
    const applyFilter = () => {
        if (!teacher?.free) return data
        if (typeOfLibrary !== 'quiz' && typeOfLibrary !== 'video') return data
        
        return data.filter(
            (d) => freePlan[typeOfLibrary].includes(d.id)
        )
    }
    
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(5)
    const [homeworkError, setHomeworkError] = React.useState<boolean>(false)
    const [itemSuccessfullyAdded, setItemSuccessfullyAdded] = React.useState<boolean>(false)

    const currentItems = applyFilter().slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)

    const handleChangePage = (event: any, newPage: any) => {
        setPage(newPage)
    }

    const handleChangeRowsPerPage = (event: any) => {
        setRowsPerPage(parseInt(event.target.value, 10))
        setPage(0)
    }

    return (
        <>
            <List>
                {/* eslint-disable-next-line no-nested-ternary */}
                {data.length === 0
                    ? (typeOfLibrary === 'studentQuiz' ? (
                        <Typography
                            style={{
                                textAlign: 'center', marginTop: 10
                            }}
                            variant="h4">
                            Dine elever har ikke lavet nogle quizzer endnu. Se video herunder ift. hvordan
                            denne funktion virker
                        </Typography>
                    )
                        : (
                            <ListItem>
                                <LibraryContactForm />
                            </ListItem>
                        )
                    )
                    : currentItems.map((row) => {
                        return (
                            <div key={row.id}>
                                <ListItem key={row.id}>
                                    {
                                        // eslint-disable-next-line max-len
                                        ((typeOfLibrary === 'quiz' || typeOfLibrary === 'communityQuiz' || typeOfLibrary === 'studentQuiz')
                                            || (typeOfLibrary === 'all' && row.type === 'studentQuiz')
                                            || (typeOfLibrary === 'all' && row.type === 'communityQuiz')
                                            || (typeOfLibrary === 'all' && row.type === 'quiz'))
                                        && (
                                            <QuizListItem
                                                isStudents={
                                                    typeOfLibrary === 'studentQuiz'
                                                    || (typeOfLibrary === 'all' && row.type === 'studentQuiz')
                                                }
                                                isCommunity={typeOfLibrary === 'communityQuiz'}
                                                setItemAdded={setItemSuccessfullyAdded}
                                                student={row}
                                                item={row} />
                                        )
                                    }
                                    {(typeOfLibrary === 'pdf'
                                        || (typeOfLibrary === 'all' && row.type === 'pdf')) && (
                                        <PDFListItem
                                            item={row} />
                                    )}
                                    {
                                        (typeOfLibrary === 'video'
                                            || (typeOfLibrary === 'all' && row.type === 'video')) && (
                                            <VideoListItem
                                                setItemAdded={setItemSuccessfullyAdded}
                                                item={row} />
                                        )
                                    }
                                    {/* {
                                    typeOfLibrary === 'all' && (
                                        <>
                                            <Typography variant="h6">
                                                {row.title}
                                            </Typography>
                                        </>
                                    )
                                } */}
                                </ListItem>
                                <Divider variant="inset" component="li" />
                            </div>
                        )
                    })}
            </List>
            <TablePagination
                labelRowsPerPage="Antal pr. side"
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={applyFilter().length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                labelDisplayedRows={({ from, to, count }) => `${from}-${to} af ${count !== -1 ? count : to}`}
                nextIconButtonProps={{ title: 'Næste side' }}
                backIconButtonProps={{ title: 'Forrige side' }} />
            <Alert
                text="Ingen lektier valgt!"
                setOpen={setHomeworkError}
                open={homeworkError}
                type="alert" />
            <Alert
                text="Lektie er nu oprettet."
                setOpen={setItemSuccessfullyAdded}
                open={itemSuccessfullyAdded}
                type="success" />
        </>
    )
}
export default PaginatedList
