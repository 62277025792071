import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { BottomBar } from '../../components/molecules/BottomBar'
import CreateAssignmentScreen from '../../components/organisms/CreateAssignmentScreen'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { useTeacher } from '../../utils/TeacherManager'

export const QuickCreateHomeworkPage = () => {
    const { fetchHomeworks, fetchClassNames } = useTeacher()

    const { state } = useLocation()
    const history = useHistory()
    const videos = state?.videoId && [state.videoId as string]
    const quizzes = state?.quizId && [state.quizId as string]

    const handleFinish = () => {
        // to update default homework screen
        fetchHomeworks.execute()
        // to ensure class names on homework screen are correct after creating a homework
        fetchClassNames.execute()
        history.push('/lektier')
    }
    
    return (
        <BodyWithTopBar>
            <CreateAssignmentScreen // Need to change this prop here
                selectedQuizzes={quizzes}
                selectedVideos={videos}
                setAfterCreate={handleFinish}/>
            <BottomBar onlyPicture />
        </BodyWithTopBar>
    )
}
