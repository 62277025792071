import {
    Button, Paper, TableContainer, ThemeProvider
} from '@material-ui/core'
import { Auth } from 'aws-amplify'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import GoBackHeader from '../../components/atoms/GoBackHeader'
import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import { Logo } from '../../components/molecules/Logo'
import { TextInputTemplate } from '../../components/molecules/TextInputTemplate'
import { Body } from '../../components/wrappers/Body'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { PricePlan } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import {
    createSubscription,
    getSubscriptionSession,
    prepareSubscription,
    validateCoupon
} from '../../utils/Services/reepay-service'
import { buttonTheme, useTableStyles } from '../../utils/styles'
import { fireWrap } from '../../utils/wrap-functions'

declare const Reepay: any

interface IncomingProps {
    email: string,
    id: string,
    lastName: string,
    pricePlan: PricePlan,
    coupon?: string
}

export const ConfirmSignUp: React.FC = () => {
    const buttonClasses = buttonStyles()
    const tableClasses = useTableStyles()
    const history = useHistory()

    const [code, setCode] = React.useState<string>('')
    const [completing, setCompleting] = React.useState(false)

    const { state }: { state: IncomingProps } = useLocation()

    const rpModalRef = React.useRef(null)

    const [sessionId, setSessionId] = React.useState<string>('')

    React.useEffect(() => {
        if (sessionId) {
            rpModalRef.current = new Reepay.WindowSubscription(sessionId)
        }
    }, [sessionId])

    const onComplete = async () => {
        if (code.length < 6) {
            showMessage('Din kode skal indeholde 6 tegn.', 'error')
            return
        }

        fireWrap(async () => {
            try {
                await Auth.confirmSignUp(state.email, code.trim())
            } catch (error) {
                if (error.code === 'CodeMismatchException') throw Error('Der er noget galt med din kode- tjek igen')
                if (error.code === 'ExpiredCodeException') {
                    throw Error('Din kode er udløbet (anmod om en ny), eller du er allerede bekræftet (log ind)')
                }
                throw Error(error)
            }

            if (state.coupon) {
                const v = await validateCoupon(state.coupon)
                if (!v.isValid) {
                    throw Error('Kuponen er ikke gyldig.')
                }
                if (v.discountPercent === 1) {
                    await createSubscription(state.id, state.email,
                        state.lastName, state.pricePlan, [state.coupon])
                    history.push('/login')
                    return
                }

                const reepayResult = await prepareSubscription(state.id, state.email,
                    state.lastName, state.pricePlan, [state.coupon])

                const subscriptionSessionId = await getSubscriptionSession(reepayResult.handle)

                if (subscriptionSessionId) {
                    setSessionId(subscriptionSessionId.id)
                }
            } else if (state.pricePlan === 'free') {
                history.push('/login')
            } 
                    
        }, {
            operation: 'Confirming Account',
            stateFunc: setCompleting,
            showSuccess: true
        })
    }

    const resendCode = async () => {
        fireWrap(async () => {
            await Auth.resendSignUp(state.email)
        }, {
            operation: 'Resending Code',
            stateFunc: setCompleting,
            showSuccess: true
        })
    }
    if (completing) {
        return <Loading />
    }

    return (
        <>
            <div style={{
                display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'
            }}>
                <Logo />
            </div>
            <Body>
                <form noValidate>
                    <>
                        <TableContainer
                            style={{ backgroundColor: 'rgba(252,252,252,0.6)' }}
                            className={tableClasses.table}
                            component={Paper}>
                            <GoBackHeader buttonText="Back to login" onClick={() => history.push('/login')} />
                            <div style={{
                                display: 'flex', flexDirection: 'column', paddingRight: 30, paddingLeft: 30
                            }}>
                                <TextInputTemplate
                                    value={code}
                                    onChange={setCode}
                                    label="Skriv din kode" />
                                <small style={{ fontFamily: 'Encode, sans-serif' }}>
                                    <strong>
                                        Vi har sendt en bekræftelseskode til din mail
                                    </strong>
                                </small>
                            </div>
                        </TableContainer>
                        <ThemeProvider theme={buttonTheme}>
                            <Button
                                className={buttonClasses.leftButton}
                                variant="contained"
                                color="primary"
                                disabled={completing || !code}
                                onClick={onComplete}>
                                Fuldfør profil-oprettelse
                            </Button>
                            <Button
                                className={buttonClasses.leftButton}
                                variant="contained"
                                color="secondary"
                                disabled={completing}
                                onClick={resendCode}>
                                Send kode igen
                            </Button>
                        </ThemeProvider>
                    </>
                </form>
                <BottomBar onlyPicture />
            </Body>
        </>
    )
}
