import { API } from 'aws-amplify'

import { PuzzleType, UpdatePuzzleType } from '../../types/types'

export const getPuzzleById = async (id: string): Promise<PuzzleType> => {
    const puzzle = await API.get('openapi', `/puzzle/${id}`, {})
    return puzzle
}

export const listPuzzles = async (): Promise<PuzzleType[]> => {
    const puzzle = await API.get('openapi', '/puzzle', {})
    return puzzle
}

export const listAdminPuzzles = async (): Promise<PuzzleType[]> => {
    const puzzle = await API.get('openapi', '/puzzle/admin', {})
    return puzzle
}

export const createPuzzle = async (puzzle: UpdatePuzzleType): Promise<PuzzleType> => {
    const newPuzzle = await API.post('api', '/puzzle', {
        body: puzzle,
    })
    return newPuzzle
}

export const createAdminPuzzle = async (puzzle: UpdatePuzzleType): Promise<PuzzleType> => {
    const newPuzzle = await API.post('api', '/puzzle/admin', {
        body: puzzle,
    })
    return newPuzzle
}

export const updatePuzzle = async (puzzle: PuzzleType): Promise<PuzzleType> => {
    const updatedPuzzle = await API.put('api', `/puzzle/${puzzle.id}`, {
        body: puzzle,
    })
    return updatedPuzzle
}

export const deletePuzzle = async (id: string): Promise<PuzzleType> => {
    return API.del('api', `/puzzle/${id}`, {})
}
