/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'

export default function CheckSvg() {
    return (
        <svg
            width="50px"
            height="50px"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            stroke="#000">
            <path
                d="m6 12 4.2426 4.2426 8.4844-8.4853"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"/>
        </svg>
    )
}
