import { createStyles, makeStyles } from '@material-ui/core'
import * as React from 'react'
import { ElfsightWidget } from 'react-elfsight-widget'

import HomeImage from '../../assets/homeImage.png'

interface Props {
    count?: number
}

const useStyles = makeStyles(() => createStyles({
    image: {
        position: 'relative',
        maxWidth: '25%',
        height: 'auto',
        marginLeft: '75%',
        marginBottom: -5,
        zIndex: -1,
        // opacity: 0.6
    }
}))

export const BackgroundImage: React.FC<Props> = () => {
    const classes = useStyles()
    return (
        <>
            <img className={classes.image} src={HomeImage} alt="People and number" />
            <ElfsightWidget widgetID="6bae44f7-e074-4f3d-aa0b-3a0a41203c21" />
        </>
    )
}
