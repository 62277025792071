import { FormGroup, Popover, Typography } from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import * as React from 'react'

import {
    gradeLevels, indskolingGradeLevels, mellemtrinGradeLevels,
    possibleQuizCategories, possibleQuizTopics, udskolingGradeLevels
} from '../../utils/globalData'
import { getGradesByKeyword } from '../../utils/helper'
import { BasicMultipleSelect } from '../atoms/BasicMultipleSelect'
import SwitchWithSideText from '../atoms/SwitchWithSideText'
import { TextInputTemplate } from './TextInputTemplate'

interface Props {
    title: string,
    setTitle: (title: string) => void,
    topics: string[],
    setTopics: (topics: string[]) => void,
    categories: string[],
    setCategory: (categories: string[]) => void,
    openQuiz: boolean,
    publicQuiz: boolean,
    setPublicQuiz: (isPublic: boolean) => void,
    setOpenQuiz: (isOpen: boolean) => void,
    selectedGrades: string[],
    setSelectedGrades: (selectedGrades: string[]) => void
}

export const FirstCreateCommunityQuizStep: React.FC<Props> = ({
    title,
    setTitle,
    categories,
    topics,
    setTopics,
    setCategory,
    openQuiz,
    publicQuiz,
    setPublicQuiz,
    setOpenQuiz, selectedGrades, setSelectedGrades
}) => {

    const handleGradesChange = (event: any) => {
        if (event.target.value.length === 0) setSelectedGrades([])
        else {
            let newGrades = event.target.value as string[]

            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'indskoling', indskolingGradeLevels, selectedGrades)
            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'mellemtrin', mellemtrinGradeLevels, selectedGrades)
            newGrades = getGradesByKeyword(event.target.value,
                newGrades, 'udskoling', udskolingGradeLevels, selectedGrades)

            setSelectedGrades(newGrades)
        }
    }
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const popoverOpen = Boolean(anchorEl)
    return (
        <FormGroup>
            <TextInputTemplate
                value={title}
                onChange={setTitle}
                label="Giv quiz en navn" />
            <BasicMultipleSelect
                label="Kategori"
                value={categories}
                setValue={setCategory}
                possibleValues={possibleQuizCategories} />
            <BasicMultipleSelect
                label="Emner"
                value={topics}
                setValue={setTopics}
                possibleValues={possibleQuizTopics} />
            <BasicMultipleSelect
                label="Klassetrin"
                value={selectedGrades}
                setValue={() => true}
                onChange={handleGradesChange}
                possibleValues={gradeLevels} />
            <SwitchWithSideText
                falseText="Lukket rækkefølge"
                trueText="Åben rækkefølge"
                checked={openQuiz}
                setChecked={setOpenQuiz} />
            <SwitchWithSideText
                falseText="Privat quiz"
                trueText="Offentlig quiz"
                checked={publicQuiz}
                setChecked={setPublicQuiz} />
            <div style={{ width: '100%', display: 'flex' }}>
                <Typography
                    aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                    aria-haspopup="true"
                    onMouseEnter={handlePopoverOpen}
                    onMouseLeave={handlePopoverClose}
                    style={{
                        cursor: 'pointer',
                        color: '#03a9f4',
                        marginLeft: 'auto',
                        marginRight: 'auto'
                    }}>
                    <HelpIcon />
                </Typography>
                <Popover
                    id="mouse-over-popover"
                    style={{ pointerEvents: 'none' }}
                    disableAutoFocus
                    disableEnforceFocus
                    open={popoverOpen}
                    anchorEl={anchorEl}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                    }}
                    onClose={handlePopoverClose}>
                    <Typography style={{ paddingLeft: 10, paddingRight: 10 }}>
                        <p><b>&quot;Lukket rækkefølge&quot;</b> betyder at opgaverne<br />
                            SKAL  besvares i kronologisk rækkefølge.<br />
                        </p>
                        <p>Ved <b>&quot;Åben rækkefølge&quot;</b> kan eleverne selv<br />
                            bestemme rækkefølgen på opgaverne.
                        </p>
                        <p><b>&quot;Privat quiz&quot;</b> betyder at det kun er dig der <br />
                            har adgang til quizzen.
                        </p>
                        <p><b>&quot;Offentlig quiz&quot;</b> betyder at du deler din<br />
                            quiz med andre lærere under ”lærerquizzer”<br />
                        </p>
                    </Typography>
                </Popover>

            </div>
        </FormGroup>
    )
}
