import React from 'react'
import {
    Redirect, Route, useHistory, useLocation 
} from 'react-router-dom'

import Routes from '../utils/Routes'
import { useTeacher } from '../utils/TeacherManager'

type Props = {
    children: any,
    exact?: boolean,
    path: string,
    signedIn?: boolean,
    type: 'auth' | 'not-auth' | 'any'
}

const AppRoute: React.FC<Props> = ({
    children, exact, path, type, signedIn
}) => {
    const history = useHistory()
    const { pathname, search, state } = useLocation()
    const { isParent } = useTeacher()
    React.useEffect(() => {
        if (isParent && (!['/', '/quiz', '/help', '/profil', '/quizbibliotek',
            '/videobibliotek', '/about', '/spil', ...Object.values(Routes)
                .filter((r) => !r.secure)].includes(pathname))) {
            history.push('/')
        }
    }, [pathname, isParent])

    let inner = children
    if (type === 'not-auth') {
        if (signedIn === true) {
            inner = <Redirect to={state ? state.redirect : '/'} />
        } else if (signedIn === undefined) {
            inner = <></>
        }
    } else if (type === 'auth') {
        if (signedIn === false) {
            inner = <Redirect to={{ pathname: '/login', state: { redirect: `${pathname}${search}` } }} />
        } else if (signedIn === undefined) {
            inner = <></>
        }
    }

    if (isParent) {
        window.location.href = 'https://glad.geekster.dk'
        return null
    }

    return (
        <Route
            {...exact}
            path={path}>
            { inner }
        </Route>
    )
}

export default AppRoute
