/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'

export default function DeleteSvg() {
    return (
        <svg
            width="50px"
            height="50px"
            fill="none"
            stroke="#000"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path
                d="m10 11v6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"/>
            <path
                d="m14 11v6"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"/>
            <path
                d="m4 7h16"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"/>
            <path
                d="m6 7h12v11c0 1.6569-1.3431 3-3 3h-6c-1.6568 0-3-1.3431-3-3v-11z"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"/>
            <path
                d="m9 5c0-1.1046 0.89543-2 2-2h2c1.1046 0 2 0.89543 2 2v2h-6v-2z"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"/>
        </svg>
    )
}
