import { createStyles, makeStyles, Typography } from '@material-ui/core'
import * as React from 'react'
import Lottie from 'react-lottie-player'

import LoadingAnimation from '../../assets/loading.json'

const useStyles = makeStyles((ThemeProvider) => createStyles({
    root: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        padding: ThemeProvider.spacing(10, 0)
    },
}),)

export const Loading: React.FC = () => {
    const classes = useStyles()
    return (
        <div className={classes.root}>
            <Lottie
                animationData={LoadingAnimation}
                play
                style={{
                    width: 500,
                    height: 500
                }} />
            <Typography align="center" variant="h3">Gør klar til at nørde</Typography>
        </div>
    )
}
