/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Button,
    createStyles,
    Divider,
    makeStyles, Paper, TableContainer, Typography
} from '@material-ui/core'
import * as React from 'react'
import ReactPlayer from 'react-player'
import { useHistory, useLocation } from 'react-router-dom'

import GoBackHeader from '../../components/atoms/GoBackHeader'
import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import { QuizItemComponent } from '../../components/molecules/QuizItemComponent'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { HomeworkType, QuizLibraryType, VideoType } from '../../types/types'
import { getHomework, getHomeWorkQuizzes, getHomeWorkVideos } from '../../utils/Services/homework-service'
import { useTableStyles } from '../../utils/styles'

const useStyles = makeStyles(() => createStyles({
    videoItem: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        paddingBottom: '4%',
    }
}))

export const HomeworkFlow: React.FC = () => {
    const classes = useTableStyles()
    const styleClasses = useStyles()
    const history = useHistory()
    const location: any = useLocation()
    const homeworkId = location.state ? location.state.id : undefined

    const [homework, setHomework] = React.useState<HomeworkType>()
    const [homeworkVideos, setHomeworkVideos] = React.useState<VideoType[]>([])
    const [homeworkQuizzes, setHomeworkQuizzes] = React.useState<QuizLibraryType[]>([])
    const [loading, setLoading] = React.useState<boolean>(true)

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            const fetchedHomework = await getHomework(homeworkId)
            if (fetchedHomework) {
                let fetchedVideos: VideoType[] = []
                let fetchedQuizzes: QuizLibraryType[] = []
                if (fetchedHomework.quizzes.length > 0) {
                    fetchedQuizzes = await getHomeWorkQuizzes(homeworkId)
                }
                if (fetchedHomework.videos.length > 0 && fetchedHomework.quizzes.length === 0) {
                    fetchedVideos = await getHomeWorkVideos(homeworkId)
                }
                setHomework(fetchedHomework)
                setHomeworkVideos(fetchedVideos)
                setHomeworkQuizzes(fetchedQuizzes)
            }
            setLoading(false)

        })()
    }, [])

    if (loading) {
        return <Loading />
    }

    return (
        <BodyWithTopBar>
            <TableContainer className={classes.tableWithoutBottomButtons} component={Paper}>
                <GoBackHeader headerText={homework ? homework.name : ''} onClick={() => history.goBack()} />
                {homeworkVideos.length > 0 && homeworkVideos.map((item) => (
                    <div className={styleClasses.videoItem}>
                        <Typography variant="body1" style={{ textAlign: 'center', marginBottom: 10 }}>
                            <b>{item.title}</b>
                        </Typography>
                        <ReactPlayer
                            url={`https://player.vimeo.com/video/${item.vimeoVideoId}`}
                            width="100%"
                            style={{ marginTop: 15, marginBottom: 15 }}
                            controls />
                        <Button variant="contained" onClick={() => window.open(item?.mainFile?.uri, '_blank')}>
                            Download denne opgavefil
                        </Button>
                        {item.supplementUri?.map((l) => (
                            <a
                                target="_blank"
                                href={l}
                                style={{ marginTop: 15, marginBottom: 15 }}
                                rel="noreferrer"> Link til Mattips opgaver om emnet
                            </a>
                        ))}
                    </div>
                ))}
                {homeworkQuizzes.length > 0 && homeworkQuizzes.map((quiz, index) => {
                    return (
                        <div
                            key={quiz.id}
                            style={{
                                marginTop: index !== 0 ? '5vh' : 0
                            }}>
                            <QuizItemComponent quiz={quiz} />
                            {index !== homeworkQuizzes.length - 1 && (
                                <Divider style={{
                                    marginTop: (index !== homeworkQuizzes.length - 1) ? '2.5vh' : 0
                                }} />
                            )}
                        </div>
                    )
                })}
            </TableContainer>
            <BottomBar onlyPicture />
        </BodyWithTopBar>
    )
}
