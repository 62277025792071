import { Button, CircularProgress, Select } from '@material-ui/core'
import * as React from 'react'
import { useState } from 'react'

import { HomeworkType } from '../../types/types'

interface Props {
    homeworks: HomeworkType[],
    loading: boolean,
    onSubmit: (homework: HomeworkType|undefined) => void
}

const SelectWithButton: React.FC<Props> = ({
    homeworks,
    onSubmit, loading
}) => {
    const [selectedValue, setSelectedValue] = useState<string>()

    const handleSubmit = () => {
        if (!selectedValue) {
            return
        }

        const homework = homeworks.find((h) => h.id === selectedValue)
        onSubmit(homework)
    }

    return (
        <>
            <Select
                MenuProps={{
                    getContentAnchorEl: null,
                }}
                native
                value={selectedValue}
                onChange={(event) => setSelectedValue(event.target.value as string|undefined)}>
                <option>Vælg en lektie fra listen</option>

                <option
                    style={{
                        borderStyle: 'solid',
                        borderColor: '#70D1F9',
                        borderWidth: 1,
                        backgroundColor: '#dae8fc',
                        borderRadius: 8,
                        padding: '8px 0'

                    }}
                    value="new"> + Opret, og tilføj denne til ny lektie
                </option>

                {homeworks && homeworks.map((item) => (
                    <option key={item.id} value={item.id}>{item.name}</option>
                ))}
            </Select>

            <Button
                style={{ margin: 10, height: 40 }}
                disabled={!selectedValue}
                variant="contained"
                color="secondary"
                onClick={handleSubmit}>
                {loading && <CircularProgress color="secondary" />}
                Tilføj til lektie
            </Button>
        </>
    )
}
export default SelectWithButton
