import { Paper, TableContainer } from '@material-ui/core'
import * as React from 'react'

import { useTableStyles } from '../../utils/styles'
import FormButtons from '../atoms/FormButtons'
import GoBackHeader from '../atoms/GoBackHeader'

interface Props {
    setCreateNew: (boolean: boolean) => void,
    create: () => void,
    deleteFunction?: () => void,
    headerText: string,
    createButtonText?: string,
    deleteButtonText?: string,
    disableButtons: boolean,
    disableCreate?: boolean,
    goBackFunction?: () => void,
    goBackText?: string
}

const CreateScreenTemplate: React.FC<Props> = ({
    headerText,
    createButtonText,
    deleteButtonText,
    children,
    setCreateNew,
    create,
    deleteFunction,
    disableButtons,
    disableCreate,
    goBackFunction,
    goBackText
}) => {
    const tableClasses = useTableStyles()

    const handleGoBack = () => {
        if (goBackFunction) goBackFunction()
        else setCreateNew(false)
    }
    return (
        <form noValidate>
            <TableContainer className={tableClasses.table} component={Paper}>
                <GoBackHeader headerText={headerText} buttonText={goBackText} onClick={handleGoBack} />
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    {children}
                </div>
            </TableContainer>
            <FormButtons
                createButtonText={createButtonText}
                onClickCreate={create}
                onClickDelete={deleteFunction}
                deleteButtonText={deleteButtonText}
                disableCreate={disableCreate}
                disableButtons={disableButtons}/>
        </form>
    )
}
export default CreateScreenTemplate
