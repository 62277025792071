import CheckIcon from '@material-ui/icons/Check'
import React, { useEffect } from 'react'

import { VimeoVideoType } from '../../types/types'
import { useLoadingManager } from '../../utils/hooks'
import { showMessage } from '../../utils/notifications'
import { validateVimeoVideo } from '../../utils/Services/video-service'
import { TextInputTemplate } from './TextInputTemplate'

type Props = {
    vimeoVideoId: string | undefined,
    value: string,
    setValue: (value: string) => void,
    onChange?(vimeoVideo: VimeoVideoType): void
    onBusy?(yes: boolean): void,
    isDisabled?: boolean
}

const decodeVimeoImageId = (id: string): string | undefined => {
    const match = id.match(/^(?:.*vimeo\.com.*\/)?(\d{7,9})(?:\/.*)?$/)
    if (match) {
        return match[1]
    }

    return undefined
}

export const VimeoVideoInput: React.FC<Props> = ({
    vimeoVideoId: vmId, onChange, onBusy, isDisabled, value, setValue
}) => {
    const { loading, startLoading, stopLoading } = useLoadingManager()
    const [state, setState] = React.useState<'unknown' | 'valid' | 'invalid'>('unknown')

    const vimeoVideoId = decodeVimeoImageId(value || '')

    useEffect(() => {
        validateDetails().then()
    }, [vmId])

    const setValue1 = (v: string) => {
        setValue(v)
        setState('unknown')
    }

    const handleKeyPress = (key: any, e: React.KeyboardEvent) => {
        if (key.toString() === 'Enter' && vimeoVideoId) {
            validateDetails().then()
            e.preventDefault()
        }
    }

    const validateDetails = async () => {
        let vimeoVideoDetails: VimeoVideoType | undefined

        if (vimeoVideoId && state === 'unknown') {
            startLoading()
            if (onBusy) {
                onBusy(true)
            }

            try {
                vimeoVideoDetails = await validateVimeoVideo(vimeoVideoId)
            } catch (ex: any) {
                showMessage(`Could not get Vimeo details for ${vimeoVideoId}`, ex.message)
            }

            stopLoading()
            if (onBusy) {
                onBusy(false)
            }
        }

        if (onChange && vimeoVideoDetails) {
            onChange(vimeoVideoDetails)
        }

        setState(vimeoVideoDetails ? 'valid' : 'invalid')
    }

    let underText = 'Video ID kan findes på din Vimeo profil'
    if (state === 'invalid') {
        underText = 'Id -videoen er ugyldig'
    } else if (state === 'valid') {
        underText = ''
    }

    return (
        <div style={{
            display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'
        }}>

            <TextInputTemplate
                fullWidth
                underText={underText}
                label={loading ? 'Indlæser...' : 'Vimeo Video ID'}
                onBlur={validateDetails}
                onKeyPress={handleKeyPress}
                disabled={loading || isDisabled}
                value={value}
                error={state === 'invalid'}
                onChange={setValue1}
                type="default" />
            {state === 'valid' && (
                <CheckIcon style={{ color: 'green' }} />
            )}
        </div>
    )
}
