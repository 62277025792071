import {
    Button, Checkbox, createStyles, FormControlLabel, FormGroup, makeStyles, Paper, TableContainer, Typography
} from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import Tooltip from '@material-ui/core/Tooltip'
import * as React from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'

import Config from '../../config'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { ClassType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { createStudentSignUpLink } from '../../utils/Services/student-service'
import { useTableStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'
import { wrap } from '../../utils/wrap-functions'
import BasicTable from '../molecules/BasicTable'
import CreateScreenTemplate from '../molecules/CreateScreenTemplate'
import { Loading } from '../molecules/Loading'

type Props = {
    onFinish(): void
    setInvite: (boolean: boolean) => void
}

const useStyles = makeStyles(() => createStyles({
    createText: {
        marginTop: 15
    },
}))

const InviteStudentScreen: React.FC<Props> = ({ onFinish, setInvite }) => {
    const { fetchClasses } = useTeacher()
    const { result, error, loading }:
        { result: ClassType[], error: Error, loading: boolean } = fetchClasses

    const [busy, setBusy] = React.useState(false)
    const [link, setLink] = React.useState<string>()
    const [copiedText, setCopiedText] = React.useState<string>()
    const [selectedClasses, setSelectedClasses] = React.useState<string[]>([])

    const styled = useStyles()
    const tableClasses = useTableStyles()
    const buttonClasses = buttonStyles()

    React.useEffect(() => {
        if (result.length) setSelectedClasses(result.map((c) => c.id))
    }, [result])

    const toggleSelectedClass = (id: string) => {
        if (selectedClasses.includes(id)) {
            setSelectedClasses(selectedClasses.filter((cid) => cid !== id))
        } else {
            setSelectedClasses([...selectedClasses, id])
        }
    }

    const createLink = async () => {
        await wrap(async () => {
            const intent = await createStudentSignUpLink(selectedClasses)
            setLink(Config.StudentSignUpUri + intent.id)
        }, {
            operation: 'Generating Link',
            stateFunc: setBusy
        })
    }

    if (busy || loading) {
        return <Loading />
    }
    if (error) {
        showMessage(error.message)
    }

    if (link) {
        return (
            <TableContainer className={tableClasses.table} component={Paper}>
                <Typography variant="h6" style={{ textAlign: 'center' }}>Tilmeld elever link</Typography>
                <BasicTable>
                    <Grid
                        item
                        lg={12}
                        component={Box}
                        paddingLeft="15px"
                        paddingRight="15px">
                        <CopyToClipboard
                            text={link}
                            onCopy={() => setCopiedText(link)}>
                            <Tooltip
                                style={{ fontWeight: 600 }}
                                title={
                                    copiedText === link
                                        ? 'Dette blev kopieret!'
                                        : 'Kopier til udklipsholder'
                                }
                                placement="top">
                                <Box
                                    className={buttonClasses.copyClipboard}
                                    component="button"
                                    fontFamily="inherit"
                                    fontSize="16px"
                                    fontWeight="400"
                                    lineHeight="1.25"
                                    display="flex"
                                    width="fit-content"
                                    padding="24px"
                                    textAlign="left"
                                    color="#000"
                                    border="0"
                                    borderRadius="4px"
                                    data-clipboard-text="album-2">
                                    <div>
                                        {link}
                                    </div>
                                </Box>
                            </Tooltip>
                        </CopyToClipboard>
                    </Grid>
                    <Grid />
                    <Typography style={{ color: '#00b3fc', textAlign: 'center' }} variant="h6">
                        OBS: linket udløber efter i 7 dage
                    </Typography>
                    <Button
                        style={{ marginLeft: 15 }}
                        className={buttonClasses.forgotPassButton}
                        type="button"
                        onClick={onFinish}>
                        Færdig!
                    </Button>

                </BasicTable>
            </TableContainer>
        )
    }

    return (
        <>
            <CreateScreenTemplate
                headerText="Mine elever"
                createButtonText="Opret elevlink"
                setCreateNew={setInvite}
                create={createLink}
                disableButtons={busy}>

                <Typography className={styled.createText} variant="body1">Klasser:</Typography>
                {result && result.length > 0 ? (
                    <FormGroup row>
                        {result?.map((item) => (
                            <FormControlLabel
                                key={item.id}
                                control={(
                                    <Checkbox
                                        name={item.title}
                                        checked={selectedClasses.includes(item.id)}
                                        onClick={() => toggleSelectedClass(item.id)} />
                                )}
                                label={item.title} />
                        ))}
                    </FormGroup>
                )
                    : <Typography variant="body1">Ingen klasser</Typography>}
            </CreateScreenTemplate>
        </>
    )
}

export default InviteStudentScreen
