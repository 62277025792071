/* eslint-disable no-useless-concat */
/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'

import styles from '../styles/puzzleEditor.module.css'

export default function DoubleMath({ addMathKey }) {
    const [inputOne] = useState(0)
    const [inputTwo] = useState(0)

    const [inputThree] = useState(0)
    const [inputFour] = useState(0)

    const handleKeyEvent = (key, event) => {
        event.preventDefault()
        if (key === 0) {
            const expression = '\u005C' + `sqrt[${inputOne}]{${inputTwo}}`
            addMathKey(expression)
        } else if (key === 1) {
            const expression = '\u005C' + `frac{${inputThree}}{${inputFour}}`
            addMathKey(expression)
        }
    }

    return (
        <div className={styles.mathButtonsCont}>
            <div className={styles.mathButtonsInner}>
                {/* <input
          className={styles.smallInput}
          type="number"
          min={0}
          value={inputOne || 0}
          onChange={(e) => setInputOne(e.target.value)}
        />
        <input
          className={styles.smallInput}
          type="number"
          min={0}
          value={inputTwo || 0}
          onChange={(e) => setInputTwo(e.target.value)}
        /> */}
                <button
                    type="button"
                    className={styles.mathButtons}
                    onClick={(e) => handleKeyEvent(0, e)}>
                    ˣ√n
                </button>
            </div>
            <div className={styles.mathButtonsInner}>
                {/* <input
          className={styles.smallInput}
          type="number"
          min={0}
          value={inputThree || 0}
          onChange={(e) => setInputThree(e.target.value)}
        />
        <input
          className={styles.smallInput}
          type="number"
          min={0}
          value={inputFour || 0}
          onChange={(e) => setInputFour(e.target.value)}
        /> */}
                <button
                    type="button"
                    className={styles.mathButtons}
                    onClick={(e) => handleKeyEvent(1, e)}>
                    x/n
                </button>
            </div>
        </div>
    )
}
