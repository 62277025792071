import { Auth, Hub } from 'aws-amplify'
import moment from 'moment'
import React from 'react'
import { BrowserRouter as Router, Switch } from 'react-router-dom'

import { Loading } from '../components/molecules/Loading'
import { NotificationPopup, Severity } from '../components/molecules/NotificationPopup'
import PaymentModal from '../components/molecules/PaymentModal'
import { TeacherType } from '../types/types'
import { channelName } from '../utils/notifications'
import Routes from '../utils/Routes'
import { useCognito } from '../utils/Services/cognito-service'
import {
    getActiveCustomerSubscriptions
} from '../utils/Services/reepay-service'
import { getById, updateTeacher } from '../utils/Services/teacher-service'
import { useTeacher } from '../utils/TeacherManager'
import { fireWrap } from '../utils/wrap-functions'
import { About } from './About'
import AppRoute from './AppRoute'
import { AssignmentDetails } from './AssignmentDetails'
import { AssignmentPage } from './Assignments'
import { ClassesPage } from './Classes'
import { CommunityQuizItem } from './CommunityQuizItem'
import { CommunityQuizLibraryPage } from './CommunityQuizLibrary'
import { CommunityQuizzes } from './CommunityQuizzes'
import { CommunityQuizzesTac } from './CommunityQuizzesTac'
import { CompleteLogInWithUniLogin } from './CompleteLogInWithUniLogin'
import { CompleteSignUpWithUniLogin } from './CompleteSignUpWithUniLogin'
import { ConfirmForgotPassword } from './ConfirmForgotPassword'
import { ConfirmSignUp } from './ConfirmSignUp'
import { Contact } from './Contact'
import { GamesLibraryPage } from './GamesLibrary'
import { Geekster360Game } from './Geekster360Game'
import { Help } from './Help'
import { HomePage } from './Home'
import { HomeworkFlow } from './HomeworkFlow'
import { Login } from './Login'
// import { GeeksterOffersPage } from './Offers'
import { OversightPage } from './Oversight'
import { PDFLibraryPage } from './PDFLibary'
import { PDFSharePage } from './PDFSharePage'
import { PrivacyPolicy } from './PrivacyPolicy'
import { Profile } from './Profile'
import PuzzleHome from './Puzzle/pages'
import PuzzlePlay from './Puzzle/pages/play'
import { QuickCreateHomeworkPage } from './QuickCreateHomework'
import { QuizItem } from './QuizItem'
import { QuizzLibaryPage } from './QuizLibary'
import { SchoolPlans } from './SchoolPlans/SchoolPlans'
import { SignUp } from './SignUp'
import { StudentsPage } from './Students'
import { StudentsQuizzLibaryPage } from './StudentsQuizLibrary'
import { Tac } from './TaC'
import { VideoLibaryPage } from './VideoLibary'

export default function RootRouter() {
    const { signedIn, setSignedIn } = useCognito()

    const [loading, setLoading] = React.useState(false)
    const [subscriptionPaid, setSubscriptionPaid] = React.useState(false)
    const [open, setOpen] = React.useState<boolean>(false)
    const [teacher, setTeacher] = React.useState<TeacherType>()
    const [showNotification, setShowNotification] = React.useState(false)
    const [notificationSeverity, setNotificationSeverity] = React.useState<Severity>('info')
    const [notificationMessage, setNotificationMessage] = React.useState('')
    const { upgrade } = useTeacher()

    React.useEffect(() => {
        if (upgrade) {
            setOpen(true)
        }
    }, [upgrade])

    const notificationListener = React.useCallback(({ payload: { data: { message, severity } } }) => {
        setShowNotification(true)
        setNotificationSeverity(severity)
        setNotificationMessage(message)
    }, [])

    React.useEffect(() => {
        Hub.listen(channelName, notificationListener)
        return () => Hub.remove(channelName, notificationListener)
    }, [])

    React.useEffect(() => {
        fireWrap(async () => {
            if (!signedIn) {
                setSubscriptionPaid(false)
                return
            }

            if (Auth.Credentials.Auth.user) {
                if (subscriptionPaid) {
                    return
                }

                const t = await getById()
                setTeacher(t)

                const subs = await getActiveCustomerSubscriptions(t.id)

                if (subs.length === 0 && !t?.free) {
                    setSignedIn(false)
                    setOpen(true)
                } else if (!t?.free) {
                    if (!t.subscriptionId || t.subscriptionExpiresAt < moment().toISOString()) {
                        await updateTeacher(t.id, t.firstName, t.lastName, t.school, t.confetti, t.hasAudio,
                            false, subs[0].handle, subs[0].expiresAt)
                    }
                    setSubscriptionPaid(true)
                }
                setSubscriptionPaid(true)
            }
        }, {
            operation: 'Checking Subscription',
            stateFunc: setLoading
        })

    }, [signedIn])

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <NotificationPopup
                onClose={() => setShowNotification(false)}
                show={showNotification}
                severity={notificationSeverity}
                message={notificationMessage} />
            <Router>
                <Switch>
                    <AppRoute
                        type="auth"
                        signedIn={signedIn}
                        exact
                        path={Routes.Home.path}>
                        <HomePage />
                    </AppRoute>
                    <AppRoute type="any" signedIn={signedIn} exact path={Routes.PuzzlePlay.path}>
                        <PuzzlePlay />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.PuzzleHome.path}>
                        <PuzzleHome />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Geekster360Game.path}>
                        <Geekster360Game />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Oversight.path}>
                        <OversightPage />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Assignment.path}>
                        <AssignmentPage />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.HomeworkFlow.path}>
                        <HomeworkFlow />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={`${Routes.Assignment.path}/:id`}>
                        <AssignmentDetails />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.NewAssignment.path}>
                        <QuickCreateHomeworkPage />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.SchoolClasses.path}>
                        <ClassesPage />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.QuizLibrary.path}>
                        <QuizzLibaryPage />
                    </AppRoute>
                    <AppRoute type="any" signedIn={signedIn} exact path={Routes.PDFLibrary.path}>
                        <PDFLibraryPage />
                    </AppRoute>
                    <AppRoute type="any" signedIn={signedIn} exact path={`${Routes.PDFShareItem.path}/:pdfPath`}>
                        <PDFSharePage />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.CommunityQuizLibrary.path}>
                        <CommunityQuizLibraryPage />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.StudentsQuizLibrary.path}>
                        <StudentsQuizzLibaryPage />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.ComTac.path}>
                        <CommunityQuizzesTac />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.CommunityQuizzes.path}>
                        <CommunityQuizzes />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.CommunityQuiz.path}>
                        <CommunityQuizItem />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.StudentQuiz.path}>
                        <CommunityQuizItem />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Quiz.path}>
                        <QuizItem />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Students.path}>
                        <StudentsPage />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.VideoLibrary.path}>
                        <VideoLibaryPage />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Games.path}>
                        <GamesLibraryPage />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.Profile.path}>
                        <Profile />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.AboutGeekster.path}>
                        <About />
                    </AppRoute>
                    <AppRoute type="auth" signedIn={signedIn} exact path={Routes.HelpGeekster.path}>
                        <Help />
                    </AppRoute>
                    {/* {teacher?.subscriptionPricePlan === '1year' && (
                        <AppRoute type="auth" signedIn={signedIn} exact path={GeeksterOffers.path}>
                            <GeeksterOffersPage />
                        </AppRoute>
                    )} */}
                    <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.Login.path}>
                        <Login />
                    </AppRoute>
                    <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.SignUp.path}>
                        <SignUp showSubscriptionOptions />
                    </AppRoute>
                    <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.Coupon.path}>
                        <SignUp showSubscriptionOptions={false} />
                    </AppRoute>
                    <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.ConfirmSignUp.path}>
                        <ConfirmSignUp />
                    </AppRoute>
                    <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.TaC.path}>
                        <Tac />
                    </AppRoute>
                    <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.PrivacyPolicy.path}>
                        <PrivacyPolicy />
                    </AppRoute>
                    <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.Contact.path}>
                        <Contact />
                    </AppRoute>
                    <AppRoute type="not-auth" signedIn={signedIn} exact path={Routes.SchoolPlans.path}>
                        <SchoolPlans />
                    </AppRoute>
                    <AppRoute
                        type="not-auth"
                        signedIn={signedIn}
                        exact
                        path={Routes.CompleteSignUpWithUniLogin.path}>
                        <CompleteSignUpWithUniLogin />
                    </AppRoute>
                    <AppRoute
                        type="not-auth"
                        signedIn={signedIn}
                        exact
                        path={Routes.CompleteLogInWithUniLogin.path}>
                        <CompleteLogInWithUniLogin />
                    </AppRoute>
                    <AppRoute
                        type="not-auth"
                        signedIn={signedIn}
                        exact
                        path={Routes.ConfirmForgotPassword.path}>
                        <ConfirmForgotPassword />
                    </AppRoute>
                </Switch>
                {teacher && (
                    <PaymentModal
                        upgrade={upgrade}
                        isSubscriptionParent={teacher.subscriptionPricePlan === '1monthParent'}
                        open={open}
                        setOpen={setOpen}
                        teacher={teacher} />
                )}
            </Router>
        </>
    )
}
