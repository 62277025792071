import { 
    Button, createStyles, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@material-ui/core'
import PersonAddIcon from '@material-ui/icons/PersonAdd'
import * as React from 'react'

import { TeacherType } from '../../types/types'
import { useTeacher } from '../../utils/TeacherManager'

const useStyles = makeStyles(() => createStyles({
    text: {
        textAlign: 'center'
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 'auto',
        marginLeft: 16
    },
    gridContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column' 
    }
}),)

interface Props {
    teachersShared: string[] | undefined,
    onDeleteTeacher: (id: string) => void
}
const TeachersSharedClassTable: React.FC<Props> = ({ teachersShared, onDeleteTeacher }) => {
    const classes = useStyles()
    const [teachers, setTeachers] = React.useState([])
    const { teachersSharedWithMe, me } = useTeacher()

    React.useEffect(() => {
        setTeachers(teachersSharedWithMe.filter((teacher: TeacherType) => teachersShared?.includes(teacher.id)))
    }, [])
    return (
        <>
            <div
                style={{
                    marginLeft: 'auto',
                    marginRight: 'auto' 
                }}
                className={classes.tableHeader}>
                <PersonAddIcon />
                <Typography style={{ marginLeft: 8 }} variant="h6">Lærere klassen er delt med</Typography>
            </div>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Lærerens navn</TableCell>
                        <TableCell>Lærerens email</TableCell>
                        <TableCell align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {teachers.map((item: TeacherType) => (
                        <TableRow key={item.id}>
                            <TableCell><b>{`${item.firstName} ${item.lastName}`}</b></TableCell>
                            <TableCell><b>{`${item.email}`}</b></TableCell>
                            <TableCell align="right">
                                <Button
                                    size="small"
                                    color="secondary"
                                    onClick={() => onDeleteTeacher(item.id)}
                                    disabled={!!me}>
                                    Fjern
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}
export default TeachersSharedClassTable
