/* eslint-disable react/jsx-filename-extension */
/* eslint-disable camelcase */
import React, { useEffect, useState } from 'react'

import styles from '../styles/puzzleGlary.module.css'
import { priFix } from '../utils/config'
import CopyUrlBox from './copyUrlBox'
import PuzzleImage from './puzzleImage'
import DeleteSvg from './svg/deleteSvg'
import EditSvg from './svg/editSvg'
import LoadingSvgAnim from './svg/loadingSvgAnim'

export default function GlaryCard({
    puzzle,
    deleting,
    user_id,
    admin,
    handleEditPuzzle,
    setDeletePopup,
}) {
    const [puzzleUrl, setPuzzleUrl] = useState(null)

    useEffect(() => {
        if (puzzle && puzzle.id) {
            const url = `${window.location.origin}${priFix}/play?id=${
                puzzle.id
            }&type=${admin ? 'admin' : 'user'}`
            setPuzzleUrl(url)
            // console.log(url)
        } else {
            // console.log('trigger', puzzle)
        }
    }, [puzzle])

    return (
        <div className={styles.cardContainer}>
            <div className={styles.imageCont}>
                <PuzzleImage imagePath={puzzle.image} />
            </div>
            <div className={styles.cardText}>
                <h1 className={styles.cardName}>
                    {!puzzle.name ? 'untitled' : puzzle.name}
                </h1>
                <h3 className={styles.cardSize}>{puzzle.size} puslespil</h3>
                <p className={styles.cardDescription}>{puzzle.description}</p>
                {puzzle.show && (
                    <div className={styles.urlBox}>
                        <h2>Link til puslespil: </h2>
                        {puzzleUrl && <CopyUrlBox url={puzzleUrl} />}
                    </div>
                )}
                <div className={styles.actionButtons}>
                    {user_id && (
                        <>
                            <button
                                type="button"
                                className={`${styles.actionButton}`}
                                onClick={() => setDeletePopup(puzzle.id)}
                                disabled={deleting === puzzle.id}>
                                {deleting !== puzzle.id ? <DeleteSvg /> : <LoadingSvgAnim />}
                            </button>
                            <button
                                type="button"
                                className={`${styles.actionButton}`}
                                onClick={() => handleEditPuzzle(puzzle)}
                                disabled={deleting === puzzle.id}>
                                <EditSvg />
                            </button>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}
