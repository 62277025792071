import * as React from 'react'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { Loading } from '../../components/molecules/Loading'
import { TeacherType } from '../../types/types'
import { UniLoginReply, useUniLoginReply } from '../../utils'
import { showMessage } from '../../utils/notifications'
import { finalizeSignUpTeacherWithUniLogin } from '../../utils/Services/teacher-service'
import { fireWrap } from '../../utils/wrap-functions'

let finalizePromise: Promise<TeacherType>

export const CompleteSignUpWithUniLogin: React.FC = () => {
    const [busy, setBusy] = useState(true)
    const [teacher, setTeacher] = useState<TeacherType>()

    const uniLoginReply = useUniLoginReply<{ id: string } & UniLoginReply>()

    if (!uniLoginReply || !uniLoginReply.id) {
        showMessage('Whoops! There was an error.', 'error')
        return <Redirect to="/" />
    }

    useEffect(() => {
        fireWrap(async () => {
            if (!finalizePromise) {
                finalizePromise = finalizeSignUpTeacherWithUniLogin({
                    uniLoginUsername: uniLoginReply.user,
                    uniLoginAuth: uniLoginReply.auth,
                    uniLoginTimestamp: uniLoginReply.timestamp,
                    group: 'teacher',
                    signUpIntentId: uniLoginReply.id
                })
            }
            setTeacher(
                await finalizePromise
            )

            window.history.replaceState({}, document.title, window.location.pathname)
        }, {
            stateFunc: setBusy,
            operation: 'Finalizing Sign-Up',
        })
    }, [])

    if (teacher) {
        return (
            <Redirect to={{
                pathname: '/confirm',
                state: {
                    email: teacher.email,
                    id: teacher.id,
                    lastName: teacher.lastName,
                    pricePlan: teacher.subscriptionPricePlan,
                    coupon: teacher.coupon
                }
            }} />
        )
    }

    if (busy) {
        return <Loading />
    }

    showMessage('Whoops! There was an error.', 'error')
    return <Redirect to="/" />
}
