/* eslint-disable jsx-a11y/anchor-is-valid */
import { Auth } from 'aws-amplify'
import moment from 'moment'
import React, {
    useCallback,
    useEffect, useState
} from 'react'
import { useAsync } from 'react-async-hook'

import {
    ClassType, HomeworkType, StudentType, SubscriptionInfo, TeacherType
} from '../types/types'
import { showMessage } from './notifications'
import { listClasses } from './Services/classes-service'
import { listCommunityQuizzes } from './Services/community-quiz-service'
import { getHomeworkClassNames } from './Services/homework-service'
import { getActiveCustomerSubscriptions } from './Services/reepay-service'
import { listSharedStudentClasses, listStudentClasses } from './Services/student-service'
import {
    addTeachersSharedWithMe,
    deleteTeachersSharedWithMe,
    getAnotherById,
    getById,
    getClassesByTeachersSharedWithMe,
    getHomeworkByTeachersSharedWithMe,
    getHomeworksByTeacherId,
    getIsParent,
    getSharedHomeworksByTeacherId,
    getStudentsByTeacherId,
    getStudentsByTeacherSharedWithMe,
    listTeachersIAmSharedWith,
    listTeachersSharedWithMe,
} from './Services/teacher-service'

// eslint-disable-next-line max-len

export const TeacherContext: React.Context<any> = React.createContext({
    fetchHomeworks: () => { },
    fetchStudents: () => { },
    fetchClasses: () => { },
    fetchTeachersSharedWithMe: () => { },
    fetchClassNames: () => { },
    fetchSubscriptionData: () => { },
    fetchStudentClassNames: () => { },
    fetchCommunityQuizzes: () => { },
    teacher: undefined,
    addTeacherShared: () => { },
    setTeacher: () => { },
    teachersSharedWithMe: [],
    teachersIAmSharedWith: []
})

// define a useContext hook for functional components
export const useTeacher = () => React.useContext(TeacherContext)

export const TeacherManagerWrapped = (props: any) => {
    const [lastLibraryVisited, setLastLibraryVisited] = useState('')
    const [searchValue, setSearchValue] = React.useState<string>('')
    const [selectedCategories, setSelectedCategories] = React.useState<string[]>([])
    const [selectedGrades, setSelectedGrades] = React.useState<string[]>([])
    const [selectedTopics, setSelectedTopics] = React.useState<string[]>([])
    const [showMyPrivate, setShowMyPrivate] = React.useState<boolean>(false)
    const [teachersSharedWithMe, setTeachersSharedWithMe] = useState<TeacherType[]>([])
    const [teachersIAmSharedWith, setTeachersIAmSharedWith] = useState<TeacherType[]>([])
    const [teacher, setTeacher] = useState<TeacherType | null>(null)
    const [homeworkShared, setHomeworkShared] = useState<HomeworkType[]>([])
    const [classesShared, setClassesShared] = useState<ClassType[]>([])
    const [studentsShared, setStudentsShared] = useState<StudentType[]>([])
    const [studentsNamesShared, setStudentsNamesShared] = useState<any>({})
    const [classNames, setClassNames] = useState<{ title: string, id: string }[][]>([])
    const [me, setMe] = useState<TeacherType>()
    const [teacherShared, setTeacherShared] = useState<TeacherType | null>(null)
    const [confetti, setConfetti] = useState<boolean | null>(null)
    const [hasAudio, setHasAudio] = useState<boolean | null>(null)
    const [upgrade, setUpgrade] = React.useState(false)

    const getSortedStudents = async () => {
        const fetchedStudents = await getStudentsByTeacherId()
        return fetchedStudents.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    }

    const getSharedSortedStudents = async (id: string) => {
        const fetchedStudents = await getStudentsByTeacherSharedWithMe(id)
        return fetchedStudents.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 1))
    }

    const getSortedHomeworks = async () => {
        const fetchedHomeworks = await getHomeworksByTeacherId()
        return fetchedHomeworks.sort((o1, o2) => {
            if (o1.createdAt > o2.createdAt) return -1
            else if (o1.createdAt < o2.createdAt) return 1
            else return 0
        })
    }

    const getSortedSharedHomeworks = async () => {
        const fetchedSharedHomeworks = await getSharedHomeworksByTeacherId()
        return fetchedSharedHomeworks.sort((o1, o2) => {
            if (o1.createdAt > o2.createdAt) return -1
            else if (o1.createdAt < o2.createdAt) return 1
            else return 0
        })
    }

    const fetchTeacherShared = useCallback((oldTeacher: TeacherType, id: string) => {
        (async () => {
            setMe({ ...oldTeacher })
            fetchHomeworkByTeachersSharedWithMe(id)
            fetchClassByTeachersSharedWithMe(id)
            fetchStudentsByTeachersSharedWithMe(id)
            setStudentsNamesShared(await getSharedStudentClassNames(id))
            const newTeacher = await getAnotherById(id)
            setTeacher(newTeacher)
            setTeacherShared(newTeacher)
        })()
    }, [])

    const fetchMeBack = () => {
        setTeacher(me as TeacherType)
        setMe(undefined)
        setTeacherShared(null)
    }

    const addTeacherShared = async (email: string): Promise<TeacherType> => {
        return addTeachersSharedWithMe(email)
    }

    const removeTeacherShared = async (email: string, typeId: string) => {
        await deleteTeachersSharedWithMe(email, typeId)
    }

    const getClassNames = async (sharedHomeworks?: HomeworkType[]): Promise<{ title: string, id: string }[][]> => {
        const homeworks = sharedHomeworks || await getSortedHomeworks()

        if (homeworks) {
            const fetchedClassNames = homeworks.map(async (item) => {
                const homeworksClasses = await getHomeworkClassNames(item.id)
                return homeworksClasses
            })
            const names = await Promise.all(fetchedClassNames)
            return names
        }
        return []
    }

    const getTeacherSubscriptionData = async (): Promise<SubscriptionInfo | undefined> => {
        const userId = await Auth.Credentials.Auth.user.attributes.sub
        const subs = await getActiveCustomerSubscriptions(userId)
        const sub = subs[0]
        if (sub) {
            const joinedAt = moment.utc(sub.joinedAt)
            const expiresAt = moment.utc(sub.expiresAt)

            const daysLeft = expiresAt.diff(moment.utc(), 'days')
            return {
                dateOfRegistration: `d. ${joinedAt.format('ll')}`,
                remainingDays: `${daysLeft} dage`,
            }
        }
        showMessage('No active subscription found')
        return undefined
    }

    const getStudentClassNames = async () => {
        const students = await getSortedStudents()

        const namePromises = students.map(async (student) => {
            const classes = await listStudentClasses(student.id)
            return {
                id: student.id,
                classes: classes.map((c) => c.title).join(', ')
            }
        })

        const names = await Promise.all(namePromises)
        return Object.assign({}, ...names.map((x) => {
            return ({ [x.id]: x.classes })
        })) as Record<string, string>
    }

    const getSharedStudentClassNames = async (id: string) => {
        const students = await getSharedSortedStudents(id)

        const namePromises = students.map(async (student) => {
            const classes = await listSharedStudentClasses(id, student.id)
            return {
                id: student.id,
                classes: classes.map((c) => c.title).join(', ')
            }
        })

        const names = await Promise.all(namePromises)
        return Object.assign({}, ...names.map((x) => {
            return ({ [x.id]: x.classes })
        })) as Record<string, string>
    }

    const fetchTeachersSharedWithMe = useCallback(() => {
        (async () => {
            setTeachersSharedWithMe(await listTeachersSharedWithMe() || [])
        })()
    }, [])

    const fetchTeachersIAmSharedWith = useCallback(() => {
        (async () => {
            setTeachersIAmSharedWith(await listTeachersIAmSharedWith() || [])
        })()
    }, [])

    const fetchHomeworkByTeachersSharedWithMe = useCallback((teacherId) => {
        return (async () => setHomeworkShared(await getHomeworkByTeachersSharedWithMe(teacherId))
        )()
    }, [])

    const fetchClassByTeachersSharedWithMe = useCallback((teacherId) => {
        return (async () => setClassesShared(await getClassesByTeachersSharedWithMe(teacherId))
        )()
    }, [])

    const fetchStudentsByTeachersSharedWithMe = useCallback((teacherId) => {
        return (async () => setStudentsShared(await getStudentsByTeacherSharedWithMe(teacherId))
        )()
    }, [])

    useEffect(() => {
        (async () => setClassNames(await getClassNames(homeworkShared)))()
    }, [homeworkShared])

    useEffect(() => {
        fetchTeachersSharedWithMe()
        fetchTeachersIAmSharedWith()
    }, [])

    const fetchSharedHomework = useAsync(getSortedSharedHomeworks, [])
    const fetchHomeworks = useAsync(getSortedHomeworks, [])
    const fetchStudents = useAsync(getSortedStudents, [])
    const fetchClasses = useAsync(listClasses, [])
    const fetchClassNames = useAsync(getClassNames, [])
    const fetchSubscriptionData = useAsync(getTeacherSubscriptionData, [])
    const fetchStudentClassNames = useAsync(getStudentClassNames, [])
    const fetchCommunityQuizzes = useAsync(listCommunityQuizzes, [])
    const [isLoading, setIsLoading] = useState<boolean>(true)
    const [isParent, setIsParent] = useState<boolean>(false)
    const [isTeacherLikeAParent, setTeacherLikeAIsParent] = useState<boolean>(false)
    const fetchIsParent = useAsync(getIsParent, [])

    useEffect(() => {
        (async () => {
            // @ts-ignore function
            setIsParent((await getIsParent()).isTeacherLikeAParent === 'true')
            setIsLoading(false)
        })()
    }, [])

    const changeLoadingStatus = () => {
        setIsLoading(false)
    }

    const changeTeacherStatus = () => {
        setTeacherLikeAIsParent(!isTeacherLikeAParent)
    }

    useEffect(() => {
        Auth.currentSession().then((res) => {
            const accessToken = res.getAccessToken()
            console.log(accessToken)
        })
    }, [])
    
    useEffect(() => {
        (async () => {
            const t = await getById()
            setTeacher(t)
            setConfetti(t.confetti)
            setHasAudio(t.hasAudio ?? true)
        })()
    }, [])

    return (
        <TeacherContext.Provider value={{
            upgrade,
            setUpgrade,
            lastLibraryVisited,
            setLastLibraryVisited,
            searchValue,
            setSearchValue,
            confetti,
            setConfetti,
            hasAudio,
            setHasAudio,
            fetchIsParent,
            isLoading,
            changeLoadingStatus,
            isParent,
            setIsParent,
            addTeacherShared,
            fetchHomeworks,
            me,
            classNames,
            homeworkShared,
            classesShared,
            studentsShared,
            studentsNamesShared,
            fetchMeBack,
            fetchSharedHomework,
            fetchStudents,
            fetchClasses,
            fetchTeachersSharedWithMe,
            fetchTeacherShared,
            fetchClassNames,
            fetchSubscriptionData,
            fetchStudentClassNames,
            fetchCommunityQuizzes,
            teacher,
            setTeacher,
            isTeacherLikeAParent,
            changeTeacherStatus,
            removeTeacherShared,
            teachersSharedWithMe,
            teachersIAmSharedWith,
            setTeachersSharedWithMe,
            setTeachersIAmSharedWith,
            teacherShared,
            setTeacherShared,
            selectedCategories,
            setSelectedCategories,
            selectedGrades,
            setSelectedGrades,
            selectedTopics,
            setSelectedTopics,
            showMyPrivate,
            setShowMyPrivate,
        }}>
            {props.children}
        </TeacherContext.Provider>
    )
}
