import { Grid } from '@material-ui/core'
import * as React from 'react'
import { useEffect, useState } from 'react'

import { PricePlan } from '../../types/types'
import { ParentPriceItems } from '../../utils/priceContent'
import { PricesTemplate } from '../molecules/PricesTemplates'

interface Props {
    selected?: PricePlan
    onChange?(plan: PricePlan): void
    showSmall?: boolean
}

export const PricesComponentParent: React.FC<Props> = ({ selected, onChange, showSmall }) => {

    const [selectedPricePlan, setSelectedPricePlan] = useState(selected)

    const togglePricePlan = (plan: PricePlan) => {
        if (onChange) {
            onChange(plan)
        }

        setSelectedPricePlan(plan)
    }

    useEffect(() => {
        setSelectedPricePlan(selected)
    }, [selected])

    return (
        <Grid
            container
            style={{
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'row',
                paddingRight: '1vw',
                paddingLeft: '1vw',
            }}>
            <Grid sm={12} lg={3} item key="1monthParent">
                <PricesTemplate
                    priceSentence="At betale: 24 kr. for 1 måned indtil aftale"
                    buttonText={selectedPricePlan === '1monthParent' ? 'Valgt' : 'Vælg denne'}
                    color="#F85C1E"
                    boxShadowColor={selectedPricePlan === '1monthParent' ? '#F85C1E' : ''}
                    content={ParentPriceItems}
                    price="kr. 48"
                    monthContent="/måned"
                    title="Forældreadgang 1 måned"
                    showSmall={showSmall}
                    onClick={() => togglePricePlan('1monthParent')} />
            </Grid>
        </Grid>
    )
}
