import {
    Button, Paper, TableContainer, ThemeProvider
} from '@material-ui/core'
import * as React from 'react'
import { useAsyncCallback } from 'react-async-hook'
import { useHistory, useParams } from 'react-router-dom'

import ConfirmDialog from '../../components/atoms/ConfirmDialog'
import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import UpdateAssignmentComponent from '../../components/organisms/UpdateAssignmentComponent'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { HomeworkType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { deleteHomework } from '../../utils/Services/homework-service'
import { buttonTheme, useTableStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'

export const AssignmentDetails = () => {
    const history = useHistory()
    const { id }: { id: string | undefined } = useParams()
    const buttonClasses = buttonStyles()
    const { fetchHomeworks, fetchClassNames, me } = useTeacher()
    const { error, loading }:
        { result: HomeworkType[], error: Error, loading: boolean } = fetchHomeworks
    const tableClasses = useTableStyles()
    const [selected, setSelected] = React.useState<string | undefined>(id)
    const [confirmDelete, setConfirmDelete] = React.useState(false)

    const onDelete = async () => {
        setConfirmDelete(true)
    }
    const onDeleteConfirm = async () => {
        if (selected) await deleteHomework(selected)
        // to update default homework scree
        fetchHomeworks.execute()
        // to ensure classes are render correctly
        fetchClassNames.execute()
        setSelected(undefined)
    }
    const onDeleteHomework = useAsyncCallback(onDeleteConfirm)

    const isLoading = () => {
        return loading || onDeleteHomework.loading || fetchClassNames.loading
    }

    // TODO: Render memory leaks

    if (error) {
        showMessage(error.message)
    }

    if (isLoading()) {
        return <Loading />
    }

    return (
        <BodyWithTopBar>
            <TableContainer className={tableClasses.table} component={Paper}>
                {selected && (
                    <UpdateAssignmentComponent
                        goBack={() => history.goBack()}
                        selectedId={selected}
                        isSelected={setSelected}
                        headerText="Mine lektier" />
                )}
            </TableContainer>
            <ConfirmDialog
                text="Er du sikker på, at du vil slette dette lektier?"
                open={confirmDelete}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={async () => {
                    await onDeleteHomework.execute().then()
                    setConfirmDelete(false)
                    history.push('/lektier')
                }} />
            <ThemeProvider theme={buttonTheme}>
                <Button
                    className={buttonClasses.leftButton}
                    variant="contained"
                    color="secondary"
                    disabled={onDeleteHomework.loading || !!me}
                    onClick={onDelete}>
                    Slet lektie
                </Button>
            </ThemeProvider>

            <BottomBar onlyPicture />
        </BodyWithTopBar>
    )
}
