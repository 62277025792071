import * as React from 'react'

interface Props {
    onClick: () => void
}

export const CouponView: React.FC<Props> = ({ onClick }) => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'flex-end',
            maxWidth: '90%',
            marginTop: 40,
            cursor: 'pointer',
            fontFamily: 'Encode, sans-serif'

        }}>
            <span
                style={{ fontSize: 18, textDecoration: 'underline' }}
                onClick={onClick}>Har du en kuponkode?
            </span>
        </div>
    )
}
