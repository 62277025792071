/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Button, createStyles, createTheme, Dialog, DialogActions,
    DialogContent, DialogTitle, Grid, Link, makeStyles, Table, TableBody, TableCell, TableHead, TableRow,
    TextField,
    ThemeProvider, Typography
} from '@material-ui/core'
import { lightBlue, red } from '@material-ui/core/colors'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import Config from '../../config'
import { ClassType, HomeworkType } from '../../types/types'
import { getClass, getClassHomeworks, getClassStudentNames } from '../../utils/Services/classes-service'
import { useModalStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'
import GoBackHeader from '../atoms/GoBackHeader'
import { Loading } from '../molecules/Loading'
import TeachersSharedClassTable from '../molecules/TeachersSharedClassTable'

interface Props {
    headerText: string;
    selectedId: string;
    isSelected: (item: any) => void;
    className: string;
    setClassName: (newName: string) => void;
    description: string;
    setDescription: (description: string) => void;
    setOpenTeachersSharedModal: (value: boolean) => void;
    onDeleteTeacher: (id: string) => void;
}

const theme = createTheme({
    palette: {
        primary: lightBlue,
        secondary: red,
    },
})

const useStyles = makeStyles(() => createStyles({
    text: {
        textAlign: 'center'
    },
    columnContainer: {
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        margin: 'auto'
    },
    textInput: {
        '& input': {
            textAlign: 'center'
        }
    }
}))

const UpdateClassComponent: React.FC<Props> = ({
    onDeleteTeacher,
    setOpenTeachersSharedModal,
    headerText,
    selectedId,
    isSelected,
    className,
    setClassName,
    description,
    setDescription
}) => {
    const { me } = useTeacher()
    const classes = useStyles()
    const history = useHistory()
    const [selected, setSelected] = React.useState<ClassType>()
    const [homeworks, setHomeworks] = React.useState<HomeworkType[]>()
    const [studentNames, setStudentNames] = React.useState<string[]>([])
    const [loading, setLoading] = React.useState(false)
    const [open, setOpen] = React.useState<boolean>(false)

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            const selectedClass = await getClass(selectedId)
            const fetchedStudentNames = await getClassStudentNames(selectedId)
            const classHomeworks = await getClassHomeworks(selectedId)
            if (classHomeworks) classHomeworks.sort((a, b) => (a.expirationDate > b.expirationDate ? -1 : 1))

            setSelected(selectedClass)
            setClassName(selectedClass.title)
            setDescription(selectedClass.description)
            setHomeworks(classHomeworks)
            setStudentNames(fetchedStudentNames.map((item) => `${item.firstName} ${item.lastName}`))
            setLoading(false)
        })()
    }, [])

    if (loading) {
        return <Loading />
    }

    return (
        <ThemeProvider theme={theme}>
            {selected && (
                <>
                    <GoBackHeader headerText={headerText} onClick={() => isSelected(null)} />
                    <Grid container>
                        <Grid container style={{ marginBottom: 20 }}>
                            <Grid item xs={12} sm={4} className={classes.columnContainer}>
                                <Typography variant="h6" className={classes.text}><b>Klasse navn</b></Typography>
                                <TextField
                                    className={classes.textInput}
                                    value={className}
                                    onChange={(input) => setClassName(input.target.value)}
                                    label="Indtast klasse navn" />
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.columnContainer}>
                                <Typography variant="h6" className={classes.text}><b>Elever</b></Typography>
                                {studentNames.length > 0 && ((studentNames.length < 8)
                                    ? (
                                        <Typography
                                            variant="h6"
                                            className={classes.text}>
                                            {studentNames.join(', ')}
                                        </Typography>
                                    ) : (
                                        <>
                                            <Typography
                                                variant="h6"
                                                className={classes.text}>
                                                {studentNames.length}
                                            </Typography>
                                            <Button
                                                style={{ textTransform: 'none' }}
                                                size="small"
                                                onClick={() => setOpen(true)}
                                                variant="contained"
                                                color="primary">
                                                <Typography
                                                    style={{ cursor: 'pointer' }}
                                                    variant="body2"
                                                    className={classes.text}>
                                                    Se alt
                                                </Typography>
                                            </Button>
                                        </>
                                    ))}
                            </Grid>
                            <Grid item xs={12} sm={4} className={classes.columnContainer}>
                                <Typography variant="h6" className={classes.text}>
                                    <b>Klasse beskrivelse</b>
                                </Typography>
                                <TextField
                                    className={classes.textInput}
                                    value={description}
                                    onChange={(input) => setDescription(input.target.value)}
                                    label="Indtast klasse beskrivelse" />

                            </Grid>
                        </Grid>

                        {homeworks && homeworks.length > 0
                            && (
                                <Table aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Navn</TableCell>
                                            <TableCell>Url</TableCell>
                                            <TableCell>Udløbsdato</TableCell>
                                            <TableCell />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {homeworks.map((item: HomeworkType) => (
                                            <TableRow key={item.id}>
                                                <TableCell>
                                                    <Link
                                                        onClick={() => history.push('/lektier', { id: item.id })}
                                                        style={{ cursor: 'pointer' }}
                                                        color="textPrimary">
                                                        <b>{item.name}</b>
                                                    </Link>
                                                </TableCell>
                                                <TableCell component="th" scope="row">
                                                    <Link
                                                        target="_blank"
                                                        href={`${Config.StudentUri}/lektie/${item.id}`}>
                                                        <b>/{item.id}</b>
                                                    </Link>
                                                </TableCell>
                                                <TableCell>
                                                    {/* reversed to correct the format */}
                                                    <b>{item.expirationDate.split('-').reverse().join('.')}</b>
                                                </TableCell>
                                                <TableCell align="right">
                                                    <Button
                                                        style={{ textTransform: 'none' }}
                                                        size="small"
                                                        onClick={() => history.push('/lektier', { id: item.id })}
                                                        variant="contained"
                                                        color="primary">
                                                        Se lektie
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            )}
                        <TeachersSharedClassTable
                            teachersShared={selected.teachers}
                            onDeleteTeacher={onDeleteTeacher} />
                        <Grid container style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <Button
                                style={{
                                    margin: 'auto',
                                    marginTop: 15,
                                    marginBottom: '3%',
                                    cursor: 'pointer',
                                    alignSelf: 'center'
                                }}
                                disabled={!!me}
                                onClick={() => setOpenTeachersSharedModal(true)}>
                                Tilføj lærer
                            </Button>
                        </Grid>
                    </Grid>
                    <StudentDialog studentNames={studentNames} open={open} setOpen={setOpen} />
                </>
            )}

        </ThemeProvider>
    )
}
export default UpdateClassComponent

interface DialogProps {
    studentNames: string[],
    open: boolean,
    setOpen: (open: boolean) => void
}

const StudentDialog: React.FC<DialogProps> = ({ open, setOpen, studentNames }) => {
    const modalClasses = useModalStyles()
    const classes = useStyles()
    return (
        <Dialog open={open} onClose={() => setOpen(false)} className={modalClasses.modal}>
            <DialogTitle id="form-dialog-title">Elever</DialogTitle>
            <DialogContent>
                <Typography variant="h6" className={classes.text}>
                    {studentNames.length > 0 && studentNames.join(', ')}
                </Typography>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    Lukke
                </Button>
            </DialogActions>
        </Dialog>
    )
}
