/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Chip, createStyles, Grid, Link, makeStyles, Typography
} from '@material-ui/core'
import CancelIcon from '@material-ui/icons/Cancel'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'
import { Howl } from 'howler'
import * as React from 'react'
import ReactPlayer from 'react-player'

import {
    AnswerType, Question, QuizLibraryType, RandomSoundsType
} from '../../types/types'
import { getQuizAnswerResult, submitQuizAnswer } from '../../utils/Services/answer-services'
import { getRandomSounds } from '../../utils/Services/sounds-service'
import { useTeacher } from '../../utils/TeacherManager'
import ParentContainerQuiz from './ParentContainerQuiz'

interface Props {
    quiz: QuizLibraryType,
}

const useStyles = makeStyles(() => createStyles({
    videoContainer: {
        display: 'flex',
        height: '100%',
        justifyContent: 'space-between',
        flexDirection: 'column',
        maxWidth: '100%',
    },
    answersContainer: {
        display: 'flex',
        justifyContent: 'center',
        flexWrap: 'wrap',
        marginTop: '1%'
    },
    questionListContainer: {
        display: 'flex', flexDirection: 'column'
    }
}))

export const QuizItemComponent: React.FC<Props> = ({ quiz }) => {
    const [soundCorrectAnswer, setSoundCorrectAnswer] = React.useState<Howl>({} as Howl)
    const [soundWrongAnswer, setSoundWrongAnswer] = React.useState<Howl>({} as Howl)
    const [randomSounds, setRandomSounds] = React.useState<RandomSoundsType>({} as RandomSoundsType)

    React.useEffect(() => {
        if (Object.keys(randomSounds).length) {
            setSoundCorrectAnswer(new Howl({
                src: String(randomSounds.positiveFeedback.soundFile?.uri),
                html5: true,
            }))
        }
    }, [randomSounds])

    React.useEffect(() => {
        if (Object.keys(randomSounds).length) {
            setSoundWrongAnswer(new Howl({
                src: String(randomSounds.negativeFeedback.soundFile?.uri),
                html5: true,
            }))
        }
    }, [randomSounds])
    const { isTeacherLikeAParent, hasAudio } = useTeacher()
    const classes = useStyles()
    const [videoLoading, setVideoLoading] = React.useState<boolean>(false)
    const [answerSubmitting, setAnswerSubmitting] = React.useState<boolean>(false)
    const [selectedQuestion, setSelectedQuestion] = React.useState<Question>()
    const [currentIndex, setCurrentIndex] = React.useState(0)
    const [answer, setAnswer] = React.useState('')
    const [resultAnsweredQuestions, setResultAnsweredQuestions] = React.useState(
        Array(quiz.questions!.length).fill(null)
    )
    const [answeredCorrectQuestions, setAnsweredCorrectQuestions] = React.useState(
        Array(quiz.questions!.length).fill(null)
    )
    const [answersFromTheBackend, setAnswersFromTheBackend] = React.useState<AnswerType[]>([])
    React.useEffect(() => {
        (async () => {
            setAnswersFromTheBackend((await getQuizAnswerResult(quiz.id))?.answers)
        })()
    }, [])

    React.useEffect(() => {
        (async () => {
            setRandomSounds(await getRandomSounds())
        })()
    }, [selectedQuestion])
    
    React.useEffect(() => {
        const tempArray = resultAnsweredQuestions
        const tempArrayAnswers = answeredCorrectQuestions
        if (answersFromTheBackend) {
            answersFromTheBackend.forEach((question) => {
                tempArray[question.questionIndex] = question.correct
                tempArrayAnswers[question.questionIndex] = question.answer
            })
            setResultAnsweredQuestions(tempArray)
            if (tempArray[0] === true) {
                setAnswer(answersFromTheBackend[0].answer)
            }
        }
    }, [answersFromTheBackend])

    React.useEffect(() => {
        (async () => {
            if (quiz && quiz.questions && quiz.questions.length > 0) {
                setSelectedQuestion(quiz.questions[0])
            }
        })()
    }, [])

    const onQuestionItemClick = (item: Question) => {
        setCurrentIndex(item?.questionIndex ? item!.questionIndex - 1 : 0)
        setSelectedQuestion(item)
        if (resultAnsweredQuestions[currentIndex]) {
            setAnswer(answeredCorrectQuestions[currentIndex])
        } else {
            setAnswer('')
        }
        if (item?.vimeoVideoId && item.vimeoVideoId !== selectedQuestion?.vimeoVideoId) setVideoLoading(true)
        if (item?.youtubeVideoId && item.youtubeVideoId !== selectedQuestion?.youtubeVideoId) setVideoLoading(true)
    }

    const onGoNext = () => {
        if (quiz && quiz.questions) setCurrentIndex(Math.min(currentIndex + 1, quiz.questions.length - 1))
    }

    const onGoPrevious = () => {
        if (quiz && quiz.questions) setCurrentIndex(Math.max(currentIndex - 1, 0))
    }

    React.useEffect(() => {
        if (quiz.questions) {
            onQuestionItemClick(quiz.questions[currentIndex])
        }
    }, [currentIndex])

    const validateAnswer = async () => {
        if (!answer) return
        setAnswerSubmitting(true)
        const { correct } = await submitQuizAnswer({
            quizId: quiz.id,
            questionIndex: currentIndex,
            answer: answer.toLowerCase().trim()
        })
        if (correct) {
            if (hasAudio) {
                soundCorrectAnswer.play() 
            }
            setResultAnsweredQuestions(resultAnsweredQuestions.map(
                (question, index) => (index === currentIndex ? true : question)
            ))
            setAnsweredCorrectQuestions(answeredCorrectQuestions.map(
                (question, index) => (index === currentIndex ? answer : question)
            ))
        } else {
            if (hasAudio) {
                soundWrongAnswer.play()
            }
            setResultAnsweredQuestions(resultAnsweredQuestions.map(
                (question, index) => (index === currentIndex ? false : question)
            ))
        }
        setAnswerSubmitting(false)
    }
    return (
        <Grid container>
            <Grid item xs={12} sm={10}>
                <div style={{ textAlign: 'center' }}>
                    {quiz?.file?.uri && (
                        <a color="primary" href={quiz?.file?.uri} target="_blank" rel="noreferrer">
                            Download tilhørende PDF
                        </a>
                    )}
                </div>
                {selectedQuestion
                    && (
                        <div className={classes.videoContainer}>
                            <Typography variant="body1" style={{ textAlign: 'center' }}>
                                {selectedQuestion.title}
                            </Typography>
                            {selectedQuestion.explanation.length > 1 && (
                                <div
                                    style={{
                                        display: 'flex',
                                        marginBottom: 15,
                                        flexDirection: 'column',
                                        justifyContent: 'flex-start',
                                        paddingLeft: 15,
                                        textAlign: 'start',
                                        border: '1px solid #ccc'
                                    }}
                                    // eslint-disable-next-line react/no-danger
                                    dangerouslySetInnerHTML={
                                        { __html: selectedQuestion.explanation as string }
                                    } />
                            )}
                            <>
                                {selectedQuestion.picture?.uri && (
                                    <div
                                        style={{

                                            display:
                                                'flex',
                                            flexDirection: 'column',
                                            justifyContent: 'center',
                                            alignItems: 'center',
                                            marginBottom: 30
                                        }}>

                                        <img
                                            alt="question"
                                            src={selectedQuestion.picture.uri}
                                            style={{
                                                maxWidth: 700,
                                                maxHeight: 700,

                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover'
                                            }} />

                                    </div>
                                )}
                            </>

                            {(selectedQuestion?.vimeoVideoId || selectedQuestion?.youtubeVideoId) && (
                                <ReactPlayer
                                    url={
                                        selectedQuestion.vimeoVideoId
                                            ? `https://player.vimeo.com/video/${selectedQuestion.vimeoVideoId}`
                                            : `https://www.youtube.com/watch?v=${selectedQuestion.youtubeVideoId}`
                                    }
                                    onReady={() => setVideoLoading(false)}
                                    onError={() => setVideoLoading(false)}
                                    width="100%"
                                    controls />
                            )}

                            <div className={classes.answersContainer}>
                                {isTeacherLikeAParent !== null && (isTeacherLikeAParent ? (
                                    <ParentContainerQuiz
                                        answerSubmitting={answerSubmitting}
                                        answerCorrect={resultAnsweredQuestions[currentIndex]}
                                        answer={answer}
                                        setAnswer={setAnswer}
                                        validateAnswer={validateAnswer}
                                        videoLoading={videoLoading}
                                        length={quiz.questions!.length - 1}
                                        index={currentIndex}
                                        onGoNext={onGoNext}
                                        onGoPrevious={onGoPrevious} />
                                )
                                    : selectedQuestion.responses && selectedQuestion.responses.map((item) => (
                                        <Chip key={item} style={{ margin: 2 }} label={item} />
                                    )))}
                            </div>
                        </div>
                    )}
            </Grid>
            <Grid item xs={12} sm={2}>
                <div className={classes.questionListContainer}>
                    {quiz?.questions && quiz.questions.map((item, index) => (
                        // eslint-disable-next-line react/jsx-no-comment-textnodes
                        <div style={{ display: 'flex' }}>
                            {resultAnsweredQuestions[index] === null && (
                                <span
                                    style={{ color: 'transparent' }}>
                                    <CheckBoxOutlineBlankIcon />
                                </span>
                            )}
                            {resultAnsweredQuestions[index] !== null
                                && resultAnsweredQuestions[index]
                                && (
                                    <span
                                        style={{
                                            color: isTeacherLikeAParent
                                                ? '#0AD000' : 'transparent'
                                        }}><CheckCircleIcon />
                                    </span>
                                )}
                            {resultAnsweredQuestions[index] !== null && !resultAnsweredQuestions[index]
                                && (
                                    <span
                                        style={{ color: 'transparent' }}><CancelIcon />
                                    </span>
                                )}
                            <Link
                                key={item.title}
                                onClick={() => onQuestionItemClick(item)}
                                style={{
                                    cursor: !isTeacherLikeAParent || (resultAnsweredQuestions[index - 1]
                                        || index === 0) ? 'pointer' : 'default'
                                }}
                                component="button"
                                disabled={videoLoading || (isTeacherLikeAParent
                                    && (index === 0 ? false : !resultAnsweredQuestions[index - 1]))}>
                                <Typography
                                    variant="body1"
                                    style={{
                                        textAlign: 'center',
                                        // eslint-disable-next-line no-nested-ternary
                                        color: isTeacherLikeAParent ? (resultAnsweredQuestions[index] ? '#6FD995'
                                            : !resultAnsweredQuestions[index - 1]
                                                ? 'grey' : 'inherit') : 'inherit'
                                    }}>
                                    {selectedQuestion?.title === item.title
                                        ? <b>{item.title}</b>
                                        : item.title}
                                </Typography>
                            </Link>
                        </div>
                    ))}
                </div>
            </Grid>
        </Grid>
    )
}
