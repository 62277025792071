import moment from 'moment'

import {
    AssignedQuizType, LibraryFilter, QuizLibraryType, VideoType
} from '../types/types'
import { listCommunityQuizzes } from './Services/community-quiz-service'

export const isQuizUpdated = (quiz: AssignedQuizType) => {
    return quiz.lastUpdated && moment.utc(quiz.lastUpdated).isAfter(moment.utc().subtract(10, 'days'))
}

export const getGradesByKeyword = (input: string[], grades: string[],
    schoolGradeKeyword: string, gradeLevel: string[], selectedGrades: string[]) => {
    let newGrades = grades
    if (input.includes(schoolGradeKeyword) || selectedGrades.includes(schoolGradeKeyword)) {
        if (input.includes(schoolGradeKeyword) && !selectedGrades.includes(schoolGradeKeyword)) {
            newGrades = Array.from(new Set([...selectedGrades, ...gradeLevel]))
        } else if (!input.includes(schoolGradeKeyword) && selectedGrades.includes(schoolGradeKeyword)) {
            newGrades = selectedGrades.filter((item) => !gradeLevel.includes(item))
        }

        if (!gradeLevel.every((r) => newGrades.includes(r))) {
            if (newGrades.indexOf(schoolGradeKeyword) > -1) {
                newGrades.splice(newGrades.indexOf(schoolGradeKeyword), 1)
            }
        }
    }
    return newGrades
}

export const filterLibrary = async (
    selectedCategories: string[],
    selectedGrades: string[],
    selectedTopics: string[],
    selectedTypes: string[],
    searchValue: string,
    listFunction: () => Promise<VideoType[] | QuizLibraryType[]>,
    filterFunction: (input: LibraryFilter) => Promise<VideoType[] | QuizLibraryType[]>,
    setFunction: (items: VideoType[] | QuizLibraryType[]) => void,
    privateCommunityQuiz?: boolean,
) => {
    const wait = setTimeout(() => {
        (async () => {
            if (selectedCategories?.length === 0
                && selectedGrades?.length === 0
                && selectedTopics?.length === 0
                && searchValue?.length === 0
                && selectedTypes?.length === 0) {
                if (privateCommunityQuiz) {
                    const myPrivateQuizzes = await listCommunityQuizzes()
                    const filteredQuizzes = myPrivateQuizzes.filter((item) => !item.isPublic)
                    setFunction(filteredQuizzes)
                } else {
                    const fetchedItems = await listFunction()
                    setFunction(fetchedItems)
                }
                return
            }

            const filteredGrades = selectedGrades.flatMap((grade) => {
                if (!Number.isNaN(parseInt(grade.slice(0, -8), 10))) return parseInt(grade.slice(0, -8), 10)
                return []
            })

            const filteredItems = await filterFunction({
                grades: filteredGrades,
                categories: selectedCategories,
                topics: selectedTopics,
                searchTextInput: searchValue || ' ',
                public: privateCommunityQuiz !== undefined ? !privateCommunityQuiz : undefined,
                types: selectedTypes
            })
            setFunction(filteredItems)
        })()
    }, 200)

    return () => clearTimeout(wait)
}

export const makeLocalUri = (pathname: string) => {
    const { protocol, port, hostname } = window.location
    return `${protocol}//${hostname}:${port || ''}${pathname}`
}

export const sortLibraryItemsByLastUpdate = (first: any, second: any) => {
    // if (first.lastUpdated === undefined) return 1
    // if (second.lastUpdated === undefined) return -1            
    // return second.lastUpdated - first.lastUpdated
    if (first.lastUpdated === undefined && first.createdAt < second.createdAt) return 1
    if (first.lastUpdated === undefined && first.createdAt > second.createdAt) return -1
    if (second.lastUpdated === undefined && first.createdAt > second.createdAt) return -1
    if (second.lastUpdated === undefined && first.createdAt < second.createdAt) return 1
    return second.lastUpdated - first.lastUpdated
}

export const sortItemsByCreatedAtString = (array: any[]) => {
    
    return array.sort((a:any, b:any) => {
        const sortA = a.createdAt ? a.createdAt : a.lastUpdated
        const sortB = b.createdAt ? b.createdAt : b.lastUpdated
        // eslint-disable-next-line no-nested-ternary
        return (sortA ? (sortA < sortB ? 1 : -1) : 1)
    })
}
