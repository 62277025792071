/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'

import styles from '../../styles/loading.module.css'

export default function LoadingSvgAnim() {
    return (
        <svg
            width="800px"
            height="800px"
            fill="none"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg"
            className={styles.loadingAnimation}>
            <path
                d="m20 12c0 1.3811-0.3576 2.7386-1.0378 3.9405-0.6803 1.2019-1.6601 2.2072-2.8441 2.9182-1.1841 0.7109-2.532 1.1032-3.9126 1.1387-1.3806 0.0354-2.7469-0.2871-3.9658-0.9362"
                stroke="#000"
                strokeLinecap="round"
                strokeWidth="3.5556"/>
        </svg>
    )
}
