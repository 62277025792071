import {
    Button
} from '@material-ui/core'
import _ from 'lodash'
import React, { useEffect, useRef } from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { videoLibraryContainer } from '../../styles/VideoStyles'
import { FileType, UploadedFile } from '../../types/types'
import { wrap } from '../../utils/wrap-functions'

const allowedDocumentFileTypes = [
    'application/pdf',
]

const allowedImageFileTypes = [
    'image/png',
    'image/gif',
    'image/jpeg'
]

type AllowedFileType = 'document' | 'image'

type Props = {
    buttonText: string
    underText: string
    file?: FileType
    allowedType: AllowedFileType
    uploadFunc(file: File): Promise<UploadedFile>
    onChange?(file: FileType | undefined): void
}

export const FileUpload: React.FC<Props> = ({
    file, buttonText, underText, allowedType, uploadFunc, onChange
}) => {
    const classes1 = buttonStyles()
    const classes2 = videoLibraryContainer()

    const [inputId] = React.useState(_.uniqueId('upload-'))
    const [currentFile, setCurrentFile] = React.useState(file)
    const [uploading, setUploading] = React.useState(false)

    const fileRef = useRef<HTMLInputElement>(null)

    useEffect(() => {
        setCurrentFile(file)
    }, [file])

    const handleUpload = async () => {
        if (!fileRef.current?.files?.length) {
            return
        }
        const f = fileRef.current.files[0]

        const upl = await wrap(async () => {
            return uploadFunc(f)
        }, {
            operation: currentFile ? 'Uploading File' : 'Filen er desværre for stor. Max 5mb',
            showSuccess: true,
            stateFunc: setUploading,
        })

        const cf = {
            name: f.name,
            key: upl.key,
            uri: upl.uri
        }

        setCurrentFile(cf)
        if (onChange) {
            onChange(cf)
        }
    }

    const handleClear = () => {
        setCurrentFile(undefined)
        if (onChange) {
            onChange(undefined)
        }
    }

    const accepted: string[] = []
    if (allowedType === 'image') {
        accepted.push(...allowedImageFileTypes)
    } else if (allowedType === 'document') {
        accepted.push(...allowedDocumentFileTypes)
    }

    return (
        <>
            <input
                accept={accepted.join(',')}
                style={{ display: 'none' }}
                id={inputId}
                ref={fileRef}
                type="file"
                onChange={handleUpload}/>
            <label htmlFor={inputId}>
                <Button
                    disabled={uploading || !!currentFile}
                    className={classes1.uploadButton}
                    fullWidth
                    component="span">
                    {uploading ? 'Uploading...' : buttonText}
                </Button>
            </label>
            <small className={classes2.smallText}>
                {underText}
            </small>

            {currentFile && (
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <p style={{ marginLeft: 10, marginRight: 10, fontWeight: 'bold' }}>Fil:  {currentFile.name}

                        <button
                            style={{ marginLeft: 10, cursor: 'pointer' }}
                            type="button"
                            onClick={handleClear}> x
                        </button>
                    </p>
                    <div style={{
                        display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'
                    }}>
                        <img style={{ maxWidth: 300, maxHeight: 300 }} src={currentFile.uri} alt="question "/>

                    </div>
                </div>
            )}
        </>
    )
}
