/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'

export default function EditSvg() {
    return (
        <svg
            fill="#000"
            enableBackground="new 0 0 220 220"
            version="1.1"
            viewBox="0 0 220 220">
            <path
                fill="#000"
                d="m103.49 155.4-48.85 16.28c-3.91 1.3-7.63-2.42-6.32-6.32l16.28-48.85 7.78 7.78-9.99 29.96c-0.69 2.08 1.28 4.05 3.36 3.36l29.96-9.99 7.78 7.78z"/>
            <rect
                transform="matrix(.7071 .7071 -.7071 .7071 95.856 -79.709)"
                x="116.65"
                y="70.85"
                width="55"
                height="10"
                fill="#000"/>
            <rect
                transform="matrix(.7071 .7071 -.7071 .7071 119.29 -23.141)"
                x="60.08"
                y="127.42"
                width="55"
                height="10"
                fill="#000"/>
            <rect
                transform="matrix(-.7071 .7071 -.7071 -.7071 309.83 111.76)"
                x="86.77"
                y="115.05"
                width="90"
                height="10"
                fill="#000"/>
            <path
                fill="#000"
                d="m130 79.39c0.98 0.98 0.98 2.56 0 3.54l-35.36 35.36c-0.98 0.98-2.56 0.98-3.54 0s-0.98-2.56 0-3.54l35.36-35.36c0.98-0.98 2.57-0.98 3.54 0z"/>
            <path
                fill="#000"
                d="m140.61 90c0.98 0.98 0.98 2.56 0 3.54l-35.36 35.36c-0.98 0.98-2.56 0.98-3.54 0s-0.98-2.56 0-3.54l35.36-35.36c0.98-0.98 2.56-0.98 3.54 0z"/>
            <rect
                transform="matrix(-.7071 .7071 -.7071 -.7071 233.01 79.939)"
                x="54.95"
                y="83.23"
                width="90"
                height="10"
                fill="#000"/>
            <path
                fill="#000"
                strokeWidth="10"
                strokeMiterlimit="10"
                d="m128.24 59.94 5.3-5.3c8.79-8.79 23.03-8.79 31.82 0s8.79 23.03 0 31.82l-5.3 5.3-31.82-31.82z"/>
        </svg>
    )
}
