/* eslint-disable react/jsx-filename-extension */
import { useDroppable } from '@dnd-kit/core'
import React from 'react'

import styles from '../styles/play.module.css'

export default function Droppable({ id, children, type }) {
    const { setNodeRef } = useDroppable({
        id,
    })

    return (
        <div
            ref={setNodeRef}
            className={`${
                type === 'unsolved'
                    ? `${styles.dropBoxContUnsolved}`
                    : `${styles.dropBoxContSolved}`
            }`}>
            {children}
        </div>
    )
}
