import {
    Paper, TableContainer, Typography
} from '@material-ui/core'
import * as React from 'react'

import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { useTableStyles } from '../../utils/styles'

export const CommunityQuizzesTac: React.FC = () => {
    const tableClasses = useTableStyles()
    const [loading] = React.useState<boolean>(false)

    if (loading) {
        return <Loading />
    }

    return (
        <BodyWithTopBar>
            <TableContainer className={tableClasses.table} component={Paper}>
                <Typography
                    variant="h5"
                    style={{ textAlign: 'center' }}>
                    Vedr. brug af Geeksters quiz-værktøj jævnfør handelsbetingelserne:
                </Typography>
                <p>Ansvarsfraskrivelse</p>
                <p>7.1</p>
                <p>Når du uploader indhold til en quiz på Geekster.dk, erklærer du, at du er berettiget til
                    at anvende og uploade det pågældende indhold, og at du ved at uploade indholdet,
                    giver Geekster ApS en uindskrænket ret til at lade brugere af geekster.dk
                    tilgå og bruge din quiz. Brugerne er enten brugere, du selv har inviteret til quizzen,
                    eller – hvis du har valgt at din quiz
                    skal være offentlig – andre brugere af Geekster.dk.
                </p>
                <p>7.2</p>
                <p>Du indestår for, at du har de fulde rettigheder til at uploade alt indhold på geekster.dk,
                    herunder videoer, tekster, musik eller andet, og at dette indhold ikke krænker
                    nogen lovgivning eller tredjemands rettigheder, herunder immaterielle rettigheder,
                    ophavsrettigheder, retten til eget billede m.v. Du indestår endvidere for, at du
                    ikke anvender
                    og uploader personhenførbare oplysninger om personer i strid med persondatareglerne.
                </p>
                <p>7.3</p>
                <p>Geekster ApS forbeholder sig ret til uden varsel eller ansvar over for dig eller andre at
                    fjerne uploadet indhold, hvis der opstår mistanke eller viden om, at indholdet krænker
                    tredjemands rettigheder.
                </p>

            </TableContainer>
            <BottomBar onlyPicture />
        </BodyWithTopBar>
    )
}
