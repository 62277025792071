import {
    createStyles,
    Grid, makeStyles, Typography 
} from '@material-ui/core'
import * as React from 'react'
import { Draggable } from 'react-beautiful-dnd'

import { Question } from '../../types/types'

interface Props {
    questions: Question[],
}

const useStyles = makeStyles(() => createStyles({
    questionContainer: {
        width: '100%', marginTop: '1vh', padding: 10, border: '2px solid #000', borderRadius: 10
    },
    questionCell: {
        border: '1px solid #000'
    }
}),) 

const DraggableQuestions: React.FC<Props> = ({
    questions,
}) => { 
    const classes = useStyles()
    return (
        <div 
            className={classes.questionContainer}
            style={{ minHeight: questions.length * 40 }}>
            {questions.map((item, index) => (
                <Draggable draggableId={item.title} index={index} key={item.title}>
                    {(provided, snapshot) => (
                        <Grid
                            container
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}>
                            <div style={{ flex: 1, display: 'flex', backgroundColor: 'white' }}>
                                {!snapshot.isDragging && (
                                    <Grid item xs={2}>
                                        <Typography>{index + 1}. </Typography>
                                    </Grid>
                                )}
                                <Grid item xs={10} className={classes.questionCell}>
                                    <Typography align="center">{item.title}</Typography>
                                </Grid>
                                <Grid item xs={10} className={classes.questionCell}>
                                    <Typography align="center">
                                        {item.responses.length ? item.responses.join(', ') : 'Åbent spørgsmål'}
                                    </Typography>
                                </Grid>
                            </div>
                        </Grid>
                    )}
                </Draggable>
            ))}
        </div>
    )
}
export default DraggableQuestions
