import {
    Paper, TableCell, TableContainer, TableRow, Typography
} from '@material-ui/core'
import * as React from 'react'
import ReactPlayer from 'react-player'

import FormButtons from '../../components/atoms/FormButtons'
import BasicTable from '../../components/molecules/BasicTable'
import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import CreateStudentScreen from '../../components/organisms/CreateStudentScreen'
import InviteStudentScreen from '../../components/organisms/InviteStudentScreen'
import UpdateStudent from '../../components/organisms/UpdateStudentScreen'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { StudentType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { useTableStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'

export const StudentsPage: React.FC = () => {
    const tableClasses = useTableStyles()
    const {
        fetchStudents,
        fetchStudentClassNames,
        me,
        studentsShared,
        studentsNamesShared,
    } = useTeacher()
    const { error, loading }:
        { error: Error, loading: boolean } = fetchStudents

    const [studentsRows, setStudentsRows] = React.useState<StudentType[]>([])
    const [studentsNamesRows, setStudentsNamesRows] = React.useState<any>({})
    React.useEffect(() => {
        (async () => {
            await fetchStudents.execute()
            await fetchStudentClassNames.execute()
        })()
        return () => {
            setStudentsRows(fetchStudents.result)
            setStudentsNamesRows(fetchStudentClassNames.result)
        }
    }, [])

    React.useEffect(() => {
        if (!fetchStudentClassNames.loading && !fetchStudents.loading) {
            if (me) {
                setStudentsRows(studentsShared)
                setStudentsNamesRows(studentsNamesShared)
            } else {
                setStudentsRows(fetchStudents.result)
                setStudentsNamesRows(fetchStudentClassNames.result)
            }
        }
    }, [studentsShared, studentsNamesShared, fetchStudents.result, fetchStudentClassNames.result])

    const [selected, setSelected] = React.useState<any>()
    const [createNew, setCreateNew] = React.useState<boolean>(false)
    const [invite, setInvite] = React.useState<boolean>(false)

    const onUpdate = () => {
        // to update default student screen
        fetchStudents.execute()
        // to update classes on default student screen
        fetchStudentClassNames.execute()
    }

    const renderCreateNewScreen = () => (
        <CreateStudentScreen setCreateNew={(boolean: boolean) => setCreateNew(boolean)} />
    )

    const renderInviteScreen = () => (
        <InviteStudentScreen
            setInvite={(yes: boolean) => setInvite(yes)}
            onFinish={() => setInvite(false)} />
    )

    if (loading || fetchStudentClassNames.loading) {
        return <Loading />
    }
    const renderDefaultScreen = () => (
        <>
            <>
                <TableContainer className={tableClasses.table} component={Paper}>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>Mine elever</Typography>
                    {studentsRows && studentsRows.length === 0
                        ? <Typography>Ingen elever fundet.</Typography>
                        : (
                            <BasicTable headerNames={['Fornavn', 'Navn', 'Brugernavn', 'Klasser']}>
                                {studentsRows && studentsRows.map((student) => (
                                    <TableRow
                                        style={{ cursor: 'pointer' }}
                                        key={student.id}
                                        hover
                                        onClick={() => setSelected(student.id)}>
                                        <TableCell component="th" scope="row">
                                            <b>{student.firstName}</b>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <b>{student.lastName}</b>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            <b>{student.username}</b>
                                        </TableCell>
                                        <TableCell component="th" scope="row">
                                            {studentsNamesRows
                                                && (
                                                    <b>
                                                        {studentsNamesRows[student.id]}
                                                    </b>
                                                )}
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </BasicTable>
                        )}
                </TableContainer>
                <FormButtons
                    createButtonText="Opret en ny elev"
                    inviteButtonText="Opret elevlink"
                    onClickInvite={() => setInvite(true)}
                    onClickCreate={() => setCreateNew(true)} />
                <ReactPlayer
                    style={{ marginTop: 120, zIndex: 9, position: 'relative', }}
                    url="https://player.vimeo.com/video/677175483"
                    width="100%"
                    controls />
            </>
        </>
    )

    const renderUpdateScreen = () => (
        <UpdateStudent onClassUpdate={onUpdate} selected={selected} isSelected={setSelected} />
    )

    if (error || fetchStudentClassNames.error) {
        const err = error || fetchStudentClassNames.error
        showMessage(err.message)
    }

    return (
        <BodyWithTopBar>
            {invite && !selected && renderInviteScreen()}
            {createNew && !selected && renderCreateNewScreen()}
            {!createNew && !selected && !invite && renderDefaultScreen()}
            {!createNew && !invite && selected && renderUpdateScreen()}
            <BottomBar onlyPicture />
        </BodyWithTopBar>
    )
}
