import { API } from 'aws-amplify'

import { LibraryFilter, PDFType } from '../../types/types'

export const getPDFById = async (id: string): Promise<PDFType> => {
    const pdf = await API.get('openapi', `/pdf/${id}`, {})
    return pdf
}

export const listPDFs = async (): Promise<PDFType[]> => {
    const pdf = await API.get('openapi', '/pdf', {})
    return pdf
}

export const filterPDFs = async (filterParams: LibraryFilter): Promise<PDFType[]> => {
    const pdf = await API.post('openapi', '/pdf/filter', {
        body: filterParams
    })
    return pdf
}

export const downloadPDFBase64 = async (id: string): Promise<string> => {
    const pdf = await API.get('openapi', `/pdf/${id}/download`, {})
    return pdf
}
