import * as React from 'react'

import { useLoadingManager } from '../../utils/hooks'
import { createClass } from '../../utils/Services/classes-service'
import { useTeacher } from '../../utils/TeacherManager'
import Alert from '../atoms/Alert'
import CreateScreenTemplate from '../molecules/CreateScreenTemplate'
import { Loading } from '../molecules/Loading'
import { TextInputTemplate } from '../molecules/TextInputTemplate'

interface Props {
    setCreateNew: (boolean: boolean) => void
}

const CreateClassScreen: React.FC<Props> = ({ setCreateNew }) => {
    const { fetchClasses } = useTeacher()
    const { loading, startLoading, stopLoading } = useLoadingManager()

    const [title, setTitle] = React.useState('')
    const [description, setDescription] = React.useState('')
    const [createError, setCreateError] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string>('')

    const onCreate = async () => {
        if (!validateForm()) { setCreateError(true); setErrorMessage('Please fill out all the inputs'); return }
        startLoading()
        try {
            await createClass(title, description)
            // to update default class screen
            fetchClasses.execute()
            setCreateNew(false)
        } catch (err) {
            // TODO: should be generalized 
            // setErrorMessage(err.message)
            setErrorMessage('Du kan højest oprette 4 klasser pr. lærer.')
            setCreateError(true)
        }
        stopLoading()
    }
    const validateForm = () => {
        if (title.length > 0 && description.length > 0) return true
        else return false
    }

    if (loading || fetchClasses.loading) {
        return <Loading />
    }
    return (
        <CreateScreenTemplate
            headerText="Mine klasser"
            createButtonText="Opret klasse"
            setCreateNew={(boolean: boolean) => setCreateNew(boolean)}
            create={onCreate}
            deleteFunction={() => setCreateNew(false)}
            disableButtons={loading || fetchClasses.loading}
            disableCreate={!validateForm()}>

            <>
                <TextInputTemplate
                    value={title}
                    onChange={setTitle}
                    label="Giv klassen et navn" />
                <TextInputTemplate
                    value={description}
                    onChange={setDescription}
                    label="Giv klassen en beskrivelse" />
            </>

            <Alert
                text={errorMessage}
                setOpen={setCreateError}
                open={createError}
                type="alert" />
        </CreateScreenTemplate>
    )
}
export default CreateClassScreen
