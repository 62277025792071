import {
    Box, Button, createStyles,
    makeStyles, Modal, Typography,
} from '@material-ui/core'
import React from 'react'

import { useTeacher } from '../../utils/TeacherManager'
import { TextInputTemplate } from '../molecules/TextInputTemplate'
import { drawerWidth } from '../wrappers/BodyWithTopBar'

interface ReasonProps {
    id: string;
    openModal: boolean,
    setOpenModal: () => void,
    addFunction: () => void,
    removeFunction: (iAmSharedWith: boolean) => void,
    deleteMode: boolean,
    email: string,
    setEmail: (email: string) => void,
    iAmSharedWith: boolean
}
const useStyles = makeStyles((currentTheme) => createStyles({
    modal: {
        [currentTheme.breakpoints.down('sm')]: {
            marginLeft: 0,
            width: '90%'
        },
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: '40%',
        backgroundColor: 'white',
        border: '2px solid #000',
        marginLeft: drawerWidth / 2,
        padding: 20,
    },
}))

export const AddOtherTeachersModal: React.FC<ReasonProps> = ({
    id,
    openModal,
    setOpenModal,
    addFunction,
    email,
    setEmail,
    deleteMode,
    removeFunction,
    iAmSharedWith
}) => {
    const { teacher, fetchTeacherShared } = useTeacher()
    const classes = useStyles()
    return (
        <Modal
            open={openModal}
            onClose={setOpenModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description">
            <Box className={classes.modal}>
                {/* eslint-disable-next-line no-nested-ternary */}
                <Typography variant="h5">{!iAmSharedWith ? (deleteMode
                    ? 'Administrer lærerens e-mail:'
                    : 'Tilføj lærerens e-mail:') : 'Administrer lærerens e-mail, jeg er delt med'}
                </Typography>
                <br />
                <TextInputTemplate
                    value={email}
                    onChange={setEmail}
                    label="E-mail" />
                <br />
                <Box style={{ display: 'flex', justifyContent: 'space-around' }}>
                    <Button variant="contained" color="primary" onClick={setOpenModal}>
                        Afbryd
                    </Button>
                    {!iAmSharedWith && deleteMode && (
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => {
                                fetchTeacherShared(teacher, id)
                                setOpenModal()
                            }}>
                            Gå til dennes konto
                        </Button>
                    )}
                    <Button
                        variant="contained"
                        onClick={() => {
                            if (deleteMode) {
                                removeFunction(iAmSharedWith)
                            } else {
                                addFunction()
                            }
                            setOpenModal()
                        }}>
                        {deleteMode ? 'Slet' : 'Bekræft'}
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}
