import * as React from 'react'

import { FileType, Question } from '../../types/types'
import { useLoadingManager } from '../../utils/hooks'
import {
    deleteCommunityQuiz, finishCreatingCommunityQuiz, getCommunityQuizById, updateCommunityQuizQuestions
} from '../../utils/Services/community-quiz-service'
import { useTeacher } from '../../utils/TeacherManager'
import Alert from '../atoms/Alert'
import ConfirmDialog from '../atoms/ConfirmDialog'
import { QuestionModal } from '../atoms/QuestionModal'
import CreateScreenTemplate from '../molecules/CreateScreenTemplate'
import { Loading } from '../molecules/Loading'
import { SecondCreateCommunityQuizStep } from '../molecules/SecondCreateCommunityQuizStep'

interface Props {
    setFinishCreatingId: (id: string) => void,
    onQuizCreate: () => void,
    createdQuizId: string,
    justUpdateQuestions: boolean,
    description: string,
    setDescription: (description: string) => void
}

const SecondCreateCommunityQuizScreen: React.FC<Props> = ({
    setFinishCreatingId, createdQuizId, justUpdateQuestions, onQuizCreate,
    description, setDescription
}) => {
    const { fetchCommunityQuizzes } = useTeacher()
    const { loading, startLoading, stopLoading } = useLoadingManager()

    const [questions, setQuestions] = React.useState<Question[]>([])
    const [file, setFile] = React.useState<FileType>()
    const [acceptedTaC, setAcceptedTaC] = React.useState<boolean>(false)

    const [openModal, setOpenModal] = React.useState<boolean>(false)
    const [createError, setCreateError] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)

    React.useEffect(() => {
        (async () => {
            if (createdQuizId) {
                startLoading()
                const fetchedQuiz = await getCommunityQuizById(createdQuizId)
                if (fetchedQuiz) setQuestions(fetchedQuiz.questions || [])
                stopLoading()
            }
        })()
    }, [])

    const handleGoBack = () => {
        onQuizCreate()
        setFinishCreatingId('')
    }
    const onCreate = async () => {
        startLoading()
        try {
            await finishCreatingCommunityQuiz(createdQuizId, description, questions, file)
            handleGoBack()
        } catch (err: any) {
            // TODO: should be generalized 
            setErrorMessage(err.message)
            setCreateError(true)
        }
        stopLoading()
    }

    const onQuestionUpdate = async () => {
        startLoading()
        try {
            await updateCommunityQuizQuestions(createdQuizId, questions)
            handleGoBack()
        } catch (err: any) {
            // TODO: should be generalized 
            setErrorMessage(err.message)
            setCreateError(true)
        }
        stopLoading()
    }
    const validateForm = () => {
        if (description.length > 0) return true
        return false
    }
    const onDelete = async () => {
        startLoading()
        await deleteCommunityQuiz(createdQuizId)
        fetchCommunityQuizzes.execute()
        handleGoBack()
        stopLoading()
    }
    const onDeleteButtonClick = () => {
        setConfirmDelete(true)
    }
    const disableButtons = () => {
        if (justUpdateQuestions) return false
        if (!validateForm() || !acceptedTaC) return true
        return false
    }
    if (loading) {
        return <Loading />
    }
    return (
        <CreateScreenTemplate
            headerText={justUpdateQuestions ? 'Opdater spørgsmål' : ' Tilføj spørgsmål og opret quiz'}
            goBackFunction={() => setFinishCreatingId('')}
            goBackText={justUpdateQuestions ? 'Min quiz' : 'Mine quizzer'}
            createButtonText={justUpdateQuestions ? 'Opdater' : 'Opret quizz'}
            setCreateNew={() => (false)}
            create={justUpdateQuestions ? onQuestionUpdate : onCreate}
            deleteFunction={!justUpdateQuestions && onDeleteButtonClick}
            deleteButtonText="Slet quiz"
            disableButtons={loading}
            disableCreate={disableButtons()}>
            <SecondCreateCommunityQuizStep
                justUpdateQuestions={justUpdateQuestions}
                description={description}
                setDescription={setDescription}
                questions={questions}
                setQuestions={setQuestions}
                setModalOpen={setOpenModal}
                file={file}
                setFile={setFile}
                acceptedTaC={acceptedTaC}
                setAcceptedTaC={setAcceptedTaC} />

            <QuestionModal
                questions={questions}
                setQuestions={setQuestions}
                quizId={createdQuizId}
                openModal={openModal}
                setOpenModal={() => setOpenModal(false)}
                type="create"
                title=""
                explanation=""
                vimeoVideoId=""
                youtubeVideoId=""
                pictureFile={undefined}
                responses={[]}
                open={false} />
            <Alert
                text={errorMessage}
                setOpen={setCreateError}
                open={createError}
                type="alert" />
            <ConfirmDialog
                text="Er du sikker på at du vil slette denne quiz?"
                open={confirmDelete}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={onDelete} />
        </CreateScreenTemplate>
    )
}
export default SecondCreateCommunityQuizScreen
