/* eslint-disable camelcase */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'

import styles from '../styles/puzzleEditor.module.css'
import MathExpression from './mathExpression'

export default function PuzzlePiece({ piceData, setEditPopup }) {
    const handleClick = (stateData) => {
        const { connect_id } = stateData
        setEditPopup(connect_id)
    }

    return (
        <div className={styles.box}>
            {/* Top */}
            <div
                className={`${styles.middler} ${styles.middlerTop} ${
                    piceData.top.connect_id > 1000 && `${styles.wrongSides}`
                }`}
                onClick={() => handleClick(piceData.top)} />
            <div
                className={`${styles.middlerTopContent} ${styles.middlerTop} ${styles.middlerTextTopBottom}`}
                onClick={() => handleClick(piceData.top)}>
                <MathExpression expression={piceData.top.equation} />
            </div>
            {/* Right */}
            <div
                className={`${styles.middler} ${styles.middlerRight} ${
                    piceData.right.connect_id > 1000 && `${styles.wrongSides}`
                }`}
                onClick={() => handleClick(piceData.right)} />
            <div
                className={`${styles.middlerTopContent} ${styles.middlerRight} ${styles.middlerBothSides}`}
                onClick={() => handleClick(piceData.right)}>
                <MathExpression side="right" expression={piceData.right.equation} />
            </div>
            {/* Left */}
            <div
                className={`${styles.middler} ${styles.middlerLeft} ${
                    piceData.left.connect_id > 1000 && `${styles.wrongSides}`
                }`}
                onClick={() => handleClick(piceData.left)} />
            <div
                className={`${styles.middlerTopContent} ${styles.middlerLeft} ${styles.middlerBothSides}`}
                onClick={() => handleClick(piceData.left)}>
                <MathExpression side="left" expression={piceData.left.equation} />
            </div>
            {/* Bottom */}
            <div
                className={`${styles.middler} ${styles.middlerBottom} ${
                    piceData.bottom.connect_id > 1000 && `${styles.wrongSides}`
                }`}
                onClick={() => handleClick(piceData.bottom)} />
            <div
                className={`${styles.middlerTopContent} ${styles.middlerBottom} ${styles.middlerTextTopBottom}`}
                onClick={() => handleClick(piceData.bottom)}>
                <MathExpression expression={piceData.bottom.equation} />
            </div>
        </div>
    )
}
