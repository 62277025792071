import SvgIcon from '@material-ui/core/SvgIcon'
import AssignmentIcon from '@material-ui/icons/Assignment'
// import CardGiftcardIcon from '@material-ui/icons/CardGiftcard'
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark'
import EmailIcon from '@material-ui/icons/Email'
import FilterNoneIcon from '@material-ui/icons/FilterNone'
import GroupIcon from '@material-ui/icons/Group'
import HomeIcon from '@material-ui/icons/Home'
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary'
import PersonIcon from '@material-ui/icons/Person'
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf'
import QuestionAnswerIcon from '@material-ui/icons/QuestionAnswer'
import SchoolIcon from '@material-ui/icons/School'
import SportsEsportsIcon from '@material-ui/icons/SportsEsports'
import VideoLibraryIcon from '@material-ui/icons/VideoLibrary'
import IcomoonReact from 'icomoon-react'
import * as React from 'react'

import iconSet from '../assets/quizedu.json'

type Route = {
    path: string;
    name: string;
    icon?: JSX.Element;
    secure: boolean;
}

const Home: Route = {
    path: '/',
    name: 'Hjem',
    icon: <HomeIcon />,
    secure: true
}

export const QuizEdu: Route = {
    path: window.location.protocol === 'http:' ? 'http://localhost:4000' : 'https://quizedu.dk',
    name: 'QuizEdu',
    icon: <IcomoonReact iconSet={iconSet} size={40} icon="1070800" />,
    secure: true
}

const Oversight: Route = {
    path: '/oversigt',
    name: 'Oversigt/Del\u00A0konto',
    icon: (
        <SvgIcon>
            <path d="M3 6H1v13c0 1.1.9 2 2 2h17v-2H3V6z" />
            <path d="M21 4h-7l-2-2H7c-1.1 0-1.99.9-1.99 2L5 15c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2z" />
        </SvgIcon>),
    secure: true
}

const SchoolClasses: Route = {
    path: '/klasser',
    name: 'Klasser',
    icon: <GroupIcon />,
    secure: true
}

const Students: Route = {
    path: '/elever',
    name: 'Elever',
    icon: <SchoolIcon />,
    secure: true
}

const Assignment: Route = {
    path: '/lektier',
    name: 'Lektier',
    icon: <AssignmentIcon />,
    secure: true
}
const NewAssignment: Route = {
    path: '/ny-lektier',
    name: 'Ny Lektier',
    secure: true
}

const HomeworkFlow: Route = {
    path: '/lektier/flow',
    name: 'Lektier flow',
    secure: true
}

const QuizLibrary: Route = {
    path: '/quizbibliotek',
    name: 'Geeksters quizzer',
    icon: <QuestionAnswerIcon />,
    secure: true
}

const PDFLibrary: Route = {
    path: '/pdfbibliotek',
    name: 'Geeksters kopi-ark',
    icon: <PictureAsPdfIcon />,
    secure: true
}

const PDFShareItem: Route = {
    path: '/del-pdf',
    name: 'Del kopi-ark',
    secure: true
}

const Games: Route = {
    path: '/spil',
    name: 'Geeksters spil',
    icon: <SportsEsportsIcon />,
    secure: true
}

const CommunityQuizLibrary: Route = {
    path: '/communityquizbibliotek',
    name: 'Lærer-quizzer',
    icon: <CollectionsBookmarkIcon />,
    secure: true
}

const StudentsQuizLibrary: Route = {
    path: '/eleverquizbibliotek',
    name: 'Elev-quizzer',
    icon: <LocalLibraryIcon />,
    secure: true
}

const CommunityQuizzes: Route = {
    path: '/minequizzer',
    name: 'Mine quizzer',
    icon: <FilterNoneIcon />,
    secure: true
}

const Quiz: Route = {
    path: '/quiz',
    name: 'Quiz Item',
    secure: true
}

const CommunityQuiz: Route = {
    path: '/quiz/community',
    name: 'Community quiz Item',
    secure: true
}

const StudentQuiz: Route = {
    path: '/quiz/student',
    name: 'Community quiz Item',
    secure: true
}

const VideoLibrary: Route = {
    path: '/videobibliotek',
    name: 'Geeksters videoer',
    icon: <VideoLibraryIcon />,
    secure: true
}

export const Profile: Route = {
    path: '/profil',
    name: 'Min profil',
    icon: <PersonIcon />,
    secure: true
}

const AboutGeekster: Route = {
    path: '/about',
    name: 'Om geekster',
    secure: true
}

const ComTac: Route = {
    path: '/tac',
    name: 'Community Quizzes Tac',
    secure: true
}

const Login: Route = {
    path: '/login',
    name: 'Login',
    secure: false
}

const SignUp: Route = {
    path: '/signUp',
    name: 'SignUp',
    secure: false
}

const ConfirmSignUp: Route = {
    path: '/confirm',
    name: 'ConfirmSignUp',
    secure: false
}

const ConfirmForgotPassword: Route = {
    path: '/confirm_forgot',
    name: 'ConfirmForgotPassword',
    secure: false
}

const CompleteSignUpWithUniLogin: Route = {
    path: '/signUp/complete',
    name: 'CompleteSignUpWithUniLogin',
    secure: false
}

const CompleteLogInWithUniLogin: Route = {
    path: '/login/complete',
    name: 'CompleteLogInWithUniLogin',
    secure: false
}

const Parents: Route = {
    path: '/parents',
    name: 'Parents',
    secure: false
}
const Coupon: Route = {
    path: '/skoler',
    name: 'Skoler',
    secure: false
}

const TaC: Route = {
    path: '/handelsbetingelser',
    name: 'TermsAndConditions',
    secure: false
}
const Contact: Route = {
    path: '/kontakt-os',
    name: 'Contact Us',
    secure: false
}
const SchoolPlans: Route = {
    path: '/skolepriser',
    name: 'Schools Plans',
    secure: false
}

const PrivacyPolicy: Route = {
    path: '/privacy-policy',
    name: 'Privacy Policy',
    secure: false
}

const HelpGeekster: Route = {
    path: '/help',
    name: 'Hjælp',
    secure: true,
    icon: <EmailIcon />
}

const PuzzleHome: Route = {
    path: '/pusle',
    name: 'Puslespil',
    secure: true,
}

const PuzzlePlay: Route = {
    path: '/play',
    name: 'PuslespilPlay',
    secure: true,
}

const Geekster360Game: Route = {
    path: '/360',
    name: 'Geekster360Game',
    secure: true,
}

// export const GeeksterOffers: Route = {
//     path: '/gaver',
//     name: 'Geeksters gaver',
//     secure: true,
//     icon: <CardGiftcardIcon />
// }

export const LibraryDrawerRoutes = [
    QuizLibrary,
    VideoLibrary,
    Games,
    PDFLibrary]

export const TeacherDrawerRoutes = [Home, Oversight, SchoolClasses, Students, Assignment]

export const ProfileRoutes = [
    CommunityQuizzes,
    StudentsQuizLibrary,
    CommunityQuizLibrary,
    // HelpGeekster
]

export default {
    PuzzlePlay,
    PuzzleHome,
    StudentQuiz,
    StudentsQuizLibrary,
    NewAssignment,
    SchoolPlans,
    Coupon,
    Parents,
    PrivacyPolicy,
    Contact,
    TaC,
    ComTac,
    Home,
    Oversight,
    SchoolClasses,
    Students,
    Assignment,
    HomeworkFlow,
    Quiz,
    QuizLibrary,
    PDFLibrary,
    CommunityQuizLibrary,
    CommunityQuizzes,
    CommunityQuiz,
    VideoLibrary,
    Games,
    Profile,
    AboutGeekster,
    HelpGeekster,
    Login,
    SignUp,
    ConfirmSignUp,
    ConfirmForgotPassword,
    CompleteSignUpWithUniLogin,
    CompleteLogInWithUniLogin,
    PDFShareItem,
    QuizEdu,
    Geekster360Game
}
