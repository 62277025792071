/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Paper, TableContainer
} from '@material-ui/core'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import GoBackHeader from '../../components/atoms/GoBackHeader'
import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import { QuizItemComponent } from '../../components/molecules/QuizItemComponent'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { QuizLibraryType } from '../../types/types'
import { useLoadingManager } from '../../utils/hooks'
import { getQuizById } from '../../utils/Services/quiz-service'
import { useTableStyles } from '../../utils/styles'

export const QuizItem: React.FC = () => {
    const classes = useTableStyles()
    const history = useHistory()
    const location: any = useLocation()
    const quizId = location.state ? location.state.id : undefined

    const [quiz, setQuiz] = React.useState<QuizLibraryType>()
    const { loading, startLoading, stopLoading } = useLoadingManager()

    React.useEffect(() => {
        (async () => {
            startLoading()
            const fetchedQuiz = (await getQuizById(quizId))
            if (fetchedQuiz.questions && fetchedQuiz.questions.length) {
                fetchedQuiz.questions = fetchedQuiz?.questions.map((question, index) => {
                    // eslint-disable-next-line no-param-reassign
                    question.questionIndex = index + 1
                    return question
                })
            }

            stopLoading()
            setQuiz(fetchedQuiz)
        })()
    }, [])

    if (loading) {
        return <Loading />
    }

    return (
        <BodyWithTopBar>
            <TableContainer className={classes.tableWithoutBottomButtons} component={Paper}>
                <GoBackHeader headerText={quiz ? quiz.title : ''} onClick={() => history.goBack()} />
                {quiz && (
                    <QuizItemComponent quiz={quiz} />
                )}
            </TableContainer>
            <BottomBar onlyPicture />
        </BodyWithTopBar>
    )
}
