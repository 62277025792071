export const Geekster360Game = {}

// import * as PANOLENS from 'panolens'
// import React, { useEffect, useRef } from 'react'
// import * as THREE from 'three'

// import pano from './images/fantasy_cave.jpg'

// export const Geekster360Game = () => {
//     const containerRef = useRef(null)
//     const panorama = useRef(null)
//     const viewer = useRef(null)
//     const infoSpot = useRef(null)
//     const controls = useRef(null)
//     const panoramaMainImage = useRef(null)

//     function handleTeacherClick(position: any, depth) {
//         console.log(position, depth)
//     }

//     function onDocumentClick(event, depth) {
//         console.log(event)
//         const relativeX = event.clientX
//         const relativeY = event.clientY
      
//         const { camera } = depth
//         const globalPosition = new THREE.Vector3(relativeX, relativeY, 0.5).unproject(camera)
      
//         // Retrieve the z-index from the intersection object
//         const z = camera.position.distanceTo(globalPosition)
      
//         // Use the global position and z-index for further processing
//         handleTeacherClick(globalPosition, z)

//     }

//     useEffect(() => {
//         if (panorama.current || viewer.current || panoramaMainImage.current) {
//             return
//         }

//         controls.current = PANOLENS.CONTROLS

//         panorama.current = new PANOLENS.ImagePanorama(pano)

//         infoSpot.current = new PANOLENS.Infospot(100)
//         infoSpot.current.position.set(1000, -1000, -2000)
//         infoSpot.current.addHoverText('Simple Info Text')
//         panorama.current.add(infoSpot.current)

//         viewer.current = new PANOLENS.Viewer({
//             container: containerRef.current,
//             autoRotate: true,
//             autoRotateSpeed: 0.1,
//             zoom: 0.1
//         })
//         viewer.current.add(panorama.current)
//         containerRef.current.addEventListener('click', (e) => onDocumentClick(e, viewer.current))

//         // viewer.current.enableControl(controls.current.DEVICEORIENTATION)
//         // viewer.current.enableControl(1)
//     }, [])

//     return (
//         <div>
//             <div
//                 ref={containerRef}
//                 id="panoCont"
//                 style={{ width: '100vw', height: '100vh', margin: '0 auto' }} />

//         </div>
//     )
// }
