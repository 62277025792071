/* eslint-disable react/jsx-filename-extension */
import React, { useState } from 'react'

import styles from '../styles/puzzleEditor.module.css'

export default function MathWithKey({ addMathKey }) {
    const [inputOne] = useState(0)

    const handleKeyEvent = (key, event) => {
        event.preventDefault()
        if (key === 0) {
            // eslint-disable-next-line no-useless-concat
            const expression = '\u005C' + `sqrt{${inputOne}}`
            addMathKey(expression)
        }
    }

    return (
        <div
            className={styles.mathButtonsContSingle}
            style={{ marginTop: 0, border: 'solid 0px var(--black)' }}>
            <button
                type="button"
                className={styles.mathButtons}
                onClick={(e) => handleKeyEvent(0, e)}>
                √n
            </button>
        </div>
    )
}
