/* eslint-disable react/jsx-filename-extension */
import 'katex/dist/katex.min.css'

import React, { useEffect, useState } from 'react'
import Latex from 'react-latex'

function MathExpression(props) {
    const [style, setStyle] = useState({
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    })

    useEffect(() => {
        if (props && props.side) {
            if (props.side === 'right') {
                setStyle({
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-end',
                })
            } else {
                setStyle({
                    width: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'flex-start',
                })
            }
        }
    }, [props])

    return (
        <div style={style}>
            <Latex>{props.expression ? `$$${props.expression}$$` : ''}</Latex>
        </div>
    )
}

export default MathExpression
