/* eslint-disable indent */
/* eslint-disable no-nested-ternary */
import {
    Button, createTheme, Dialog, DialogActions, DialogTitle, Hidden, IconButton, ThemeProvider, Toolbar
} from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import CssBaseline from '@material-ui/core/CssBaseline'
import Divider from '@material-ui/core/Divider'
import Drawer from '@material-ui/core/Drawer'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles'
import AccountBoxIcon from '@material-ui/icons/AccountBox'
import MenuIcon from '@material-ui/icons/Menu'
import ScreeRotate from '@material-ui/icons/ScreenRotation'
import { Auth, Hub } from 'aws-amplify'
import IcomoonReact from 'icomoon-react'
import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import iconSet from '../../assets/children.json'
import LogoColor from '../../assets/colorgeek.png'
import background from '../../assets/homeBackground.png'
import Logo from '../../assets/logo.png'
import LogoGeekster from '../../assets/LogoGeekster.svg'
import { showMessage } from '../../utils/notifications'
import { 
    LibraryDrawerRoutes, Profile, ProfileRoutes, QuizEdu, TeacherDrawerRoutes,
} from '../../utils/Routes'
import { useCognito } from '../../utils/Services/cognito-service'
import { useTeacher } from '../../utils/TeacherManager'
import { wrap } from '../../utils/wrap-functions'
import FAB from '../atoms/Fab'
import { LogoutModal } from '../molecules/LogoutModal'
import { Body } from './Body'

export const drawerWidth = 240

const buttonTheme = createTheme({
    palette: {
        primary: { main: 'rgba( 245, 245, 245, 1)' },
        secondary: { main: 'rgba( 155, 155, 155, 1)' },
        background: {
            default: '#fff'
        }
    },
})

interface Props {
    display?: boolean
    children?: React.ReactNode
}

const notAllowedFreeAccounts = [
    '/oversigt',
    '/minequizzer',
    '/eleverquizbibliotek',
    '/gaver',
]

export const BodyWithTopBar: React.FC = ({ children, display = true }: Props) => {
    const history = useHistory()
    const location = useLocation()
    const { signedIn } = useCognito()
    const {
        isLoading, isTeacherLikeAParent, changeTeacherStatus, teacher, teacherShared,
        fetchMeBack, setUpgrade
    } = useTeacher()

    const [selectedMenuItem] = React.useState<string>(location.pathname)
    const useStyles = makeStyles((theme: Theme) => createStyles({
        root: {
            display: 'fixed',
            backgroundImage: `url(${background})`,
            backgroundSize: 'cover',
            backgroundRepeat: 'no-repeat',
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
            },
            marginLeft: teacher ? drawerWidth - 5 : 0,
            minHeight: '100vh',
        },
        appBar: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
                width: '100%',
            },
            width: display ? `calc(100% - ${teacher ? drawerWidth : 0}px)` : 0,
            marginLeft: teacher ? drawerWidth : 0,
            backgroundColor: 'transparent',
        },
        drawer: {
            width: display ? teacher ? drawerWidth : 0 : 0,
            flexShrink: 0,
        },
        drawerPaper: {
            width: display ? teacher ? drawerWidth : 0 : 0,
        },
        drawerHeader: {
            display: 'flex',
            alignItems: 'center',
            padding: theme.spacing(0, 1),
            // necessary for content to be below app bar
            ...theme.mixins.toolbar,
            justifyContent: 'flex-end',
        },
        content: {
            flexGrow: 1,
            padding: theme.spacing(3),
            // marginLeft: 0,
        },
        image: {
            width: 64,
            height: 64,
            margin: 15,
            marginLeft: 20
        },
        selectedMenuItem: {
            backgroundColor: 'orange',
            position: 'absolute',
            left: 0,
            width: 5,
            height: 23
        },
        appBarButtons: {
            marginLeft: 'auto',
            display: 'flex',
            flexDirection: 'row'
        },
        centeredDialog: {
            [theme.breakpoints.down('sm')]: {
                marginLeft: 0,
            },
            marginLeft: drawerWidth - 5,
            zIndex: 1301
        }
    }))
    const classes = useStyles()
    const [logout, setLogout] = React.useState(false)
    const [loggingOut, setLoggingOut] = React.useState(false)
    const [mobileOpen, setMobileOpen] = React.useState(false)
    const [confirm, setConfirm] = React.useState(false)

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen)
    }

    const handleLogout = async () => {
        await wrap(async () => {
            await Auth.signOut()

            Hub.dispatch('auth', { event: 'signOut' }) // todo: this should be removed!
            setLoggingOut(false)

            if (localStorage.getItem('logged_in_via_uni_login') === 'true') {
                history.push('/login')
            } else {
                history.push('/login')
            }
        }, {
            operation: 'logging out',
            stateFunc: setLoggingOut
        })
    }

    const isSelected = (name: string) => selectedMenuItem === name
    const drawer = (
        <>{isTeacherLikeAParent ? (
            <img
                className={classes.image}
                src={LogoColor}
                alt="geekster" />
        ) : <img className={classes.image} src={Logo} alt="geekster" />}
            <List>
                {
                    teacherShared && (
                        <>
                            <div style={{
                                display: 'flex',
                                justifyContent: 'center'
                            }}>
                                <small>{'Du er nu på '}
                                    <b style={{ textTransform: 'capitalize' }}>
                                        {`${teacher.firstName}${teacher.firstName
                                            .charAt(teacher.firstName.length - 1) === 's' ? '\'' : 's'}`}
                                    </b> konto
                                </small>
                            </div>

                            <ListItem
                                button
                                onClick={() => fetchMeBack()}>
                                <ListItemIcon><AccountBoxIcon /></ListItemIcon>
                                <ListItemText primary="Gå tilbage til egen profil" />
                            </ListItem>
                            <Divider />
                        </>
                    )
                }
                {isTeacherLikeAParent
                    ? TeacherDrawerRoutes.filter((item) => item.name === 'Hjem').map((item) => (
                        <ListItem
                            button
                            key={item.name}
                            onClick={() => history.push(item.path, { className: undefined })}>
                            {isSelected(item.path)
                                && <div className={classes.selectedMenuItem} />}
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    )) 
                    : TeacherDrawerRoutes.map((item) => (
                        <ListItem
                            button
                            key={item.name}
                            onClick={
                                () => ((teacher?.free && notAllowedFreeAccounts.includes(item.path))
                                    ? showMessage('Du skal opgradere for at bruge denne seje funktion!', 'error')
                                    : history.push(item.path, { className: undefined }))
                            }>
                            {isSelected(item.path)
                                && <div className={classes.selectedMenuItem} />}
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>

                    ))}

            </List>
            <Divider />
            <List>
                {LibraryDrawerRoutes.map((item) => (!isTeacherLikeAParent ? (
                    <ListItem button key={item.name} onClick={() => history.push(item.path)}>
                        {isSelected(item.path)
                            && <div className={classes.selectedMenuItem} />}
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItem>
                ) : item.path !== '/quizbibliotek' ? <></> : (
                    <ListItem button key={item.name} onClick={() => history.push(item.path)}>
                        {isSelected(item.path)
                            && <div className={classes.selectedMenuItem} />}
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItem>
                )))}

            </List>
            {!isTeacherLikeAParent && <Divider />}
            {
                isTeacherLikeAParent ? null
                 /* ProfileRoutes.filter((item) => item.name !== 'Mine quizzer').map((item) => (
                    <ListItem button key={item.name} onClick={() => history.push(item.path)}>
                        {isSelected(item.path)
                            && <div className={classes.selectedMenuItem} />}
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.name} />
                    </ListItem>
                )) */ 
                    : ProfileRoutes.map((item) => (
                        <ListItem
                            button
                            key={item.name}
                            onClick={
                                () => ((teacher?.free && notAllowedFreeAccounts.includes(item.path))
                                    ? showMessage('Du skal opgradere for at bruge denne seje funktion!', 'error')
                                    : history.push(item.path, { className: undefined }))
                            }>
                            {isSelected(item.path)
                                && <div className={classes.selectedMenuItem} />}
                            <ListItemIcon>{item.icon}</ListItemIcon>
                            <ListItemText primary={item.name} />
                        </ListItem>
                    ))
            }
            {!isTeacherLikeAParent && (
            <> <Divider />
                <ListItem button key={Profile.name} onClick={() => history.push(Profile.path)}>
                    {isSelected(Profile.path)
                        && <div className={classes.selectedMenuItem} />}
                    <ListItemIcon>{Profile.icon}</ListItemIcon>
                    <ListItemText primary={Profile.name} />
                </ListItem>
            </>
                )}
            
            {/* {
                teacher && teacher.subscriptionPricePlan === '1year' && (
                    <ListItem button key={GeeksterOffers.name} onClick={() => history.push(GeeksterOffers.path)}>
                        {isSelected(GeeksterOffers.path)
                            && <div className={classes.selectedMenuItem} />}
                         <ListItemIcon>{GeeksterOffers.icon}</ListItemIcon>
                        <ListItemText primary={GeeksterOffers.name} />
                    </ListItem>
                )
            } */}
            <Divider />
            <ListItem
                button
                key="foraeldreadgang"
                onClick={() => {
                    if (teacher.free) {
                        showMessage('Du skal opgradere for at bruge denne seje funktion!', 'error')
                        return
                    }
                    changeTeacherStatus()
                }}>
                <ListItemIcon>
                    {isTeacherLikeAParent
                        ? <img height="35" src={LogoGeekster} alt="geekster" />
                        : <IcomoonReact iconSet={iconSet} size={40} icon="children" />}
                </ListItemIcon>

                <ListItemText primary={isTeacherLikeAParent ? 'Gå tilbage' : 'Quick Quizzer'} />
            </ListItem>
            <ListItem
                button
                key="quiz-edu"
                onClick={() => setConfirm(true)}>
                <ListItemIcon>{QuizEdu.icon}</ListItemIcon>
                <ListItemText primary={QuizEdu.name} />
            </ListItem>
        </>
    )

    return (
        <Body>
            <Dialog
                open={confirm}
                onClose={() => setConfirm(false)}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
                className={classes.centeredDialog}>
                <DialogTitle id="alert-dialog-title">Er du en betalende bruger?</DialogTitle>
                <DialogActions>
                    <ThemeProvider theme={buttonTheme}>
                        <Button style={{ textTransform: 'revert' }} onClick={() => setConfirm(false)}>
                            Nej
                        </Button>
                        <Button
                            style={{ textTransform: 'revert', color: '#f50057' }}
                            onClick={() => window.open(QuizEdu.path, '_blank')}
                            color="secondary">
                            Ja, helt sikker
                        </Button>
                    </ThemeProvider>
                </DialogActions>
            </Dialog>
            <div className={classes.root}>
                <CssBaseline />
                <>
                    <AppBar position="fixed" className={classes.appBar} elevation={0}>
                        <Toolbar>
                            <Hidden mdUp>
                                <IconButton
                                    color="inherit"
                                    aria-label="open drawer"
                                    edge="start"
                                    onClick={handleDrawerToggle}>
                                    <MenuIcon />
                                </IconButton>
                            </Hidden>
                            <FAB Icon={ScreeRotate} />
                            <div className={classes.appBarButtons}>
                                <ThemeProvider theme={buttonTheme}>
                                    {teacher?.free && (
                                        <Button
                                            onClick={() => setUpgrade(true)}
                                            color="primary">
                                            Opgradér: klik her
                                        </Button>
                                    )}

                                    <a
                                        style={{ textDecoration: 'none' }}
                                        href="https://nedtaelling.geekster.dk/"
                                        target="_blank"
                                        rel="noreferrer">
                                        <Button
                                            color="primary">

                                            Nedtælling
                                        </Button>
                                    </a>
                                    <Button onClick={() => history.push('/pdfbibliotek')} color="primary">
                                        Kopi-ark
                                    </Button>
                                    <a
                                        style={{ textDecoration: 'none' }}
                                        href="https://spilleplade.geekster.dk/"
                                        target="_blank"
                                        rel="noreferrer">
                                        <Button
                                            color="primary">

                                            Spilleplader
                                        </Button>
                                    </a>
                                    <Button onClick={() => history.push('/help')} color="primary">
                                        Hjælp
                                    </Button>
                                    {signedIn && (
                                        <>
                                            <Button
                                                variant="outlined"
                                                onClick={() => setLogout(true)}
                                                color="secondary"
                                                style={{ background: '#fff' }}>
                                                LOG UD
                                            </Button>
                                        </>
                                    )}
                                    {/* // : ( */}
                                    {/* //     <>
                                    //         <Button onClick={() => history.push('/')} color="primary">
                                    //             BUY ACCESS
                                    //         </Button>
                                    //         <Button onClick={() => history.push('/about')} color="primary">
                                    //             ABOUT GEEKSTER
                                    //         </Button>
                                    //         <Button onClick={() => history.push('/')} color="primary">
                                    //             PRICES
                                    //         </Button>
                                    //         <Button href="#text-buttons" color="primary">
                                    //             TRY VIDEOS, QUIZZES AND GAMES
                                    //         </Button>
                                    //         <Button
                                    //             variant="outlined"
                                    //             onClick={() => setLogout(true)}
                                    //             color="secondary"
                                    //             style={{ background: '#fff' }}>
                                    //             LOG UD
                                    //         </Button>
                                    //     </>
                                    // )} */}
                                </ThemeProvider>
                            </div>
                        </Toolbar>
                    </AppBar>
                    <Hidden mdUp={false}>
                        <Drawer
                            className={classes.drawer}
                            open={mobileOpen}
                            variant="temporary"
                            onClose={handleDrawerToggle}
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            ModalProps={{
                                keepMounted: true, // Better open performance on mobile.
                            }}
                            anchor="left">
                            {!isLoading && drawer}
                        </Drawer>
                    </Hidden>
                    <Hidden smDown mdUp={false}>
                        <Drawer
                            className={classes.drawer}
                            open
                            variant="permanent"
                            classes={{
                                paper: classes.drawerPaper,
                            }}
                            anchor="left">
                            {!isLoading && drawer}
                        </Drawer>
                    </Hidden>
                </>
                <main
                    className={classes.content}>
                    <div className={classes.drawerHeader} />
                    {children}
                </main>
                <LogoutModal
                    disableAccept={loggingOut}
                    onConfirmAction={handleLogout}
                    onClose={() => setLogout(true)}
                    onCancelAction={() => setLogout(false)}
                    isOpen={logout}
                    headerText="Er du sikker på at du vil logge ud?"
                    confirmActionButtonText="Ja, helt sikker"
                    cancelActionButtonText="Nej" />
            </div>
        </Body>
    )
}
