import {
    Button, Checkbox, FormLabel, Link, ThemeProvider
} from '@material-ui/core'
import * as React from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import validator from 'validator'

// import unilogin from '../../assets/unilogin.png'
// import { AuthButton } from '../../components/molecules/AuthButton'
import { Loading } from '../../components/molecules/Loading'
import { Logo } from '../../components/molecules/Logo'
import { NoAuthenticatedNav } from '../../components/molecules/NoAuthenticatedNav'
import { TextInputTemplate } from '../../components/molecules/TextInputTemplate'
import { SignUpForm } from '../../components/organisms/SignUpForm'
import { Body } from '../../components/wrappers/Body'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { PricePlan } from '../../types/types'
// import { makeLocalUri } from '../../utils/helper'
import { showMessage } from '../../utils/notifications'
// import Routes from '../../utils/Routes'
import { validateCoupon } from '../../utils/Services/reepay-service'
import { signUpTeacher } from '../../utils/Services/teacher-service'
import { buttonTheme, useTableStyles } from '../../utils/styles'
import { wrap } from '../../utils/wrap-functions'

interface Props {
    showSubscriptionOptions: boolean
}

export const SignUp: React.FC<Props> = ({ showSubscriptionOptions }) => {
    const buttonClasses = buttonStyles()
    const tableClasses = useTableStyles()

    const [subscribeNewsLetter, setSubscibeNewsLetter] = React.useState(false)
    const [email, setEmail] = React.useState<string>('')
    const [password, setPassword] = React.useState<string>('')
    const [repeatPassword, setRepeatPassword] = React.useState<string>('')
    const [school, setSchool] = React.useState<string>('')
    const [firstName, setFirstName] = React.useState<string>('')
    const [lastName, setLastName] = React.useState<string>('')
    const [coupon, setCoupon] = React.useState<string>('')
    const [acceptedTaC, setAcceptedTaC] = React.useState(false)
    const [goToLogin, setGoToLogin] = React.useState(false)
    const [confirmStage, setConfirmStage] = React.useState(false)
    const [signingUp, setSigningUp] = React.useState(false)
    // const [redirectToUniLogin, setRedirectToUniLogin] = React.useState(false)
    const [userId, setUserId] = React.useState<string>('')
    const [selectedPricePlan, setSelectedPricePlan] = React.useState<PricePlan>()
    // const [signUpView, setSignUpView] = React.useState<null | number>(null)

    const history = useHistory()

    // const location = useLocation()

    React.useEffect(() => {
        if (!showSubscriptionOptions) setSelectedPricePlan('1year')
    }, [showSubscriptionOptions])
    const returnErrorIfFormNotValid = async () => {
        let errorMessage = ''
        if (!email) errorMessage = 'Tilføj venligst en gyldig e-mail'

        if (!selectedPricePlan) errorMessage = 'Vælg venligst en prisplan'

        if (coupon?.length) {
            if (!(await validateCoupon(coupon)).isValid) {
                errorMessage = 'Kuponen er ikke gyldig'
            }
        }

        return errorMessage
    }

    const signUp = async () => {
        const formErrorMessage = await returnErrorIfFormNotValid()
        if (formErrorMessage.length > 0) {
            showMessage(formErrorMessage, 'error')
            return
        }
        if (!validator.isStrongPassword(password, {
            returnScore: false, minLength: 8, minLowercase: 0, minSymbols: 0, minUppercase: 0, minNumbers: 0
        })) {
            showMessage('Adgangskoden skal være mindst 8 tegn lang', 'error')
            return
        }

        if (repeatPassword !== password) {
            showMessage('Adgangskoderne passer ikke sammen', 'error')
            return
        }
        await wrap(async () => {
            const teacher = await signUpTeacher({
                email,
                password,
                school,
                firstName,
                lastName,
                isParent: false,
                subscriptionPricePlan: selectedPricePlan as PricePlan,
                coupon: coupon?.length ? coupon : undefined,
                subscribeNewsLetter
            })

            setUserId(teacher.id)
            setConfirmStage(true)
        }, {
            showSuccess: true,
            stateFunc: setSigningUp,
            operation: 'Signing Up'
        })

    }

    // const signUpWithUniLogin = async () => {
    //     const formErrorMessage = await returnErrorIfFormNotValid()
    //     if (formErrorMessage.length > 0) {
    //         showMessage(formErrorMessage, 'error')
    //         return
    //     }
    //     await wrap(async () => {
    //         const returnPath = makeLocalUri(Routes.CompleteSignUpWithUniLogin.path)
    //         const { clientUri } = await initiateSignUpTeacherWithUniLogin({
    //             email,
    //             school,
    //             firstName,
    //             lastName,
    //             isParent: false,
    //             subscriptionPricePlan: selectedPricePlan as PricePlan,
    //             password: 'dummy',
    //             coupon: coupon?.length ? coupon : undefined,
    //             subscribeNewsLetter
    //         }, returnPath)

    //         setRedirectToUniLogin(true)
    //         window.location.href = clientUri
    //     }, {
    //         stateFunc: setSigningUp,
    //         operation: 'Signing Up'
    //     })
    // }

    if (signingUp) {
        return <Loading />
    }

    if (confirmStage) {
        return (
            <Redirect to={{
                pathname: '/confirm',
                state: {
                    email,
                    id: userId,
                    lastName,
                    pricePlan: selectedPricePlan,
                    coupon: coupon?.length ? coupon : undefined
                }
            }} />
        )
    }

    if (goToLogin) {
        return (
            <Redirect to="/login" />
        )
    }

    return (
        <div className={tableClasses.authBackground}>
            <NoAuthenticatedNav onClick={() => setGoToLogin(true)} title="Log ind" />
            <div className={tableClasses.authLogoContainer} style={{ marginBottom: 30 }}>
                <Logo />
            </div>
            <Body>
                {/* <div className={tableClasses.divAuthButtons}>
                    {/* This should be uncommented in order to allow the parents profile creation */}
                {/* <AuthButton
                        textColor={signUpView === 0 ? '#FFFFFF' : '#000'}
                        backgroundColor={signUpView === 0 ? '#3C8CAC' : '#FFFFFF'}
                        onClick={() => setSignUpView(0)}
                        title="Opret forældreadgang med Geekster"
                        visible={location.pathname !== '/skoler'} /> }
                    <AuthButton
                        textColor={signUpView === 1 ? '#FFFFFF' : '#000'}
                        backgroundColor={signUpView === 1 ? '#3C8CAC' : '#FFFFFF'}
                        onClick={() => setSignUpView(1)}
                        title="Opret lærerprofil med Geekster" />
                    <AuthButton
                        showLogo
                        source={unilogin}
                        alt="Unilogin logo"
                        textColor={signUpView === 2 ? '#FFFFFF' : '#000'}
                        backgroundColor={signUpView === 2 ? '#3C8CAC' : '#FFFFFF'}
                        onClick={() => setSignUpView(2)}
                        title="Opret lærerprofil med" />
                </div> */}

                {/* {signUpView === 1 && ( */}
                <SignUpForm
                    gratis={selectedPricePlan === 'free'}
                    showSubscriptionOptions={showSubscriptionOptions}
                    selectedPricePlan={selectedPricePlan}
                    setSelectedPricePlan={setSelectedPricePlan}
                    onCouponClick={() => history.push('/skoler')}>
                    <TextInputTemplate value={email} onChange={setEmail} label="Email" />

                    <TextInputTemplate value={school} onChange={setSchool} label="Skoler" />
                    <TextInputTemplate
                        value={password}
                        label="Password"
                        onChange={setPassword}
                        type="password" />

                    <TextInputTemplate
                        value={repeatPassword}
                        onChange={setRepeatPassword}
                        label="Gentag Password"
                        type="password" />

                    <div className={tableClasses.divInputRow}>
                        <TextInputTemplate
                            value={firstName}
                            onChange={setFirstName}
                            label="Fornavn" />

                        <TextInputTemplate
                            value={lastName}
                            onChange={setLastName}
                            label="Efternavn" />
                    </div>
                    {selectedPricePlan !== 'free' && (
                        <TextInputTemplate
                            value={coupon}
                            onChange={setCoupon}
                            label="Kupon" />
                    )}
                    <div className={tableClasses.checkBox}>
                        <Checkbox
                            checked={acceptedTaC}
                            onChange={(e) => setAcceptedTaC(e.target.checked)}
                            inputProps={{ 'aria-label': 'primary checkbox' }} />
                        <Link
                            target="_blank"
                            style={{ fontFamily: 'Encode, sans-serif' }}
                            href="/handelsbetingelser">Jeg er enig i Geekster.dk*
                        </Link>
                    </div>
                    <div className={tableClasses.checkBox}>
                        <Checkbox
                            id="newsletter"
                            checked={subscribeNewsLetter}
                            onChange={(e) => setSubscibeNewsLetter(e.target.checked)}
                            inputProps={{ 'aria-label': 'primary checkbox' }} />
                        <FormLabel htmlFor="newsletter">Tilmeld dig Geeksters nyhedsbrev</FormLabel>
                    </div>
                    <ThemeProvider theme={buttonTheme}>
                        <div>
                            <Button
                                className={buttonClasses.authButton}
                                variant="contained"
                                color="primary"
                                disabled={!acceptedTaC
                                    || !email || !firstName || !lastName || !password}
                                onClick={signUp}>
                                Opret profil
                            </Button>
                        </div>
                    </ThemeProvider>
                </SignUpForm>
                {/* )} */}
                {/* {signUpView === 2 && (
                    <SignUpForm
                        showSubscriptionOptions={showSubscriptionOptions}
                        selectedPricePlan={selectedPricePlan}
                        setSelectedPricePlan={setSelectedPricePlan}
                        onCouponClick={() => history.push('/skoler')}>
                        <TextInputTemplate value={email} onChange={setEmail} label="Email" />

                        <TextInputTemplate value={school} onChange={setSchool} label="Skoler" />
                        <div className={tableClasses.divInputRow}>
                            <TextInputTemplate
                                value={firstName}
                                onChange={setFirstName}
                                label="Fornavn" />

                            <TextInputTemplate
                                value={lastName}
                                onChange={setLastName}
                                label="Efternavn" />
                        </div>
                        <TextInputTemplate
                            value={coupon}
                            onChange={setCoupon}
                            label="Kupon" />
                        <div className={tableClasses.checkBox}>
                            <Checkbox
                                checked={acceptedTaC}
                                onChange={(e) => setAcceptedTaC(e.target.checked)}
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                            <Link
                                target="_blank"
                                style={{ fontFamily: 'Encode, sans-serif' }}
                                href="/handelsbetingelser">Jeg er enig i Geekster.dk*
                            </Link>
                        </div>
                        <div className={tableClasses.checkBox}>
                            <Checkbox
                                id="newsletter"
                                checked={subscribeNewsLetter}
                                onChange={(e) => setSubscibeNewsLetter(e.target.checked)}
                                inputProps={{ 'aria-label': 'primary checkbox' }} />
                            <FormLabel htmlFor="newsletter">Tilmeld dig Geeksters nyhedsbrev</FormLabel>
                        </div>
                        <ThemeProvider theme={buttonTheme}>
                            <div>
                                <Button
                                    className={buttonClasses.authButton}
                                    variant="contained"
                                    color="secondary"
                                    disabled={!acceptedTaC || !email || !firstName || !lastName}
                                    onClick={signUpWithUniLogin}>
                                    Opret profil med UniLogin
                                </Button>
                            </div>
                        </ThemeProvider>
                    </SignUpForm>
                )} */}
            </Body>
        </div>
    )
}
