import {
    Paper, TableContainer
} from '@material-ui/core'
import * as React from 'react'
import { useAsync } from 'react-async-hook'

import { ClassType } from '../../types/types'
import { deleteClass, updateClass } from '../../utils/Services/classes-service'
import { getHomeWorkClasses } from '../../utils/Services/homework-service'
// Need to check that out
import { useTableStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'
import ConfirmDialog from '../atoms/ConfirmDialog'
import FormButtons from '../atoms/FormButtons'
import AddTeacherSharedDialog from '../molecules/AddTeacherSharedDialog'
import { Loading } from '../molecules/Loading'
import UpdateClassComponent from './UpdateClassComponent'

interface Props {
    defaultCheckedState: boolean[]
    selected: string,
    isSelected: (item: any) => void
}

const UpdateClassScreen: React.FC<Props> = ({
    selected,
    isSelected,
    defaultCheckedState
}) => {
    const [teachers, setTeachers] = React.useState([])

    const tableClasses = useTableStyles()
    const {
        fetchClasses,
        fetchClassNames,
        fetchStudentClassNames,
        teachersSharedWithMe,
        classesShared,
        me,
    } = useTeacher()
    const fetchHomeworkClasses = useAsync(getHomeWorkClasses, [selected])

    React.useEffect(() => {
        setTeachers(
            [...fetchClasses.result, ...classesShared].find(
                (classes: ClassType) => classes.id === selected
            ).teachers
        )

    }, [fetchClasses.result, classesShared])
    const [title, setClassName] = React.useState<string>('')
    const [description, setDescription] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)
    const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
    const [openTeachersSharedModal, setOpenTeachersSharedModal] = React.useState<boolean>(false)

    const addTeachers = async (checkedTeachers: string[]) => {
        setLoading(true)
        await updateClass(title, description, [...teachers, ...checkedTeachers], selected)
        setLoading(false)
        fetchClasses.execute()
        // to update selected classes
        fetchHomeworkClasses.execute(selected)
        // to update class names of homeworks on home screen
        fetchClassNames.execute()
        setOpenTeachersSharedModal(false)
    }

    const onDelete = async () => {
        setConfirmDelete(false)
        setLoading(true)
        await deleteClass(selected)
        // to update default class screen
        fetchClasses.execute()
        // to update class names on student screen
        fetchStudentClassNames.execute()
        isSelected(null)
        setLoading(false)
    }

    const onDeleteTeacher = async (id: string) => {
        setConfirmDelete(false)
        setLoading(true)
        await updateClass(title, description, teachers.filter((t: string) => t !== id), selected)
        // to update default class screen
        fetchClasses.execute()
        // to update class names on student screen
        fetchStudentClassNames.execute()
        setLoading(false)
    }

    const onUpdate = async () => {
        setLoading(true)
        await updateClass(title, description, teachers, selected)
        // to update default class screen
        fetchClasses.execute()
        // to update class names of homeworks on home screen
        fetchClassNames.execute()
        // to update class names on student screen
        fetchStudentClassNames.execute()
        isSelected(null)
        setLoading(false)
    }

    if (loading || fetchClasses.loading) {
        return <Loading />
    }

    return (
        <>
            <TableContainer className={tableClasses.table} component={Paper}>
                <UpdateClassComponent
                    selectedId={selected}
                    isSelected={isSelected}
                    headerText="En klasse"
                    className={title}
                    setClassName={setClassName}
                    description={description}
                    setDescription={setDescription}
                    setOpenTeachersSharedModal={setOpenTeachersSharedModal}
                    onDeleteTeacher={onDeleteTeacher} />
            </TableContainer>

            <ConfirmDialog
                text="Er du sikker på at du vil slette denne quiz?"
                open={confirmDelete}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={onDelete} />
            <AddTeacherSharedDialog
                open={openTeachersSharedModal}
                setOpen={setOpenTeachersSharedModal}
                confirmSelection={addTeachers}
                selectedClassIds={teachers || []}
                allTeachers={teachersSharedWithMe}
                defaultCheckedState={defaultCheckedState} />
            <FormButtons
                createButtonText="Gem"
                onClickCreate={onUpdate}
                onClickDelete={() => setConfirmDelete(true)}
                deleteButtonText="Slet klasse"
                disableButtons={loading || !!me} />

        </>
    )
}
export default UpdateClassScreen
