import {
    Button,
    Checkbox,
    createStyles, Dialog, DialogContent, FormControlLabel, makeStyles, Popover, Select, TextField, Typography
} from '@material-ui/core'
import HelpIcon from '@material-ui/icons/Help'
import * as React from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import { ClassType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { createHomework } from '../../utils/Services/homework-service'
import { useTeacher } from '../../utils/TeacherManager'
import { wrap } from '../../utils/wrap-functions'
import Alert from '../atoms/Alert'
import GoBackHeader from '../atoms/GoBackHeader'
import SwitchWithSideText from '../atoms/SwitchWithSideText'
import CreateScreenTemplate from '../molecules/CreateScreenTemplate'
import { TextInputTemplate } from '../molecules/TextInputTemplate'
import { drawerWidth } from '../wrappers/BodyWithTopBar'

interface Props {
    selectedQuizzes?: string[],
    selectedVideos?: string[],
    setCreateNew?: (boolean: boolean) => void,
    setAfterCreate?: (boolean: boolean) => void,
    onHomeworkCreate?: () => void
}

type LocationState = {
    state: {
        videoId?: string;
        quizId?: string;
        name: string
    }
}

const useStyles = makeStyles((theme) => createStyles({
    createText: {
        marginTop: 15
    },
    centeredSnackbar: {
        [theme.breakpoints.up('md')]: {
            marginLeft: drawerWidth - 20
        },
    }
}),)

const CreateAssignmentScreen: React.FC<Props> = ({
    setCreateNew,
    setAfterCreate,
    onHomeworkCreate,
    selectedQuizzes, selectedVideos
}) => {
    const classes = useStyles()

    const { fetchClasses, fetchStudents } = useTeacher()
    const { result, error, loading }:
        { result: ClassType[], error: Error, loading: boolean } = fetchClasses
    const { result: resultStudents } = fetchStudents
    const [name, setName] = React.useState('')
    const [selectedClass, setSelectedClass] = React.useState<string>()
    const [date, setDate] = React.useState<string>('')

    const [createError, setCreateError] = React.useState<boolean>(false)
    const [errorMessage, setErrorMessage] = React.useState<string>('')
    const [processing, setProcessing] = React.useState<boolean>(false)
    const [isPublic, setIsPublic] = React.useState<boolean>(false)
    const [open, setOpen] = React.useState(false)

    const history = useHistory()

    const isLoading = () => {
        return processing || loading
    }

    const handleCreate = async () => {
        let id
        const selectedClasses = selectedClass ? [selectedClass] : []

        if (checkDate()) {
            setCreateError(true)
            setErrorMessage('Use a different date!')
            return
        }

        if (!validateForm()) {
            setCreateError(true)
            setErrorMessage('Please fill out all the inputs')
            return
        }

        await wrap(async () => {
            // TODO: use window location domain here.
            ({ id } = await createHomework(name,

                selectedClasses,
                selectedQuizzes || [],
                selectedVideos || [],
                date,
                'geekster.dk',
                isPublic,
                [{ id: String(selectedClass), exclusions }]))

            if (onHomeworkCreate) {
                onHomeworkCreate()
            }
        }, {
            operation: 'Creating Homework',
            stateFunc: setProcessing
        })

        if (setCreateNew) {
            setCreateNew(false)
        }

        if (setAfterCreate) {
            setAfterCreate(true)
        }
        window.location.href = `lektier/${String(id)}`
    }

    const checkDate = (): boolean => {
        const difference = new Date(date).getTime() - new Date().getTime()
        return difference <= 0
    }

    const validateForm = () => {
        return (name.length > 0 && !checkDate())
    }

    if (error) {
        showMessage(error.message)
    }

    const { state } = useLocation() as LocationState

    React.useEffect(() => {
        if (state?.videoId || state?.quizId) {
            setName(state.name)
        }
    }, [])

    const [studentsSelected, setStudentsSelected] = React.useState<any[] | undefined>([])

    React.useEffect(() => {
        if (selectedClass) {
            const array = result.find((r) => r.id === selectedClass)!
                .students?.filter((student) => !student.startsWith('anon-')).map((id) => resultStudents
                    .find((student: any) => id === student.id)).filter(Boolean).map((student) => (
                        { ...student, selected: true }
                    ))
            setStudentsSelected(array)
        }
    }, [selectedClass])

    const [exclusions, setExclusions] = React.useState<string[]>([])
    const handleCheckStudent = (student: any) => {
        const index = exclusions.indexOf(student.id)
        if (index > -1) {
            exclusions.splice(index, 1)
            setExclusions([...exclusions])
        } else {
            setExclusions((students) => [...students, student.id])
        }
        // eslint-disable-next-line no-param-reassign
        Object.assign(student, { selected: !student.selected })
        setStudentsSelected((students) => [...students!])
    }
    const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)

    const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const handlePopoverClose = () => {
        setAnchorEl(null)
    }

    const popoverOpen = Boolean(anchorEl)
    return (
        <CreateScreenTemplate
            headerText="Mine lektie"
            createButtonText="Opret lektie"
            setCreateNew={(boolean: boolean) => (setCreateNew ? setCreateNew(boolean) : false)}
            create={handleCreate}
            deleteFunction={() => (setCreateNew ? setCreateNew(false) : history.goBack())}
            disableButtons={isLoading()}
            goBackFunction={() => history.goBack()}
            disableCreate={!validateForm()}>

            <>
                <Dialog
                    open={open}
                    onClose={() => setOpen(false)}
                    aria-labelledby="form-dialog-title"
                    className={classes.centeredSnackbar}>
                    <DialogContent>
                        <GoBackHeader
                            headerText="Fjern de elever der ikke skal ha´ denne lektie."
                            onClick={() => setOpen(!open)} />
                        <div style={{ width: '100%', display: 'flex' }}>
                            <Typography
                                aria-owns={popoverOpen ? 'mouse-over-popover' : undefined}
                                aria-haspopup="true"
                                onMouseEnter={handlePopoverOpen}
                                onMouseLeave={handlePopoverClose}
                                style={{
                                    cursor: 'pointer',
                                    color: '#03a9f4',
                                    marginLeft: 'auto',
                                    marginRight: 'auto'
                                }}>
                                <HelpIcon />
                            </Typography>
                            <Popover
                                id="mouse-over-popover"
                                style={{ pointerEvents: 'none' }}
                                disableAutoFocus
                                disableEnforceFocus
                                open={popoverOpen}
                                anchorEl={anchorEl}
                                anchorOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'left',
                                }}
                                transformOrigin={{
                                    vertical: 'top',
                                    horizontal: 'left',
                                }}
                                PaperProps={{
                                    style: { width: 'calc(100vw - 30px)', maxWidth: 500 },
                                }}
                                onClose={handlePopoverClose}>
                                <Typography style={{ paddingLeft: 10, paddingRight: 10 }} component="p">
                                    Når du fjerner markeringen for eleverne, så optræder denne
                                    lektie ikke for dem når de logger ind. Dette gælder kun for
                                    den aktuelle lektie. På den måde kan du differentiere i opgaverne
                                    inden for den samme klasse
                                </Typography>
                            </Popover>

                        </div>
                        <Typography variant="h5">
                            {selectedClass && studentsSelected!.map((student) => (
                                <p>
                                    <FormControlLabel
                                        control={
                                            (
                                                <Checkbox
                                                    style={{
                                                        color: '#ff1744',
                                                    }}
                                                    checked={student.selected}
                                                    onClick={() => {
                                                        handleCheckStudent(student)
                                                    }} />
                                            )
                                        }
                                        label={`${student.firstName} ${student.lastName}`}
                                        key={student.id} />
                                </p>
                            ))}
                        </Typography>
                        <div style={{ display: 'flex', marginTop: 30, justifyContent: 'center' }}>
                            <Button
                                onClick={() => setOpen(false)}
                                variant="contained"
                                color="primary">Fuldfør
                            </Button>
                        </div>
                    </DialogContent>
                </Dialog>
                <TextInputTemplate
                    value={name}
                    onChange={setName}
                    label="Giv lektien et navn" />

                <Typography className={classes.createText} variant="body1">Tilføj til klasse</Typography>
                <Select
                    MenuProps={{
                        getContentAnchorEl: null,
                    }}
                    native
                    value={selectedClass}
                    onChange={(event) => setSelectedClass(event.target.value as string | undefined)}>

                    <option aria-label="None" value="" />
                    {result && result.map((item: ClassType) => (
                        <option key={item.id} value={item.id}>{item.title}</option>
                    ))}

                </Select>
                <Button
                    style={{ marginLeft: 'auto', marginRight: 'auto', width: 'fit-content' }}
                    disabled={!selectedClass}
                    size="small"
                    onClick={() => setOpen(!open)}>
                    Klik her for at fjerne elever fra denne lektie
                </Button>
                <TextField
                    id="date"
                    label="Udløbsdato"
                    type="date"
                    value={date}
                    onChange={(event) => setDate(event.target.value)}
                    error={checkDate()}
                    className={classes.createText}
                    InputLabelProps={{
                        shrink: true,
                    }} />
                <Typography className={classes.createText} variant="caption">
                    Vælg udløbsdato for lektien. Fra denne dato vil lektien ikke længere være tilgængelig
                </Typography>
                <SwitchWithSideText
                    falseText="Privat"
                    trueText="Offentlig"
                    checked={isPublic}
                    setChecked={setIsPublic} />
            </>

            <Alert text={errorMessage} setOpen={setCreateError} open={createError} type="alert" />
        </CreateScreenTemplate>
    )

}
export default CreateAssignmentScreen
