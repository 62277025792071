/* eslint-disable camelcase */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
import { DndContext, DragOverlay } from '@dnd-kit/core'
import qs from 'query-string'
import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { getPuzzleById } from '../../../utils/Services/puzzle-services'
import ConfettiCont from '../components/confetti'
import CounterScreen from '../components/counterScreen'
import Draggable from '../components/draggable'
import Droppable from '../components/droppable'
import Loading from '../components/loading'
import Logo from '../components/logo'
import PlayPuzzleBox from '../components/playPuzzleBox'
import styles from '../styles/play.module.css'
import { puzzlePieces } from '../utils/puzzleData'
import { microSolver, miniSolver } from '../utils/puzzleSolver'

export default function PuzzlePlay() {
    const [isLoading, setIsLoading] = useState(true)
    const [puzzleBoxes, setPuzzleBoxes] = useState(puzzlePieces)
    const [puzzleSize, setPuzzleSize] = useState(null)
    const [unsolvedBoxes, setUnsolvedBoxes] = useState([])
    const [solvedBoxes, setSolvedBoxes] = useState([])
    const [solved, setSolved] = useState(false)
    const [showIt, setShowIt] = useState(null)
    const [activeBox, setActiveBox] = useState(null)
    const [imageUrl, setImageUrl] = useState(null)
    const [loose, setLoose] = useState(null)
    const [placeSound, setPlaceSound] = useState(null)
    const [winAudio, setWinAudio] = useState(null)
    const [noPuzzle, setNoPuzzle] = useState(null)
    const [isAdmin, setIsAdmin] = useState(false)

    const { search: query } = useLocation()

    const { id, type } = qs.parse(query)
    
    useEffect(() => {
        const getPuzzles = async (id) => {
            // let tableName = "puzzles";
            // if (type === "admin") tableName = "admin_puzzles";
            const puzzleData = await getPuzzleById(id)
            if (puzzleData.error) {
                setNoPuzzle('Beklager ingen puslespil fundet.')
                setIsLoading(null)
                return
            }
            const { data } = puzzleData.boxes
            const { size } = puzzleData
            const imagePath = puzzleData.image
            const { isAdmin } = puzzleData
            setIsAdmin(isAdmin)
            const newPuzzleBoxes = shuffle(data)
            setPuzzleSize(size)
            setPuzzleBoxes(newPuzzleBoxes)

            const placeAudio = new Audio('https://geekster-files-prod1.s3.eu-west-1.amazonaws.com/beep.mp3')
            const winAudio = new Audio('https://geekster-files-prod1.s3.eu-west-1.amazonaws.com/win.mp3')
            setWinAudio(winAudio)
            setPlaceSound(placeAudio)

            setImageUrl(imagePath)
            setIsLoading(null)
        }

        const init = async () => {
            if (
                query
                && id
                && type
                && id.length > 30
                && id.length < 40
            ) {
                await getPuzzles(id, type)
            } else {
                setNoPuzzle('Beklager ugyldig puslespils-URL.')
                setIsLoading(null)
            }
        }
        init()
    }, [query])

    useEffect(() => {
        if (puzzleBoxes && puzzleBoxes.length > 0) {
            setUnsolvedBoxes(() => {
                const boxes = []
                puzzleBoxes.forEach((box, index) => {
                    boxes.push({
                        id: `puzzlePice-${index}`,
                        data: box,
                        component: (
                            <Draggable key={`puzzlePice-${index}`} id={`puzzlePice-${index}`}>
                                <PlayPuzzleBox box={box} />
                            </Draggable>
                        ),
                    })
                })
                return boxes
            })

            setSolvedBoxes(() => {
                const boxes = []
                puzzleBoxes.forEach(() => {
                    boxes.push({})
                })
                return boxes
            })
        }
    }, [puzzleBoxes])

    useEffect(() => {
        if (solvedBoxes.length > 0) {
            const result = solvedBoxes.every((box) => {
                if (box && !box.id) return false
                return true
            })
            if (result) {
                const check = solvedBoxes.every((box, index) => {
                    return sideCheck(solvedBoxes, box, index)
                })
                if (check) {
                    setSolved(result)
                    if (result && showIt) {
                        winAudio.play()
                    }
                } else {
                    setLoose(true)
                    setTimeout(() => {
                        setLoose(null)
                    }, 5000)
                }
            }
        }
    }, [solvedBoxes, showIt])

    // Utils
    function shuffle(array) {
        let currentIndex = array.length
        let randomIndex

        // While there remain elements to shuffle.
        // eslint-disable-next-line eqeqeq
        while (currentIndex != 0) {
            // Pick a remaining element.
            randomIndex = Math.floor(Math.random() * currentIndex)
            // eslint-disable-next-line no-plusplus
            currentIndex--;

            // And swap it with the current element.
            // eslint-disable-next-line no-param-reassign
            [array[currentIndex], array[randomIndex]] = [
                array[randomIndex],
                array[currentIndex],
            ]
        }

        return array
    }

    function findBoxIndexById(list, id) {
        let itemIndex = null
        list.every((box, index) => {
            if (box.id === id) {
                itemIndex = index
                return
            }
            // eslint-disable-next-line consistent-return
            return true
        })

        return itemIndex
    }

    function getNumberFromId(text) {
        return parseInt(text.split('-')[1], 10)
    }

    function handleDragStart(event) {
        const boxId = event.active.id
        // console.log({ ...solvedBoxes[0] });
        // console.log({ unsolvedBoxes });
        const boxIndexInUnsolved = findBoxIndexById(unsolvedBoxes, boxId)
        const boxIndexInSolved = findBoxIndexById(solvedBoxes, boxId)
        let currentActiveBox = null
        // console.log({ boxIndexInUnsolved, boxIndexInSolved, currentActiveBox });
        // console.log(boxIndexInUnsolved !== null && boxIndexInUnsolved >= 0);
        if (boxIndexInUnsolved !== null && boxIndexInUnsolved >= 0) {
            // console.log({ unsolvedBoxes });
            currentActiveBox = { ...unsolvedBoxes[boxIndexInUnsolved] }
        } else if (boxIndexInSolved !== null && boxIndexInSolved >= 0) {
            // console.log({ solvedBoxes });
            currentActiveBox = { ...solvedBoxes[boxIndexInSolved] }
        }
        // console.log({ boxIndexInUnsolved, boxIndexInSolved, currentActiveBox });
        setActiveBox(currentActiveBox)
        // console.log(boxIndex, unsolvedBoxes[boxIndex]);
    }

    function sideCheck(puzzleList, placingPice, solvedIndex) {
        let result
        if (puzzleList.length < 10) {
            result = microSolver(puzzleList, placingPice, solvedIndex)
        } else if (puzzleList.length > 10) {
            result = miniSolver(puzzleList, placingPice, solvedIndex)
        }
        // console.log({ "Result 👉": result });
        return result
    }

    function handleDragEnd(e) {
        const { over } = e
        if (over === null) {
            const currentActiveBox = { ...activeBox }
            const boxIndexInUnsolved = findBoxIndexById(
                unsolvedBoxes,
                currentActiveBox.id
            )
            // const boxIndexInSolved = findBoxIndexById(
            //     solvedBoxes,
            //     currentActiveBox.id
            // )
            if (boxIndexInUnsolved !== null && boxIndexInUnsolved >= 0) {
                // eslint-disable-next-line no-unused-expressions
                null
            } else {
                // console.log("Out trigger");
                // console.log(currentActiveBox);
                // const solvedIndex = getNumberFromId(over.id);
                // console.log(activeBox);
                const removeIndex = findBoxIndexById(solvedBoxes, currentActiveBox.id)
                // console.log(removeIndex);
                setSolvedBoxes((oldList) => {
                    const newList = [...oldList]
                    newList[removeIndex] = {}
                    return newList
                })

                setUnsolvedBoxes((oldList) => {
                    const newList = [...oldList]
                    newList.unshift(currentActiveBox)
                    return newList
                })
            }
        }
        if (over && over.id) {
            const currentActiveBox = { ...activeBox }
            const boxIndexInUnsolved = findBoxIndexById(
                unsolvedBoxes,
                currentActiveBox.id
            )
            const boxIndexInSolved = findBoxIndexById(
                solvedBoxes,
                currentActiveBox.id
            )
            if (
                boxIndexInUnsolved !== null
                && boxIndexInUnsolved >= 0
                && over.id !== 'unsolved'
            ) {
                // console.log(currentActiveBox);
                const solvedIndex = getNumberFromId(over.id)

                const canPlace = true // sideCheck(solvedBoxes, activeBox, solvedIndex);
                if (canPlace && !solvedBoxes[solvedIndex].id) {
                    // console.log(activeBox);
                    const removeIndex = findBoxIndexById(
                        unsolvedBoxes,
                        currentActiveBox.id
                    )
                    setSolvedBoxes((oldList) => {
                        const newList = [...oldList]
                        newList[solvedIndex] = currentActiveBox
                        return newList
                    })

                    setUnsolvedBoxes((oldList) => {
                        const newList = [...oldList]
                        newList.splice(removeIndex, 1)
                        return newList
                    })
                    placeSound.play()
                }
            } else if (
                boxIndexInSolved !== null
                && boxIndexInSolved >= 0
                && over.id === 'unsolved'
            ) {
                // console.log("trigger");
                // console.log(currentActiveBox);
                // const solvedIndex = getNumberFromId(over.id);
                // console.log(activeBox);
                const removeIndex = findBoxIndexById(solvedBoxes, currentActiveBox.id)
                // console.log(removeIndex);
                setSolvedBoxes((oldList) => {
                    const newList = [...oldList]
                    newList[removeIndex] = {}
                    return newList
                })

                setUnsolvedBoxes((oldList) => {
                    const newList = [...oldList]
                    newList.unshift(currentActiveBox)
                    return newList
                })

                placeSound.play()
            } else if (
                boxIndexInSolved !== null
                && boxIndexInSolved >= 0
                && over.id !== 'unsolved'
            ) {
                // Check for the 🧱 sides 🧱
                const solvedIndex = getNumberFromId(over.id)
                if (solvedIndex !== boxIndexInSolved) {
                    const canPlace = true // sideCheck(solvedBoxes, activeBox, solvedIndex);
                    if (canPlace && !solvedBoxes[solvedIndex].id) {
                        const removeIndex = findBoxIndexById(
                            solvedBoxes,
                            currentActiveBox.id
                        )
                        // console.log(removeIndex);
                        setSolvedBoxes((oldList) => {
                            const newList = [...oldList]
                            newList[removeIndex] = {}
                            newList[solvedIndex] = currentActiveBox
                            return newList
                        })
                        placeSound.play()
                    }
                }
            }
        }
        setActiveBox(null)
    }

    return (
        <>
            {isLoading ? (
                <Loading />
            ) : (
                <main
                    className={`${styles.body} ${styles.container}`}
                    style={{
                        touchAction: 'none',
                        overflow: 'hidden',
                        height: '100vh',
                        // eslint-disable-next-line no-dupe-keys
                        height: '100dvh',
                    }}>
                    {noPuzzle ? (
                        <h1>{noPuzzle}</h1>
                    ) : (
                        <>
                            {!solved ? (
                                <DndContext
                                    onDragStart={handleDragStart}
                                    onDragEnd={handleDragEnd}>
                                    <h1 className={styles.title}>
                                        Velkommen til Geeksters puslespil: læg brikkerne vha.
                                        matematik
                                    </h1>
                                    <div
                                        className={
                                            `${styles.gridContainer} 
                                            ${puzzleSize === '4x4' && `${styles.gridContainer4}`}`
                                        }>
                                        {solvedBoxes.map((box, index) => (
                                            <Droppable
                                                key={`solvable-${index}`}
                                                id={`solvable-${index}`}
                                                type="solved">
                                                <div
                                                    className={
                                                        `${styles.puzzlePlace}
                                                        ${box && box.id && `${styles.puzzlePlaced}`}`
                                                    }>
                                                    {box && box.id ? (
                                                        box.component
                                                    ) : (
                                                        <h4 className={styles.placePlaceholder}>
                                                            Læg brik
                                                        </h4>
                                                    )}
                                                </div>
                                            </Droppable>
                                        ))}
                                    </div>
                                    <div className={styles.unsolvedPieces}>
                                        <Droppable id="unsolved" type="unsolved">
                                            {unsolvedBoxes.map((box) => box.component)}
                                        </Droppable>
                                    </div>

                                    <DragOverlay>
                                        {activeBox && activeBox.id ? activeBox.component : null}
                                    </DragOverlay>
                                    {loose && (
                                        <div
                                            className={styles.looseContainer}
                                            onClick={() => setLoose(null)}>
                                            <h1 className={styles.looseMessage}>
                                                Der er nogle brikker der ikke passer sammen endnu Prøv
                                                igen
                                            </h1>
                                        </div>
                                    )}
                                </DndContext>
                            ) : !showIt ? (
                                <CounterScreen setShowIt={setShowIt} />
                            ) : (
                                <ConfettiCont />
                            )}
                            <img
                                className={`${styles.winImage} ${!showIt && `${styles.hideImage}`}`}
                                src={imageUrl}
                                alt="win" />
                        </>
                    )}
                    { isAdmin && solved ? null : <Logo /> }
                </main>
            )}
        </>
    )
}
