/* eslint-disable react/jsx-filename-extension */
import React from 'react'
import Confetti from 'react-confetti'

import useWindowSize from '../hooks/useWindowSize'

export default function ConfettiCont() {
    const { width, height } = useWindowSize()
    return <Confetti width={width} height={height} />
}
