import * as React from 'react'
import { useLocation } from 'react-router-dom'

import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import CreateClassScreen from '../../components/organisms/CreateClassScreen'
import DefaultClassScreen from '../../components/organisms/DefaultClassScreen'
import UpdateClassScreen from '../../components/organisms/UpdateClassScreen'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { ClassType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { useTeacher } from '../../utils/TeacherManager'

export const ClassesPage: React.FC = () => {
    const [defaultCheckedState, setDefaultCheckedState] = React.useState<boolean[]>([])
    const location: any = useLocation()
    const { fetchClasses, teachersSharedWithMe } = useTeacher()

    React.useEffect(() => {
        if (fetchClasses.result) setDefaultCheckedState(new Array(teachersSharedWithMe.length).fill(false))
    }, [fetchClasses.result])
    const { result, error, loading }:
        { result: ClassType[], error: Error, loading: boolean } = fetchClasses

    const myparam = location.state ? location.state.class : undefined
    const [selected, setSelected] = React.useState<string>(myparam)
    const [createNew, setCreateNew] = React.useState<boolean>(false)

    const renderCreateNewScreen = () => (
        <CreateClassScreen setCreateNew={(boolean: boolean) => setCreateNew(boolean)} />
    )

    const renderDefaultScreen = () => (
        <DefaultClassScreen 
            classes={result}
            setCreateNew={(boolean: boolean) => setCreateNew(boolean)}
            setSelected={(id: string) => setSelected(id)} />
    )

    const renderUpdateScreen = () => {
        return (
            <UpdateClassScreen
                defaultCheckedState={defaultCheckedState}
                selected={selected}
                isSelected={setSelected} />
        )
    }

    if (error) {
        showMessage(error.message)
    }

    if (loading) {
        return <Loading />
    }

    return (
        <BodyWithTopBar>
            {createNew && !selected && !loading && renderCreateNewScreen()}
            {!createNew && !selected && !loading && renderDefaultScreen()}
            {!createNew && selected && !loading && renderUpdateScreen()}
            <BottomBar onlyPicture />
        </BodyWithTopBar>
    )
}
