/* eslint-disable @typescript-eslint/no-unused-vars */
import {
    Box, Button, Container, Typography
} from '@material-ui/core'
import CheckIcon from '@material-ui/icons/Check'
import React from 'react'
import { useHistory } from 'react-router-dom'
import { genericHashLink } from 'react-router-hash-link'

import creditCards from '../../assets/credit-cards.jpg'
import { priceContainerStyle } from '../../styles/PriceContainerStyle'

interface Props {
    showSmall?: boolean;
    color?: string;
    boxShadowColor?: string;
    title?: string;
    price?: string;
    onChoosing?: void;
    buttonText: string
    content: string[];
    monthContent?: string;
    priceSentence: string;
    redirect?: boolean;
    onClick?: () => void;
    free?: boolean;
}

export const PricesTemplate: React.FC<Props> = ({
    showSmall,
    color,
    monthContent,
    title,
    boxShadowColor,
    price,
    onChoosing,
    priceSentence,
    content,
    redirect,
    buttonText,
    onClick,
    free = false
}) => {
    const classes = priceContainerStyle()

    const history = useHistory()
    const MyButton = genericHashLink(Button)

    return (
        <div style={{
            maxWidth: 350,
            marginBottom: 15,
            marginLeft: 'auto',
            marginRight: 'auto'
        }}>
            {!showSmall && (
                <Box boxShadow={`0 4px 20px 0 ${boxShadowColor}`} className={classes.container} onClick={onClick}>
                    <p style={{ color }} className={classes.boxTittle}>{title}</p>
                    <Container className={classes.priceContainer} style={{ backgroundColor: color }}>
                        <Typography
                            className={classes.priceText}
                            variant="h4">{price}
                        </Typography>
                        <span className={classes.priceMonth}>{monthContent}</span>
                    </Container>
                    <Container className={classes.ul}>
                        <ul style={{ minHeight: 338 }}>
                            {content?.map((c) => {
                                return (
                                    <div key={c} className={classes.items}>
                                        <CheckIcon />
                                        <li className={classes.li}>{c}</li>
                                    </div>
                                )
                            })}
                        </ul>
                        <p className={classes.priceSentence}>{priceSentence}</p>
                        <img
                            style={{ margin: '0 auto 10px auto', width: 150, visibility: free ? 'hidden' : 'visible' }}
                            src={creditCards}
                            alt="Accepted Credit card" />

                        {redirect ? (
                            <MyButton
                                style={{ width: '100%', backgroundColor: color }}
                                className={classes.button}
                                smooth
                                to="/skolepriser#contact-form-input">
                                {buttonText}
                            </MyButton>
                        ) : (
                            <Button
                                className={classes.button}
                                style={{ backgroundColor: color }}
                                fullWidth>{buttonText}
                            </Button>
                        )}
                    </Container>
                </Box>
            )}
            {showSmall && (
                <Box
                    boxShadow={`0 4px 20px 0 ${boxShadowColor}`}
                    style={{ backgroundColor: color }}
                    className={classes.container}
                    onClick={onClick}>
                    <Container className={classes.priceContainer} style={{ backgroundColor: color }}>
                        <Typography className={classes.priceText} variant="h4">{price}</Typography>
                        <span className={classes.priceMonth}>{monthContent}</span>
                        <Button
                            className={classes.button}
                            style={{ backgroundColor: color }}
                            fullWidth>{buttonText}
                        </Button>
                    </Container>
                </Box>
            )}
        </div>
    )
}
