import {
    Box, Button, CircularProgress,
    Grid, TextField, Typography
} from '@material-ui/core'
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos'
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos'
import React from 'react'

import rightAnswer from '../../assets/rightanswericon.png'
import wrongAnswer from '../../assets/wronganswericon.png'
import { useTeacher } from '../../utils/TeacherManager'
import { ConfettiContainer } from '../atoms/QuestionCorrectConfetti'

interface Props {
    videoLoading: boolean
    answerCorrect: boolean
    onGoNext: () => void
    onGoPrevious: () => void
    index: number
    length: number
    answer: string
    setAnswer: (answer: string) => void;
    validateAnswer: () => void;
    answerSubmitting: boolean;
}

const ParentContainerQuiz: React.FC<Props> = ({
    onGoNext,
    onGoPrevious,
    index,
    length,
    videoLoading,
    answer,
    setAnswer,
    validateAnswer,
    answerCorrect,
    answerSubmitting,
}: Props) => {
    const [isAnswered, setIsAnswered] = React.useState(false)

    const { confetti } = useTeacher()

    React.useEffect(() => {
        const timer = setTimeout(() => {
            setIsAnswered(false)
        }, 4000)

        return () => {
            clearTimeout(timer)
        }

    }, [isAnswered])
    return (
        <>
            <Box style={{ paddingTop: 10 }} justifyContent="center" display="flex" alignItems="center" mt={0.5}>
                {answerCorrect && confetti && isAnswered && (
                    <div style={{
                        position: 'relative'
                    }}>
                        <ConfettiContainer />
                    </div>
                )}
                {answerCorrect !== null && (
                    <>
                        <img
                            style={{
                                width: 30,
                                height: 30,
                                marginRight: 5
                            }}
                            src={answerCorrect ? rightAnswer : wrongAnswer}
                            alt={answerCorrect ? 'Right Icon' : 'Wrong Icon'} />

                        <Typography component="span">
                            {answerCorrect ? (
                                'Godt gået, dit svar er korrekt!'

                            ) : 'Desværre forkert svar, prøv igen'}
                        </Typography>
                    </>
                )}
            </Box>

            <Grid container justifyContent="center" alignItems="center" style={{ marginTop: 15 }}>
                <Grid item md={4} sm={12} xs={12} style={{ textAlign: 'center' }}>
                    {index !== 0 ? (
                        <Button
                            style={{
                                backgroundColor: '#0196bb',
                                color: 'white',
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                            disabled={videoLoading}
                            onClick={onGoPrevious}
                            startIcon={<ArrowBackIosIcon />}
                            variant="contained">Forrige
                        </Button>
                    )
                        : <Box />}
                </Grid>
                <Grid item md={2} sm={12} xs={12}>

                    {answerSubmitting ? <Typography>Tjekker dit svar ...</Typography> : (
                        <TextField
                            autoFocus
                            autoComplete="off"
                            style={{ width: '100%' }}
                            variant="outlined"
                            value={answer}
                            onChange={(e) => setAnswer(e.target.value)}
                            onKeyPress={(e) => {
                                if (!videoLoading && e.key === 'Enter') {
                                    setIsAnswered(true)
                                    validateAnswer()
                                }
                            }}
                            disabled={answerCorrect || answerSubmitting}
                            label="Svar..."
                            color="primary"
                            size="medium" />
                    )}
                </Grid>
                <Grid item md={4} sm={12} xs={12} style={{ textAlign: 'center' }}>
                    {index !== length ? (
                        <Button
                            style={{
                                backgroundColor: answerCorrect ? '#0196bb' : '#00000042',
                                color: answerCorrect ? 'white' : '#00000042',
                                marginTop: 10,
                                marginBottom: 10,
                            }}
                            disabled={videoLoading || answerCorrect !== true}
                            onClick={onGoNext}
                            endIcon={<ArrowForwardIosIcon />}
                            variant="contained">Næste
                        </Button>
                    ) : <Box />}
                </Grid>
            </Grid>
            <Grid item md={2} sm={12} xs={12} style={{ marginTop: 20 }}>
                <Button
                    disabled={videoLoading || answerSubmitting || answerCorrect}
                    style={{
                        backgroundColor: '#6fd995',
                        marginBottom: 10,
                        fontWeight: 600,
                        height: 40,
                        padding: 5,
                        width: '100%'
                    }}
                    onClick={() => {
                        setIsAnswered(true)
                        validateAnswer()
                    }}>
                    {answerSubmitting ? <CircularProgress size={24} color="inherit" /> : <Typography>Svar</Typography>}
                </Button>
            </Grid>
        </>
    )
}
export default ParentContainerQuiz
