import {
    Checkbox, createStyles, FormControlLabel, FormGroup, makeStyles, Typography
} from '@material-ui/core'
import * as React from 'react'

import { ClassType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { createStudent } from '../../utils/Services/student-service'
import { useTeacher } from '../../utils/TeacherManager'
import CreateScreenTemplate from '../molecules/CreateScreenTemplate'
import { Loading } from '../molecules/Loading'
import { TextInputTemplate } from '../molecules/TextInputTemplate'

interface Props {
    setCreateNew: (boolean: boolean) => void
}

const useStyles = makeStyles(() => createStyles({
    createText: {
        marginTop: 15
    },
}))

const CreateStudentScreen: React.FC<Props> = ({ setCreateNew }) => {
    const classes = useStyles()
    const { fetchClasses, fetchStudents, fetchStudentClassNames } = useTeacher()
    const { result, error, loading }:
        { result: ClassType[], error: Error, loading: boolean } = fetchClasses
    const [firstName, setFirstName] = React.useState('')
    const [secondName, setSecondName] = React.useState('')
    const [username, setUsername] = React.useState('')
    const [password, setPassword] = React.useState('')
    const [repeatPassword, setRepeatPassword] = React.useState('')
    const [checkedCheckboxes, setCheckedCheckboxes] = React.useState<boolean[]>([])
    const [createLoading, setCreateLoading] = React.useState<boolean>(false)
    React.useEffect(() => {
        if (result) setCheckedCheckboxes(new Array(result.length).fill(false))
    }, [result])

    const getChecked = () => {
        const checkedIndexes: string[] = []
        checkedCheckboxes.forEach((item, index) => {
            if (item) checkedIndexes.push(result[index].id)
        })
        return checkedIndexes
    }

    const onCreate = async () => {
        const classIds = getChecked()
        if (!validateForm()) {
            showMessage('Please fill out all the inputs')
            return
        }

        if (password.length > 5) {
            if (password === repeatPassword) {
                try {
                    setCreateLoading(true)
                    await createStudent(firstName, secondName, username, classIds, password)
                    // to update default student screen with newly created student
                    fetchStudents.execute()
                    // to update number of students on class screen
                    fetchClasses.execute()
                    // update the classes for them students
                    fetchStudentClassNames.execute()

                    checkedCheckboxes.fill(false)
                    setCreateLoading(false)
                    setCreateNew(false)
                } catch (err) {
                    setCreateLoading(false)
                    showMessage(`Error: ${err.response.data.error}`, 'error')
                }

            } else {
                showMessage('Passwords do not match')
            }

        } else {
            showMessage('Password needs to be at least 6 characters')
        }
    }

    const handleOnChange = (position: number) => {
        const updatedCheckedState = checkedCheckboxes.map((item, index) => (index === position ? !item : item))
        setCheckedCheckboxes(updatedCheckedState)
    }

    const validateForm = () => {
        return firstName.length > 1 && secondName.length > 1 && username.length > 2
            && password.length > 6 && repeatPassword.length > 6
    }
    if (error) {
        showMessage(error.message)
    }

    if (loading || createLoading || fetchStudents.loading) {
        return <Loading />
    }

    return (
        <CreateScreenTemplate
            headerText="Mine elever"
            createButtonText="Opret elev"
            setCreateNew={(boolean: boolean) => setCreateNew(boolean)}
            create={onCreate}
            deleteFunction={() => setCreateNew(false)}
            disableButtons={loading || createLoading || fetchStudents.loading}
            disableCreate={!validateForm()}>
            <>
                <TextInputTemplate
                    error={firstName.length !== 0 && firstName.length < 2}
                    required
                    value={firstName}
                    onChange={setFirstName}
                    label="Fornavn" />
                <TextInputTemplate
                    error={secondName.length !== 0 && secondName.length < 2}
                    required
                    value={secondName}
                    onChange={setSecondName}
                    label="Efternavn" />
                <TextInputTemplate
                    error={username.length !== 0 && username.length < 3}
                    required
                    value={username}
                    onChange={setUsername}
                    label="Brugernavn" />
                <TextInputTemplate
                    error={password.length !== 0 && password.length < 7}
                    required
                    value={password}
                    onChange={setPassword}
                    type="password"
                    label="Adgangskode" />
                <TextInputTemplate
                    error={repeatPassword.length !== 0
                        && (repeatPassword.length < 7 || repeatPassword !== password)}
                    required
                    value={repeatPassword}
                    onChange={setRepeatPassword}
                    type="password"
                    label="Bekræftelse af adgangskode" />
                <Typography className={classes.createText} variant="body1">Klasser:</Typography>
                {checkedCheckboxes.length > 0 ? (
                    <FormGroup row>
                        {result && result.map((item, index) => (
                            <FormControlLabel
                                control={(
                                    <Checkbox
                                        name={item.title}
                                        checked={checkedCheckboxes[index]}
                                        onClick={() => handleOnChange(index)} />
                                )}
                                label={item.title} />
                        ))}
                    </FormGroup>
                )
                    : <Typography variant="body1">Ingen klasser</Typography>}
                <Typography className={classes.createText} variant="body1">* : Obligatoriske felter</Typography>
            </>
        </CreateScreenTemplate>

    )
}
export default CreateStudentScreen
