/* eslint-disable max-len,no-nested-ternary */
import {
    Button,
    Grid,
    ThemeProvider
} from '@material-ui/core'
import {
    createTheme, makeStyles
} from '@material-ui/core/styles'
import * as React from 'react'
import { Link } from 'react-router-dom'

import QuizCardsImage from '../../assets/digitalquizbunkegeekster.png'
import EleverKlasserImage from '../../assets/elevklassergeekster.png'
import ImageGaming from '../../assets/gamingbutton.png'
import ConceptImage from '../../assets/Konceptgeekster.png'
import PDFsImage from '../../assets/kopiarkgeekster.png'
import ImageQuiz from '../../assets/quizbutton.png'
import QuizzerImage2 from '../../assets/quizvaerktøjgeekster.png'
import QuizzerImage from '../../assets/quizzergeekster.png'
import GamesImage from '../../assets/spilgeekster.png'
import ImageVideo from '../../assets/videobutton.png'
import VideosImage from '../../assets/videoergeekster.png'
import { AddOtherTeachersModal } from '../../components/atoms/AddOtherTeachersModal'
import ConfirmDialog from '../../components/atoms/ConfirmDialog'
import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { TeacherType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { QuizEdu } from '../../utils/Routes'
import { useTeacher } from '../../utils/TeacherManager'

interface Props {

}
const theme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#808080'
        },
    },
    typography: {
        button: {
            textTransform: 'none',
        }
    }
})

const useStyles = makeStyles({
    buttonWithoutHoverEffect: {
        '&:hover': {
            backgroundColor: 'transparent'
        }
    }
})

export const HomePage: React.FC<Props> = () => {
    const classes = useStyles()
    const [confirm, setConfirm] = React.useState(false)
    const [confirmDeleteStudents, setConfirmDeleteStudents] = React.useState<boolean>(JSON.parse(localStorage.getItem('confirmDeleteStudents') as string) as boolean ?? true)
    // const history = useHistory()
    const [openAddTeacherModal, setOpenAddTeacherModal] = React.useState<boolean>(false)
    const [openIamSharedWithTeachers] = React.useState<boolean>(false)
    const [email, setEmail] = React.useState<string>('')
    const [id, setId] = React.useState<string>('')
    const [deleteModeSharedTeacher, setDeleteModeSharedTeacher] = React.useState<boolean>(false)

    const {
        isTeacherLikeAParent,
        fetchClasses,
        fetchHomeworks,
        fetchClassNames,
        fetchSubscriptionData,
        addTeacherShared,
        removeTeacherShared,
        teachersSharedWithMe,
        setTeachersSharedWithMe,
        teacher,
        teachersIAmSharedWith,
        setTeachersIAmSharedWith
    } = useTeacher()

    const isLoading = () => {
        return fetchSubscriptionData.loading || fetchClasses.loading || fetchHomeworks.loading || fetchClassNames.loading
    }

    const addFunction = async () => {
        if (teachersSharedWithMe.map((t: TeacherType) => t.email).includes(email)) {
            showMessage('Error: Du har allerede tilføjet denne lærer', 'error')

            return
        }
        if (email === teacher?.email) {
            showMessage('Error: Du kan ikke vælge din egen e-mail', 'error')

            return
        }
        try {
            // const newSharedTeacher = 
            await addTeacherShared(email)
            // setTeachersSharedWithMe([...teachersSharedWithMe, newSharedTeacher])
            showMessage('Du har tilføjet en lærer', 'success')
        } catch (err) {
            showMessage('Error: Tjek venligst, om du har indtastet lærerens adresse-e-mail, som du ønsker at blive delt med korrekt', 'error')
        }

    }
    const removeFunction = async (iAmShared: boolean) => {

        try {
            if (!iAmShared) {
                await removeTeacherShared(email, 'shared')
                setTeachersSharedWithMe(
                    teachersSharedWithMe.filter((t: TeacherType) => t.email !== email)
                )
                showMessage('Du har fjernet en lærer', 'success')
            } else {
                removeTeacherShared(email, 'iamshared')
                setTeachersIAmSharedWith(teachersIAmSharedWith.filter((t: TeacherType) => t.email !== email))
            }
        } catch (error) {
            // console.log(error)
        }
    }
    const setOpenModal = () => {
        setId('')
        setEmail('')
        if (deleteModeSharedTeacher) {
            setDeleteModeSharedTeacher(!deleteModeSharedTeacher)
        }
        setOpenAddTeacherModal(!openAddTeacherModal)
    }

    if (isLoading()) {
        return <Loading />
    }

    return (
        <BodyWithTopBar>
            <ConfirmDialog
                cancelColor="#46b1e1"
                confirmColor="#46b1e1"
                textCancel="Luk"
                textConfirm="Vis ikke mere"
                open={confirmDeleteStudents}
                onConfirm={() => {
                    setConfirmDeleteStudents(false)
                    localStorage.setItem('confirmDeleteStudents', 'false')
                }}
                onCancel={() => {
                    setConfirmDeleteStudents(false)
                }}
                text={`Pga at Geekster går væk fra UNILOGIN skal du desværre oprette dine elever igen via et 
                oprettelseslink. Hvis du har spørgsmål så skriv til Jesper Albinus`} />
            <ThemeProvider theme={theme}>
                {isTeacherLikeAParent ? (
                    <>
                        <Grid
                            style={{
                                maxWidth: '85%', margin: 'auto', position: 'relative', zIndex: 5
                            }}
                            container
                            spacing={2}>
                            <Grid container item spacing={6}>
                                <Grid item xs={12} sm={6} md={6} style={{ marginBottom: 0, paddingBottom: 0 }}>
                                    <Link to="/quizbibliotek">
                                        <img
                                            alt="quizzes"
                                            src={ImageQuiz}
                                            style={{
                                                maxWidth: '100%', maxHeight: '50vh', marginTop: 5, marginBottom: 5, paddingRight: 10
                                            }} />
                                    </Link>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Link to="/videobibliotek">
                                        <img
                                            alt="videos"
                                            src={ImageVideo}
                                            style={{
                                                maxWidth: '100%', maxHeight: '50vh', marginTop: 5, marginBottom: 5, paddingRight: 10
                                            }} />
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={6}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Link to="/spil">
                                        <img
                                            alt="games"
                                            src={ImageGaming}
                                            style={{
                                                display: 'block',
                                                maxWidth: '100%',
                                                maxHeight: '50vh',
                                                marginTop: 5,
                                                marginBottom: 5,
                                                paddingRight: 10
                                            }} />
                                    </Link>

                                </Grid>
                            </Grid>
                        </Grid>
                        <BottomBar onlyPicture />
                    </>
                ) : (
                    <>
                        <Grid
                            style={{
                                maxWidth: '85%', margin: 'auto', position: 'relative', zIndex: 5
                            }}
                            container
                            spacing={2}>
                            <p style={{
                                marginBottom: 40,
                                fontSize: 20,
                                textAlign: 'center',
                                width: '100%',
                                color: 'white'
                            }}>Første gang du bruger Geekster? Klik <a style={{ color: 'white' }} href="https://player.vimeo.com/video/785552958" target="_blanc">her</a> for en hurtig introduktion
                            </p>

                            <Grid container item spacing={6}>
                                <Grid item xs={12} sm={6} md={6} style={{ marginBottom: 0, paddingBottom: 0 }}>
                                    <Link to="/#">
                                        <img
                                            alt="concept"
                                            src={ConceptImage}
                                            style={{
                                                display: 'block',
                                                maxWidth: '100%',
                                                maxHeight: '50vh',
                                                marginTop: 5,
                                                marginBottom: 5,
                                                paddingRight: 10
                                            }} />
                                    </Link>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Link to="/elever">
                                        <img
                                            alt="students"
                                            src={EleverKlasserImage}
                                            style={{
                                                display: 'block', maxWidth: '100%', maxHeight: '50vh', marginTop: 5, marginBottom: 5, paddingRight: 10
                                            }} />
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={6}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Link to="/quizbibliotek">
                                        <img
                                            alt="quizzes"
                                            src={QuizzerImage}
                                            style={{
                                                display: 'block',
                                                maxWidth: '100%',
                                                maxHeight: '50vh',
                                                marginTop: 5,
                                                marginBottom: 5,
                                                paddingRight: 10
                                            }} />
                                    </Link>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Link to="/videobibliotek">
                                        <img
                                            alt="videos"
                                            src={VideosImage}
                                            style={{
                                                display: 'block',
                                                maxWidth: '100%',
                                                maxHeight: '50vh',
                                                marginTop: 5,
                                                marginBottom: 5,
                                                paddingRight: 10
                                            }} />
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={6}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Link to="/minequizzer">
                                        <img
                                            alt="my quizzes"
                                            src={QuizzerImage2}
                                            style={{
                                                display: 'block',
                                                maxWidth: '100%',
                                                maxHeight: '50vh',
                                                marginTop: 5,
                                                marginBottom: 5,
                                                paddingRight: 10
                                            }} />
                                    </Link>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Link to="/spil">
                                        <img
                                            alt="games"
                                            src={GamesImage}
                                            style={{
                                                display: 'block',
                                                maxWidth: '100%',
                                                maxHeight: '50vh',
                                                marginTop: 5,
                                                marginBottom: 5,
                                                paddingRight: 10
                                            }} />
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={6}>
                                <Grid item xs={12} sm={6} md={6}>
                                    {/* <a href="https://quizedu.dk" target="_blank" rel="noreferrer"> */}
                                    <Button onClick={() => setConfirm(!confirm)} className={classes.buttonWithoutHoverEffect} disableRipple>
                                        <img
                                            alt="quiz cards"
                                            src={QuizCardsImage}
                                            style={{
                                                display: 'block',
                                                maxWidth: '100%',
                                                maxHeight: '50vh',
                                                marginTop: 5,
                                                marginBottom: 5,
                                                paddingRight: 10
                                            }} />
                                    </Button>
                                    {/* </a> */}
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Link to="/pdfbibliotek">
                                        <img
                                            alt="PDFs"
                                            src={PDFsImage}
                                            style={{
                                                display: 'block',
                                                maxWidth: '100%',
                                                maxHeight: '50vh',
                                                marginTop: 5,
                                                marginBottom: 5,
                                                paddingRight: 10
                                            }} />
                                    </Link>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid
                            style={{
                                maxWidth: '85%', margin: 'auto', position: 'relative', zIndex: 5
                            }}
                            container
                            spacing={2}>

                            <Grid container item spacing={6} />
                            {/* <ReactPlayer
                                        style={{ marginTop: 120, zIndex: 9, position: 'relative', }}
                                        url="https://player.vimeo.com/video/780103668"
                                        width="100%"
                                        controls /> */}
                        </Grid><BottomBar onlyPicture />
                    </>
                )}
            </ThemeProvider>
            <AddOtherTeachersModal
                id={id}
                iAmSharedWith={openIamSharedWithTeachers}
                email={email}
                setEmail={setEmail}
                addFunction={addFunction}
                removeFunction={removeFunction}
                openModal={openAddTeacherModal}
                setOpenModal={setOpenModal}
                deleteMode={deleteModeSharedTeacher} />
            <ConfirmDialog
                text="Er du en betalende bruger?"
                open={confirm}
                onCancel={() => setConfirm(false)}
                onConfirm={() => window.open(QuizEdu.path, '_blank')} />
        </BodyWithTopBar>
    )
}
