import * as React from 'react'
import ReactPlayer from 'react-player'

import { Loading } from '../../components/molecules/Loading'
import PaginatedList, { freePlan } from '../../components/molecules/PaginatedList'
import LibraryScreen from '../../components/organisms/LibraryScreen'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { PDFType, QuizLibraryType, VideoType } from '../../types/types'
import { possibleQuizCategories, possibleQuizTopics } from '../../utils/globalData'
import { sortItemsByCreatedAtString } from '../../utils/helper'
import { listPublicCommunityQuizzes } from '../../utils/Services/community-quiz-service'
import { getAllResources } from '../../utils/Services/teacher-service'
import { useTeacher } from '../../utils/TeacherManager'

interface Props {

}

export const CommunityQuizLibraryPage: React.FC<Props> = () => {

    const [
        typeOfLibrary, setTypeOfLibrary
    ] = React.useState<'video' | 'quiz' | 'communityQuiz' | 'studentQuiz' | 'pdf' | 'all'>('communityQuiz')

    const {
        teacher,
        searchValue,
        setSearchValue,
        lastLibraryVisited,
        setLastLibraryVisited,
        setSelectedCategories,
        setSelectedGrades,
        setSelectedTopics,
        setShowMyPrivate,
    } = useTeacher()

    React.useEffect(() => {
        if (lastLibraryVisited !== 'CommunityQuizzLibaryPage') {
            setLastLibraryVisited('CommunityQuizzLibaryPage')
            setSearchValue('')
            setSelectedCategories([])
            setSelectedGrades([])
            setSelectedTopics([])
            setShowMyPrivate(false)
        }
    }, [])
    const [rows, setRows] = React.useState<any[]>([])
    const [loading, setLoading] = React.useState<boolean>(true)

    const requestSearch = (searchedVal: string) => {
        setSearchValue(searchedVal)
    }

    const updateRows = (newRows: any) => {
        if (typeOfLibrary === 'all') {
            let {
                videos, studentQuizzes, communityQuizzes, pdfs, quizzes
            } = newRows
            videos = videos.map((video: VideoType) => Object.assign(video, { type: 'video' }))
            studentQuizzes = studentQuizzes.map(
                (quiz: QuizLibraryType) => Object.assign(quiz, { type: 'studentQuiz' })
            )
            communityQuizzes = communityQuizzes.map(
                (quiz: QuizLibraryType) => Object.assign(quiz, { type: 'communityQuiz' })
            )
            pdfs = pdfs.map((pdf: PDFType) => Object.assign(pdf, { type: 'pdf' }))
            quizzes = quizzes.map((quiz: QuizLibraryType) => Object.assign(quiz, { type: 'quiz' }))
            const array = [
                ...videos.filter((video: VideoType) => (teacher?.free ? freePlan.video.includes(video.id) : true)),
                ...studentQuizzes,
                ...communityQuizzes,
                ...pdfs,
                ...quizzes.filter((quiz: QuizLibraryType) => (teacher?.free ? freePlan.quiz.includes(quiz.id) : true))
            ]
            const set = new Set()
            const uniqueItemsArray = array.filter((item) => {
                if (!set.has(item.id)) {
                    set.add(item.id)
                    return true
                } else {
                    return false
                }
            })
            setRows(sortItemsByCreatedAtString(
                uniqueItemsArray
            ))
        } else {
            setRows(sortItemsByCreatedAtString(newRows))
        }
    }
    React.useEffect(() => {
        if (typeOfLibrary === 'communityQuiz') {
            (async () => {
                setLoading(true)
                const fetchedQuizzes = await listPublicCommunityQuizzes()
                setLoading(false)
                updateRows(fetchedQuizzes)
            })()
        }
    }, [typeOfLibrary])

    React.useEffect(() => {
        if (typeOfLibrary === 'all') {
            (async () => {
                setLoading(true)
                const fetchedResources = await getAllResources()
                setLoading(false)
                updateRows(fetchedResources)
            })()
        }
    }, [typeOfLibrary])

    if (loading) {
        return <Loading />
    }

    return (
        <BodyWithTopBar>
            <LibraryScreen
                items={rows}
                listOfCategories={possibleQuizCategories}
                listOfTopics={possibleQuizTopics}
                setRows={(item) => updateRows(item)}
                headerText="Vores Quizzer"
                searchFunction={requestSearch}
                searchValue={searchValue}
                typeOfLibrary={typeOfLibrary}
                setTypeOfLibrary={setTypeOfLibrary}
                originalTypeOfLibrary="communityQuiz">
                <PaginatedList data={rows} typeOfLibrary={typeOfLibrary} />
            </LibraryScreen>
            <ReactPlayer
                style={{ zIndex: 9, position: 'relative', marginTop: -40 }}
                url="https://player.vimeo.com/video/782556348"
                width="100%"
                controls />
        </BodyWithTopBar>
    )
}
