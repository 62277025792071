/* eslint-disable react/jsx-filename-extension */
import React, { useEffect, useState } from 'react'

import styles from '../styles/play.module.css'

export default function CounterScreen({ setShowIt }) {
    const [number, setNumber] = useState(3)
    useEffect(() => {
        const timer = setInterval(() => {
            if (number <= 1) {
                setShowIt(true)
                clearInterval(timer)
                return
            }
            // eslint-disable-next-line no-shadow
            setNumber((number) => {
                // console.log(number)
                return number - 1
            })
        }, 1000)
        return () => clearInterval(timer)
    }, [number])

    return (
        <div className={styles.counterCont}>
            <h1 className={styles.counterTitle}>
                Du samlede puslespillet! <br /> Her er billedet:
            </h1>

            <h1 className={styles.counter}>{number}</h1>
        </div>
    )
}
