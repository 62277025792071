import {
    Paper, TableContainer, Typography 
} from '@material-ui/core'
import React from 'react'

import { PricePlan } from '../../types/types'
import { useTableStyles } from '../../utils/styles'
import { CouponView } from '../atoms/CouponView'
import { PricesComponent } from './PricesComponent'
import { PricesComponentParent } from './PricesComponentParent'

interface Props {
    showSubscriptionOptions: boolean,
    selectedPricePlan: PricePlan | undefined,
    setSelectedPricePlan: (selectedPricePlan: PricePlan) => void,
    onCouponClick: () => void
    parentsProfile?: boolean
    gratis?: boolean
}

export const SignUpForm: React.FC<Props> = ({ 
    showSubscriptionOptions,
    selectedPricePlan,
    setSelectedPricePlan,
    onCouponClick,
    children,
    parentsProfile = false,
    gratis = false
}) => {
    const tableClasses = useTableStyles()
    
    const headline = () => {
        return (
            <div
                style={{
                    paddingTop: 20,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center'
                }}>
                <Typography variant="h5">
                    Denne side er for dig som lærer, der er tilknyttet en skole med adgang
                </Typography>
                <Typography variant="h6">
                    Opret dig nedenfor, og brug din skoles kupon til oprettelsen
                </Typography>
            </div>
        )
    }

    return (
        <form noValidate>
            <TableContainer
                style={{
                    backgroundColor: 'rgba(252,252,252,0.9)'
                }}
                className={tableClasses.table}
                component={Paper}>
                <div>
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {showSubscriptionOptions ? (
                        <div>
                            {parentsProfile 
                                ? <PricesComponentParent selected={selectedPricePlan} onChange={setSelectedPricePlan}/>
                                : (
                                    <>
                                        <PricesComponent selected={selectedPricePlan} onChange={setSelectedPricePlan} />
                                        {!gratis && <CouponView onClick={onCouponClick} />}
                                    </>
                                )}
                        </div>
                    )
                        : gratis ? null : headline()}
                    <div className={tableClasses.divInputs}>
                        {children}
                    </div>
                </div>
            </TableContainer>
        </form>
    )
}
