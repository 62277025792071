export const ParentPriceItems = [
    'Adgang til alle videoer og quizzer',
    'ingen binding',
]
export const PriceItems = [
    'Adgang til alle videoer og quizzer',
    'Lav egne quizzer og escape rooms',
    'Følg elevers svar i quizzer ”live” ',
    'Sammensæt og del opgaver helt enkelt',
    'EAN-betaling',
    'Online spil og værktøjer'
]

export const SchoolPriceItems = [
    'Få alle features fra de andre pakker',
    'Videoopgaver og quizzer med facitlister',
    'Sammensætte og dele videoer',
    'Oprette klasser',
    'Ingen binding',
    'EAN-betaling',
    'Online spil og værktøjer'
]

export const freeItems = [
    'Adgang til 12 videoer',
    'Adgang til 5 quizzer',
    'Adgang til 2 Geekster-spil og Geeksters værktøjer',

]
