/* eslint-disable consistent-return */
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    Checkbox,
    createStyles,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    makeStyles,
    Typography
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import * as React from 'react'

import { ClassType, StudentType } from '../../types/types'
import { useTeacher } from '../../utils/TeacherManager'
import { drawerWidth } from '../wrappers/BodyWithTopBar'

const useStyles = makeStyles((theme) => createStyles({
    centeredSnackbar: {
        [theme.breakpoints.up('md')]: {
            marginLeft: drawerWidth - 20
        },
    }
}),)
interface Props {
    open: boolean,
    selectedClassIds: string[],
    allClasses: ClassType[],
    defaultCheckedState: boolean[],
    setOpen: (isOpen: boolean) => void,
    confirmSelection: (value: { checkedClasses: string[]; exclusions: {id: string}[] }) => void
    selectedClass: string
    selectedId: string
}

type ClassTypeWithStudentType = ClassType & {
    students: StudentType[] | undefined
}

type subCheckBox = {
    [key: string]: (boolean|undefined)[]
}

const AddClassDialog: React.FC<Props> = ({
    open, selectedClassIds, defaultCheckedState, allClasses, setOpen, confirmSelection, selectedClass, selectedId
}) => {
    const { fetchStudents } = useTeacher()
    const [studentsRows, setStudentsRows] = React.useState<ClassTypeWithStudentType[]>([])
    const [
        defaultSubCheckedState, setDefaultSubCheckedState
    ] = React.useState<subCheckBox>(null as unknown as subCheckBox)
    React.useEffect(() => {
        (async () => {
            fetchStudents.execute()
        })()
        const classes = allClasses.map((classe) => (
            {
                ...classe,
                students: classe.students
                ?.filter((student) => !student.includes('anon-'))
                .map((student) => fetchStudents.result.find((s: any) => s.id === student))
            }))
        setStudentsRows(classes)
        setDefaultSubCheckedState(classes.reduce((acc, curr) => ({
            ...acc, [curr.id]: curr.students?.map((student) => (student === undefined ? undefined : false))
        }), {}))
        return () => {
        }
    }, [allClasses])
    const [checkedState, setCheckedState] = React.useState<boolean[]>(defaultCheckedState)
    React.useEffect(() => {
        if (selectedClass !== 'ignore') {
            const classToBeUpdated = studentsRows?.find((value) => value.id === selectedClass)
            const exclusions = classToBeUpdated?.exclusionsFromHomeworks.find((e) => e.homeworkId === selectedId)
            const subCheckedStateToBeUpdated = classToBeUpdated?.students?.filter(Boolean) // @ts-ignore
                ?.map((student) => !exclusions?.studentIds.includes(student?.id))
            if (subCheckedStateToBeUpdated) {
                setDefaultSubCheckedState({ [selectedClass]: subCheckedStateToBeUpdated })
            }
            setCheckedState([true])
        }
    }, [selectedClass, studentsRows])
    
    const classes = useStyles()

    const [rows, setRows] = React.useState<ClassTypeWithStudentType[]>([])
    React.useEffect(() => {
        // eslint-disable-next-line array-callback-return
        const newClasses = studentsRows.filter((item) => {
            if (selectedClassIds.indexOf(item.id) < 0) return item
        })
        if (selectedClass !== 'ignore') {
            setRows(studentsRows.filter((item) => item.id === selectedClass))
        } else {
            setRows(newClasses)
        }
    }, [studentsRows, selectedClass])
    
    const handleOnChange = (position: number, key: string) => {
        const updatedCheckedState = checkedState.map((item, i) => (i === position ? !item : item))
        
        setCheckedState(updatedCheckedState)

        const updatedCheckedSubState = JSON.parse(JSON.stringify(defaultSubCheckedState[key]))
            .map(() => true)
        setDefaultSubCheckedState({ ...defaultSubCheckedState, [key]: updatedCheckedSubState })
    }

    const handleOnChangeSubCheckbox = (position: string, index: number) => {
        const updatedCheckedState = JSON.parse(JSON.stringify(defaultSubCheckedState[position]))
            ?.map((subitem: any, j: number) => (j === index ? !subitem : subitem))
        setDefaultSubCheckedState({ ...defaultSubCheckedState, [position]: updatedCheckedState })
    }

    const handleSubmit = () => {
        const indexes = getChecked()
        checkedState.fill(false)
        confirmSelection(indexes)
    }

    const getChecked = () => {
        const checkedIndexes: string[] = []
        checkedState.forEach((item, index) => {
            if (item && !checkedIndexes.includes(rows[index].id)) checkedIndexes.push(rows[index].id)
        })
        const exclusions = checkedIndexes.map((id) => ({ id }))

        exclusions.forEach((checked) => {
            const foundClass = rows.find((c) => c.id === checked.id)
            const subCheckedIndexes = defaultSubCheckedState[checked.id]
            const studentIds: string[] = []
            subCheckedIndexes.forEach((subChecked, index) => {
                if (!subChecked) { // eslint-disable-next-line block-spacing
                    if (foundClass?.students) { // @ts-ignore
                        studentIds.push(foundClass?.students.filter(Boolean)[index].id)
                    }
                }
            })

            Object.assign(checked, { exclusions: studentIds })
        })
        return { checkedClasses: checkedIndexes, exclusions }
    }
    
    return (
        <Dialog
            className={classes.centeredSnackbar}
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">Tilføj klasse</DialogTitle>
            <DialogContent>
                <FormGroup>
                    
                    {rows.length === 0 && (
                        <Typography variant="body1">
                            Der findes ikke flere klasser!
                        </Typography>
                    )}
                    {checkedState && rows.map((item, i) => (
                        <> 
                            <Accordion>
                                <AccordionSummary
                                    id={item.id}
                                    expandIcon={checkedState[i] ? <ExpandMoreIcon /> : null}>
                                    <FormControlLabel
                                        control={(
                                            <Checkbox
                                                name={item.title}
                                                checked={checkedState[i]}
                                                onClick={() => handleOnChange(i, item.id)} />
                                        )}
                                        label={item.title}
                                        key={item.id} />
                                </AccordionSummary>
                                <AccordionDetails
                                    style={{ display: 'flex', flexDirection: 'column' }}>
                                    {/* eslint-disable-next-line spaced-comment, no-nested-ternary  */}
                                    {checkedState[i] ? item.students?.length // @ts-ignore */}
                                        ? (item.students?.filter(Boolean).map((student: StudentType, j) => {
                                            return (
                                                <FormControlLabel
                                                    control={(
                                                        <Checkbox // eslint-disable-next-line spaced-comment
                                                            name={student.id} //@ts-ignore 
                                                            checked={defaultSubCheckedState[item.id][j]}
                                                            disabled={!checkedState[i]}
                                                            onClick={() => handleOnChangeSubCheckbox(item.id, j)} />
                                                    )}
                                                    label={`${student.firstName} ${student.lastName}`}
                                                    key={item.id} />
                                            )
                                        }
                                       )) : <Typography>Der er ingen elevrekord på nuværende tidspunkt</Typography>
                                        : null}
                                </AccordionDetails>
                            </Accordion>
                        </>
                    ))}
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => setOpen(false)} color="primary">
                    Annuller
                </Button>
                {rows.length > 0 && (
                    <Button onClick={handleSubmit} color="primary">
                        Bekræft
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    )
}
export default AddClassDialog
