import {
    Button, Paper, TableContainer, ThemeProvider
} from '@material-ui/core'
import * as React from 'react'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { ClassCheckbox, ClassType, StudentType } from '../../types/types'
import { deleteStudent, getStudent, updateStudent } from '../../utils/Services/student-service'
import { buttonTheme, useTableStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'
import ConfirmDialog from '../atoms/ConfirmDialog'
import { Loading } from '../molecules/Loading'
import UpdateStudentComponent from './UpdateStudentComponent'

interface Props {
    selected: string,
    isSelected: (item: any) => void,
    onClassUpdate: () => void
}

const UpdateStudent: React.FC<Props> = ({ selected, isSelected, onClassUpdate }) => {
    const buttonClasses = buttonStyles()
    const tableClasses = useTableStyles()

    const { fetchStudents, fetchClasses } = useTeacher()
    const [checkedState, setCheckedState] = React.useState<ClassCheckbox[]>([])
    const [selectedStudent, setSelectedStudent] = React.useState<StudentType>()
    const [loading, setLoading] = React.useState<boolean>(false)
    const [confirmDelete, setConfirmDelete] = React.useState(false)

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            const fetchedStudent = await getStudent(selected)
            if (fetchClasses.result) {
                const defaultChecked = fetchClasses.result.map((item: ClassType) => {
                    if (fetchedStudent.schoolClasses) {
                        const check = fetchedStudent.schoolClasses
                            && fetchedStudent.schoolClasses.filter((oldItem: string) => oldItem === item.id)
                        if (check.length > 0) return { name: item.title, id: item.id, selected: true }
                        else return { name: item.title, id: item.id, selected: false }
                    } else return { name: item.title, id: item.id, selected: false }
                })
                setCheckedState(defaultChecked)
            }
            setLoading(false)
            setSelectedStudent(fetchedStudent)
        })()

    }, [])

    const handleOnChange = (position: number) => {
        const updatedCheckedState = checkedState.map((item, index) => {
            if (index === position) {
                return {
                    name: item.name,
                    id: item.id,
                    selected: !item.selected
                }
            } else {
                return {
                    name: item.name,
                    id: item.id,
                    selected: item.selected
                }
            }
        })

        setCheckedState(updatedCheckedState)
    }
    const onDelete = () => {
        setConfirmDelete(true)
    }

    const onDeleteConfirm = async () => {
        setConfirmDelete(false)
        setLoading(true)
        await deleteStudent(selected)
        // to update default student screen
        fetchStudents.execute()
        // to update number of students on class screen
        fetchClasses.execute()
        setLoading(false)
        isSelected(null)
    }

    const onUpdate = async () => {
        const checkedClasses = checkedState.filter(
            (checkedClass: ClassCheckbox) => checkedClass.selected
        ).map(
            (checkedClass) => checkedClass.id
        )
        setLoading(true)
        await updateStudent(selected, checkedClasses)
        // to update number of students on class screen
        fetchClasses.execute()
        onClassUpdate()
        isSelected(null)
        setLoading(false)
    }

    if (loading || fetchStudents.loading || fetchClasses.loading) {
        return <Loading />
    }
    
    return (
        <>
            <TableContainer className={tableClasses.table} component={Paper}>
                {(selectedStudent && (
                    <UpdateStudentComponent
                        selected={selectedStudent}
                        isSelected={isSelected}
                        headerText="Mine elever"
                        checkedState={checkedState}
                        setCheckedState={(position: number) => handleOnChange(position)} />
                ))}
            </TableContainer>
            <ConfirmDialog
                text={`Er du sikker på, at du vil slette din elev "${selectedStudent?.firstName}"?`}
                open={confirmDelete}
                onCancel={() => setConfirmDelete(false)}
                onConfirm={onDeleteConfirm} />
            <ThemeProvider theme={buttonTheme}>
                <Button
                    disabled={loading}
                    onClick={onUpdate}
                    className={buttonClasses.leftButton}
                    variant="contained"
                    color="primary">
                    Gem
                </Button>
                <Button disabled={loading} className={buttonClasses.otherButtons} variant="contained" color="default">
                    Nulstil password
                </Button>
                <Button
                    disabled={loading}
                    className={buttonClasses.otherButtons}
                    variant="contained"
                    color="secondary"
                    onClick={onDelete}>
                    Slet elev
                </Button>
            </ThemeProvider>
        </>
    )
}
export default UpdateStudent
