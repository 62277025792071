/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Button, Link, Table, TableBody, TableCell, TableHead, TableRow
} from '@material-ui/core'
import moment from 'moment'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { QuizLibraryType, VideoType } from '../../types/types'
import Routes from '../../utils/Routes'
import { useTeacher } from '../../utils/TeacherManager'
import { QuizTag } from '../atoms/QuizUpdatedTag'

interface Props {
    header: 'Videos' | 'Quizzes',
    items: (VideoType | QuizLibraryType)[],
    onItemDelete: (id: string) => void,
}

const HomeworkItemsTable: React.FC<Props> = ({
    header, items, onItemDelete
}) => {
    const { me } = useTeacher()
    const history = useHistory()

    const navigationLink = (id: string) => {
        if (id.startsWith('com')) return Routes.CommunityQuizLibrary.path.slice(1)
        if (header === 'Videos') return Routes.VideoLibrary.path.slice(1)
        else return Routes.QuizLibrary.path.slice(1)
    }
    const isOneQuizUpdated = (quiz: QuizLibraryType) => {
        return quiz.lastUpdated && moment.utc(quiz.lastUpdated).isAfter(moment.utc().subtract(10, 'days'))
    }
    return (
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    <TableCell>{header}</TableCell>
                    <TableCell align="right" />
                </TableRow>
            </TableHead>
            <TableBody>
                {items.map((item: VideoType | QuizLibraryType) => (
                    <TableRow key={item.id}>
                        <TableCell key={item.id}>
                            <b>
                                <Link
                                    color="inherit"
                                    style={{ cursor: 'pointer' }}
                                    onClick={
                                        () => history.push(`/${navigationLink(item.id)}`, { search: item.title })
                                    }>
                                    {item.title}
                                </Link>
                            </b>
                            {header === 'Quizzes' && isOneQuizUpdated(item as QuizLibraryType) && <QuizTag />}
                        </TableCell>
                        <TableCell align="right">
                            <Button
                                size="small"
                                color="secondary"
                                onClick={() => onItemDelete(item.id)}
                                disabled={!!me}>
                                Fjern
                            </Button>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}
export default HomeworkItemsTable
