import { Grid } from '@material-ui/core'
import * as React from 'react'

import { PriceItems } from '../../utils/priceContent'
import { PricesTemplate } from '../molecules/PricesTemplates'

interface Props {
    showSmall?: boolean
}

export const SchoolPlansComponents: React.FC<Props> = ({ showSmall }) => {

    return (
        <>
            <Grid
                container
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexDirection: 'row',
                    paddingRight: '1vw',
                    paddingLeft: '1vw',
                }}>
                <Grid sm={12} lg={4} item key="1year">
                    <PricesTemplate
                        priceSentence="At betale: 4800kr kr. for 1 år"
                        buttonText="Kontakt Geekster"
                        color="#72D67C"
                        boxShadowColor=""
                        content={PriceItems}
                        price="kr. 4800"
                        monthContent="/år ex. moms"
                        title="Skoleadgang 1 år"
                        showSmall={showSmall}
                        redirect />
                </Grid>
                <Grid item sm={12} lg={4} key="2years">
                    <PricesTemplate
                        priceSentence="At betale: 4000kr kr. for 2 år"
                        buttonText="Kontakt Geekster"
                        color="#72D67C"
                        boxShadowColor=""
                        content={PriceItems}
                        price="kr. 4000"
                        monthContent="/år ex. moms"
                        title="Skoleadgang 2 år"
                        showSmall={showSmall}
                        redirect />
                </Grid>
            </Grid>
            <Grid
                container
                style={{
                    display: 'flex',
                    justifyContent: 'space-around',
                    flexDirection: 'row',
                    paddingRight: '1vw',
                    paddingLeft: '1vw',
                }}>
                <Grid item sm={12} lg={4} key="3years">
                    <PricesTemplate
                        priceSentence="At betale: 3.500kr kr. for 3 år"
                        buttonText="Kontakt Geekster"
                        color="#72D67C"
                        boxShadowColor=""
                        content={PriceItems}
                        price="kr. 3.500"
                        monthContent="/år ex. moms"
                        title="Skoleadgang 3 år"
                        redirect
                        showSmall={showSmall} />
                </Grid>
                <Grid item sm={12} lg={4} key="unk">
                    <PricesTemplate
                        priceSentence="At betale: 3000 kr. for 5 år"
                        buttonText="Kontakt Geekster"
                        color="#72D67C"
                        boxShadowColor=""
                        content={PriceItems}
                        price="kr. 3000"
                        monthContent="/år ex. moms"
                        title="Skoleadgang 5 år"
                        redirect
                        showSmall={showSmall} />
                </Grid>
            </Grid>
        </>
    )
}
