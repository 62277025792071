import { Auth } from 'aws-amplify'
import axios from 'axios'

import config from '../../config'
import { UploadedFile } from '../../types/types'
import { showMessage } from '../notifications'

const endpoint = config.API.endpoints.find((e) => e.name === 'api')
axios.defaults.baseURL = `${endpoint!!.endpoint}/`

export const uploadFile = async (file: File): Promise<UploadedFile | undefined> => {
    const formData = new FormData()
    formData.append('file', file)

    const jwt = (await Auth.currentSession()).getIdToken().getJwtToken()
    const res = await axios.post('/files', formData, {
        headers: {
            Authorization: `Bearer ${jwt}`,
            'Content-Type': 'multipart/form-data'
        }
    })

    if (res.status === 200) {
        return res.data as UploadedFile
    } else {
        showMessage('Failed to upload file.')
        return undefined
    }
}
