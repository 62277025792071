import { API } from 'aws-amplify'

import {
    ClassType, HomeworkResultType, HomeworkType, QuizLibraryType, StudentType, VideoType
} from '../../types/types'

export const listHomeworks = async (): Promise<HomeworkType[]> => {
    const homeworks = await API.get('api', '/homeworks', {})
    return homeworks
}

export const getHomework = async (id: string): Promise<HomeworkType> => {
    const classItem = await API.get('api', `/homeworks/${id}`, {})
    return classItem
}

type Exceptions = {
    id : string;
    exclusions: string[]
}

export const createHomework = async (
    name: string,
    classIds: string[],
    quizIds: string[],
    videoIds: string[],
    expirationDate: string,
    domain: string,
    publicHomeworkForStudents: boolean,
    exceptions: Exceptions[]
) => {
    return API.post('api', '/homeworks', {
        body: {
            name,
            classIds,
            quizzes: quizIds,
            videos: videoIds,
            expirationDate,
            url: domain,
            publicHomeworkForStudents,
            exceptions
        }
    })
}

export const updateHomeworkClasses = async (id: string, classIds: string[], exceptions: any[]) => {
    return API.put('api', `/homeworks/${id}`, {
        body: { classIds, exceptions }
    })
}

export const updateHomeworkDate = async (id: string, expirationDate: string) => {
    return API.put('api', `/homeworks/${id}`, {
        body: { expirationDate }
    })
}

export const updateHomeworkPrivacy = async (id: string, publicHomeworkForStudents: boolean) => {
    return API.put('api', `/homeworks/${id}`, {
        body: { publicHomeworkForStudents }
    })
}

export const updateHomeworkVideos = async (id: string, videos: string[]) => {
    return API.put('api', `/homeworks/${id}`, {
        body: { videos }
    })
}
export const updateHomeworkQuizzes = async (id: string, quizzes: string[]) => {
    return API.put('api', `/homeworks/${id}`, {
        body: { quizzes }
    })
}

export const deleteHomework = async (id: string) => {
    return API.del('api', `/homeworks/${id}`, {})
}

export const getFirstFewHomeworks = async (): Promise<HomeworkType[]> => {
    const homeworks = await API.get('api', '/homeworks/examples', {})
    return homeworks
}

export const getHomeWorkClasses = async (homeworkId: string): Promise<ClassType[]> => {
    const classes = await API.get('api', `/homeworks/classes/${homeworkId}`, {})
    return classes
}

export const getHomeworkClassNames = async (homeworkId: string): Promise<{ title: string, id: string }[]> => {
    const classnames = await API.get('api', `/homeworks/classes/names/${homeworkId}`, {})
    return classnames
}
export const getHomeWorkVideos = async (homeworkId: string): Promise<VideoType[]> => {
    const videos = await API.get('api', `/homeworks/videos/${homeworkId}`, {})
    return videos
}

export const getHomeWorkQuizzes = async (homeworkId: string): Promise<QuizLibraryType[]> => {
    const quizzes = await API.get('api', `/homeworks/quizzes/${homeworkId}`, {})
    return quizzes
}

export const getHomeworkResults = async (homeworkId: string): Promise<HomeworkResultType[]> => {
    return API.get('api', `/homeworks/${homeworkId}/results`, {})
}
export const getHomeworkStudents = async (homeworkId: string): Promise<StudentType[][]> => {
    return API.get('api', `/homeworks/${homeworkId}/students`, {})
}
