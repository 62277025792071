import * as React from 'react'
import { useEffect, useState } from 'react'
import { Redirect } from 'react-router-dom'

import { Loading } from '../../components/molecules/Loading'
import { useUniLoginReply } from '../../utils'
import { showMessage } from '../../utils/notifications'
import { restoreCognitoSession } from '../../utils/Services/cognito-service'
import { finalizeLogInTeacherWithUniLogin } from '../../utils/Services/teacher-service'
import { fireWrap } from '../../utils/wrap-functions'

export const CompleteLogInWithUniLogin: React.FC = () => {
    const [wasError, setWasError] = useState(false)

    const uniLoginReply = useUniLoginReply()
    if (!uniLoginReply) {
        showMessage('Whoops! There was an error.', 'error')
        return <Redirect to="/"/>
    }

    useEffect(() => {
        fireWrap(async () => {
            const creds = await finalizeLogInTeacherWithUniLogin({
                uniLoginUsername: uniLoginReply.user,
                uniLoginAuth: uniLoginReply.auth,
                uniLoginTimestamp: uniLoginReply.timestamp,
                group: 'teacher',
            })

            restoreCognitoSession(creds)
            localStorage.setItem('logged_in_via_uni_login', 'true')
        }, {
            operation: 'Finalizing Sign-In',
            errorStateFunc: setWasError
        })
    }, [])

    if (wasError) {
        return <Redirect to="/"/>
    }

    return <Loading/>
}
