import {
    Paper, TableContainer
} from '@material-ui/core'
import { Auth } from 'aws-amplify'
import * as React from 'react'
import { Redirect, useHistory, useLocation } from 'react-router-dom'

import FormButtons from '../../components/atoms/FormButtons'
import GoBackHeader from '../../components/atoms/GoBackHeader'
import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import { Logo } from '../../components/molecules/Logo'
import { TextInputTemplate } from '../../components/molecules/TextInputTemplate'
import { Body } from '../../components/wrappers/Body'
import Routes from '../../utils/Routes'
import { useTableStyles } from '../../utils/styles'
import { wrap } from '../../utils/wrap-functions'

export const ConfirmForgotPassword: React.FC = () => {
    const tableClasses = useTableStyles()
    const history = useHistory()

    const [code, setCode] = React.useState('')
    const [busy, setBusy] = React.useState(false)
    const [password, setPassword] = React.useState('')
    const [confirmPassword, setConfirmPassword] = React.useState('')
    const [done, setDone] = React.useState(false)

    const { state }: any = useLocation()

    const performConfirm = async () => {
        await wrap(async () => {
            await Auth.forgotPasswordSubmit(state.email, code, password)
        }, {
            operation: 'Confirming Password',
            showSuccess: true,
            stateFunc: setBusy
        })

        setDone(true)
    }

    if (done) {
        return <Redirect to={Routes.Home.path} />
    }

    if (busy) {
        return <Loading />
    }

    return (
        <>
            <div style={{
                display: 'flex', justifyContent: 'center', flexDirection: 'column', alignItems: 'center'
            }}>
                <Logo />
            </div>
            <Body>
                <form noValidate>
                    <>
                        <TableContainer
                            style={{ backgroundColor: 'rgba(252,252,252,0.6)' }}
                            className={tableClasses.table}
                            component={Paper}>
                            <GoBackHeader buttonText="Back to login" onClick={() => history.push('/login')} />
                            <div style={{
                                display: 'flex', flexDirection: 'column', paddingRight: 30, paddingLeft: 30
                            }}>
                                <TextInputTemplate
                                    value={password}
                                    onChange={setPassword}
                                    label="Password"
                                    type="password" />
                                <TextInputTemplate
                                    value={confirmPassword}
                                    onChange={setConfirmPassword}
                                    label="Confirm Password"
                                    type="password" />
                                <TextInputTemplate
                                    value={code}
                                    onChange={setCode}
                                    label="Code" />
                            </div>
                        </TableContainer>
                        <FormButtons
                            createButtonText="Indsend"
                            disableCreate={busy || password !== confirmPassword || code.length < 4}
                            onClickCreate={performConfirm} />
                    </>
                </form>
                <BottomBar onlyPicture />
            </Body>
        </>
    )
}
