import {
    Collapse, createStyles, Grid, Link, makeStyles, Typography
} from '@material-ui/core'
import { purple } from '@material-ui/core/colors'
import * as React from 'react'
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router-dom'

import {
    HomeworkType, QuizLibraryType
} from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { deleteQuizAnswerResult, getQuizAnswerResult } from '../../utils/Services/answer-services'
import { updateHomeworkQuizzes } from '../../utils/Services/homework-service'
import { updateQuizHomeworks } from '../../utils/Services/quiz-service'
import { useLibraryStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'
import { wrap } from '../../utils/wrap-functions'
import LibraryItemTag from '../atoms/LibraryItemTag'
import LibraryTextProperties from '../atoms/LibraryTextProperties'
import SelectWithButton from '../atoms/SelectWithButton'

interface Props {
    item: QuizLibraryType,
    setItemAdded: (successful: boolean) => void,
    isCommunity: boolean
    isStudents: boolean
}

const useStyles = makeStyles(() => createStyles({
    generalTags: {
        marginTop: 10,
    },
}))

export const usePurpleSwitch = () => {
    return {
        root: {
            padding: 7
        },
        thumb: {
            width: 24,
            height: 24,
            backgroundColor: '#fff',
            boxShadow:
                '0 0 12px 0 rgba(0,0,0,0.08), 0 0 8px 0 rgba(0,0,0,0.12), 0 0 4px 0 rgba(0,0,0,0.38)'
        },
        switchBase: {
            color: 'rgba(0,0,0,0.38)',
            padding: 7
        },
        track: {
            borderRadius: 20,
            backgroundColor: purple[300]
        },
        checked: {
            '& $thumb': {
                backgroundColor: '#fff'
            },
            '& + $track': {
                opacity: '1 !important'
            }
        },
        focusVisible: {}
    }
}

const QuizListItem: React.FC<Props> = ({
    item, setItemAdded, isCommunity, isStudents
}) => {
    const { isTeacherLikeAParent, fetchHomeworks } = useTeacher()
    const libraryClasses = useLibraryStyles()
    const classes = useStyles()
    const history = useHistory()
    const { result, error, loading }:
        { result: HomeworkType[], error: Error, loading: boolean } = fetchHomeworks
    const [open, setOpen] = React.useState(false)
    const [homeworks, setHomeWorks] = React.useState<HomeworkType[]>([])
    const [busy, setBusy] = React.useState(false)
    const [stats, setStats] = React.useState<string>('')
    const [hasStats, setHasStats] = React.useState<boolean | null>(null)
    const [hasPreviousAnswers, setHasPreviousAnswers] = React.useState<boolean | null>(null)

    React.useEffect(() => {
        if (result) {
            const filteredHomeworks = result.filter((homework) => homework.videos.length <= 0)
            setHomeWorks(filteredHomeworks)
        }
    }, [result])

    React.useEffect(() => {
        if (open) {
            (async () => {
                const results = await getQuizAnswerResult(item.id)
                if (results) {
                    const correctItems = results
                        .answers?.map(
                            (answer) => (answer.correct ? 1 : 0)).reduce((acc: number, curr: number) => acc + curr, 0)
                    const totalItems = item.questions?.length
                    setHasStats(true)
                    setStats(`Du har klaret ${correctItems} ud af ${totalItems} opgaver`)
                    setHasPreviousAnswers(!!results.answers.length)
                } else {
                    setHasPreviousAnswers(false)
                    setHasStats(false)
                    setStats('Du har endnu ikke gennemført denne quiz')
                }
            })()
        } else {
            //
        }
    }, [open])

    // Need to change this function
    const addToLesson = async (homework: HomeworkType | undefined) => {
        if (homework) {
            await wrap(async () => {
                if (isCommunity) {
                    await updateHomeworkQuizzes(homework.id, [item.id])
                } else {
                    await updateQuizHomeworks(item.id, [homework.id])
                }

                setItemAdded(true)
            }, {
                operation: 'Adding to Lesson',
                stateFunc: setBusy
            })

        } else {
            history.push('/ny-lektier', { quizId: item.id, name: item.title })
        }
    }

    const getFirstImage = () => {
        let firstImage = item.questions?.find((question) => question.picture
            && question.picture?.uri.length > 0)?.picture?.uri
        if (!firstImage) {
            firstImage = item.questions?.find((question) => question.thumbnailUri
                && question?.thumbnailUri.length > 0)?.thumbnailUri
        }
        return firstImage
    }

    const getFirstVimeoVideo = () => {
        return item.questions?.find((question) => question.vimeoVideoId
            && question.vimeoVideoId?.length > 0)?.vimeoVideoId
    }
    const getFirstYoutubeVideo = () => {
        return item.questions?.find((question) => question.youtubeVideoId
            && question.youtubeVideoId?.length > 0)?.youtubeVideoId
    }

    const handleSeeQuizClick = () => {
        if (isCommunity) history.push('/quiz/community', { id: item.id })
        else if (isStudents) history.push('/quiz/student', { id: item.id })
        else history.push('/quiz', { id: item.id })
    }
    const handleDeleteAndStartNewQuizSession = () => {
        const hadleDelete = async () => {
            await deleteQuizAnswerResult(item.id)
        }
        const promise = hadleDelete()

        promise.then(async () => ((isCommunity) ? history.push(
            '/quiz/community', { id: item.id }
        ) : history.push(
            '/quiz', { id: item.id }
        ))).catch((e) => console.error(e))

    }
    const handleStartNewQuizSession = () => ((isCommunity) ? history.push(
        '/quiz/community', { id: item.id }
    ) : history.push(
        '/quiz', { id: item.id }
    ))

    if (error) {
        showMessage(error.message)
    }

    return (
        <div className={libraryClasses.container}>
            <Grid
                className={open ? libraryClasses.secondGrid : libraryClasses.firstGrid}
                container
                onClick={() => setOpen(!open)}>
                {getFirstImage() && (
                    <Grid
                        xs={12}
                        sm={4}
                        style={{ display: 'flex', justifyContent: 'flex-start' }}>
                        <img
                            style={{
                                maxWidth: '100%', maxHeight: '25vh', marginTop: 5, marginBottom: 5, paddingRight: 10
                            }}
                            src={item.questions
                                && getFirstImage()}
                            alt="Quiz" />
                    </Grid>
                )}
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <LibraryTextProperties headerText="Navn" smallerText={item.title} />

                    {item?.categories?.map((itemTag) => (
                        <LibraryItemTag key={itemTag} itemTag={itemTag} />
                    ))}
                </Grid>
                <Grid item xs={12} sm={4} className={classes.generalTags}>

                    <LibraryTextProperties
                        headerText="Klassetrin"
                        smallerText={item.grades?.join(', ') || 'Ingen'} />
                </Grid>
                {isStudents && (
                    <Grid
                        item
                        xs={12}
                        sm={4}
                        className={classes.generalTags}
                        style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
                        <b style={{ textTransform: 'capitalize' }}>{item.name}</b>
                        <small>{item.username}</small>
                    </Grid>
                )}
            </Grid>
            <Grid container>
                <Collapse
                    className={libraryClasses.collapse}
                    in={open}
                    timeout="auto"
                    unmountOnExit>
                    <Typography style={{ marginBottom: 20 }} variant="h4">{item.title}</Typography>
                    <Typography style={{ marginBottom: 20 }} variant="body1">{item.description}</Typography>
                    {getFirstImage() && item.questions && !getFirstVimeoVideo() && !getFirstYoutubeVideo() && (
                        <div className={libraryClasses.videoContainer}>
                            <img
                                style={{
                                    maxWidth: 400, maxHeight: 400, marginTop: 5, marginBottom: 5, paddingRight: 10
                                }}
                                src={item.questions
                                    && getFirstImage()}
                                alt="Quiz" />
                        </div>
                    )}
                    {(isTeacherLikeAParent && hasStats === true) && (
                        <Grid
                            justifyContent="center"
                            alignItems="center"
                            item
                            xs={12}>
                            <Typography align="center">{stats}</Typography>
                        </Grid>
                    )}

                    {(getFirstVimeoVideo() || getFirstYoutubeVideo()) && item.questions && (
                        <div className={libraryClasses.videoContainer}>
                            <ReactPlayer
                                url={
                                    getFirstVimeoVideo()
                                        ? `https://player.vimeo.com/video/${getFirstVimeoVideo()}`
                                        : `https://www.youtube.com/watch?v=${getFirstYoutubeVideo()}`
                                }
                                width="100%"
                                controls />
                        </div>
                    )}{(isTeacherLikeAParent) ? (
                        <Grid container alignItems="center">
                            {hasPreviousAnswers === true && (

                                <Grid item xs={12} alignItems="center">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <Link
                                        onClick={handleSeeQuizClick}
                                        style={{ cursor: 'pointer' }}>
                                        <Typography
                                            style={{ fontSize: 22 }}
                                            align="center">
                                            FORSÆT QUIZ
                                        </Typography>
                                    </Link>
                                </Grid>
                            )}

                            {hasPreviousAnswers === true ? (
                                <Grid item xs={12} alignItems="center">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <Link
                                        align="center"
                                        onClick={handleDeleteAndStartNewQuizSession}
                                        style={{ cursor: 'pointer' }}>
                                        <Typography style={{ fontSize: 22, color: '#AC9362' }} align="center">
                                            (Klik her for at starte forfra)
                                        </Typography>
                                    </Link>
                                </Grid>
                            ) : hasPreviousAnswers === false && (
                                <Grid item xs={12} alignItems="center">
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <Link
                                        align="center"
                                        onClick={handleStartNewQuizSession}
                                        style={{ cursor: 'pointer' }}>
                                        <Typography
                                            style={{ fontSize: 22 }}
                                            align="center">
                                            START QUIZ
                                        </Typography>
                                    </Link>
                                </Grid>
                            )}
                            {hasStats === false && (
                                <Grid
                                    justifyContent="center"
                                    alignItems="center"
                                    item
                                    xs={12}>
                                    <Typography
                                        align="center">{stats}
                                    </Typography>
                                </Grid>
                            )}
                        </Grid>
                    )
                        : (
                            <Grid container>
                                <Grid item xs={12} sm={2} className={libraryClasses.container}>
                                    {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                    <Link
                                        onClick={handleSeeQuizClick}
                                        style={{ cursor: 'pointer' }}>
                                        Klik her for at se quizzen
                                    </Link>
                                    {item?.file?.uri && (
                                        <Link
                                            key={item?.file?.uri}
                                            target="_blank"
                                            href={item?.file?.uri}
                                            rel="noreferrer"> Download tilhørende PDF?
                                        </Link>
                                    )}
                                    {item.supplementUri?.map((l) => (
                                        <Link
                                            key={l}
                                            target="_blank"
                                            href={l}
                                            rel="noreferrer"> Link til Mattips opgaver om emnet
                                        </Link>
                                    ))}
                                </Grid>
                                {/* <Grid item xs={12} sm={2}>
                                    <Typography variant="body2">Skal elever logge ind?</Typography>
                                    <Switch
                                        checked={studentNeedsToLogin}
                                        style={{ flex: 1 }}
                                        value={studentNeedsToLogin}
                                        onChange={
                                            (event) => setStudentNeedsToLogin(event.target.checked)
                                        }
                                        color="primary"
                                        inputProps={{ 'aria-label': 'secondary checkbox' }} />
                                </Grid> */}
                                <Grid item xs={12} sm={10} className={libraryClasses.container}>
                                    <SelectWithButton
                                        loading={busy || loading}
                                        homeworks={homeworks}
                                        onSubmit={addToLesson} />
                                </Grid>
                            </Grid>
                        )}
                </Collapse>
            </Grid>
        </div>
    )
}
export default QuizListItem
