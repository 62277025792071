/* eslint-disable react/jsx-filename-extension */
import React from 'react'

import styles from '../styles/puzzleGlary.module.css'

export default function PuzzleImage({ imagePath }) {
    if (imagePath) {
        return <img src={imagePath} alt="puzzle" className={styles.puzzleImage} />
    } else {
        return (
            <div className={styles.noImage}>
                <h2>Intet billede</h2>
            </div>
        )
    }
    // return <div className={styles.imageLoading} />
}
