import { Typography } from '@material-ui/core'
import * as React from 'react'

import LogoImage from '../../assets/LogoGeekster.png'

export const Logo: React.FC = () => {
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: 30,
            alignItems: 'center'
        }}>
            <img style={{ width: 150, height: 150 }} src={LogoImage} alt="Geekster Logo"/>
            <Typography variant="h3">Geekster.dk</Typography>
        </div>
    )
}
