import {
    FormControlLabel,
    InputLabel,
    Paper, Switch, TableContainer, Typography
} from '@material-ui/core'
import { Auth, Hub } from 'aws-amplify'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import ConfirmDialog from '../../components/atoms/ConfirmDialog'
import FormButtons from '../../components/atoms/FormButtons'
import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import { TextInputTemplate } from '../../components/molecules/TextInputTemplate'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { showMessage } from '../../utils/notifications'
import { deleteMyself, updateTeacher } from '../../utils/Services/teacher-service'
import { useTableStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'

interface Props {

}

export const Profile: React.FC<Props> = () => {
    const tableClasses = useTableStyles()
    const history = useHistory()
    const {
        teacher, setTeacher, me, confetti, setConfetti, hasAudio, setHasAudio
    } = useTeacher()

    const [email, setEmail] = React.useState<string>('')
    const [schoolName, setSchoolName] = React.useState<string>('')
    const [firstName, setFirstName] = React.useState<string>('')
    const [lastName, setLastName] = React.useState<string>('')
    const [oldPassword, setOldPassword] = React.useState<string>('')
    const [repeatOldPassword, setRepeatOldPassword] = React.useState<string>('')
    const [newPassword, setNewPassword] = React.useState<string>('')

    const [confirmDelete, setConfirmDelete] = React.useState<boolean>(false)
    const [loading, setLoading] = React.useState(false)

    React.useEffect(() => {
        if (teacher) {
            setEmail(teacher.email)
            setSchoolName(teacher.school)
            setFirstName(teacher.firstName)
            setLastName(teacher.lastName)
        }
    }, [teacher])

    const onDelete = async () => {
        setConfirmDelete(true)
    }

    const onDeleteConfirm = async () => {
        if (teacher) {
            await deleteMyself()
            await Auth.signOut()
            history.push('/login')
            Hub.dispatch('auth', { event: 'signOut' }) // todo: this should be removed!
        }
    }

    const onUpdate = async () => {
        if (teacher && firstName && lastName && schoolName) {
            setLoading(true)
            setTeacher(await updateTeacher(teacher?.id, firstName, lastName, schoolName, confetti, hasAudio))
            setLoading(false)
        }
        if (oldPassword.length > 0 && newPassword.length > 0 && repeatOldPassword.length > 0) {
            if (oldPassword === repeatOldPassword) {
                try {
                    const currentUser = await Auth.currentAuthenticatedUser()
                    await Auth.changePassword(
                        currentUser,
                        oldPassword,
                        newPassword
                    )
                    window.location.reload()
                } catch (err) { showMessage(`User can not be updated: ${err}`) }
            } else showMessage('Passwords do not match!')
        }
    }

    if (loading) {
        return <Loading />
    }

    return (
        <BodyWithTopBar>
            <form noValidate>
                <TableContainer className={tableClasses.table} component={Paper}>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>
                        Min profil
                    </Typography>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <TextInputTemplate disabled value={email} label="Email" />
                        <TextInputTemplate
                            disabled={!!me}
                            value={schoolName}
                            onChange={setSchoolName}
                            label="Skole" />
                        <TextInputTemplate
                            disabled={!!me}
                            value={firstName}
                            onChange={setFirstName}
                            label="Fornavn" />
                        <TextInputTemplate
                            disabled={!!me}
                            value={lastName}
                            onChange={setLastName}
                            label="Efternavn" />
                        <TextInputTemplate
                            disabled={!!me}
                            value={oldPassword}
                            type="password"
                            onChange={setOldPassword}
                            label="Nuværende Password" />
                        <TextInputTemplate
                            disabled={!!me}
                            value={repeatOldPassword}
                            type="password"
                            onChange={setRepeatOldPassword}
                            label="Gentag nuværende password" />
                        <TextInputTemplate
                            disabled={!!me}
                            value={newPassword}
                            type="password"
                            onChange={setNewPassword}
                            label="Ny password" />
                        <InputLabel>Vis konfetti ved besvarelse af quizzer</InputLabel>
                        <FormControlLabel
                            control={(
                                <Switch
                                    checked={confetti}
                                    onChange={() => {
                                        setConfetti(!confetti)
                                    }}
                                    color="primary" />
                            )}
                            label={confetti ? 'Tændt' : 'Slukket'} />
                        <InputLabel>Afspil lyd-effekter når du svarer rigtig/forkert på quizzer</InputLabel>
                        <FormControlLabel
                            control={(
                                <Switch
                                    checked={hasAudio}
                                    onChange={() => {
                                        setHasAudio(!hasAudio)
                                    }}
                                    color="primary" />
                            )}
                            label={hasAudio ? 'Tændt' : 'Slukket'} />
                    </div>
                </TableContainer>
                <ConfirmDialog
                    text="Er du sikker på, at du vil slette din profil?"
                    open={confirmDelete}
                    onCancel={() => setConfirmDelete(false)}
                    onConfirm={onDeleteConfirm} />
                <FormButtons
                    createButtonText="Opdater profil"
                    onClickCreate={onUpdate}
                    onClickDelete={onDelete}
                    deleteButtonText="Slet profil"
                    disableButtons={loading || !!me} />
            </form>
            <BottomBar onlyPicture />
        </BodyWithTopBar>
    )
}
