/* eslint-disable no-plusplus */
/* eslint-disable no-shadow */
/* eslint-disable no-param-reassign */
/* eslint-disable camelcase */
const puzzlePieces = [
    {
        id: 'index-0',
        created: false,
        left: {
            id: 'l-0',
            equation: '9',
            connect_id: 376300831,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-0',
            equation: '1+3',
            connect_id: 1,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-0',
            equation: '45/7',
            connect_id: 685234944,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-0',
            equation: '1+2',
            connect_id: 2,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-1',
        created: false,
        left: {
            id: 'l-1',
            equation: '4',
            connect_id: 1,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-1',
            equation: '10+10',
            connect_id: 3,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-1',
            equation: '1',
            connect_id: 837471497,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-1',
            equation: '10+11',
            connect_id: 4,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-2',
        created: false,
        left: {
            id: 'l-2',
            equation: '20',
            connect_id: 3,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-2',
            equation: '505',
            connect_id: 568256424,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-2',
            equation: '2',
            connect_id: 146107955,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-2',
            equation: '5+7',
            connect_id: 5,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-3',
        created: false,
        left: {
            id: 'l-3',
            equation: '78',
            connect_id: 817538785,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-3',
            equation: '9+9',
            connect_id: 6,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-3',
            equation: '3',
            connect_id: 2,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-3',
            equation: '50+50',
            connect_id: 7,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-4',
        created: false,
        left: {
            id: 'l-4',
            equation: '18',
            connect_id: 6,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-4',
            equation: '100+20',
            connect_id: 8,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-4',
            equation: '21',
            connect_id: 4,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-4',
            equation: '80+50',
            connect_id: 9,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-5',
        created: false,
        left: {
            id: 'l-5',
            equation: '120',
            connect_id: 8,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-5',
            equation: '524',
            connect_id: 18680843,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-5',
            equation: '12',
            connect_id: 5,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-5',
            equation: '6+10',
            connect_id: 10,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-6',
        created: false,
        left: {
            id: 'l-6',
            equation: '123',
            connect_id: 670144378,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-6',
            equation: '75+52',
            connect_id: 11,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-6',
            equation: '100',
            connect_id: 7,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-6',
            equation: '456',
            connect_id: 991606989,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-7',
        created: false,
        left: {
            id: 'l-7',
            equation: '172',
            connect_id: 11,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-7',
            equation: '89+89',
            connect_id: 12,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-7',
            equation: '130',
            connect_id: 9,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-7',
            equation: '789',
            connect_id: 591767759,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-8',
        created: false,
        left: {
            id: 'l-8',
            equation: '178',
            connect_id: 12,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-8',
            equation: '986',
            connect_id: 852957689,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-8',
            equation: '16',
            connect_id: 10,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-8',
            equation: '1010',
            connect_id: 582855683,
            type: 'b',
            isCreated: true,
        },
    },
]

const test4x4 = [
    {
        id: 'index-0',
        created: false,
        left: {
            id: 'l-0',
            equation: '45',
            connect_id: 260913491,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-0',
            equation: '1+2',
            connect_id: 1,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-0',
            equation: '30',
            connect_id: 352389430,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-0',
            equation: '1+5',
            connect_id: 2,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-1',
        created: false,
        left: {
            id: 'l-1',
            equation: '3',
            connect_id: 1,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-1',
            equation: '1+3',
            connect_id: 3,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-1',
            equation: '31',
            connect_id: 553972075,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-1',
            equation: '1+4',
            connect_id: 4,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-2',
        created: false,
        left: {
            id: 'l-2',
            equation: '4',
            connect_id: 3,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-2',
            equation: '1+7',
            connect_id: 13,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-2',
            equation: '32',
            connect_id: 975234292,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-2',
            equation: '1+6',
            connect_id: 5,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-3',
        created: false,
        left: {
            id: 'l-3',
            equation: '8',
            connect_id: 13,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-3',
            equation: '34',
            connect_id: 55354771,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-3',
            equation: '33',
            connect_id: 941642883,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-3',
            equation: '1+8',
            connect_id: 7,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-4',
        created: false,
        left: {
            id: 'l-4',
            equation: '44',
            connect_id: 985545875,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-4',
            equation: '1+9',
            connect_id: 14,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-4',
            equation: '6',
            connect_id: 2,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-4',
            equation: '12+1',
            connect_id: 15,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-5',
        created: false,
        left: {
            id: 'l-5',
            equation: '10',
            connect_id: 14,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-5',
            equation: '10+1',
            connect_id: 16,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-5',
            equation: '5',
            connect_id: 4,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-5',
            equation: '13+1',
            connect_id: 17,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-6',
        created: false,
        left: {
            id: 'l-6',
            equation: '11',
            connect_id: 16,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-6',
            equation: '11+1',
            connect_id: 18,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-6',
            equation: '7',
            connect_id: 5,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-6',
            equation: '14+1',
            connect_id: 19,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-7',
        created: false,
        left: {
            id: 'l-7',
            equation: '12',
            connect_id: 18,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-7',
            equation: '35',
            connect_id: 204788592,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-7',
            equation: '9',
            connect_id: 7,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-7',
            equation: '15+1',
            connect_id: 20,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-8',
        created: false,
        left: {
            id: 'l-8',
            equation: '43',
            connect_id: 435743410,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-8',
            equation: '16+1',
            connect_id: 21,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-8',
            equation: '13',
            connect_id: 15,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-8',
            equation: '19+1',
            connect_id: 22,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-9',
        created: false,
        left: {
            id: 'l-9',
            equation: '17',
            connect_id: 21,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-9',
            equation: '17+1',
            connect_id: 23,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-9',
            equation: '14',
            connect_id: 17,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-9',
            equation: '20+1',
            connect_id: 24,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-10',
        created: false,
        left: {
            id: 'l-10',
            equation: '18',
            connect_id: 23,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-10',
            equation: '18+1',
            connect_id: 25,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-10',
            equation: '15',
            connect_id: 19,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-10',
            equation: '21+1',
            connect_id: 26,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-11',
        created: false,
        left: {
            id: 'l-11',
            equation: '19',
            connect_id: 25,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-11',
            equation: '36',
            connect_id: 17614528,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-11',
            equation: '16',
            connect_id: 20,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-11',
            equation: '22+1',
            connect_id: 27,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-12',
        created: false,
        left: {
            id: 'l-12',
            equation: '42',
            connect_id: 127722775,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-12',
            equation: '23+1',
            connect_id: 28,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-12',
            equation: '20',
            connect_id: 22,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-12',
            equation: '41',
            connect_id: 280901836,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-13',
        created: false,
        left: {
            id: 'l-13',
            equation: '24',
            connect_id: 28,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-13',
            equation: '24+1',
            connect_id: 29,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-13',
            equation: '21',
            connect_id: 24,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-13',
            equation: '40',
            connect_id: 886014643,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-14',
        created: false,
        left: {
            id: 'l-14',
            equation: '25',
            connect_id: 29,
            type: 'l',
            isCreated: true,
        },
        right: {
            id: 'r-14',
            equation: '25+1',
            connect_id: 30,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-14',
            equation: '22',
            connect_id: 26,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-14',
            equation: '39',
            connect_id: 646222949,
            type: 'b',
            isCreated: true,
        },
    },
    {
        id: 'index-15',
        created: false,
        left: {
            id: 'l-15',
            equation: '26',
            connect_id: 30,
            type: 'l',
            isCreated: false,
        },
        right: {
            id: 'r-15',
            equation: '37',
            connect_id: 689827796,
            type: 'r',
            isCreated: true,
        },
        top: {
            id: 't-15',
            equation: '23',
            connect_id: 27,
            type: 't',
            isCreated: true,
        },
        bottom: {
            id: 'b-15',
            equation: '38',
            connect_id: 340520175,
            type: 'b',
            isCreated: true,
        },
    },
]

function createConnectionIdForMicro(puzzleNumber) {
    let left_id = null
    let top_id = null
    let right_id = null
    let bottom_id = null
    if (puzzleNumber === 0) {
        left_id = Math.round(Math.random() * 1000000000)
        top_id = Math.round(Math.random() * 1000000000)
        right_id = 1
        bottom_id = 2
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 1) {
        left_id = 1
        top_id = Math.round(Math.random() * 1000000000)
        right_id = 3
        bottom_id = 4
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 2) {
        left_id = 3
        top_id = Math.round(Math.random() * 1000000000)
        right_id = Math.round(Math.random() * 1000000000)
        bottom_id = 5
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 3) {
        left_id = Math.round(Math.random() * 1000000000)
        top_id = 2
        right_id = 6
        bottom_id = 7
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 4) {
        left_id = 6
        top_id = 4
        right_id = 8
        bottom_id = 9
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 5) {
        left_id = 8
        top_id = 5
        right_id = Math.round(Math.random() * 1000000000)
        bottom_id = 10
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 6) {
        left_id = Math.round(Math.random() * 1000000000)
        top_id = 7
        right_id = 11
        bottom_id = Math.round(Math.random() * 1000000000)
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 7) {
        left_id = 11
        top_id = 9
        right_id = 12
        bottom_id = Math.round(Math.random() * 1000000000)
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 8) {
        left_id = 12
        top_id = 10
        right_id = Math.round(Math.random() * 1000000000)
        bottom_id = Math.round(Math.random() * 1000000000)
        return {
            left_id, top_id, right_id, bottom_id 
        }
    }

    return {
        left_id, top_id, right_id, bottom_id 
    }
}

function createConnectionIdForMini(puzzleNumber) {
    let left_id = null
    let top_id = null
    let right_id = null
    let bottom_id = null
    if (puzzleNumber === 0) {
        left_id = Math.round(Math.random() * 1000000000)
        top_id = Math.round(Math.random() * 1000000000)
        right_id = 1
        bottom_id = 2
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 1) {
        left_id = 1
        top_id = Math.round(Math.random() * 1000000000)
        right_id = 3
        bottom_id = 4
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 2) {
        left_id = 3
        top_id = Math.round(Math.random() * 1000000000)
        right_id = 13
        bottom_id = 5
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 3) {
        left_id = 13
        right_id = Math.round(Math.random() * 1000000000)
        top_id = Math.round(Math.random() * 1000000000)
        bottom_id = 7
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 4) {
        left_id = Math.round(Math.random() * 1000000000)
        top_id = 2
        right_id = 14
        bottom_id = 15
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 5) {
        left_id = 14
        top_id = 4
        right_id = 16
        bottom_id = 17
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 6) {
        left_id = 16
        top_id = 5
        right_id = 18
        bottom_id = 19
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 7) {
        left_id = 18
        top_id = 7
        right_id = Math.round(Math.random() * 1000000000)
        bottom_id = 20
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 8) {
        left_id = Math.round(Math.random() * 1000000000)
        top_id = 15
        right_id = 21
        bottom_id = 22
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 9) {
        left_id = 21
        top_id = 17
        right_id = 23
        bottom_id = 24
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 10) {
        left_id = 23
        top_id = 19
        right_id = 25
        bottom_id = 26
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 11) {
        left_id = 25
        top_id = 20
        right_id = Math.round(Math.random() * 1000000000)
        bottom_id = 27
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 12) {
        left_id = Math.round(Math.random() * 1000000000)
        top_id = 22
        right_id = 28
        bottom_id = Math.round(Math.random() * 1000000000)
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 13) {
        left_id = 28
        top_id = 24
        right_id = 29
        bottom_id = Math.round(Math.random() * 1000000000)
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 14) {
        left_id = 29
        top_id = 26
        right_id = 30
        bottom_id = Math.round(Math.random() * 1000000000)
        return {
            left_id, top_id, right_id, bottom_id 
        }
    } else if (puzzleNumber === 15) {
        left_id = 30
        top_id = 27
        right_id = Math.round(Math.random() * 1000000000)
        bottom_id = Math.round(Math.random() * 1000000000)
        return {
            left_id, top_id, right_id, bottom_id 
        }
    }

    return {
        left_id, top_id, right_id, bottom_id 
    }
}

function findSideByConnectId(connection_id, puzzleData) {
    const sides = []
    puzzleData.every((puzzle) => {
        if (puzzle.left.connect_id === connection_id) {
            sides.push(puzzle.left)
        } else if (puzzle.right.connect_id === connection_id) {
            sides.push(puzzle.right)
        } else if (puzzle.top.connect_id === connection_id) {
            sides.push(puzzle.top)
        } else if (puzzle.bottom.connect_id === connection_id) {
            sides.push(puzzle.bottom)
        }
        return true
    })

    if (sides.length > 0) {
        return sides
    }
    return null
}

function setEquation(connection_id, first, second, puzzleData) {
    puzzleData.every((puzzle) => {
        if (puzzle.left.connect_id === connection_id) {
            puzzle.left.equation = first
            puzzle.left.isCreated = true
        } else if (puzzle.right.connect_id === connection_id) {
            puzzle.right.equation = second
            puzzle.right.isCreated = true
        } else if (puzzle.top.connect_id === connection_id) {
            puzzle.top.equation = first
            puzzle.top.isCreated = true
        } else if (puzzle.bottom.connect_id === connection_id) {
            puzzle.bottom.equation = second
            puzzle.bottom.isCreated = true
        }
        return true
    })

    return puzzleData
}

function setSingleEquation(connection_id, equation, puzzleData) {
    puzzleData.every((puzzle) => {
        if (puzzle.left.connect_id === connection_id) {
            puzzle.left.equation = equation
            puzzle.left.isCreated = true
        } else if (puzzle.right.connect_id === connection_id) {
            puzzle.right.equation = equation
            puzzle.right.isCreated = true
        } else if (puzzle.top.connect_id === connection_id) {
            puzzle.top.equation = equation
            puzzle.top.isCreated = true
        } else if (puzzle.bottom.connect_id === connection_id) {
            puzzle.bottom.equation = equation
            puzzle.bottom.isCreated = true
        }
        return true
    })

    return puzzleData
}

function generateNewPuzzle(size) {
    const puzzlePieces = []
    if (size === '3x3') {
        for (let i = 0; i < 9; i++) {
            const {
                left_id, top_id, right_id, bottom_id 
            } = createConnectionIdForMicro(i)
            puzzlePieces.push({
                id: `index-${i}`,
                created: false,
                left: {
                    id: `l-${i}`,
                    equation: '',
                    connect_id: left_id,
                    type: 'l',
                    isCreated: false,
                },
                right: {
                    id: `r-${i}`,
                    equation: '',
                    connect_id: right_id,
                    type: 'r',
                    isCreated: false,
                },
                top: {
                    id: `t-${i}`,
                    equation: '',
                    connect_id: top_id,
                    type: 't',
                    isCreated: false,
                },
                bottom: {
                    id: `b-${i}`,
                    equation: '',
                    connect_id: bottom_id,
                    type: 'b',
                    isCreated: false,
                },
            })
        }
    } else if (size === '4x4') {
        for (let i = 0; i < 16; i++) {
            const {
                left_id, top_id, right_id, bottom_id 
            } = createConnectionIdForMini(i)
            puzzlePieces.push({
                id: `index-${i}`,
                created: false,
                left: {
                    id: `l-${i}`,
                    equation: '',
                    connect_id: left_id,
                    type: 'l',
                    isCreated: false,
                },
                right: {
                    id: `r-${i}`,
                    equation: '',
                    connect_id: right_id,
                    type: 'r',
                    isCreated: false,
                },
                top: {
                    id: `t-${i}`,
                    equation: '',
                    connect_id: top_id,
                    type: 't',
                    isCreated: false,
                },
                bottom: {
                    id: `b-${i}`,
                    equation: '',
                    connect_id: bottom_id,
                    type: 'b',
                    isCreated: false,
                },
            })
        }
    }

    return puzzlePieces
}

export {
    puzzlePieces,
    setEquation,
    findSideByConnectId,
    generateNewPuzzle,
    test4x4,
    setSingleEquation,
}
