import {
    Button,
    Collapse, createStyles, Grid, Link, makeStyles, Typography
} from '@material-ui/core'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'
import LinkIcon from '@material-ui/icons/Link'
import * as React from 'react'
import { Document, Page, pdfjs } from 'react-pdf'

import { PDFType } from '../../types/types'
import { downloadPDFBase64 } from '../../utils/Services/pdf-service'
import { useLibraryStyles } from '../../utils/styles'
import LibraryTextProperties from '../atoms/LibraryTextProperties'

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`

interface Props {
    item: PDFType
}

const useStyles = makeStyles(() => createStyles({
    generalTags: {
        marginBottom: 10,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
}))

const PDFListItem: React.FC<Props> = ({ item }) => {
    const libraryClasses = useLibraryStyles()
    const classes = useStyles()

    const [open, setOpen] = React.useState<boolean>(false)
    const [base64, setBase64] = React.useState('')
    const [fileName, setFileName] = React.useState('')

    React.useEffect(() => {
        if (!base64 || !fileName) return;
        (() => {
            const downloadLink = document.createElement('a')
            downloadLink.href = base64
            downloadLink.download = fileName
            downloadLink.click()
            setFileName('')
        })()
    }, [base64, fileName])

    const copy = async () => {
        await navigator.clipboard.writeText(encodeURI(`laerer.geekster.dk/del-pdf/${item.id}`))
    }

    return (
        <div className={libraryClasses.container}>
            <Grid
                className={open ? libraryClasses.secondGrid : libraryClasses.firstGrid}
                onClick={() => setOpen(!open)}
                container>
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <Document
                        file={item.mainFile?.uri}>
                        <Page pageIndex={0} height={150} />
                    </Document>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <LibraryTextProperties headerText="Titel" smallerText={item.title} />
                    <LibraryTextProperties headerText="Kategori" smallerText={item.categories.join(', ')} />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <LibraryTextProperties headerText="Beskrivelse" smallerText={item.description} />
                    <LibraryTextProperties
                        headerText="Klassetrin"
                        smallerText={item.grades?.join(', ') || 'Ingen'} />
                </Grid>
            </Grid>
            <Grid container>
                <Collapse
                    className={libraryClasses.collapse}
                    in={open}
                    timeout="auto"
                    unmountOnExit>
                    <Grid container>
                        <Grid
                            item
                            xs={12}
                            sm={12}
                            className={libraryClasses.container}>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <Typography variant="h5">Opgave-fil</Typography>
                                <div>
                                    <Button
                                        variant="outlined"
                                        color="primary"
                                        onClick={copy}
                                        startIcon={<LinkIcon />}>
                                        <Typography
                                            variant="h5"
                                            style={{ textTransform: 'none' }}
                                            color="textPrimary">Kopier pdf-link
                                        </Typography>
                                    </Button>
                                </div>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center' }}>

                                <embed
                                    width="700px"
                                    height="800px"
                                    src={`${item.mainFile?.uri}#toolbar=0`}
                                    type="application/pdf" />
                            </div>

                            <Typography variant="h5" style={{ marginRight: 'auto', marginLeft: 'auto' }}>
                                <Button
                                    variant="outlined"
                                    color="primary"
                                    onClick={async () => {
                                        const pdf = await downloadPDFBase64(item.id)
                                        setBase64(pdf)
                                        setFileName(item.mainFile?.name)
                                    }}
                                    startIcon={<CloudDownloadIcon />}>
                                    <Typography
                                        variant="h5"
                                        style={{ textTransform: 'none' }}
                                        color="textPrimary">Download {item.mainFile?.name}
                                    </Typography>
                                </Button>
                            </Typography>
                            {item.supplementUri?.map((l) => (
                                <Link
                                    key={l}
                                    target="_blank"
                                    href={l}
                                    rel="noreferrer"> Link til Mattips opgaver om emnet
                                </Link>
                            ))}
                        </Grid>
                    </Grid>
                </Collapse>
            </Grid>
        </div>
    )
}
export default PDFListItem
