import { Paper, TableContainer, Typography } from '@material-ui/core'
import parse from 'html-react-parser'
import * as React from 'react'
import ReactPlayer from 'react-player'

import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { getPresentation } from '../../utils/Services/presentation-service'
import { useTableStyles } from '../../utils/styles'

interface Props { }

export const About: React.FC<Props> = () => {
    const tableClasses = useTableStyles()
    const [presentation, setPresentation] = React.useState<string>('')
    const [loading, setLoading] = React.useState<boolean>(false)

    React.useEffect(() => {
        (async () => {
            setLoading(true)
            const result = await getPresentation()
            setLoading(false)
            const { content } = result
            setPresentation(content)
        })()
    }, [])
    if (loading) {
        return <Loading />
    }

    return (
        <BodyWithTopBar>
            <TableContainer className={tableClasses.table} component={Paper}>
                <Typography variant="h3" style={{ textAlign: 'center' }}>Om geekster</Typography>
                <div style={{
                    display: 'flex', justifyContent: 'center', margin: 10, marginBottom: 15
                }}>
                    <ReactPlayer
                        url="https://player.vimeo.com/video/490185084"
                        width="100%"
                        controls />
                </div>
                <Typography
                    variant="h5"
                    style={{
                        textAlign: 'center', marginLeft: 30, marginRight: 30, color: 'black'
                    }}>
                    {parse(presentation)}
                </Typography>
            </TableContainer>
            <BottomBar onlyPicture />
        </BodyWithTopBar>
    )
}
