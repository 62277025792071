/* eslint-disable no-nested-ternary */
import { Grid } from '@material-ui/core'
import * as React from 'react'
import { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'

import { PricePlan } from '../../types/types'
import { freeItems, PriceItems } from '../../utils/priceContent'
import { useTeacher } from '../../utils/TeacherManager'
import { PricesTemplate } from '../molecules/PricesTemplates'

interface Props {
    selected?: PricePlan
    onChange?(plan: PricePlan): void
    showSmall?: boolean
}

export const PricesComponent: React.FC<Props> = ({ selected, onChange, showSmall }) => {

    const { upgrade } = useTeacher()

    const [selectedPricePlan, setSelectedPricePlan] = useState(selected)

    const history = useHistory()

    const togglePricePlan = (plan: PricePlan) => {
        if (onChange) {
            onChange(plan)
        }

        setSelectedPricePlan(plan)
    }

    useEffect(() => {
        setSelectedPricePlan(selected)
    }, [selected])

    return (
        <Grid
            container
            style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                paddingRight: '1vw',
                paddingLeft: '1vw',
            }}>
            {!upgrade && (
                <Grid sm={12} lg={showSmall ? 4 : 3} item key="free">
                    <PricesTemplate
                        priceSentence="At betale: 0 kr"
                        buttonText={selectedPricePlan === 'free' ? 'Valgt' : 'Vælg denne'}
                        color="#e91e63"
                        boxShadowColor={selectedPricePlan === 'free' ? '#e91e63' : ''}
                        content={freeItems}
                        price="kr. 0"
                        monthContent="/måned"
                        title="GRATIS læreradgang"
                        showSmall={showSmall}
                        onClick={() => togglePricePlan('free')}
                        free />
                </Grid>
            )}
            <Grid sm={12} lg={showSmall ? upgrade ? 6 : 4 : 3} item key="1month">
                <PricesTemplate
                    priceSentence="At betale: 48 kr. for 1 måned"
                    buttonText={selectedPricePlan === '1month' ? 'Valgt' : 'Vælg denne'}
                    color="#F85C1E"
                    boxShadowColor={selectedPricePlan === '1month' ? '#F85C1E' : ''}
                    content={PriceItems}
                    price="kr. 48"
                    monthContent="/måned"
                    title="Læreradgang 1 måned"
                    showSmall={showSmall}
                    onClick={() => togglePricePlan('1month')} />
            </Grid>
            <Grid item sm={12} lg={showSmall ? upgrade ? 6 : 4 : 3} key="1year">
                <PricesTemplate
                    priceSentence="At betale: 240 kr. for 1 år"
                    buttonText={selectedPricePlan === '1year' ? 'Valgt' : 'Vælg denne'}
                    color="#3C8CAC"
                    boxShadowColor={selectedPricePlan === '1year' ? '#3C8CAC' : ''}
                    monthContent="/år"
                    content={PriceItems}
                    price="kr.240"
                    title="Læreradgang 1 år"
                    showSmall={showSmall}
                    onClick={() => togglePricePlan('1year')} />

            </Grid>
            {!showSmall && (
                <Grid item sm={12} lg={3} key="unk">
                    <PricesTemplate
                        priceSentence="At betale: 1200 kr. for 1 år"
                        buttonText="Se Alle Skolepriser ->"
                        color="#72D67C"
                        boxShadowColor=""
                        content={PriceItems}
                        price="kr. 1200"
                        monthContent="/år ex. moms"
                        title="Skoleadgang 1 år"
                        onClick={() => history.push('/skolepriser')}
                        showSmall={showSmall} />
                </Grid>
            )}
        </Grid>
    )
}
