import {
    Checkbox, createStyles, FormControlLabel, FormGroup, Grid, makeStyles, Typography
} from '@material-ui/core'
import * as React from 'react'

import { ClassCheckbox, StudentType } from '../../types/types'
import GoBackHeader from '../atoms/GoBackHeader'

interface Props {
    headerText: string,
    selected: StudentType,
    isSelected: (item: any) => void,
    checkedState: ClassCheckbox[],
    setCheckedState: (pos: number) => void
}

const useStyles = makeStyles(() => createStyles({
    text: {
        textAlign: 'center'
    }
}))

const UpdateStudentComponent: React.FC<Props> = ({
    headerText, selected, isSelected, checkedState, setCheckedState
}) => {
    const classes = useStyles()

    return (
        <>
            <GoBackHeader headerText={headerText} onClick={() => isSelected(null)} />
            <Grid container>
                <Grid container style={{ marginBottom: 20 }}>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" className={classes.text}><b>Fornavn</b></Typography>
                        <Typography variant="h6" className={classes.text}>{selected.firstName}</Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" className={classes.text}><b>Navn</b></Typography>
                        <Typography variant="h6" className={classes.text}>
                            {`${selected.firstName} ${selected.lastName}`}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={4}>
                        <Typography variant="h6" className={classes.text}><b>Brugernavn</b></Typography>
                        <Typography variant="h6" className={classes.text}>{selected.username}</Typography>
                    </Grid>
                </Grid>

                <Grid container>
                    <Grid
                        style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}
                        item
                        xs={12}
                        sm={4}>
                        <Typography variant="h6" className={classes.text}><b>Klasser</b></Typography>
                        <FormGroup>
                            {checkedState.map((item: ClassCheckbox, index: number) => (
                                <FormControlLabel
                                    control={(
                                        <Checkbox
                                            checked={checkedState[index].selected}
                                            onClick={() => setCheckedState(index)}
                                            name={item.name} />
                                    )}
                                    label={item.name}
                                    key={item.id} />
                            ))}
                        </FormGroup>
                    </Grid>
                </Grid>
            </Grid>
        </>
    )
}
export default UpdateStudentComponent
