/* eslint-disable max-len,no-nested-ternary */
import {
    Button,
    Card,
    CardContent,
    createStyles,
    Grid,
    Hidden,
    makeStyles,
    Theme,
    ThemeProvider
} from '@material-ui/core'
import { createTheme } from '@material-ui/core/styles'
import * as React from 'react'
import ReactPlayer from 'react-player'
import { Link, useHistory } from 'react-router-dom'

import ImageGaming from '../../assets/gamingbutton.png'
import ImageQuiz from '../../assets/quizbutton.png'
import ImageVideo from '../../assets/videobutton.png'
import { AddOtherTeachersModal } from '../../components/atoms/AddOtherTeachersModal'
import BasicCardContent from '../../components/atoms/BasicCardContent'
import { BottomBar } from '../../components/molecules/BottomBar'
import ClassListItem from '../../components/molecules/ClassListItem'
import { Loading } from '../../components/molecules/Loading'
import Simplecard from '../../components/molecules/SimpleCard'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { ClassType, HomeworkType, TeacherType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { useTeacher } from '../../utils/TeacherManager'

interface Props {

}
const theme = createTheme({
    palette: {
        primary: {
            main: '#000000'
        },
        secondary: {
            main: '#808080'
        },
    },
    typography: {
        button: {
            textTransform: 'none',
        }
    }
})

// eslint-disable-next-line no-shadow
const useStyles = makeStyles((theme: Theme) => createStyles({
    card: {
        borderRadius: 25,
        elevation: 3,
        // minHeight: '30vh',
    },
    nestedCard: {
        display: 'flex',
        flexDirection: 'row',
        // minHeight: '30vh',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
        },
    },
    secondaryCard: {
        backgroundColor: '#FAFAFA',
        borderRadius: 25,
        marginTop: -16,
        marginBottom: -24,
        marginRight: -16,
        marginLeft: 'auto',
        minWidth: '57%',
    },
    cardContent: {
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        '&:last-child': {
            paddingBottom: 0
        }
    },
}))

export const OversightPage: React.FC<Props> = () => {
    const classes = useStyles()
    const history = useHistory()
    const [openAddTeacherModal, setOpenAddTeacherModal] = React.useState<boolean>(false)
    const [openIamSharedWithTeachers, setOpenIamSharedWithTeachers] = React.useState<boolean>(false)
    const [email, setEmail] = React.useState<string>('')
    const [id, setId] = React.useState<string>('')
    const [deleteModeSharedTeacher, setDeleteModeSharedTeacher] = React.useState<boolean>(false)
    const [seeMore, setSeeMore] = React.useState<boolean | null>(null)

    const {
        isTeacherLikeAParent,
        fetchClasses,
        fetchHomeworks,
        fetchClassNames,
        fetchSubscriptionData,
        addTeacherShared,
        removeTeacherShared,
        teachersSharedWithMe,
        setTeachersSharedWithMe,
        teacher,
        teachersIAmSharedWith,
        setTeachersIAmSharedWith
    } = useTeacher()

    const isLoading = () => {
        return fetchSubscriptionData.loading || fetchClasses.loading || fetchHomeworks.loading || fetchClassNames.loading
    }

    const addFunction = async () => {
        if (teachersSharedWithMe.map((t: TeacherType) => t.email).includes(email)) {
            showMessage('Error: Du har allerede tilføjet denne lærer', 'error')

            return
        }
        if (email === teacher?.email) {
            showMessage('Error: Du kan ikke vælge din egen e-mail', 'error')

            return
        }
        try {
            // const newSharedTeacher = 
            await addTeacherShared(email)
            // setTeachersSharedWithMe([...teachersSharedWithMe, newSharedTeacher])
            showMessage('Du har tilføjet en lærer', 'success')
        } catch (err) {
            showMessage('Error: Tjek venligst, om du har indtastet lærerens adresse-e-mail, som du ønsker at blive delt med korrekt', 'error')
        }

    }
    const removeFunction = async (iAmShared: boolean) => {

        try {
            if (!iAmShared) {
                await removeTeacherShared(email, 'shared')
                setTeachersSharedWithMe(
                    teachersSharedWithMe.filter((t: TeacherType) => t.email !== email)
                )
                showMessage('Du har fjernet en lærer', 'success')
            } else {
                removeTeacherShared(email, 'iamshared')
                setTeachersIAmSharedWith(teachersIAmSharedWith.filter((t: TeacherType) => t.email !== email))
            }
        } catch (error) {
            // console.log(error)
        }
    }
    const setOpenModal = () => {
        setId('')
        setEmail('')
        if (deleteModeSharedTeacher) {
            setDeleteModeSharedTeacher(!deleteModeSharedTeacher)
        }
        setOpenAddTeacherModal(!openAddTeacherModal)
    }
    const containerRef: any = React.useRef(null)
    const [seeMoreComponent, setSeeMoreComponent] = React.useState(true)

    React.useEffect(() => {
        const checkButtonAvailability = () => {
            if (seeMore === null) {
                if (containerRef.current) {
                    if (containerRef.current.clientHeight <= 155) {
                        setSeeMoreComponent(false)
                    }
                    setSeeMore(false)
                }
            }
        }
        const timer = setTimeout(() => {
            checkButtonAvailability()
        }, 2500)
        return () => clearTimeout(timer)
    }, [containerRef.current?.clientHeight])

    if (isLoading()) {
        return <Loading />
    }

    return (
        <BodyWithTopBar>
            <ThemeProvider theme={theme}>
                {isTeacherLikeAParent ? (
                    <>
                        <Grid
                            style={{
                                maxWidth: '85%', margin: 'auto', position: 'relative', zIndex: 5
                            }}
                            container
                            spacing={2}>
                            <Grid container item spacing={6}>
                                <Grid item xs={12} sm={6} md={6} style={{ marginBottom: 0, paddingBottom: 0 }}>
                                    <Link to="/quizbibliotek">
                                        <img
                                            alt="quizzes"
                                            src={ImageQuiz}
                                            style={{
                                                maxWidth: '100%', maxHeight: '50vh', marginTop: 5, marginBottom: 5, paddingRight: 10
                                            }} />
                                    </Link>
                                </Grid>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Link to="/videobibliotek">
                                        <img
                                            alt="videos"
                                            src={ImageVideo}
                                            style={{
                                                maxWidth: '100%', maxHeight: '50vh', marginTop: 5, marginBottom: 5, paddingRight: 10
                                            }} />
                                    </Link>
                                </Grid>
                            </Grid>
                            <Grid container item spacing={6}>
                                <Grid item xs={12} sm={6} md={6}>
                                    <Link to="/spil">
                                        <img
                                            alt="games"
                                            src={ImageGaming}
                                            style={{
                                                maxWidth: '100%', maxHeight: '50vh', marginTop: 5, marginBottom: 5, paddingRight: 10
                                            }} />
                                    </Link>

                                </Grid>
                            </Grid>
                        </Grid>
                        <BottomBar onlyPicture />
                    </>
                ) : (
                    <>

                        <Grid
                            style={{
                                maxWidth: '85%', margin: 'auto', position: 'relative', zIndex: 5
                            }}
                            container
                            spacing={2}>

                            <p style={{
                                fontSize: 20,
                                textAlign: 'center',
                                width: '100%',
                                color: 'white'
                            }}>Første gang du bruger Geekster? Klik <a style={{ color: 'white' }} href="https://player.vimeo.com/video/785552958" target="_blanc">her</a> for en hurtig introduktion
                            </p>
                            <Grid container spacing={6} item>
                                <Grid item xs={12} lg={6}>
                                    <Card className={classes.card}>
                                        <CardContent className={classes.nestedCard}>
                                            <BasicCardContent
                                                headerText="Klasser"
                                                bottomText="klasser >"
                                                bottomTextBold={`${(fetchClasses.result && fetchClasses.result.length) || 0}`}
                                                iconName="SchoolIcon"
                                                bottomTextLink="klasser" />
                                            <Hidden smDown>
                                                <Grid item direction="column" className={classes.secondaryCard}>
                                                    <CardContent>
                                                        {fetchClasses.result
                                                            && fetchClasses.result.map((classItem: ClassType) => (
                                                                <ClassListItem
                                                                    key={classItem.id}
                                                                    boldText={classItem.title}
                                                                    onClick={() => history.push('klasser',
                                                                        { class: classItem.id })} />
                                                            ))}
                                                    </CardContent>
                                                </Grid>
                                            </Hidden>
                                        </CardContent>
                                    </Card>
                                </Grid>
                                <Grid
                                    item
                                    xs={12}
                                    lg={6}
                                    style={{
                                        position: 'relative',
                                        overflow: 'hidden',
                                        width: '100%'
                                    }}>

                                    <Card className={classes.card}>
                                        {seeMoreComponent && (
                                            <Hidden smDown>
                                                {seeMore ? null : (
                                                    <div style={{
                                                        borderRadius: 25,
                                                        position: 'absolute',
                                                        width: 'calc(100% - 48px)',
                                                        height: 'calc(100% - 48px)',
                                                        zIndex: 8,
                                                        background: 'transparent',
                                                        backgroundImage: 'linear-gradient(0deg, rgba(255,255,255, 1) 0%, rgba(255,255,255, 0.95) 10%, rgba(255,255,255, 0.85) 20%,rgba(255,255,255, 0.6) 30%, rgba(0,0,0,0) 100%)'
                                                    }} />
                                                )}
                                            </Hidden>
                                        )}
                                        <CardContent style={{ justifyContent: 'center' }} className={classes.nestedCard}>
                                            <BasicCardContent
                                                headerText="Lektier"
                                                bottomText="lektie >"
                                                bottomTextBold={`${(fetchHomeworks.result && fetchHomeworks.result.length) || 0}`}
                                                iconName="AssignmentLateIcon"
                                                bottomTextLink="lektier" />
                                            <Hidden smDown>
                                                <Grid ref={containerRef} item md={6} className={classes.secondaryCard}>
                                                    <CardContent className={classes.cardContent}>
                                                        <Grid
                                                            item
                                                            direction="column"
                                                            style={{
                                                                position: 'relative',
                                                                maxHeight: seeMore ? 'fit-content' : 155,
                                                            }}>
                                                            {fetchHomeworks.result
                                                                // eslint-disable-next-line max-len
                                                                && fetchHomeworks.result.map((assignmentItem: HomeworkType, index: number) => {
                                                                    const classItemNames = fetchClassNames.result[index].map((cn: { title: string, id: number }) => cn.title)
                                                                    return (index < 1 ? (
                                                                        <ClassListItem
                                                                            boldText={assignmentItem.name}
                                                                            key={assignmentItem.id}
                                                                            smallText={classItemNames.join(',')}
                                                                            onClick={() => history.push(`lektier/${assignmentItem.id}`)} />
                                                                    )
                                                                        : (
                                                                            <Hidden key={assignmentItem.id}>
                                                                                <ClassListItem
                                                                                    boldText={assignmentItem.name}
                                                                                    smallText={classItemNames.join(',')}
                                                                                    onClick={() => history.push(`lektier/${assignmentItem.id}`)} />
                                                                            </Hidden>
                                                                        ))
                                                                })}

                                                        </Grid>
                                                        {(seeMore || !seeMoreComponent) && (
                                                            <Button
                                                                style={{
                                                                    margin: 6,
                                                                    marginLeft: 'auto',
                                                                    marginRight: 'auto',
                                                                    padding: '0 8px',
                                                                    width: 'fit-content'
                                                                }}
                                                                onClick={() => history.push('lektier', { id: undefined })}
                                                                color="secondary">
                                                                Gå til lektier {'>'}
                                                            </Button>
                                                        )}
                                                    </CardContent>
                                                </Grid>
                                            </Hidden>
                                            {seeMoreComponent && (
                                                <Hidden smDown>
                                                    <Button
                                                        onClick={() => setSeeMore(!seeMore)}
                                                        style={{
                                                            color: '#00b3fc',
                                                            marginLeft: 'auto',
                                                            marginRight: 'auto',
                                                            padding: '0 8px',
                                                            position: 'absolute',
                                                            bottom: 20,
                                                            zIndex: 9,
                                                            fontSize: 20,
                                                            fontWeight: 1000
                                                        }}>{seeMore === true ? 'Se mindre' : seeMore === false ? 'Se mere' : null}
                                                    </Button>
                                                </Hidden>
                                            )}
                                        </CardContent>
                                    </Card>

                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <Card className={classes.card}>
                                        <CardContent className={classes.nestedCard}>
                                            <BasicCardContent
                                                headerText="Lærere, der har adgang til din konto"
                                                bottomTextBold={`${(fetchHomeworks.result && fetchHomeworks.result.length) || ''}`}
                                                iconName="Announcement" />
                                            <Grid item direction="column" className={classes.secondaryCard}>
                                                <CardContent>
                                                    {teachersIAmSharedWith?.length
                                                        ? teachersIAmSharedWith.map((classItem: TeacherType) => (
                                                            <ClassListItem
                                                                key={classItem.id}
                                                                boldText={classItem.email}
                                                                onClick={() => {
                                                                    setOpenIamSharedWithTeachers(true)
                                                                    setId(classItem.id)
                                                                    setEmail(classItem.email)
                                                                    setDeleteModeSharedTeacher(true)
                                                                    setOpenAddTeacherModal(true)
                                                                }} />
                                                        ))
                                                        : null}
                                                </CardContent>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} lg={6}>
                                    <Card className={classes.card}>
                                        <CardContent className={classes.nestedCard}>
                                            <BasicCardContent
                                                headerText="Konti delt med mig"
                                                bottomTextFunction={setOpenAddTeacherModal}
                                                bottomTextBold={`${(fetchHomeworks.result && fetchHomeworks.result.length) || ''}`}
                                                iconName="AccountBox" />
                                            <Grid item direction="column" className={classes.secondaryCard}>
                                                <CardContent>
                                                    {teachersSharedWithMe?.length
                                                        ? teachersSharedWithMe.map((classItem: TeacherType) => (
                                                            <ClassListItem
                                                                key={classItem.id}
                                                                boldText={classItem.email}
                                                                onClick={() => {
                                                                    setOpenIamSharedWithTeachers(false)
                                                                    setId(classItem.id)
                                                                    setEmail(classItem.email)
                                                                    setDeleteModeSharedTeacher(true)
                                                                    setOpenAddTeacherModal(true)
                                                                }} />
                                                        ))
                                                        : null}
                                                </CardContent>
                                            </Grid>
                                        </CardContent>
                                    </Card>
                                </Grid>

                                <Grid item xs={12} sm={6} lg={3}>
                                    {fetchSubscriptionData?.result?.dateOfRegistration && <Simplecard headerText="Medlem Siden" bottomText={fetchSubscriptionData.result.dateOfRegistration} iconName="LibraryBooksIcon" />}
                                </Grid>
                                <Grid item xs={12} sm={6} lg={3}>
                                    {fetchSubscriptionData?.result?.remainingDays && <Simplecard headerText="Udløber om" bottomText={fetchSubscriptionData.result.remainingDays} iconName="ComputerIcon" />}
                                </Grid>
                            </Grid>
                            <Grid container item spacing={6} />
                            <ReactPlayer
                                style={{ marginTop: 120, zIndex: 9, position: 'relative', }}
                                url="https://player.vimeo.com/video/780103668"
                                width="100%"
                                controls />
                        </Grid><BottomBar onlyPicture />
                    </>
                )}
            </ThemeProvider>
            <AddOtherTeachersModal
                id={id}
                iAmSharedWith={openIamSharedWithTeachers}
                email={email}
                setEmail={setEmail}
                addFunction={addFunction}
                removeFunction={removeFunction}
                openModal={openAddTeacherModal}
                setOpenModal={setOpenModal}
                deleteMode={deleteModeSharedTeacher} />
        </BodyWithTopBar>
    )
}
