import CheckIcon from '@material-ui/icons/Check'
import React, { useEffect } from 'react'

import { TextInputTemplate } from './TextInputTemplate'

type Props = {
    onChange: (vimeoVideo: string) => void,
    value: string,
    isDisabled?: boolean
}

export const YoutubeVideoInput: React.FC<Props> = ({
    isDisabled, value, onChange
}) => {
    const [state, setState] = React.useState<'unknown' | 'valid' | 'invalid'>('unknown')

    useEffect(() => {
        if (value) validVideoId(value).then()
        else setState('invalid')
    }, [value])

    async function validVideoId(id: string) {
        const regex = /^[A-Za-z0-9_-]{11}$/
        if (!regex.test(id)) setState('invalid')
        else setState('valid')
        
        // const url = `http://img.youtube.com/vi/${id}/mqdefault.jpg`
        // const { status } = await fetch(url)
        // if (status === 404) setState('invalid')
        // else setState('valid')
    }

    let underText = 'Video ID kan findes på Youtube'
    if (state === 'invalid') {
        underText = 'Id -videoen er ugyldig'
    } else if (state === 'valid') {
        underText = ''
    }

    return (
        <div style={{
            display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center'
        }}>

            <TextInputTemplate
                fullWidth
                underText={underText}
                label="Youtube Video ID"
                disabled={isDisabled}
                value={value}
                error={state === 'invalid'}
                onChange={onChange}
                type="default" />
            {state === 'valid' && (
                <CheckIcon style={{ color: 'green' }} />
            )}
        </div>
    )
}
