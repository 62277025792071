import {
    Paper, TableContainer, Typography
} from '@material-ui/core'
import * as React from 'react'

import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import { useTableStyles } from '../../utils/styles'

export const PrivacyPolicy: React.FC = () => {
    const tableClasses = useTableStyles()
    const [loading] = React.useState<boolean>(false)

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <TableContainer className={tableClasses.table} component={Paper}>
                <Typography
                    variant="h5"
                    style={{ textAlign: 'center', fontWeight: 500 }}>
                    PRIVACY POLICY
                </Typography>
                <p>
                    Geekster respects the privacy of our users (“user” or “you”). This Privacy Policy explains
                    how we collect, use, disclose, and safeguard your information when
                    you visit our mobile application (“Math or Splat”) (the “Application”).
                </p>
                <p>Please read this Privacy Policy carefully.</p>
                <Typography
                    variant="h6"
                    style={{ fontWeight: 700 }}>
                    IF YOU DO NOT AGREE WITH THE TERMS OF THIS PRIVACY POLICY, PLEASE DO
                    NOT ACCESS OUR APPLICATIONS.
                </Typography>
                <p>
                    We reserve the right to make changes to this Privacy Policy at any time and for any reason.
                    We will alert you about any changes by updating the “Last updated” date of this
                    Privacy Policy. You are encouraged to periodically review this Privacy Policy to stay
                    informed of updates. You will be deemed to have been made aware of,
                    will be subject to, and will be deemed to have accepted the changes
                    in any revised Privacy Policy by your continued use of the Application after the date such
                    revised Privacy Policy is posted.
                </p>
                <p><strong>COLLECTION OF YOUR INFORMATION</strong></p>
                <p>
                    We may collect information about you in a variety of ways. The information we may collect
                    via the Application depends on the content and materials you use, and includes:
                </p>
                <p><strong>Personal Data</strong></p>
                <p>
                    Demographic and other personally identifiable information (such as your name and email
                    address) that you voluntarily give to us when choosing to participate in various activities
                    related to the Application, such as chat, posting messages in comment sections or in our
                    forums, liking posts, sending feedback, and responding to surveys. If you choose to share
                    data about yourself via your profile, online chat, or other interactive areas of the
                    Application, please be advised that all data you disclose in these areas is public and
                    your data will be accessible to anyone who accesses the Application.
                </p>
                <p><strong>Mobile Device Access</strong></p>

                <p>
                    We may request access or permission to certain features from your mobile device, including
                    your mobile device’s [bluetooth, calendar, camera, contacts, microphone, reminders, sensors,
                    SMS messages, social media accounts, storage,] and other features. If you wish to change
                    our access or permissions, you may do so in your device’s settings.
                </p>
                <p><strong>Mobile Device Data</strong></p>

                <p>
                    Device information such as your mobile device ID number, model, and manufacturer, version
                    of your operating system, phone number, country, location, and any other data you
                    choose to provide.
                </p>

                <p><strong>Push Notifications</strong></p>

                <p>
                    We may request to send you push notifications regarding your account or the Application.
                    If you wish to opt-out from receiving these types of communications,
                    you may turn them off in your device’s settings.

                </p>

                <p><strong>Third-Party Data</strong></p>

                <p>
                    Information from third parties, such as personal information or network friends, if you
                    connect your account to the third party and grant the Application permission to access
                    this information.

                </p>

                <p><strong>Data From Contests, Giveaways, and Surveys</strong></p>
                <p>
                    Personal and other information you may provide when entering contests or giveaways
                    and/or responding to surveys.

                </p>

                <p><strong>TRACKING TECHNOLOGIES</strong></p>

                <p>
                    We may use cookies, web beacons, tracking pixels, and other tracking technologies on the
                    Application to help customise the Application and improve your experience. When you access
                    the Application, your personal information is not collected through the use of tracking
                    technology. Most browsers are set to accept cookies by default. You can remove or reject
                    cookies, but be aware that such action could affect the availability and functionality of
                    the Application. You may not decline web beacons. However, they can be rendered
                    ineffective by declining all cookies or by modifying your web browser’s settings to notify
                    you each time a cookie is tendered, permitting you to accept or decline cookies on an
                    individual basis.
                </p>
                <p><strong>THIRD-PARTY WEBSITES</strong></p>
                <p>
                    The Application may contain links to third-party websites and applications of interest,
                    including advertisements and external services, that are not affiliated with us. Once you
                    have used these links to leave the Application, any information you provide to these third
                    parties is not covered by this Privacy Policy, and we cannot guarantee the safety and
                    privacy of your information. Before visiting and providing any information to any
                    third-party websites, you should inform yourself of the privacy policies and practices
                    (if any) of the third party responsible for that website, and should take those steps
                    necessary to, in your discretion, protect the privacy of your information. We are not
                    responsible for the content or privacy and security practices and policies of any third
                    parties, including other sites, services or applications that may be linked to or from
                    the Application.
                </p>
                <p><strong>CONTACT US</strong></p>
                <p>If you have questions or comments about this Privacy Policy, please contact us at
                    jesperalbinus@geekster.dk.
                </p>
            </TableContainer>
            <BottomBar onlyPicture />
        </>
    )
}
