/* eslint-disable jsx-a11y/anchor-is-valid */
import {
    Button, createStyles, Grid, makeStyles, Paper, TableContainer, Typography
} from '@material-ui/core'
import * as React from 'react'
import { useHistory } from 'react-router-dom'

import { useTableStyles } from '../../utils/styles'

const useStyles = makeStyles(() => createStyles({
    topDiv: {
        display: 'flex',
        justifyContent: 'center',
        flexDirection: 'column',
    },
    smallerGrids: {
        display: 'flex',
        justifyContent: 'center'
    }
}))

interface Props {
    setCreateNew: (openCreateScreen: boolean) => void,
    setAfterCreate: (openAfterCreateScreen: boolean) => void,
}

const AfterCreateAssignmentScreen: React.FC<Props> = ({
    setCreateNew,
    setAfterCreate
}) => {
    const tableClasses = useTableStyles()
    const history = useHistory()
    const classes = useStyles()

    const handleCreateNewClick = () => {
        setAfterCreate(false)
        setCreateNew(true)
    }
    const handleVideoClick = () => {
        history.push('/videobibliotek')
    }
    const handleQuizClick = () => {
        history.push('/quizbibliotek')
    }
    return (
        <>
            <TableContainer className={tableClasses.table} component={Paper}>
                <div className={classes.topDiv}>
                    <Typography variant="h6" style={{ textAlign: 'center' }}>Mine lektier</Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        style={{ minWidth: '70%', marginTop: 15 }}
                        onClick={handleCreateNewClick}>
                        Opret en ny lektie
                    </Button>
                </div>
                <Grid container style={{ marginTop: '3.5vh' }}>
                    <Grid item xs={12} sm={6} className={classes.smallerGrids}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ minWidth: '50%' }}
                            onClick={handleQuizClick}>
                            Tilføj quiz
                        </Button>
                    </Grid>
                    <Grid item xs={12} sm={6} className={classes.smallerGrids}>
                        <Button
                            variant="contained"
                            color="primary"
                            style={{ minWidth: '50%' }}
                            onClick={handleVideoClick}>
                            Tilføj video
                        </Button>
                    </Grid>
                </Grid>
            </TableContainer>
        </>
    )
}
export default AfterCreateAssignmentScreen
