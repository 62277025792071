import { 
    Button, createStyles, makeStyles, Table, TableBody, TableCell, TableHead, TableRow, Typography
} from '@material-ui/core'
import LocalMallIcon from '@material-ui/icons/LocalMall'
import * as React from 'react'

import { ClassType } from '../../types/types'
import { useTeacher } from '../../utils/TeacherManager'

const useStyles = makeStyles(() => createStyles({
    text: {
        textAlign: 'center'
    },
    tableHeader: {
        display: 'flex',
        alignItems: 'center',
        marginRight: 'auto',
        marginLeft: 16
    },
    gridContainer: {
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column' 
    }
}),)

interface Props {
    homeworkClasses: ClassType[],
    onDelete: (id: string) => void
    onUpdate: (id: string) => void
}
const HomeworkClassTable: React.FC<Props> = ({ homeworkClasses, onDelete, onUpdate }) => {
    const { me } = useTeacher()
    const classes = useStyles()
    return (
        <>
            <div className={classes.tableHeader}>
                <LocalMallIcon />
                <Typography style={{ marginLeft: 8 }} variant="h6">Klasser</Typography>
            </div>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Klasse navn</TableCell>
                        <TableCell align="right" />
                    </TableRow>
                </TableHead>
                <TableBody>
                    {homeworkClasses.map((item) => (
                        <TableRow key={item.id}>
                            <TableCell><b>{item.title}</b></TableCell>
                            <TableCell align="right">
                                <Button
                                    size="small"
                                    color="secondary"
                                    onClick={() => {
                                        onUpdate(item.id)
                                    }}
                                    disabled={me}>
                                    Rédiger
                                </Button>
                                <Button
                                    size="small"
                                    color="secondary"
                                    onClick={() => onDelete(item.id)}
                                    disabled={me}>
                                    Fjern
                                </Button>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}
export default HomeworkClassTable
