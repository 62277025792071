/* eslint-disable react/no-array-index-key */
/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable react/jsx-filename-extension */
/* eslint-disable no-shadow */
import React, { useEffect, useRef, useState } from 'react'

import styles from '../styles/puzzleEditor.module.css'
import {
    findSideByConnectId,
    generateNewPuzzle,
} from '../utils/puzzleData'
import DoubleMath from './dubleMathEdit'
import MathExpression from './mathExpression'
import MathKeys from './mathKeys'
import PuzzlePiece from './puzzlePiece'
import BackSvg from './svg/backSvg'
import LoadingSvgAnim from './svg/loadingSvgAnim'

export default function PuzzleEditor({
    puzzleBoxes,
    setPuzzleBoxes,
    handleEditEquation,
    nextToConfirm,
    openCreatePuzzle,
    selectedSize,
    handleSaveUnFinish,
    saving,
}) {
    const [editPopup, setEditPopup] = useState(null)
    const [canNext, setCanNext] = useState(null)
    const [LHS, setLHS] = useState(null)
    const [RHS, setRHS] = useState(null)
    const [wrongValue, setWrongValue] = useState(null)
    const [savePopup, setSavePopup] = useState(null)
    const [activeField, setActiveField] = useState(null)
    const LHSRef = useRef()
    const RHSRef = useRef()
    const wrongValueRef = useRef()

    useEffect(() => {
        if (puzzleBoxes.length <= 0) {
            const newPuzzle = generateNewPuzzle(selectedSize)
            setPuzzleBoxes(newPuzzle)
        }
    }, [selectedSize])

    useEffect(() => {
        if (editPopup) {
            if (editPopup > 1000) {
                const data = findSideByConnectId(editPopup, puzzleBoxes)
                setWrongValue(data[0].equation)
            } else {
                const data = findSideByConnectId(editPopup, puzzleBoxes)
                data.forEach((dataPoint) => {
                    if (dataPoint.type === 'l') {
                        setLHS(dataPoint.equation)
                    } else if (dataPoint.type === 't') {
                        setLHS(dataPoint.equation)
                    } else if (dataPoint.type === 'r') {
                        setRHS(dataPoint.equation)
                    } else if (dataPoint.type === 'b') {
                        setRHS(dataPoint.equation)
                    }
                })
            }
        }
    }, [editPopup])

    useEffect(() => {
        const checkCanSave = () => {
            const can = puzzleBoxes.every((box) => {
                if (!box.left.equation) {
                    return false
                } else if (!box.right.equation) {
                    return false
                } else if (!box.top.equation) {
                    return false
                } else if (!box.bottom.equation) {
                    return false
                }
                return true
            })
            // if (can) {
            //   console.log({ "This one 👉": puzzleBoxes });
            // }
            setCanNext(can)
        }

        if (puzzleBoxes && puzzleBoxes.length > 0) {
            checkCanSave()
        }
    }, [puzzleBoxes])

    const handleSubmit = (e) => {
        e.preventDefault()
        const { LHS, RHS, wrongValue } = Object.fromEntries(new FormData(e.target))
        if (wrongValue) {
            handleEditEquation(editPopup, wrongValue, undefined, true)
        } else {
            handleEditEquation(editPopup, LHS, RHS)
        }
        setEditPopup(null)
        setLHS(null)
        setRHS(null)
    }

    const handleLhsChange = (e) => {
        const { value } = e.target
        const newStr = value.replace(/\s+/g, '\\>')
        setLHS(newStr)
    }

    const handleRhsChange = (e) => {
        const { value } = e.target
        const newStr = value.replace(/\s+/g, '\\>')
        setRHS(newStr)
    }

    const handleWrongChange = (e) => {
        const { value } = e.target
        const newStr = value.replace(/\s+/g, '\\>')
        setWrongValue(newStr)
    }

    const closePopup = () => {
        setEditPopup(null)
    }

    const popupClick = (e) => {
        e.stopPropagation()
    }

    const handleBackButton = () => {
        setSavePopup(true)
    }

    const closeSavePopup = () => {
        setSavePopup(false)
    }

    const addMathKey = (key) => {
        if (activeField === 'LHS') {
            setLHS((old) => old + key)
            LHSRef.current.focus()
        } else if (activeField === 'RHS') {
            setRHS((old) => old + key)
            RHSRef.current.focus()
        } else if (activeField === 'wrongValue') {
            setWrongValue((old) => old + key)
            wrongValueRef.current.focus()
        }
    }

    return (
        <div className={styles.container}>
            <div
                className={`${styles.gridContainer} ${
                    selectedSize === '4x4' && `${styles.gridContainer4}`
                }`}>
                {puzzleBoxes.map((box, index) => (
                    <PuzzlePiece
                        key={`boxId-${index}`}
                        piceData={box}
                        setEditPopup={setEditPopup}/>
                ))}
            </div>
            <button
                type="button"
                className={`${styles.nextBtn} ${canNext || `${styles.disabled}`}`}
                onClick={nextToConfirm}
                disabled={!canNext}>
                næste <BackSvg />
            </button>
            <button type="button" className={styles.backBtn} onClick={handleBackButton}>
                <BackSvg /> tilbage
            </button>
            {editPopup && (
                <div onClick={closePopup} className={styles.editPopupBg}>
                    <form
                        onClick={popupClick}
                        onSubmit={handleSubmit}
                        className={styles.editPopup}>
                        {' '}
                        {editPopup < 1000 ? (
                            <>
                                <input
                                    type="text"
                                    name="RHS"
                                    className={styles.input}
                                    placeholder="Skriv opgave..."
                                    value={RHS || ''}
                                    onChange={handleRhsChange}
                                    onFocus={() => setActiveField('RHS')}
                                    autoFocus
                                    ref={RHSRef}/>
                                <div className={styles.output}>
                                    <h4>Visning: </h4>
                                    <MathExpression expression={RHS} />
                                </div>

                                <input
                                    type="text"
                                    name="LHS"
                                    className={styles.input}
                                    placeholder="skriv svaret..."
                                    value={LHS || ''}
                                    onFocus={() => setActiveField('LHS')}
                                    onChange={handleLhsChange}
                                    ref={LHSRef}/>
                                <div className={styles.output}>
                                    <h4>Visning: </h4>
                                    <MathExpression expression={LHS} />
                                </div>
                            </>
                        ) : (
                            <>
                                <input
                                    type="text"
                                    name="wrongValue"
                                    className={styles.input}
                                    placeholder="skriv noget der ikke bruges på andre brikker..."
                                    value={wrongValue || ''}
                                    onChange={handleWrongChange}
                                    onFocus={() => setActiveField('wrongValue')}
                                    ref={wrongValueRef}
                                    autoFocus/>
                                <div className={styles.output}>
                                    <h4>Visning: </h4>
                                    <MathExpression expression={wrongValue} />
                                </div>
                            </>
                        )}
                        <div className={styles.keysCont}>
                            <MathKeys addMathKey={addMathKey} expression={RHS} />
                            <DoubleMath addMathKey={addMathKey} />
                        </div>
                        <button type="submit" className={styles.buttons}>
                            Gem
                        </button>
                    </form>
                </div>
            )}
            {savePopup && (
                <div
                    className={styles.editPopupBg}
                    onClick={closeSavePopup}
                    disabled={saving}>
                    <div className={styles.savePopup} onClick={popupClick}>
                        <button
                            type="button"
                            className={styles.closePopupBtn}
                            onClick={closeSavePopup}
                            disabled={saving}>
                            X
                        </button>
                        <h1>Sikker på at du vil gå tilbage uden at gemme?</h1>
                        <div className={styles.savePopupControls}>
                            <button
                                type="button"
                                className={styles.saveButtons}
                                onClick={handleSaveUnFinish}
                                disabled={saving}>
                                {saving ? (
                                    <>
                                        <LoadingSvgAnim /> Gemmer
                                    </>
                                ) : (
                                    'gem'
                                )}
                            </button>
                            <button
                                type="button"
                                className={styles.saveButtons}
                                onClick={() => openCreatePuzzle(true)}
                                disabled={saving}>
                                gem ikke
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    )
}
