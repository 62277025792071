import {
    Button, Modal, Table, TableBody, TableCell, TableHead, TableRow,
    Typography
} from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import DoneIcon from '@material-ui/icons/Done'
import React, { forwardRef } from 'react'
import ReactToPrint from 'react-to-print'

import { buttonStyles } from '../../styles/ButtonsStyles'
import { answerModalStyles, getModalStyle, modalStyles } from '../../styles/ModalStyles'
import { CommunityQuizType, HomeworkResultType, StudentType } from '../../types/types'
import { getHomeworkResults, getHomeworkStudents } from '../../utils/Services/homework-service'
import { fireWrap } from '../../utils/wrap-functions'

type Props = {
    headerText?: string
    isOpen: boolean
    onClose?: () => void,
    selectedId: string,
    homeworkQuizzes: CommunityQuizType[],
}

type AnswersTableProps = {
    answers: HomeworkResultType[],
    homeworkQuiz: CommunityQuizType,
    showAnswers: boolean,
    students: StudentType[][]
}

const formatAttempts = (attempts: number | undefined) => {
    if (attempts && attempts >= 5) {
        return (
            <p style={{ backgroundColor: 'red', padding: '5px 10px', fontWeight: 'bolder' }}> 5+</p>
        )
    } else if (attempts && attempts >= 4) {
        return (
            <p
                style={{ backgroundColor: 'orange', padding: '5px 10px', fontWeight: 'bolder' }}> {attempts.toString()}
            </p>
        )
    } else if (attempts && attempts >= 3) {
        return (
            <p style={{ backgroundColor: 'yellow', padding: '5px 10px', fontWeight: 'bolder' }}>
                {attempts.toString()}
            </p>
        )
    } else if (attempts && attempts >= 2) {
        return (
            <p style={{ backgroundColor: 'beige', padding: '5px 10px', fontWeight: 'bolder' }}>
                {attempts.toString()}
            </p>
        )
    } else if (attempts && attempts >= 1) {
        return (
            <p style={{ backgroundColor: '#83e883', padding: '5px 10px', fontWeight: 'bolder' }}>
                {attempts.toString()}
            </p>
        )
    } else if (attempts && attempts === 0) {
        return (
            <p style={{ backgroundColor: 'transparent', padding: '5px 10px', fontWeight: 'bolder' }}>
                {attempts.toString()}
            </p>
        )
    } else {
        return (attempts || 0).toString()
    }
}

const AnswersTable = forwardRef<HTMLDivElement, AnswersTableProps>(
    ({
        answers, homeworkQuiz, showAnswers, students
    }, ref) => {
        const answerModalClasses = answerModalStyles()
        return (
            <div ref={ref}>
                <Typography
                    className={answerModalClasses.title}
                    variant="h4">
                    {homeworkQuiz.title}
                </Typography>
                <Table aria-label="simple table" style={{ border: '2px solid #000' }}>
                    <TableHead>
                        <TableRow style={{ backgroundColor: '#00e7fd66' }}>
                            <TableCell style={{ backgroundColor: '#00c0fd90' }} />
                            {homeworkQuiz.questions && homeworkQuiz.questions.map((quiz, index) => (
                                <TableCell key={quiz.title + quiz.explanation} style={{ border: '2px solid #000' }}>
                                    <b>Opgave {index + 1}</b>
                                </TableCell>
                            ))}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {students.map((studentClass) => studentClass.sort(
                            (a, b) => `${a.firstName} ${a.lastName}`.localeCompare(b.username)
                        ).map((student) => (
                            <TableRow style={{ border: '2px solid #000' }} key={`${student.id}+${homeworkQuiz.id}`}>
                                <TableCell style={{ backgroundColor: '#00c0fd90' }}>
                                    <b>{student.firstName} {student.lastName}</b>
                                </TableCell>

                                {homeworkQuiz.questions && homeworkQuiz.questions.map((quiz, index) => {
                                    return (
                                        <TableCell
                                            key={quiz.title + quiz.explanation}
                                            style={{ border: '2px solid #000' }}>
                                            {answers.map((answer) => (
                                                answer.student.id === student.id && answer.answers[index] && (
                                                    <div key={answer.student.id} className={answerModalClasses.answer1}>
                                                        {answer.answers[index].correct
                                                            ? <DoneIcon style={{ color: 'green' }} />
                                                            : <CloseIcon style={{ color: 'red' }} />}
                                                        {showAnswers && (
                                                            <Typography component="span">
                                                                {answer.answers[index].answer}<br />
                                                                {answer.answers[index].sharedUrl && (
                                                                    <a
                                                                        target="_blank"
                                                                        rel="noreferrer"
                                                                        href={answer.answers[index].sharedUrl}>
                                                                        Delt fil
                                                                    </a>
                                                                )}
                                                                <div
                                                                    style={{
                                                                        display: 'flex',
                                                                        justifyContent: 'flex-start',
                                                                        alignItems: 'center'
                                                                    }}>
                                                                    <span style={{ paddingRight: 5, fontWeight: 500 }}>
                                                                        Antal Forsøg:
                                                                    </span>
                                                                    {formatAttempts(answer.answers[index].attempts)}
                                                                </div>
                                                            </Typography>
                                                        )}
                                                    </div>
                                                )
                                            ))}
                                        </TableCell>
                                    )
                                })}
                            </TableRow>
                        )))}
                    </TableBody>
                </Table>
            </div>
        )
    }
)

export const AnswersModal: React.FC<Props> = ({
    isOpen,
    headerText,
    onClose,
    homeworkQuizzes,
    selectedId
}) => {
    const classes = modalStyles()
    const buttonClasses = buttonStyles()

    const [students, setStudents] = React.useState<StudentType[][]>([])
    const [answers, setAnswers] = React.useState<HomeworkResultType[]>([])

    const [showAnswers, setShowAnswers] = React.useState<boolean>(false)
    const [modalStyle] = React.useState(getModalStyle)
    const answerModalClasses = answerModalStyles()
    const answersTableRef = React.useRef(null)
    const [timeInterval, setTimeInterval] = React.useState(0)

    setTimeout(() => {
        setTimeInterval(timeInterval + 1)
    }, 5000)

    React.useEffect(() => {
        // TODO: should the wrap be loading?
        fireWrap(async () => {
            setAnswers(await getHomeworkResults(selectedId))
            setStudents(await getHomeworkStudents(selectedId))
        })
    }, [timeInterval, selectedId])

    return (
        <Modal
            aria-labelledby="simple-modal-title"
            aria-describedby="simple-modal-description"
            open={isOpen}
            onClose={onClose}>
            <div style={modalStyle} className={classes.FullPage}>
                <Button className={buttonClasses.close} type="button" onClick={onClose}>x</Button>
                <ReactToPrint
                    trigger={() => (
                        <Button
                            className={buttonClasses.download}
                            variant="contained"
                            onClick={() => window.print()}>
                            Download resultater
                        </Button>
                    )}
                    content={() => answersTableRef.current} />
                <Button
                    variant="contained"
                    onClick={() => setShowAnswers(!showAnswers)}>
                    {showAnswers ? 'Skjul ' : 'Vis '}svar
                </Button>
                <Typography
                    className={answerModalClasses.title}
                    variant="h4">{headerText}
                </Typography>
                {homeworkQuizzes.map((quiz, index) => {
                    const allOccurrences = answers.filter((answer) => answer.quizId === quiz.id)
                    return (
                        <AnswersTable
                            key={quiz.id}
                            students={students}
                            showAnswers={showAnswers}
                            ref={answersTableRef}
                            answers={allOccurrences}
                            homeworkQuiz={homeworkQuizzes[index]} />
                    )
                })}
            </div>
        </Modal>
    )

}
