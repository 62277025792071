import {
    Table, TableBody, TableCell, TableHead, TableRow 
} from '@material-ui/core'
import * as React from 'react'

interface Props {
    headerNames?: string[],
}

const BasicTable: React.FC<Props> = ({ children, headerNames }) => {

    return (
        <Table aria-label="simple table">
            <TableHead>
                <TableRow>
                    {headerNames?.map((headerName) => (
                        <TableCell key={headerName}>{headerName}</TableCell>
                    ))}
                </TableRow>
            </TableHead>
            <TableBody>
                {children}
            </TableBody>
        </Table>

    )
}
export default BasicTable
