import {
    Collapse, createStyles, Grid, Link, makeStyles, Typography
} from '@material-ui/core'
import * as React from 'react'
import ReactPlayer from 'react-player'
import { useHistory } from 'react-router-dom'

import { HomeworkType, VideoType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { updateHomeworkVideos } from '../../utils/Services/homework-service'
import { useLibraryStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'
import { wrap } from '../../utils/wrap-functions'
import LibraryTextProperties from '../atoms/LibraryTextProperties'
import SelectWithButton from '../atoms/SelectWithButton'

interface Props {
    item: VideoType,
    setItemAdded: (successful: boolean) => void
}

const useStyles = makeStyles(() => createStyles({
    generalTags: {
        marginBottom: 10,
        marginTop: 10,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-evenly'
    },
}))

const VideoListItem: React.FC<Props> = ({ item, setItemAdded }) => {
    const libraryClasses = useLibraryStyles()
    const classes = useStyles()
    const { fetchHomeworks } = useTeacher()
    const { result, error, loading }:
        { result: HomeworkType[], error: Error, loading: boolean } = fetchHomeworks

    const [open, setOpen] = React.useState<boolean>(false)
    const [homeworks, setHomeWorks] = React.useState<HomeworkType[]>([])
    const [busy, setBusy] = React.useState<boolean>(false)

    const history = useHistory()

    React.useEffect(() => {
        if (result) {
            const filteredHomeworks = result.filter((homework) => homework.quizzes.length <= 0)
            setHomeWorks(filteredHomeworks)
        }
    }, [result])

    const addToLesson = async (homework: HomeworkType | undefined) => {
        if (homework) {
            await wrap(async () => {
                const videos = [...homework.videos, item.id] || [item.id]
                setItemAdded(true)

                await updateHomeworkVideos(homework.id, videos)
            }, {
                operation: 'Adding to Lesson',
                stateFunc: setBusy
            })
        } else {
            history.push('/ny-lektier', { videoId: item.id, name: item.title })
        }
    }

    if (error) {
        showMessage(error.message)
    }
    const { isTeacherLikeAParent } = useTeacher()

    return (
        <div className={libraryClasses.container}>
            <Grid
                className={open ? libraryClasses.secondGrid : libraryClasses.firstGrid}
                onClick={() => setOpen(!open)}
                container>
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <img
                            style={{
                                maxWidth: '100%', paddingRight: 10
                            }}
                            src={item.thumbnailUri}
                            alt="Video" />

                    </div>
                </Grid>
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <LibraryTextProperties headerText="Titel" smallerText={item.title} />
                    <LibraryTextProperties headerText="Kategori" smallerText={item.categories.join(', ')} />
                </Grid>
                <Grid item xs={12} sm={4} className={classes.generalTags}>
                    <LibraryTextProperties headerText="Beskrivelse" smallerText={item.description} />
                    <LibraryTextProperties
                        headerText="Klassetrin"
                        smallerText={item.grades?.join(', ') || 'Ingen'} />
                </Grid>
            </Grid>
            <Grid container>
                <Collapse className={libraryClasses.collapse} in={open} timeout="auto" unmountOnExit>
                    <div className={libraryClasses.videoContainer}>
                        <ReactPlayer
                            url={`https://player.vimeo.com/video/${item.vimeoVideoId}`}
                            width="100%"
                            controls />
                    </div>
                    <Grid container>
                        <Grid item xs={12} sm={4} className={libraryClasses.container}>
                            <Typography variant="h6">Opgave-fil</Typography>
                            <Typography>
                                <Link
                                    target="_blank"
                                    href={item?.mainFile?.uri}>
                                    {item.mainFile?.name}
                                </Link>
                            </Typography>
                            {item.resultsFile?.uri && (
                                <div>
                                    <Typography variant="h6">Facit-fil</Typography>
                                    <Typography>
                                        <Link
                                            target="_blank"
                                            href={item?.resultsFile?.uri}>
                                            {item.resultsFile?.name}
                                        </Link>
                                    </Typography>
                                </div>
                            )}

                            {item.supplementUri?.map((l) => (
                                <Link
                                    key={l}
                                    target="_blank"
                                    href={l}
                                    rel="noreferrer"> Link til Mattips opgaver om emnet
                                </Link>
                            ))}
                        </Grid>
                        {!isTeacherLikeAParent && (
                            <Grid item xs={12} sm={8} className={libraryClasses.container}>
                                <SelectWithButton
                                    loading={loading || busy}
                                    homeworks={homeworks}
                                    onSubmit={addToLesson} />
                            </Grid>
                        )}
                    </Grid>
                </Collapse>
            </Grid>
        </div>
    )
}
export default VideoListItem
