import {
    Button,
    createStyles, FormGroup, Grid, makeStyles, Typography 
} from '@material-ui/core'
import * as React from 'react'
import { DragDropContext, Droppable } from 'react-beautiful-dnd'

import { FileType, Question } from '../../types/types'
import { uploadFile } from '../../utils/Services/file-service'
import DraggableQuestions from '../atoms/DraggableQuestions'
import TaCCheck from '../atoms/TaCCheck'
import { FileUpload } from './FileUpload'
import { TextInputTemplate } from './TextInputTemplate'

interface Props {
    questions: Question[],
    setQuestions: (questions: Question[]) => void,
    description: string,
    setDescription: (description: string) => void,
    setModalOpen: (open: boolean) => void,
    file: FileType | undefined,
    setFile: (file: FileType) => void,
    acceptedTaC: boolean,
    setAcceptedTaC: (acceptedTaC: boolean) => void,
    justUpdateQuestions: boolean
}

const useStyles = makeStyles(() => createStyles({
    addButton: {
        margin: '0 auto', minWidth: '25%', marginTop: '4vh', marginBottom: '6vh' 
    }
}),)

export const SecondCreateCommunityQuizStep: React.FC<Props> = ({
    questions, setQuestions, description, setDescription, setModalOpen, file, setFile,
    acceptedTaC, setAcceptedTaC, justUpdateQuestions
}) => {
    const classes = useStyles()

    const onDragEnd = (result: any) => {
        const { destination, source } = result
        if (!destination) return
        if (destination.droppableId === source.droppableId 
            && destination.index === source.index) return
        const newQuestions = [...questions]
        const questionToBeMoved = newQuestions[source.index]
        newQuestions.splice(source.index, 1)
        newQuestions.splice(destination.index, 0, questionToBeMoved)
        setQuestions(newQuestions)
    }
    return (
        <FormGroup>
            {!justUpdateQuestions && (
                <TextInputTemplate
                    value={description}
                    onChange={setDescription}
                    label="Quiz beskrivelse" />
            )}
            {questions.length > 0 && (
                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="secondScreen">
                        {(provided) => (
                            <Grid 
                                container
                                style={{ margin: '3vh 0', flexDirection: 'column' }}
                                ref={provided.innerRef}
                                {...provided.droppableProps}>
                                <Typography>Spørgsmål</Typography>
                                <DraggableQuestions questions={questions} />
                                {provided.placeholder}
                            </Grid>
                        )}
                    </Droppable>
                </DragDropContext>
            ) }
            <Button 
                className={classes.addButton}
                variant="contained"
                onClick={() => setModalOpen(true)}
                startIcon={(
                    <svg xmlns="http://www.w3.org/2000/svg" height="36" viewBox="0 0 24 24" width="36">
                        <path d="M0 0h24v24H0z" fill="none"/>
                        <path d="M19 13h-6v6h-2v-6H5v-2h6V5h2v6h6v2z"/>
                    </svg>
                )}>
                Tilføj spørgsmål
            </Button>
            {!justUpdateQuestions && (
                <>
                    <FileUpload
                        file={file}
                        buttonText="Upload"
                        underText="Her kan du evt. uploade en pdf-fil til svar-ark."
                        uploadFunc={uploadFile}
                        allowedType="document"
                        onChange={setFile}/>
                    <TaCCheck acceptedTaC={acceptedTaC} setAcceptedTaC={setAcceptedTaC} />
                </>
            )}
        </FormGroup>
    )
}
