import {
    Button, Paper, TableCell, TableContainer, TableRow, ThemeProvider, Typography
} from '@material-ui/core'
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline'
import * as React from 'react'
import ReactPlayer from 'react-player'
import { useLocation } from 'react-router-dom'

import BasicTable from '../../components/molecules/BasicTable'
import { BottomBar } from '../../components/molecules/BottomBar'
import { Loading } from '../../components/molecules/Loading'
import FirstCreateCommunityQuizScreen from '../../components/organisms/FirstCreateCommunityQuizScreen'
import SecondCreateCommunityQuizScreen from '../../components/organisms/SecondCreateCommunityQuizScreen'
import UpdateCommunityQuizScreen from '../../components/organisms/UpdateCommunityQuizScreen'
import { BodyWithTopBar } from '../../components/wrappers/BodyWithTopBar'
import { buttonStyles } from '../../styles/ButtonsStyles'
import { CommunityQuizType } from '../../types/types'
import { showMessage } from '../../utils/notifications'
import { buttonTheme, useTableStyles } from '../../utils/styles'
import { useTeacher } from '../../utils/TeacherManager'

export const CommunityQuizzes: React.FC = () => {
    const buttonClasses = buttonStyles()
    const tableClasses = useTableStyles()

    const location: any = useLocation()

    const { fetchCommunityQuizzes } = useTeacher()
    const { result, error, loading }:
        { result: CommunityQuizType[], error: Error, loading: boolean } = fetchCommunityQuizzes

    const myparam = location.state ? location.state.quiz : undefined
    const [description, setDescription] = React.useState<string>('')
    const [selected, setSelected] = React.useState<string>(myparam)
    const [createNew, setCreateNew] = React.useState<boolean>(false)
    const [finishCreatingId, setFinishCreatingId] = React.useState<string>('')

    const onQuizUpdate = () => {
        // to update default quiz screen
        fetchCommunityQuizzes.execute()
    }
    const isSelected = (itemSelected: string) => {
        setSelected(itemSelected)
    }

    const renderCreateNewScreen = () => (
        <FirstCreateCommunityQuizScreen
            onQuizCreate={onQuizUpdate}
            setCreateNew={(boolean: boolean) => setCreateNew(boolean)}
            setFinishCreatingId={setFinishCreatingId} />
    )

    const renderFinishCreatingScreen = () => (
        <SecondCreateCommunityQuizScreen
            description={description}
            setDescription={setDescription}
            onQuizCreate={onQuizUpdate}
            justUpdateQuestions={false}
            setFinishCreatingId={setFinishCreatingId}
            createdQuizId={finishCreatingId} />
    )

    const handleTableRowClick = (quizId: string, quizStatus: string) => {
        if (quizStatus === 'unpublished') setFinishCreatingId(quizId)
        else setSelected(quizId)
    }

    const renderDefaultScreen = () => (
        <>
            <TableContainer className={tableClasses.table} component={Paper}>
                <Typography variant="h6" style={{ textAlign: 'center' }}>Mine quizzer</Typography>
                {result && result.length === 0
                    ? !loading
                    && (<Typography align="center">Ingen quizzer fundet.</Typography>)
                    : (
                        <BasicTable headerNames={[
                            'Navn', 'Beskrivelse', 'Kategori', 'Emner', 'Spørgsmål', 'Status', 'Udgives'
                        ]}>
                            {result && result.map((item) => (
                                <TableRow
                                    style={{ cursor: 'pointer' }}
                                    key={item.id}
                                    hover
                                    onClick={() => handleTableRowClick(item.id, item.status as string)}>
                                    <TableCell component="th" scope="row">
                                        <b>{item.title}</b>
                                    </TableCell>
                                    <TableCell component="th" scope="row"><b>{item.description}</b></TableCell>
                                    <TableCell component="th" scope="row">
                                        <b>{item.categories?.join(', ')}</b>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <b>{item.topics.join(', ')}</b>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <b>{item.questions?.length || 0}</b>
                                    </TableCell>
                                    <TableCell
                                        component="th"
                                        scope="row">
                                        <div style={{ alignItems: 'center', display: 'flex', flex: 1 }}>
                                            {item.status !== 'approved'
                                                && <ErrorOutlineIcon style={{ fill: 'red' }} />}
                                            {item.status === 'rejected' && <b>afvist</b>}
                                            {item.status === 'approved' && <b>godkendt</b>}
                                            {item.status === 'unpublished' && <b>ikke udgivet</b>}
                                        </div>
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                        <b>{item.isPublic ? 'offentlig' : 'privat'}</b>
                                    </TableCell>
                                </TableRow>
                            ))}
                        </BasicTable>
                    )}
            </TableContainer>
            <ThemeProvider theme={buttonTheme}>
                <Button
                    className={buttonClasses.leftButton}
                    variant="contained"
                    color="primary"
                    onClick={() => setCreateNew(true)}>
                    Opret ny quiz
                </Button>
            </ThemeProvider>
            <ReactPlayer
                style={{ zIndex: 9, position: 'relative', marginTop: 120 }}
                url="https://player.vimeo.com/video/684993212"
                width="100%"
                controls />
        </>
    )

    const renderUpdateScreen = () => {
        if (selected) {
            return (
                <UpdateCommunityQuizScreen
                    onQuizUpdate={onQuizUpdate}
                    selected={selected}
                    isSelected={isSelected}
                    setFinishCreatingId={setFinishCreatingId} />
            )
        }
        return <Loading />
    }
    const renderUpdateQuestionsScreen = () => (
        <SecondCreateCommunityQuizScreen
            description={description}
            setDescription={setDescription}
            onQuizCreate={onQuizUpdate}
            justUpdateQuestions
            setFinishCreatingId={setFinishCreatingId}
            createdQuizId={finishCreatingId} />
    )

    if (loading) {
        return <Loading />
    }
    if (error) {
        showMessage(error.message)
    }

    return (
        <BodyWithTopBar>
            {!createNew && !finishCreatingId && !selected && renderDefaultScreen()}
            {createNew && !finishCreatingId && !selected && renderCreateNewScreen()}
            {!createNew && !finishCreatingId && selected && renderUpdateScreen()}
            {!createNew && finishCreatingId && !selected && renderFinishCreatingScreen()}
            {!createNew && finishCreatingId && selected && renderUpdateQuestionsScreen()}
            <BottomBar onlyPicture />
        </BodyWithTopBar>
    )
}
