/* eslint-disable max-len */
/* eslint-disable react/jsx-filename-extension */
import React from 'react'

export default function CopySvg() {
    return (
        <svg
            width="10px"
            height="10px"
            fill="#000"
            viewBox="0 0 24 24"
            xmlns="http://www.w3.org/2000/svg">
            <path d="m8 7v1h-1.5c-0.82843 0-1.5 0.67157-1.5 1.5v9c0 0.82843 0.67157 1.5 1.5 1.5h7c0.82843 0 1.5-0.67157 1.5-1.5v-1.5h1v1.5c0 1.3807-1.1193 2.5-2.5 2.5h-7c-1.3807 0-2.5-1.1193-2.5-2.5v-9c0-1.3807 1.1193-2.5 2.5-2.5h1.5zm8-3h-5.5c-0.82843 0-1.5 0.67157-1.5 1.5v9c0 0.82843 0.67157 1.5 1.5 1.5h7c0.82843 0 1.5-0.67157 1.5-1.5v-7.5h-2.5c-0.27614 0-0.5-0.22386-0.5-0.5v-2.5zm4 2.528v7.972c0 1.3807-1.1193 2.5-2.5 2.5h-7c-1.3807 0-2.5-1.1193-2.5-2.5v-9c0-1.3807 1.1193-2.5 2.5-2.5h5.972c0.13275-0.0084195 0.27092 0.035839 0.38153 0.14645l3 3c0.11061 0.11061 0.15487 0.24878 0.14645 0.38153zm-3-0.52798h1.2929l-1.2929-1.2929v1.2929zm-5.5 7c-0.27614 0-0.5-0.22386-0.5-0.5s0.22386-0.5 0.5-0.5h2c0.27614 0 0.5 0.22386 0.5 0.5s-0.22386 0.5-0.5 0.5h-2zm0-2c-0.27614 0-0.5-0.22386-0.5-0.5s0.22386-0.5 0.5-0.5h5c0.27614 0 0.5 0.22386 0.5 0.5s-0.22386 0.5-0.5 0.5h-5zm0-2c-0.27614 0-0.5-0.22386-0.5-0.5s0.22386-0.5 0.5-0.5h5c0.27614 0 0.5 0.22386 0.5 0.5s-0.22386 0.5-0.5 0.5h-5z" />
        </svg>
    )
}
